
import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import PropTypes from "prop-types";

import './InfoModal.scss';

import { Text } from 'components/Text';
import { Icon, icons } from 'components/Icon';
import Button from 'components/Button';

class InfoModal extends Component {
    static class = 'modal-medium';

    render() {
        return (
            <div className="info-modal">
                <Text header className={this.props.title ? 'has-title' : ''}>
                    <Icon
                        className={`${this.props.iconBlue ? 'icon-blue' : ''} ${this.props.iconRed ? 'icon-red' : ''}`}
                        icon={this.props.icon} />
                    {this.props.title}
                </Text>
                <Text large className="description">{this.props.text}</Text>

                {this.props.confirm ? <Button onClick={() => this.props.closeAction(true)} green>{this.props.confirm}</Button> : ""}
                {this.props.cancel ? <Button onClick={() => this.props.closeAction()}>{this.props.cancel}</Button> : ""}
            </div>
        );
    }
}

InfoModal.propTypes = {
    title: PropTypes.any,
    text: PropTypes.any,
    icon: PropTypes.any,
    iconBlue: PropTypes.bool,
    iconRed: PropTypes.bool,
    confirm: PropTypes.any,
    cancel: PropTypes.any,
    closeAction: PropTypes.func,
}

export default view(InfoModal);


export function getInfoProps(title, text, confirm = '', cancel = 'ok', icon = icons.info, closeOnBg = true, iconRed = false, iconBlue = false) {
    return { title, text, confirm, cancel, icon, closeOnBg, iconRed, iconBlue }
}