import React, { Component } from 'react';
import { useLocation, useNavigate } from 'react-router';
import PropTypes from "prop-types";
import { store, view } from '@risingstack/react-easy-state';

import '../LoginScreen.scss';

import Container from 'components/Container';
import Icon, { icons } from 'components/Icon';
import Input from 'components/Input';
import Text from 'components/Text';
import Message from 'components/Message';
import Button, { ProgressButton } from 'components/Button';
import Checkbox from 'components/Checkbox';

import setFirstPassword from 'api/login/setFirstPassword';
import { forgotCode } from 'api/login/forgotCode';

import getLogoImg from 'utils/getLogoImg';
import { validateRequiredField } from 'utils/forms';
import layoutStore from 'storeUtils/layoutStore';
import { logout } from 'api/login/verifyLogin';

const ForgotCode = (props) => {
    let navigate = useNavigate();
    const { pathname } = useLocation();

    return <RawForgotCodeWithView navigate={navigate} pathname={pathname} setPassword={props?.setPassword} />
}

/**
 * This component is used in two ways:
 * - as normal /forgotPassword user flow
 * - as first time password generation after manager create account for another user /setPassword
 */
class RawForgotCode extends Component {
    static class = 'modal-small';

    constructor(props) {
        super(props)

        const queryParams = new URLSearchParams(window.location.search);
        const codeKey = queryParams.get('c')
        const userId = queryParams.get('u')

        this.ezState = store({
            newPassword: '',
            repeatNewPassword: '',
            confirmedWeakPassword: false,
            confirmWeakPasswordDialog: false,
            errors: {},
            forgot_code: codeKey ? codeKey : '',
            user_id: userId
        })

        const APIKey = localStorage.getItem('APIKey');
        if (APIKey) {
            logout();
        }
    }

    componentWillUnmount() {
        layoutStore.request = {}
    }

    resetState() {
        this.ezState.oldPassword = '';
        this.ezState.newPassword = '';
        this.ezState.repeatNewPassword = '';
        this.ezState.confirmedWeakPassword = false;
        this.ezState.confirmWeakPasswordDialog = false;
        this.ezState.errors = {};
        this.ezState.forgot_code = '';
        this.ezState.user_id = '';
    }

    validateAll() {
        let isValid = true

        if (!validateRequiredField({
            fieldName: 'forgot_code',
            value: this.ezState.forgot_code,
            validationErrorObject: this.ezState.errors
        })
        ) {
            isValid = false;
        }

        if (!this.validatePassword('newPassword')) {
            isValid = false;
        }

        if (!this.validatePassword('repeatNewPassword')) {
            isValid = false;
        }

        return isValid
    }

    testPasswordStrength(value) {
        const passwordRegexPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
        const isPasswordStrength = passwordRegexPattern.test(value);

        if (!isPasswordStrength) {
            this.ezState.errors.repeatNewPassword = 'Your password should contain at least six characters, at least one upper and lower case letter, one digit and special character'
            this.ezState.errors.newPassword = true

            this.ezState.confirmWeakPasswordDialog = true;
        }

        return isPasswordStrength
    }

    testPasswordMismatch(v1, v2) {
        const isPasswordMismatch = this.ezState[v1] === this.ezState[v2];

        if (!isPasswordMismatch) {
            this.ezState.errors.repeatNewPassword = 'Passwords do not match'
            this.ezState.errors.newPassword = ' '
        }

        return isPasswordMismatch;
    }

    validatePassword(fieldName, withMismatchCheck = true) {
        let isValid = validateRequiredField({
            fieldName: fieldName,
            value: this.ezState[fieldName],
            validationErrorObject: this.ezState.errors
        });

        if (isValid && withMismatchCheck) {
            isValid = this.testPasswordMismatch('newPassword', 'repeatNewPassword')
        }

        if (isValid && !this.testPasswordStrength(this.ezState.newPassword) && !this.ezState.confirmedWeakPassword) {
            isValid = false;
            this.ezState.confirmWeakPasswordDialog = true;
        } else if (isValid && this.ezState.confirmedWeakPassword) {
            this.ezState.errors.repeatNewPassword = ''
            this.ezState.errors.newPassword = ''
        }

        return isValid
    }

    _onKeyDown(e) {
        if (e.key === 'Enter') {
            this.onSubmit(e)
        }
    }

    onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        if (layoutStore.request.success || layoutStore.request.progress) return;

        if (this.validateAll()) {
            layoutStore.request.progress = true;

            if (this.props.setPassword) {
                try {
                    setFirstPassword(
                        this.ezState.forgot_code,
                        this.ezState.user_id,
                        this.ezState.newPassword,
                    ).then((data) => {
                        if (layoutStore.request.error) return false;

                        this.ezState.errors = {}
                        this.ezState.confirmedWeakPassword = false
                        this.ezState.confirmWeakPasswordDialog = false

                        layoutStore.request = {
                            progress: false,
                            error: data.request.error,
                            message: data.request.message,
                            success: data.request.success
                        }

                        this.resetState();

                    }).catch(() => {
                        layoutStore.request = {}
                    })
                } catch (error) {
                    layoutStore.request = {}
                }
            } else {
                try {
                    forgotCode(this.ezState.forgot_code,
                        this.ezState.user_id,
                        this.ezState.newPassword,
                    ).then((data) => {
                        if (!layoutStore.request.error) {
                            this.ezState.errors = {}
                            this.ezState.confirmedWeakPassword = false
                            this.ezState.confirmWeakPasswordDialog = false

                            layoutStore.request = {
                                progress: false,
                                error: data.request.error,
                                message: data.request.message,
                                success: data.request.success
                            }

                            this.resetState()
                        }
                    }).catch(() => {
                        layoutStore.request = {}
                    })
                } catch (error) {
                    layoutStore.request = {}
                }
            }

        }
    }

    render() {
        return (
            <div className="login forgot-form">
                <div className="login-panel">
                    <Container>
                        <div className="login-header cursor-pointer" onClick={() => this.props.navigate('/')}>
                            {getLogoImg()}
                        </div>
                        <Container fill>
                            <Text large>{this.props.setPassword ? 'Set Your Password' : 'Recovery Password'}</Text>
                            <form className={
                                "change-password-grid py-3 " +
                                ((layoutStore.request.message && !layoutStore.request.error) ? ' fadeout' : '')
                            }>
                                <Input
                                    placeholder={(!this.props.setPassword ? 'New ' : '') + 'Password*'}
                                    name="new-password"
                                    spaced
                                    leftIcon={icons.password}
                                    value={this.ezState.newPassword}
                                    onChange={(e) => {
                                        this.ezState.newPassword = e.target.value
                                        this.validateAll();
                                    }}
                                    onBlur={() => this.validatePassword('newPassword', false)}
                                    onSubmit={() => this.validatePassword('newPassword')}
                                    password
                                    autoComplete="new-password"
                                    hasError={this.ezState.errors.newPassword}
                                    customErrorText={this.ezState.errors.newPassword} />
                                <Input
                                    placeholder={'Repeat ' + (!this.props.setPassword ? 'New ' : '') + 'Password*'}
                                    name="repeat-new-password"
                                    spaced
                                    leftIcon={icons.password}
                                    value={this.ezState.repeatNewPassword}
                                    onChange={(e) => {
                                        this.ezState.repeatNewPassword = e.target.value
                                        this.validateAll();
                                    }}
                                    onBlur={() => this.validateAll()}
                                    password
                                    autoComplete="repeat-new-password"
                                    hasError={this.ezState.errors.repeatNewPassword}
                                    customErrorText={this.ezState.errors.repeatNewPassword} />
                                {this.ezState.confirmWeakPasswordDialog && <div className="checkbox cursor-pointer" onClick={() => {
                                    this.ezState.confirmedWeakPassword = !this.ezState.confirmedWeakPassword
                                    this.validateAll()
                                }} >
                                    <Checkbox checked={this.ezState.confirmedWeakPassword} />
                                    <span className="checkbox-text">This password is weaker than recommended. Use anyway.</span>
                                </div>}
                            </form>
                            <Message className="resist-fadeout pb-2" request={layoutStore.request} />
                            <div className="button-row">
                                {(!layoutStore.request.success) && <ProgressButton inProgress={layoutStore.request && layoutStore.request.progress} extraProps={{ right: true, disabled: layoutStore.request.message && !layoutStore.request.error }} onClick={(e) => this.onSubmit(e)}>
                                    <Icon icon="fal fa-save" /> Save Password</ProgressButton>}
                                {(layoutStore.request.success) && <Button to="/" right green block>
                                    <Icon icon="fal fa-save" /> Go to the login page</Button>}
                            </div>
                            <div className="login-footer">
                            </div>
                        </Container>
                    </Container>
                </div>
            </div>
        );
    }
}

RawForgotCode.propTypes = {
    setPassword: PropTypes.bool,
    pathname: PropTypes.string.isRequired,
    navigate: PropTypes.func.isRequired,
};

const RawForgotCodeWithView = view(RawForgotCode);

export default view(ForgotCode);
