import React from 'react';

const calendarIcon = () => (
    <svg viewBox="0 0 59.143 60">
        <path d="M44.294,12.663c2.299,0,4.162-1.85,4.162-4.154V4.162C48.456,1.856,46.595,0,44.294,0
    s-4.16,1.854-4.16,4.162v4.349C40.136,10.813,41.994,12.663,44.294,12.663z"/>
        <path d="M59.079,7.199h-8.377v2.116c0,3.492-2.837,6.338-6.336,6.338c-3.503,0-6.334-2.845-6.334-6.338
    V7.199H21.127v2.116c0,3.492-2.841,6.338-6.338,6.338s-6.336-2.843-6.336-6.338V7.199L0,7.094V60h4.228h50.693l4.222-0.012
    L59.079,7.199z M54.917,55.787H4.228V21.988h50.693v33.798H54.917z"/>
        <path d="M14.723,12.663c2.299,0,4.164-1.85,4.164-4.154V4.162C18.887,1.854,17.023,0,14.723,0
    s-4.158,1.854-4.158,4.162v4.349C10.566,10.813,12.422,12.663,14.723,12.663z"/>
        <rect x="21.139" y="26.523" width="6.657" height="5.876" />
        <rect x="31.903" y="26.523" width="6.663" height="5.876" />
        <rect x="41.885" y="26.523" width="6.657" height="5.876" />
        <rect x="21.139" y="36.281" width="6.657" height="5.872" />
        <rect x="31.903" y="36.281" width="6.663" height="5.872" />
        <rect x="41.885" y="36.281" width="6.657" height="5.872" />
        <rect x="21.139" y="46.233" width="6.657" height="5.872" />
        <rect x="10.566" y="36.281" width="6.653" height="5.872" />
        <rect x="10.566" y="46.233" width="6.653" height="5.872" />
        <rect x="31.903" y="46.233" width="6.663" height="5.872" />
        <rect x="41.885" y="46.233" width="6.657" height="5.872" />
    </svg>
)

export default calendarIcon;