import React from 'react';
import { view } from '@risingstack/react-easy-state';

import './IosPwaInstallPrompt.scss'

import { iOSCanInstall, iOSIsInstalled, isIOSDevice, isSafari } from 'utils/pwaUtils';

import Icon, { FA, icons } from 'components/Icon';
import Text from 'components/Text';
import layoutStore from 'storeUtils/layoutStore';

export default view((props) => {
    layoutStore.iosPwaInfoHidden = JSON.parse(sessionStorage.getItem('iosPwaInfoHidden'))
    const isAllowed = (isIOSDevice && iOSCanInstall && isSafari && !iOSIsInstalled);

    if (!isAllowed || layoutStore.iosPwaInfoHidden) return null;

    return <div className="ios-pwa-install-prompt" onClick={() => {
        layoutStore.iosPwaInfoHidden = true;
        sessionStorage.setItem('iosPwaInfoHidden', JSON.stringify(true))
    }} >
        <FA icon="fal fa-times-circle" />
        <Text>Install this webapp on your iPhone: tap <Icon className="share-outline" icon={icons.shareOutline} /> and then Add to Home Screen.</Text>
    </div>
});