import { store } from '@risingstack/react-easy-state';
import moment from 'moment';
import prepareStoreData from 'utils/prepareStoreData';
import { newNutritionObject } from 'subsystems/nutrition/nutrition.js';

/**
 * @typedef RangeType
 * @type {object}
 * @property {string} id
 * @property {string} from - YYYY-MM-DD
 * @property {string} to - YYYY-MM-DD
 * @property {string} className - ugabuga
 * @example {from: 1, to: 2}
 */

const defaults = {
    currentMeal: 'Breakfast',
    currentUser: {},

    // application state
    APIKey: undefined,

    presets: [],

    availableProfiles: [],

    patternWeek: {},

    mainSections: {},

    // data dump for user profile operations
    temporaryProfile: {},

    // user profile
    profile: {
        id: 0,
        name: '',
        surname: '',
        age: 0,
        gender: '',
        pregnancy: 0,
        lactating: 0,
        weight: 0,
        height: 0,

        preset: 0,
        restrictions: {},
        extra: {},

        zip: '',
        groceryStore: '',
        pharmacy: '',
        extraPharmacy: '',

        // wellness
        targetDate: '',
        targetWeight: 0,
        targetWeightPerWeek: 0,

        activityLevel: {
            general: 0,
            specific: {
                0: 0,
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
            }
        }
    },

    // those little white boxes with text in the corner
    notifications: [],

    // data for chart bar in /home
    chartData: [],
    showLegend: true,
    showFakeChart: false,

    // data for /home and /mymenu
    menu: [],

    // meals for current week
    weeklyData: [],
    backupWeeklyData: [],

    // current day
    selectedDay: null,

    // nutrition for current day
    menuNutrition: newNutritionObject(),

    // system wide portion types
    portionTypes: [],

    // system wide restrictions
    restrictions: [],

    // recent recipe searches and clicks
    recentResults: [],

    memberStatistics: null,

    foodLog: {
        month: moment().get('month'),
        year: moment().get('year'),
        selectedDate: '',
        data: [],
        availableDates: {},
        specialDays: [],
        menuDateMap: {},
        selectedDateMenu: []
    },

    debugDataEnabled: false,
    debugData: [],
    debugDataIndex: 0,
}

const storeData = prepareStoreData({ ...defaults })

const mainStore = store(storeData);

export default mainStore;
export { mainStore, defaults };
