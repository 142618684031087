import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';

import "./Privacy.scss"

import { Text } from 'components/Text';

import layoutStore from 'storeUtils/layoutStore';

class Privacy extends Component {
  constructor(props) {
    super(props);

    layoutStore.pageCaption = 'Privacy';
  }

  static class = 'modal-medium';

  render() {
    return (
      <div className="privacy">
        <Text header fullWidth className="text-center mt-2 mb-3"><strong>PRIVACY POLICY</strong></Text>
        <h3 className="text-center mb-2">By using the NutriStyle Services, you agree to our privacy policy.</h3>
        <p className="mb-2">At NutriStyle, we are committed to providing you with the highest quality nutritional information in an
          environment of privacy and security. This Privacy Policy will tell you what information is collected
          about you as you use our Services and our NutriStyle web application (together the “Services”) and
          how we protect that information. It will also explain what choices you have about how your
          information is used. Please read this Privacy Policy carefully so that you understand how your
          information is collected, protected, and used. By using the NutriStyle Services, you agree to the
          terms of this Privacy Policy.</p>
        <p className="mb-2">NutriStyle may provide public forums such as chat rooms, blogs, online discussions, or bulletin
          boards for use by its members. Please be advised that any information that you disclose in such
          locations is not protected by this Privacy Policy. Also, if you access a third party Services from a link
          in the NutriStyle Services, any information you disclose at that third party Services is not protected
          by this Privacy Policy.</p>

        <h3 className="text-center mb-2">INFORMATION COLLECTED ABOUT YOU</h3>
        <p className="mb-2">Registration. Certain information is required as part of the registration process, including name,
          email address, zip code, and date of birth. This is called personally identifiable information (PII).
          When you use our Services, NutriStyle collects only that PII that you provide to us. When PII is
          combined with information on your health or medical status, it is referred to as “personal health
          information.”</p>
        <p className="mb-2">Cookies. As part of offering its Services, NutriStyle uses cookies. A cookie is a small amount of
          information passed between a web Services or web application and the web browser on your
          computer. A cookie helps your browser remember specific information that the web server can later
          retrieve. NutriStyle uses cookies to store session information when you log in.</p>
        <h3 className="text-center mb-2">CHILDREN</h3>
        <p className="mb-2">NutriStyle is committed to protecting the privacy of children. The Services are neither designed for
          nor intended to attract children under the age of 18. The parent or guardian of any child who is using
          the Services is responsible for creating and maintaining the child’s Profile.</p>
        <h3 className="text-center mb-2">HOW DOES NUTRISTYLE USE YOUR PERSONAL INFORMATION?</h3>
        <p className="mb-2">NutriStyle may provide to third parties non-personal information about you that does not allow you to
          be identified or contacted and that is combined with the non-personal information of others
          (aggregated information). For example, NutriStyle might inform a third party about the percentage of
          its users that engage in regular exercise or that are vegetarians. NutriStyle may charge third parties
          for such information. NutriStyle does not restrict how third parties use such aggregated information,
          except that it requires third parties to agree that they will not attempt to combine the aggregated
          information with other information to identify or attempt to identify specific individuals.</p>
        <h3 className="text-center mb-2">DISCLOSURE OF YOUR INFORMATION</h3>
        <p className="mb-2">Except as set forth in this Privacy Policy, or as specifically agreed to by you, NutriStyle will not
          disclose any personal information it gathers from you while using our Services. In certain
          circumstances, NutriStyle may be required to disclose personal information to comply with valid legal
          requirements, such as governmental regulations, search warrants, subpoenas, or court orders or in
          an emergency situation. The only entities that will have access to your personal health information
          are NutriStyle and any third parties hired by NutriStyle to assist us in operating, maintaining, and
          improving NutriStyle. These third parties are required by us to maintain the confidentiality of your
          information and may not use it in any manner except to fulfill their responsibilities to NutriStyle.</p>

        <h3 className="text-center mb-2">HOW NUTRISTYLE PROTECTS YOUR PERSONAL INFORMATION</h3>
        <p className="mb-2">NutriStyle uses passwords to authenticate users before granting access to personal information, and uses role-based
          security to ensure that only authorized persons can access information on our servers. We also use firewalls to
          protect information held in our servers.</p>
        <p className="mb-2">NutriStyle uses sophisticated technology to minimize the potential risk that hackers may find a way to thwart our
          security system. As you know, however, there is always some risk that an unauthorized third party may find a way to
          override our security system or that transmissions over the Internet will be intercepted.</p>
        <p className="mb-2">A limited number of NutriStyle employees are authorized to access your personal information. All NutriStyle
          employees must abide by this Privacy Policy and those who violate it are subject to disciplinary action, up to and
          including termination.</p>

        <h3 className="text-center mb-2">YOUR PRIVACY CHOICES</h3>
        <p className="mb-2">From time to time, you may be given the option of receiving informational emails or newsletters regarding a
          particular health or wellness issue, or relevant product information that affects you or in which you have expressed
          an interest. If you wish to receive these, you will be given the opportunity to opt in. You can unsubscribe from
          these services at any time. You may also be given the opportunity to opt in to receive emails or newsletters from
          third parties or to participate in marketing surveys and other activities. You may unsubscribe from these services
          at any time.</p>

        <h3 className="text-center mb-2">UPDATING OR CHANGING YOUR INFORMATION</h3>
        <p className="mb-2">You may at any time correct, update, or review information you previously submitted to NutriStyle. If you wish to
          remove information from our databases previously provided to NutriStyle, please contact us at 
          <a href="mailto://info@nutristyle.com">info@nutristyle.com</a>.
        </p>
        <p className="mb-2">NutriStyle backs up your data to prevent inadvertent loss. This means that your personal information may exist in
          backup media. It is not possible to remove each and every record of the information that you have provided to
          NutriStyle. Upon receiving your request, all personal information stored in active databases and other readily
          searchable media will be removed so that you cannot be identified or associated with it.</p>
        <h3 className="text-center mb-2">CONTACTING NUTRISTYLE</h3>
        <p className="mb-2">If you have a complaint or problem regarding privacy, please contact us by email at
            <a href="mailto://info@nutristyle.com">info@nutristyle.com</a>.
            Our customer service staff will forward your email to the appropriate
          department for resolution.</p>
        <h3 className="text-center mb-2">CHANGES TO OUR PRIVACY POLICY</h3>
        <p className="mb-2">If we make significant changes to our Privacy Policy, we will notify you by email of the change and obtain your
          opt-in consent to any such change. We may also make non-significant changes that generally will not affect our use
          of your personal information. You generally will not be notified of such changes. We urge you to check this posted
          Privacy Policy periodically for any changes. If you do not agree to the terms of this Privacy Policy or any revised
          policy, you are not authorized to use the Services.</p>
      </div>
    );
  } 
}

export default view(Privacy);
