import layoutStore from 'storeUtils/layoutStore';
import { fetchJSON } from 'utils/fetchJSON';

export function registration(mail, password, invitation_code) {
    layoutStore.request.progress = true;
    layoutStore.request.message = '';
    layoutStore.request.error = false;
    return fetchJSON('/api/registration/', { mail, password, invitation_code }, false)
        .then((json) => {
            if (json?.request) {
                return {
                    success: json.request.success,
                    message: json.request.message,
                    progress: false
                }
            }
            if (json.success) {
                return {
                    success: json.success,
                    message: json.message,
                    progress: false
                }
            }
            return {
                error: true,
                message: json?.message ? json?.message : 'Server is currently offline.',
                progress: false
            }
        })
        .catch(error => {
            if (error?.request) {
                return {
                    error: error.request.error,
                    message: error.request.message,
                    progress: false
                }
            }
            return {
                error: true,
                message: error?.message ? error?.message : 'Server is currently offline.',
                progress: false
            }
        });
}