import React from 'react';
import PropTypes from 'prop-types';
import { view } from '@risingstack/react-easy-state';

import './Loading.scss'

import Logo from 'components/Logo';
import getLogoImg from 'utils/getLogoImg';

/**
 * @typedef LoadingProps
 * @type {object}
 * @property {boolean} withoutTransition remove transition (style)
 * @property {boolean} invisible make component invisible (style)
 * @property {boolean} invisibleDelay delay invisible state change (style)
 * @property {boolean} transparent set components background transparent
 * @property {boolean} white set components background white
 * @property {boolean} spinnerHidden
 * @property {boolean} logo
 * @property {string} logoClassName
 * @property {string} title default value "Loading..."
*/

/**
 * @param {LoadingProps} props
 */
const RawLoading = (props) => {
    return <div className={
        'loading ' +
        (props.withoutTransition ? 'without-transition ' : '') +
        (props.invisible ? 'invisible ' : 'visible ') +
        (props.invisibleDelay ? 'invisible-delay ' : '') +
        (props.transparent ? 'transparent ' : '') +
        (props.white ? 'white ' : '') +
        (props.spinnerHidden ? 'spinner-hidden ' : '')
    }>
        {!props.spinnerHidden ? <div>
            <div className="loading-knob loading-knob-bg">
                <i className="fas fa-cog fa-spin fa-spin-ease-other" />
            </div>
            <div className="loading-knob loading-knob-fg">
                <i className="fas fa-sync fa-spin fa-spin-ease" />
            </div>
        </div> : ''}
        {props.logo ? <div className="loading-logo">
            <Logo className={props.logoClassName ? props.logoClassName : ''}>{getLogoImg()}</Logo>
        </div> : ''}
        <div className="loading-knob loading-knob-text">
            {typeof (props.title) === 'string' ? props.title : "Loading..."}
        </div>
    </div>
}

RawLoading.propTypes = {
    withoutTransition: PropTypes.bool,
    invisible: PropTypes.bool,
    invisibleDelay: PropTypes.bool,
    transparent: PropTypes.bool,
    white: PropTypes.bool,
    spinnerHidden: PropTypes.bool,
    logo: PropTypes.bool,
    logoClassName: PropTypes.string,
    title: PropTypes.string,
};

/**
 * fullscreen Spinner Loading component with animation and logo
 * 
 * @component
 * @type {{ new(props: LoadingProps): {
 *   props: { 
 *     withoutTransition: boolean, 
 *     invisible: boolean, 
 *     invisibleDelay: boolean, 
 *     transparent: boolean,
 *     white: boolean,
 *     spinnerHidden: boolean,
 *     logo: boolean,
 *     logoClassName: string
 *     title: string
 *     }
 *   }
 *  }}
 */
const Loading = view(RawLoading)

export default Loading