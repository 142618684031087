import React from 'react';

const settingsIcon = () => (
    <svg viewBox="0 0 59.673 60">
        <path d="M21.09,1.232c-0.527,0.101-0.871,0.61-0.77,1.14l0.81,4.256c-3.479,1.308-6.622,3.383-9.198,6.073
		l-3.558-2.428c-0.223-0.152-0.483-0.199-0.727-0.152c-0.244,0.047-0.469,0.188-0.619,0.411l-3.607,5.345
		c-0.301,0.447-0.185,1.05,0.258,1.353l3.57,2.436C5.701,23.063,4.932,26.765,4.996,30.5l-4.209,0.81
		c-0.527,0.101-0.871,0.61-0.77,1.14l1.208,6.344c0.102,0.53,0.607,0.875,1.134,0.774l4.233-0.815c1.3,3.498,3.364,6.658,6.04,9.248
		l-2.415,3.578c-0.302,0.447-0.185,1.05,0.257,1.354l5.315,3.627c0.444,0.303,1.045,0.188,1.346-0.259l2.423-3.59
		c3.378,1.555,7.06,2.329,10.775,2.264l0.805,4.232c0.102,0.53,0.607,0.875,1.134,0.774l6.31-1.214
		c0.527-0.101,0.87-0.61,0.769-1.14l-0.81-4.256c3.479-1.308,6.622-3.383,9.198-6.073l3.558,2.428
		c0.444,0.303,1.044,0.188,1.346-0.259l3.607-5.344c0.302-0.447,0.185-1.05-0.257-1.353l-3.57-2.436
		c1.547-3.397,2.316-7.099,2.252-10.834l4.208-0.81c0.528-0.101,0.871-0.61,0.77-1.14l-1.208-6.344
		c-0.102-0.53-0.606-0.875-1.133-0.774l-4.233,0.815c-1.3-3.498-3.364-6.658-6.04-9.249l2.415-3.578
		c0.301-0.447,0.185-1.05-0.257-1.353l-5.316-3.627c-0.221-0.152-0.483-0.199-0.726-0.152c-0.244,0.047-0.469,0.188-0.619,0.411
		l-2.423,3.589c-3.378-1.555-7.061-2.329-10.776-2.264l-0.805-4.232c-0.102-0.53-0.607-0.875-1.134-0.774L21.09,1.232z
		 M27.261,16.473c3.568-0.687,7.261,0.08,10.267,2.131c3.006,2.051,5.078,5.219,5.761,8.807c1.422,7.471-3.448,14.686-10.878,16.116
		c-7.43,1.43-14.606-3.467-16.028-10.938c-0.683-3.588,0.079-7.301,2.119-10.323S23.693,17.16,27.261,16.473z"/>
    </svg>
)

export default settingsIcon;