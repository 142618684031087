import React from 'react';

const facebookIcon = () => (
    <svg viewBox="0 0 60 60">
        <path d="M30,0C13.432,0,0,13.432,0,29.999C0,46.568,13.432,60,30,60C46.568,60,60,46.568,60,29.999
		C60,13.432,46.568,0,30,0z M38.81,18.199c0,0-2.983,0-4.168,0c-1.472,0-1.778,0.602-1.778,2.125c0,1.266,0,3.677,0,3.677h5.945
		l-0.573,6.456h-5.372V49.74h-7.7V30.538h-4.006v-6.538h4.006c0,0,0-0.94,0-5.147c0-4.831,2.583-7.354,8.313-7.354
		c0.938,0,5.332,0,5.332,0V18.199z"/>
    </svg>
)

export default facebookIcon;