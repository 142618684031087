import React from 'react';

const editIcon = () => (
    <svg viewBox="0 0 59.514 60">
        <path d="M58.707,15.167L43.673,0.731c-1.046-1.003-2.707-0.97-3.711,0.075l-4.376,4.558L35.56,5.339
    L9.879,32.084l18.82,18.071l17.59-18.319l0.027,0.025l12.467-12.983C59.787,17.832,59.753,16.171,58.707,15.167z"/>
        <path d="M0.19,56.4c-0.216,0.537-0.257,1.149-0.074,1.745c0.425,1.385,1.891,2.163,3.276,1.739
    l21.909-6.793c0.065-0.017,0.129-0.037,0.193-0.059L7.091,35.362L0.19,56.4z"/>
    </svg>
)

export default editIcon;