import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import 'moment-timezone';

//main styles:
import 'styles/reset.scss';
import 'styles/ubuntuFont/stylesheet.scss';
import 'styles/base.scss';

import 'styles/fontAwesome/fa.scss';
import 'react-day-picker/lib/style.css';

// solution of resolve css build conflicts due to code-splitting
import 'components/Collapse/Collapse.scss';
import 'components/Select/Select.scss';
import 'components/InputSearch/InputSearch.scss';
import 'screens/AccountSetup/components/SettingsMenu/SettingsMenu.scss';

import registerServiceWorker from './registerServiceWorker.js';
import reportWebVitals from './reportWebVitals';
import App from './App';

import { getAppConfig } from 'getAppConfig.js';

import { fetchJSONSettings } from 'utils/fetchJSON.js';

import layoutStore from 'storeUtils/layoutStore.js';
import mainStore from 'storeUtils/mainStore.js';

import { verifyAPILogin } from 'api/login/verifyLogin.js';

registerServiceWorker();

const appConfig = getAppConfig(window.location.hostname);
layoutStore.config = appConfig;

const APIKey = localStorage.getItem('APIKey');
fetchJSONSettings.layoutStore = layoutStore;

if (APIKey) {
    const apiV = APIKey.split('|');
    mainStore.APIKey = apiV[1];
    layoutStore.initialLoading = true;
    verifyAPILogin(apiV[0], apiV[1]);
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// Webpack HRM Module
if (module.hot) {
    module.hot.accept();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
