import React from 'react';

const infoIcon = () => (
    <svg viewBox="0 0 60 60">
        <path d="M51.213,8.787C45.784,3.358,38.284,0,30,0C21.716,0,14.216,3.358,8.787,8.787S0,21.716,0,30
		c0,8.284,3.358,15.784,8.787,21.213C14.216,56.642,21.716,60,30,60c8.284,0,15.784-3.358,21.213-8.787
		C56.642,45.784,60,38.284,60,30C60,21.716,56.642,14.216,51.213,8.787z M34.738,46.316c0,1.304-0.533,2.488-1.391,3.347
		c-0.858,0.858-2.043,1.391-3.346,1.391c-1.304,0-2.488-0.533-3.347-1.391c-0.858-0.858-1.391-2.043-1.391-3.347V25.447
		c0-1.304,0.533-2.488,1.391-3.346c0.858-0.858,2.043-1.391,3.347-1.391c1.304,0,2.488,0.533,3.346,1.391
		c0.858,0.858,1.391,2.043,1.391,3.346V46.316z M33.441,17.254C32.561,18.135,31.344,18.68,30,18.68
		c-1.344,0-2.561-0.545-3.441-1.426c-0.881-0.881-1.426-2.097-1.426-3.441c0-1.344,0.545-2.56,1.426-3.441
		C27.439,9.492,28.656,8.947,30,8.947c1.344,0,2.56,0.545,3.441,1.425c0.881,0.881,1.425,2.097,1.425,3.441
		C34.866,15.157,34.322,16.374,33.441,17.254z"/>
    </svg>
)

export default infoIcon;