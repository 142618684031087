import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { view } from '@risingstack/react-easy-state';

// moved scss import to index.js due to css build conflict
// import './Select.scss'

import Label from 'components/Label';
import { isIOSDevice, isSafari } from 'utils/pwaUtils';

class SelectOptions extends Component {

    shouldComponentUpdate(nextProps) {
        if (nextProps.options !== this.props.options) return true;
        if (nextProps.optionLabels !== this.props.optionLabels) return true;
        return false;
    }

    render() {
        this.lastoptions = this.props.options;
        if (this.props.accessor) {
            return this.props.options.map((option) => {
                const opt = this.props.accessor(option);
                return (<option value={opt.key} key={opt.key}>{opt.value}</option>);
            });
        } else {
            return this.props.options.map((option, index) => {
                if (typeof (option) === 'object') {
                    return <option value={option.key} key={option.key}>{option.caption}</option>
                } else {
                    return <option value={option} key={option}>{this.props.optionLabels ? this.props.optionLabels[index] : option}</option>
                }
            });
        }
    }
}

SelectOptions.propTypes = {
    options: PropTypes.any,
    optionLabels: PropTypes.any,
    accessor: PropTypes.any,
}

const Select = (props) => {
    const isDisabled = props.disabled ? props.disabled : false;
    return (
        <label className={
            "select-wrapper " +
            (props.inline ? 'inline ' : '') +
            (props.small ? ' small ' : '') +
            (props.disabled ? 'disabled ' : '') +
            (props.hasError ? 'has-error ' : '') +
            (props.label ? 'label-relative ' : '')
        }>
            {props.label && <Label for={props.name} backText largeBackText={props.largeLabel} hasError={props.hasError}>
                {props.label}
            </Label>}
            <select className={
                ((props.apperance && !isSafari && !isIOSDevice) ? 'apperance ' : '') +
                (props.value ? 'has-value ' : '') +
                (props.className ? props.className : '')
            }
                onChange={props.onChange}
                value={props.value}
                required
                disabled={isDisabled}
            >
                {props.defaultOption ? <option value="" disabled hidden>{props.defaultOptionText ? props.defaultOptionText : 'Select your option'}</option> : ''}
                {isDisabled ? undefined : <SelectOptions disabled={isDisabled} options={props.options} accessor={props.accessor} optionLabels={props.optionLabels} />}
            </select>
            {props.hasError ? <div className="validation-error">
                {props.customErrorText ? props.customErrorText : ''}
            </div> : ''}
        </label>
    )
}

Select.propTypes = {
    name: PropTypes.any,
    largeLabel: PropTypes.any,
    inline: PropTypes.any,
    defaultOption: PropTypes.any,
    small: PropTypes.any,
    disabled: PropTypes.any,
    hasError: PropTypes.any,
    apperance: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.any,
    defaultOptionText: PropTypes.any,
    optionLabels: PropTypes.any,
    accessor: PropTypes.any,
    options: PropTypes.any,
    className: PropTypes.any,
    label: PropTypes.any,
    customErrorText: PropTypes.any,
}
export default view(Select);
