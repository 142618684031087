import React from 'react';

const youtubeIcon = () => (
    <svg viewBox="0 0 60 60">
        <path d="M29.999,0C13.432,0,0,13.431,0,30c0,16.568,13.432,30,29.999,30C46.568,60,60,46.568,60,30
    C60,13.431,46.568,0,29.999,0z M45.391,38.878c-0.377,0.811-0.994,1.385-1.777,1.794c-2.114,1.111-23.89,1.097-26.673,0.18
    c-1.241-0.408-2.075-1.222-2.525-2.453C13.324,35.392,13,22.819,15.104,20.343c0.719-0.846,1.645-1.342,2.747-1.461
    c5.671-0.611,23.2-0.523,25.219,0.205c1.183,0.425,2.023,1.2,2.479,2.385C46.745,24.578,46.787,35.883,45.391,38.878z"/>
        <path d="M25.949,34.958c3.41-1.769,6.791-3.521,10.213-5.297c-3.428-1.789-6.808-3.553-10.213-5.33
    C25.949,27.884,25.949,31.398,25.949,34.958z"/>
    </svg>
)

export default youtubeIcon;