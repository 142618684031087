function arrayToMultivalueMap(array, field){
  if(!Array.isArray(array)) return {};
  const result = {};
  for(let i = 0; i < array.length; i++){
    if(result[array[i][field]] === undefined) {
        result[array[i][field]] = [];
    }
    result[array[i][field]].push(array[i]);
  }
  return result;
}

export default arrayToMultivalueMap;