import React from 'react';

const walkingIcon = () => (
    <svg version="1.1" id="Isolation_Mode" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" viewBox="0 0 184.1 176.6">
        <g>
            <path d="M74.8,44.7c-4.7-0.4-11.7,4.9-17.5,12.6c-3.7,4.8-7.1,10.5-9.2,16.2c-2.2,5.9-3.2,11.8-1.8,16.7
		c1.5,5.6,5.8,9.7,14.1,11L74.8,44.7z"/>
            <path d="M140.1,76.6c-1.4-1.8-5-3-7.1-3.7c-1.8-0.6-3.5-1.3-5.3-2c-2.2-0.9-4.3-2.1-6.2-3.4c-5.5-3.8-9.5-9-12.5-14.9
		c0-0.1-0.1-0.2-0.2-0.3l-0.1-0.1c-3.2-3.7-7.5-6-12.2-7.2c-3.2-0.8-6.5-1-9.8-0.9c-2.5,0.1-4.9,0.3-7.3,0.6c-0.5,0.1-1,0.4-1.2,0.8
		l-15.9,61.8l-16.7,42l-0.1,0.1c-1.1,1.6-1.9,3.5-2.2,5.4c-0.6,3.2,0.4,6,2.7,8.3c0,0,0.1,0.1,0.2,0.1c1.5,1.3,3.3,2.2,5.3,2.7
		c3.7,0.8,7-0.5,9.8-2.9l0.1-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0.6-1,1.2-2,1.8-3c1.1-1.9,2.3-3.9,3.5-5.8c3.3-5.5,6.5-11,9.7-16.5
		c1.9-3.2,3.7-6.4,5.5-9.6c1.5,1.3,3.1,2.6,4.7,3.9c2.2,1.8,4.5,3.7,6.3,5.9l0.1,0.2l9.6,24.2l0,0.1c0.3,1.8,1.2,3.7,2.2,5.2
		c1.8,2.6,4.4,3.9,7.6,4.1c0,0,0.1,0,0.1,0l0.2,0c1.6,0,3.6-0.7,5-1.5c3.5-1.9,5.2-5.3,5.6-9.2c0-0.2,0-0.3,0-0.5l0,0
		c-0.2-0.5-0.4-1.1-0.6-1.6c-0.5-1.4-0.9-2.8-1.3-4.1c-1.3-4.6-2.4-9.2-3.2-13.9l-3.3-19c-0.1-0.3-0.2-0.6-0.4-0.9
		c-2.7-2.2-18.2-15.1-20-15.4l0.5-2.6l0,0c0,0,0-0.1,0-0.1c0.2-1.1,0.4-2.3,0.6-3.4c0.2-0.9,0.4-1.9,0.6-2.8c0.1-0.6,0.3-1.3,0.5-2
		c0,0,0-0.1,0.1-0.1l4.7-19.8l1.6,1c4.8,3.1,9.8,6.1,15,8.5c3.9,1.9,8.7,3.9,13,4.4c5.5,0.6,9.2-1,9.9-6.9
		C141.1,79.6,141.1,77.9,140.1,76.6z"/>
            <path d="M95.1,5.4c-4.7,0-8.9,1.9-11.9,4.9c-3,3-4.9,7.3-4.9,11.9c0,4.7,1.9,8.9,4.9,11.9c3,3,7.3,4.9,11.9,4.9
		c4.7,0,8.9-1.9,11.9-4.9c3-3,4.9-7.3,4.9-11.9c0-4.7-1.9-8.9-4.9-11.9C104,7.3,99.8,5.4,95.1,5.4z"/>
        </g>
    </svg>
)

export default walkingIcon;