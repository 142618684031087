import React from 'react';

const myMenuIcon = () => (
    <svg viewBox="0 0 60 54.106">
        <path d="M6.885,49.484c-1.008,1.004-1.008,2.635-0.007,3.639c1.01,1.006,2.639,1.006,3.645,0
    l16.053-16.053l-3.641-3.641L6.885,49.484z"/>
        <path d="M59.591,12.11c-0.533-0.539-1.407-0.539-1.951,0.002L46.337,23.418l-1.953-1.951l11.308-11.308
    c0.541-0.541,0.541-1.412,0.007-1.951c-0.546-0.541-1.414-0.539-1.953,0.002l-11.31,11.308l-1.955-1.955L51.79,6.26
    c0.539-0.544,0.539-1.416,0.004-1.955c-0.539-0.537-1.416-0.537-1.957,0.004l-11.303,11.31l-1.955-1.951L47.884,2.361
    c0.546-0.541,0.546-1.416,0.007-1.953c-0.539-0.544-1.414-0.544-1.955,0L34.238,12.106c-0.088,0.088-0.138,0.199-0.202,0.302
    c-3.555,3.985-3.582,8.086-1.429,11.428c-0.081,0.061-0.182,0.092-0.252,0.164l-2.689,2.687l3.641,3.641l2.689-2.687
    c0.075-0.072,0.103-0.169,0.167-0.25c3.334,2.146,7.437,2.12,11.426-1.433c0.107-0.055,0.215-0.112,0.305-0.199l11.694-11.696
    C60.137,13.524,60.137,12.647,59.591,12.11z"/>
        <path d="M22.299,22.414c-0.127-0.121-0.276-0.18-0.412-0.272c1.876-3.952,0.526-9.62-3.689-13.833
    C13.176,3.283,6.044,2.264,2.276,6.03C-1.492,9.8-0.477,16.932,4.547,21.958c4.215,4.215,9.881,5.567,13.833,3.691
    c0.092,0.136,0.156,0.285,0.276,0.405l27.295,27.298c1.008,1.004,2.632,1.006,3.643,0c1.006-1.004,1.002-2.632-0.002-3.639
    L22.299,22.414z"/>
    </svg>
)

export default myMenuIcon;