import { store } from '@risingstack/react-easy-state';
import prepareStoreData from 'utils/prepareStoreData';

const defaults = {
    pageCaption: '',
    helpVideoLink: '',
    contentClassName: '', // addidional css className string for app base content div 

    // application state
    loggedIn: false,

    // loading
    bigLoading: false,
    request: {
        message: '',
        success: false,
        error: false
    },

    initialLoading: false,
    insideSubmission: false,

    isAdmin: false,
    selectUserProfileLoading: false,

    // wizard (first login)
    profileWizard: {
        currentStep: 0,
        steps: {
            0: {},
            1: {},
            2: {},
            3: {},
            4: {},
        },
        validation: {},
        errors: {}
    },

    config: {
        type: '',
        logo: {
            alt: '',
            src: '',
            width: '0',
            height: '0',
        }
    },

    routerBlocked: false,
    routerBlockedModalFunction: undefined
}

const storeData = prepareStoreData(defaults)

const layoutStore = store(storeData);


/* ======================================== STORE UTILS ======================================== */

function capitalize(string) {
    if (typeof (string) === 'string' && string?.length > 0) {
        return string[0].toUpperCase() + string.slice(1);
    }
    return '';
}

function fixCapitalizeName(name) {
    if (typeof (name) === 'string') {
        return name.split(' ').map(word => capitalize(word)).join(' ')
    }
    return '';
}

function removeDefaultBrand(brand) {
    if (typeof (brand) === 'string' && brand.toLocaleLowerCase() === 'generic') {
        return '';
    }
    return brand;
}

function resetLayoutStoreToDefaults() {
    const newDefaults = JSON.parse(JSON.stringify(defaults));
    for (let i in newDefaults) {
        if (i !== 'config') {
            layoutStore[i] = newDefaults[i]
        }
    }
}

export {
    layoutStore,
    fixCapitalizeName,
    removeDefaultBrand,
    resetLayoutStoreToDefaults
};
export default layoutStore;
