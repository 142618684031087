import React from 'react';
import { view } from '@risingstack/react-easy-state';
import PropTypes from 'prop-types';

import './Message.scss';


/**
 * @typedef MessageProps
 * @type {object}
 * @property {boolean} textCenter
 * @property {boolean} textLeft
 * @property {boolean} preLine
 * @property {string} className
 * @property {object} request {message, error}
 * @property {boolean} extra
 * @property {object} children
*/

const RawMessage = props => {
    if (props.request && props.request.message) {
        return (
            <div className={
                'clear validation message-component ' +
                (props.request.error ? 'error ' : '') +
                (props.textLeft ? 'text-left ' : '') +
                (props.textCenter ? 'text-center ' : '') +
                (props.preLine ? 'pre-line ' : '') +
                (props.className ? props.className : '')
            }>
                {props.label}
                {props.request.message}
                {props.extra && props.children}
            </div>
        )
    }
    return null;
}

RawMessage.propTypes = {
    textCenter: PropTypes.bool,
    textLeft: PropTypes.bool,
    preLine: PropTypes.bool,
    className: PropTypes.string,
    extra: PropTypes.bool,
    request: PropTypes.any,
    children: PropTypes.any
};
/**
* Component that show request information
* 
* @component
* @param {CollapseProps} props
* @type {{ new(props: CollapseProps): {
    *   props: { 
        *     textCenter: boolean,
        *     textLeft: boolean,
        *     preLine: boolean,
        *     className: string,
        *     extra: boolean,
        *     request: any,
        *     children: any,
        *   }
        *  }
        * }}
        */
const Message = view(RawMessage)
export default Message;
