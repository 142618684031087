import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { view } from '@risingstack/react-easy-state';

import './Button.scss'

/**
 * @typedef ButtonProps
 * @type {object}
 * @property {boolean} block
 * @property {boolean} green
 * @property {boolean} red
 * @property {boolean} blue
 * @property {boolean} brandNew
 * @property {boolean} yellow
 * @property {boolean} gold
 * @property {boolean} inverted inverts base color class
 * @property {boolean} clear
 * @property {boolean} bordered
 * @property {boolean} small
 * @property {boolean} big
 * @property {boolean} fill
 * @property {boolean} left float left
 * @property {boolean} right float right
 * @property {boolean} spaceAround margin 0.5
 * @property {boolean} iconButton
 * @property {boolean} inputHeight
 * @property {boolean} largeHeight line-height 2rem
 * @property {boolean} nolrpadding
 * @property {boolean} transformNormal
 * @property {boolean} transparent background-color
 * @property {boolean} disabled
 * @property {boolean} disableFocusOutline
 * @property {boolean} noShadow
 * @property {boolean} hasError
 * @property {string} to react-dom route path
 * @property {string} type type='submit'
 * @property {string} className
 * @property {string} exact
 * @property {object} style react css style object
 * @property {any} children React.Element
 * @property {number} tabIndex
 * @property {funtion} onClick
*/

/**
 * @param {ButtonProps} props
 */
const RawButton = (props) => props.to ?
    <NavLink to={props.to} className={
        'navlink-button ' +
        (props.block ? 'block ' : '') +
        (props.green ? 'green ' : '') +
        (props.red ? 'red ' : '') +
        (props.blue ? 'blue ' : '') +
        (props.brandNew ? 'brand-new ' : '') +
        (props.yellow ? 'yellow ' : '') +
        (props.gold ? 'gold ' : '') +
        (props.inverted ? 'inverted ' : '') +
        (props.clear ? 'clear ' : '') +
        (props.bordered ? 'bordered ' : '') +
        (props.small ? 'small ' : '') +
        (props.big ? 'big ' : '') +
        (props.fill ? 'fill ' : '') +
        (props.left ? 'left ' : '') +
        (props.right ? 'right ' : '') +
        (props.spaceAround ? 'spacearound ' : '') +
        (props.iconButton ? 'icon-button ' : '') +
        (props.inputHeight ? 'inputHeight ' : '') +
        (props.largeHeight ? 'largeHeight ' : '') +
        (props.nolrpadding ? 'nolrpadding ' : '') +
        (props.transformNormal ? 'transform-normal ' : '') +
        (props.transparent ? 'transparent ' : '') +
        (props.disabled ? 'disabled ' : '') +
        (props.disableFocusOutline ? 'no-focus-outline ' : '') +
        (props.noShadow ? 'no-shadow ' : '') +
        (props.hasError ? 'has-error ' : '') +
        (props.className ? props.className : '')
    } exact={props.exact} style={props.style} {...props.tabIndex} type={props.type}>
        {props.children}
    </NavLink>
    :
    <button className={
        (props.block ? 'block ' : '') +
        (props.green ? 'green ' : '') +
        (props.blue ? 'blue ' : '') +
        (props.red ? 'red ' : '') +
        (props.brandNew ? 'brand-new ' : '') +
        (props.gold ? 'gold ' : '') +
        (props.yellow ? 'yellow ' : '') +
        (props.inverted ? 'inverted ' : '') +
        (props.clear ? 'clear ' : '') +
        (props.bordered ? 'bordered ' : '') +
        (props.small ? 'small ' : '') +
        (props.left ? 'left ' : '') +
        (props.right ? 'right ' : '') +
        (props.big ? 'big ' : '') +
        (props.disabled ? 'disabled ' : '') +
        (props.disableFocusOutline ? 'no-focus-outline ' : '') +
        (props.fill ? 'fill ' : '') +
        (props.spaceAround ? 'spacearound ' : '') +
        (props.iconButton ? 'icon-button ' : '') +
        (props.inputHeight ? 'inputHeight ' : '') +
        (props.largeHeight ? 'largeHeight ' : '') +
        (props.nolrpadding ? 'nolrpadding ' : '') +
        (props.transformNormal ? 'transform-normal ' : '') +
        (props.transparent ? 'transparent ' : '') +
        (props.noShadow ? 'no-shadow ' : '') +
        (props.hasError ? 'has-error ' : '') +
        (props.className ? props.className : '')
    } onClick={props.onClick} style={props.style} type={props.type}
    >
        {props.children}
    </button>


RawButton.propTypes = {
    block: PropTypes.bool,
    green: PropTypes.bool,
    red: PropTypes.bool,
    blue: PropTypes.bool,
    brandNew: PropTypes.bool,
    yellow: PropTypes.bool,
    gold: PropTypes.bool,
    inverted: PropTypes.bool,
    clear: PropTypes.bool,
    bordered: PropTypes.bool,
    small: PropTypes.bool,
    big: PropTypes.bool,
    fill: PropTypes.bool,
    left: PropTypes.bool,
    right: PropTypes.bool,
    spaceAround: PropTypes.bool,
    iconButton: PropTypes.bool,
    inputHeight: PropTypes.bool,
    largeHeight: PropTypes.bool,
    nolrpadding: PropTypes.bool,
    transformNormal: PropTypes.bool,
    transparent: PropTypes.bool,
    disabled: PropTypes.bool,
    disableFocusOutline: PropTypes.bool,
    noShadow: PropTypes.bool,
    hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    to: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    exact: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    children: PropTypes.any,
    tabIndex: PropTypes.number
};

/**
 * @component
 * @type {{ new(props: ButtonProps): {
 *   props: { 
 *     block: boolean,
 *     green: boolean,
 *     red: boolean,
 *     blue: boolean,
 *     brandNew: boolean,
 *     yellow: boolean,
 *     gold: boolean,
 *     inverted: boolean,
 *     clear: boolean,
 *     bordered: boolean,
 *     small: boolean,
 *     big: boolean,
 *     fill: boolean,
 *     left: boolean,
 *     right: boolean,
 *     spaceAround: boolean,
 *     iconButton: boolean,
 *     inputHeight: boolean,
 *     largeHeight: boolean,
 *     nolrpadding: boolean,
 *     transformNormal: boolean,
 *     transparent: boolean,
 *     disabled: boolean,
 *     disableFocusOutline: boolean,
 *     noShadow: boolean,
 *     hasError: boolean,
 *     to: string,
 *     type: string,
 *     className: string,
 *     exact: string,
 *     style: Object,
 *     onClick: Function,
 *     children: any
 *    }
 *  }
 * }}
 */
const Button = view(RawButton);
export default Button;