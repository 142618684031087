import React from 'react';

const helpIcon = () => (
    <svg viewBox="0 0 60 60">
        <path d="M30,0C13.5,0,0,13.5,0,30c0,16.5,13.5,30,30,30c16.5,0,30-13.5,30-30C60,13.5,46.5,0,30,0z
		 M33.35,47.169c0,0.306-0.248,0.554-0.554,0.554H27.41c-0.306,0-0.554-0.248-0.554-0.554v-5.387c0-0.306,0.248-0.554,0.554-0.554
		h5.387c0.306,0,0.554,0.248,0.554,0.554V47.169z M42.911,25.332c-0.226,0.756-0.565,1.492-1.014,2.205l-0.467-0.294l0.469,0.295
		c-0.005,0.008-0.01,0.015-0.015,0.022c-0.446,0.696-1.132,1.512-2.061,2.446c-0.911,0.916-2.064,1.955-3.462,3.116
		c-0.694,0.579-1.258,1.1-1.691,1.567c-0.405,0.436-0.682,0.81-0.832,1.123c-0.147,0.309-0.256,0.756-0.327,1.342
		c-0.076,0.631-0.106,1.397-0.091,2.3l0.002,0c0.005,0.306-0.238,0.558-0.544,0.564h-0.01h-0.005h-6.151
		c-0.306,0-0.554-0.248-0.554-0.554c0-0.013,0-0.025,0.001-0.038c-0.008-0.417-0.014-0.759-0.018-1.027
		c-0.006-0.364-0.008-0.551-0.008-0.559c0-1.026,0.087-1.971,0.26-2.834c0.175-0.87,0.439-1.648,0.791-2.336l-0.002-0.001
		l0.001-0.002l0,0c0.003-0.006,0.006-0.012,0.01-0.018c0.352-0.685,0.873-1.402,1.562-2.157c0.678-0.742,1.524-1.525,2.537-2.352
		c0.97-0.788,1.755-1.448,2.352-1.981c0.564-0.503,0.931-0.866,1.098-1.088l-0.001,0c0.012-0.016,0.025-0.031,0.038-0.045
		c0.243-0.326,0.428-0.672,0.554-1.036c0.13-0.377,0.195-0.774,0.195-1.189c0-1.153-0.465-2.145-1.396-2.982l0,0l-0.002-0.002l0,0
		c-0.005-0.005-0.011-0.01-0.016-0.015c-0.466-0.411-1.013-0.72-1.642-0.928c-0.653-0.216-1.397-0.324-2.232-0.324
		c-0.801,0-1.532,0.113-2.193,0.339c-0.656,0.224-1.248,0.559-1.776,1.004c-0.524,0.446-0.969,1.012-1.334,1.698
		c-0.372,0.7-0.663,1.529-0.871,2.488l0,0.001c-0.062,0.288-0.338,0.474-0.626,0.43l-6.204-0.772l-0.001,0
		c-0.304-0.038-0.519-0.315-0.481-0.618l0-0.002c0.095-1.501,0.463-2.891,1.105-4.173c0.645-1.289,1.564-2.459,2.754-3.512
		l0.366,0.413l-0.367-0.415c0.006-0.006,0.013-0.011,0.02-0.016c1.191-1.045,2.565-1.83,4.124-2.354
		c1.555-0.523,3.286-0.784,5.197-0.784c2.001,0,3.804,0.265,5.409,0.793c1.622,0.533,3.039,1.335,4.251,2.405
		c1.205,1.067,2.113,2.225,2.72,3.475c0.613,1.262,0.92,2.611,0.92,4.049C43.252,23.795,43.138,24.574,42.911,25.332z"/>
    </svg>
)

export default helpIcon;