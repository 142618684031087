import mainStore from 'storeUtils/mainStore';
import { pageNeedsReloading } from 'storeUtils/storeUtils';
import { fetchJSON } from 'utils/fetchJSON';

export default function commitChanges(person_id, changes, commitType, pattern_week_id, current_day) {
    return fetchJSON('/api/pattern/commitChanges', {
        person_id,
        changes,
        commitType,
        pattern_week_id,
        current_day,
        baseInfoTimeUTC: mainStore.baseInfoTimeUTC
    })
        .then((json) => {
            if (json.request?.error) {
                pageNeedsReloading();
            }
            return json;
        })
        .catch(error => {
            console.log('[api/pattern/commitChanges.js] Error: ', error);
            return undefined;
        });
}
