import React from 'react';

const RTEIcon = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 70 50">
        <g>
            <path d="M46,6.3c-0.2,0.3-0.4,0.5-0.7,0.8c-0.7,0.9-1.4,1.7-2.1,2.6C43,9.9,43,9.9,42.8,9.8c-1.8-0.9-3.6-1.5-5.6-1.8
		c-1.5-0.2-3-0.2-4.5,0C26.4,8.8,21,13,18.8,19c-0.5,1.4-0.9,2.9-1,4.4c-0.4,5.1,1.1,9.6,4.7,13.3c2.5,2.6,5.5,4.3,9.1,5
		c1.4,0.3,2.9,0.4,4.3,0.3c4.1-0.3,7.6-1.8,10.6-4.6c2.5-2.4,4.2-5.2,5-8.6c0.4-1.6,0.5-3.1,0.4-4.7c0-0.3,0.1-0.6,0.3-0.8
		c1.1-1.3,2.2-2.7,3.3-4c0-0.1,0.1-0.1,0.2-0.2c2.4,8.1-0.2,19-10.1,24.7c-9.4,5.4-21.5,3.1-28.2-5.9c-6.6-8.8-5.5-21.2,2.7-28.8
		C28.2,1.6,39.2,2.1,46,6.3z"/>
            <path d="M56.8,11.4c0,0.2-0.1,0.4-0.2,0.5c-0.7,0.9-1.4,1.7-2.1,2.6c-3.2,4-6.5,7.9-9.7,11.9c-2.9,3.5-5.7,7-8.6,10.5
		c0,0-0.1,0.1-0.1,0.1c-0.3,0.3-0.6,0.3-0.9,0.1c-0.3-0.3-0.6-0.6-0.9-0.8c-4.1-3.8-8.2-7.6-12.3-11.3c-0.3-0.3-0.7-0.6-1-0.9
		c-0.3-0.3-0.4-0.7,0-1c1.4-1.6,2.9-3.1,4.3-4.7c0.3-0.3,0.7-0.4,1,0c0.8,0.7,1.5,1.4,2.3,2.1c1.8,1.6,3.5,3.3,5.3,4.9
		c0.6,0.6,1.4,0.5,1.9-0.1c1.8-2.2,3.6-4.4,5.4-6.6c3-3.7,6.1-7.4,9.1-11.1c0.1-0.1,0.2-0.2,0.3-0.4c0.3-0.3,0.7-0.4,1-0.1
		c0.6,0.5,1.1,0.9,1.7,1.4c1.1,0.9,2.1,1.7,3.2,2.6c0.1,0.1,0.1,0.1,0.2,0.2C56.7,11.1,56.8,11.2,56.8,11.4z"/>
            <path d="M68.4,11.6c0,0.6,0,1.3-0.1,2.1c-0.1,0.7-0.1,1.4-0.2,2.2c-0.1,0.9-0.2,1.8-0.2,2.7c-0.1,0.9-0.1,1.8-0.2,2.8
		c-0.1,1-0.2,2.1-0.2,3.1c0,0.1,0,0.2-0.1,0.2c-0.3,0.2-0.6,0.4-0.7,0.8c-0.2,0.4-0.3,0.9-0.4,1.3c-0.2,0.8-0.2,1.5-0.2,2.3
		c-0.1,1.5,0,3,0.1,4.4c0.1,1.4,0.2,2.8,0.3,4.2c0.1,1.3,0.2,2.7,0.4,4c0.1,1.4,0.3,2.8,0.4,4.2c0,0.6,0.1,1.3,0.1,1.9
		c0,0.5-0.2,0.9-0.5,1.2c-0.8,0.8-2.3,0.8-3.1,0c-0.4-0.4-0.6-0.8-0.6-1.3c0.1-1.4,0.2-2.8,0.3-4.3c0.1-1.2,0.2-2.4,0.3-3.6
		c0.1-1.3,0.2-2.6,0.3-3.9c0.1-1.1,0.1-2.2,0.2-3.3c0-0.9,0-1.8,0-2.7c0-1.1-0.1-2.3-0.3-3.4c-0.1-0.3-0.2-0.7-0.3-1
		c-0.2-0.4-0.4-0.8-0.8-1c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0-7.9,0-15.8,0-23.6c0-0.3,0-0.3,0.3-0.2
		c1.2,0.2,2.1,0.8,2.9,1.7c0.8,0.9,1.4,2,1.8,3.2c0.4,1.1,0.6,2.2,0.7,3.3C68.3,9.6,68.3,10.5,68.4,11.6z"/>
            <path d="M2.5,0.8c0.1,0.6,0.2,1.2,0.3,1.9c0,0,0,0.1,0,0.1C2.9,3.3,3,3.7,3,4.1c0.1,0.6,0.1,1.2,0.2,1.8
		c0.1,1.5,0.2,3,0.3,4.5c0.1,0.8,0.1,1.5,0.2,2.3c0.1,0.2,0,0.3,0.1,0.5c0,0,0-0.1,0-0.1c0.1-0.5,0.1-1,0.1-1.5
		C4,10.1,4.1,8.5,4.2,7c0.1-1.2,0.1-2.3,0.2-3.5C4.5,2.7,4.5,2,4.7,1.2c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0C4.9,1.4,4.9,1.8,5,2.1
		c0.1,1.2,0.2,2.5,0.3,3.7C5.3,7,5.4,8.1,5.5,9.2c0.1,1,0.1,2,0.2,3c0,0.3,0.1,0.6,0.1,0.9c0,0.1,0.1,0.1,0,0.1c0,0,0-0.1,0-0.2
		C5.9,12,6,10.9,6,9.9C6.1,8.3,6.2,6.6,6.3,5c0.1-1.2,0.1-2.3,0.3-3.5c0-0.2,0-0.3,0.1-0.5c0,0,0,0,0,0C6.9,1.6,6.9,2.1,7,2.7
		c0.2,1.6,0.3,3.2,0.4,4.9c0.1,1.1,0.2,2.2,0.2,3.3c0.1,0.7,0.1,1.4,0.2,2.1c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0,0,0,0,0-0.1
		C8,12.4,8.1,11.7,8.1,11c0.1-1.6,0.2-3.2,0.3-4.8C8.5,4.8,8.6,3.4,8.8,2c0.1-0.4,0.1-0.8,0.3-1.2c0,0.2,0,0.4,0,0.6
		c0.1,1.6,0.1,3.2,0.2,4.8c0,1,0.1,2.1,0.1,3.1c0.1,1.6,0.2,3.2,0.2,4.8c0.1,1.5,0.1,3,0.2,4.5c0,0.3,0,0.5,0,0.8
		c-0.1,0-0.2,0-0.3-0.1C9,19.2,8.4,19.3,8,19.8c-0.3,0.4-0.5,0.8-0.7,1.2c-0.3,0.7-0.4,1.5-0.5,2.2c-0.2,1.6-0.2,3.2-0.2,4.8
		c0,1.4,0.1,2.7,0.2,4.1c0.1,1.1,0.1,2.2,0.2,3.2c0.1,1.4,0.2,2.7,0.4,4.1c0.1,1.2,0.2,2.3,0.3,3.5c0.1,1.4,0.2,2.8,0.3,4.2
		c0,0.2,0,0.4,0,0.6c0,0.5-0.3,0.9-0.7,1.2c-0.9,0.7-2.3,0.8-3.2,0c-0.5-0.4-0.8-0.9-0.7-1.6c0.1-1.1,0.1-2.1,0.2-3.2
		C3.8,43,4,41.8,4.1,40.5c0.1-1.1,0.2-2.2,0.3-3.2c0.1-1.4,0.3-2.8,0.4-4.2c0.1-0.8,0.1-1.7,0.2-2.5C5,29.1,5.1,27.5,5,26
		c0-1.3-0.1-2.7-0.4-4c-0.1-0.5-0.3-1-0.5-1.5C4,20.3,3.8,20.1,3.7,20c-0.5-0.7-1.2-0.8-2-0.6c0-0.3,0-0.6,0-0.9
		c0-0.4,0-0.9,0.1-1.3c0.1-1.2,0.1-2.3,0.2-3.5C2,12.4,2,11.1,2.1,9.7c0.1-1,0.1-2.1,0.1-3.1c0.1-1.5,0.1-3,0.2-4.5
		c0-0.3,0-0.6,0-0.9C2.5,1.1,2.4,0.9,2.5,0.8z"/>
        </g>
    </svg>
)

export default RTEIcon;