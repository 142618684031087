import { modalStore as modalWrapperStore } from "components/ModalWrapper";

export const fetchJSONSettings = {
    layoutStore: undefined
};

export const creds = {
    key: undefined,
    id: undefined
}

function checkResponseCode(response) {
    if (!response.ok) {
        //some kind of bad response.
        if (fetchJSONSettings?.layoutStore?.loggedIn) { // In case when Fronted Server returns not valid status code and User is loggedIn
            fetchJSONSettings.layoutStore.loggedIn = false
            // fetchJSONSettings.layoutStore.request.message = 'API Key invalid, please log in.';
            // fetchJSONSettings.layoutStore.request.error = true;
        }
        modalWrapperStore.modalWindows = []; // clear out all modals

        return Promise.reject({ error: true, mesage: 'Invalid response status: ' + response.status })
    }
    return response;
}

export function fetchJSON(url, data, sendAPIKey = true) {
    if (sendAPIKey) {
        if (!data) data = {};
        data.__key = creds.key;
        data.__id = creds.id;
    }

    data = JSON.stringify(data);
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: data
    };
    return fetch(url, options)
        .then(checkResponseCode)
        .then((response) => response.json())
        .then((json) => {
            if ((typeof (json) === 'object') && (json.invalidAPIKey)) {
                if (fetchJSONSettings?.layoutStore?.loggedIn) { // when layoutStore is initalized
                    fetchJSONSettings.layoutStore.loggedIn = false
                    // fetchJSONSettings.layoutStore.request.message = 'API Key invalid, please log in.';
                    // fetchJSONSettings.layoutStore.request.error = true;
                }
                modalWrapperStore.modalWindows = []; // clear out all modals

                return Promise.reject(json);
            }
            if ((typeof (json) === 'object') && (json.error)) {
                return Promise.reject(json);
            }
            else return json;
        });
}
