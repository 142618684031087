import React from 'react';
import './Label.scss'
import { view } from '@risingstack/react-easy-state';

export default view((props) => (
    <label className={
        'label ' +
        (props.inline ? 'inline ' : '') +
        (props.backText ? 'backText ' : '') +
        (props.largeBackText ? 'backText large ' : '') +
        (props.hasError ? 'has-error ' : '') +
        (props.className ? props.className : '')
    }
        htmlFor={props.for} >{props.children}</label>
));