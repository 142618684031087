import { fetchJSON } from 'utils/fetchJSON';

export default function getFoodPortionTypes(food_id) {
    return fetchJSON('/api/food/portion-types', {
        food_id
    })
        .then((json) => {
            return json;
        })
        .catch(error => {
            return error;
        });
}


export function getFoodPortionTypesForDropdown(food_id, allPortionTypes) {
    return fetchJSON('/api/food/portion-types', {
        food_id
    })
        .then((json) => {
            //REVIEW: GOOD SPOT? MOVE TO API?
            if (json?.portionTypes?.length > 0) {
                const allowedPortionsList = json.portionTypes.map(fp => {
                    // return result from api with names? maybe?
                    const el = allPortionTypes.find(y => fp.portion_type_id === y.id) || {};
                    return { ...el, key: fp.portion_type_id, value: (el?.name || `PortionType#${fp.portion_type_id}`), ...fp };
                })
                return allowedPortionsList;
            }
            return [];
        })
        .catch(error => {
            console.log('[api/food/getPortionTypes.js] Error: ', error)
            return undefined;
        });
}