import PropTypes from "prop-types";
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import './DateInput.scss';

import {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import { view } from '@risingstack/react-easy-state';

const DateInput = (props) => (
    <div className={
        "date-input-container " +
        (props.hasError ? 'has-error' : '')
    }>
        <DayPickerInput
            onDayChange={(day, /*modifiers, dayPickerInput*/) => {
                if (props.validateEveryChange) {
                    if (props.onChange) {
                        props.onChange(day);
                    }
                } else if (day !== undefined) {
                    if (props.onChange) {
                        props.onChange(day);
                    }
                }
            }}
            value={formatDate(props.value, "YYYY-MM-DD")}
            format="YYYY-MM-DD"
            dateFormat="YYYY-MM-DD"
            parseDate={(str) => parseDate(str, "YYYY-MM-DD")}
            placeholder="YYYY-MM-DD"
        />
        {props.hasError ? <div className="validation-error">
            {props.customErrorText ? props.customErrorText : ''}
        </div> : ''}
    </div>

)

DateInput.propTypes = {
    customErrorText: PropTypes.any,
    hasError: PropTypes.any,
    onChange: PropTypes.func,
    validateEveryChange: PropTypes.any,
    value: PropTypes.any
}

export default view(DateInput);
