import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { store, view } from '@risingstack/react-easy-state';
import mainStore from 'storeUtils/mainStore';

import './RecipeModal.scss';

import { Button } from 'components/Button';
import Container from 'components/Container';
import Text from 'components/Text';
import Icon, { FA, icons } from 'components/Icon';
import InfoModal from 'components/InfoModal';
import { clearModals, addModal } from 'components/ModalWrapper';
import TableRecipeIngredientRow from 'components/TableRecipeIngredientRow';
import { addNotification } from 'components/NotificationWrapper';
import FractionControllInput from 'components/FractionControllInput';
import Tooltip from 'components/Tooltip';

import ReplaceModal from 'screens/ReplaceModal';
import SettingsPatternModalMyMenu from 'screens/SettingsPatternModalMyMenu';
import NutrientsModal from 'screens/NutrientsModal';
import Collapse from 'components/Collapse';

import getAssembledFood from 'api/recipe/recipeGetAssembledFood';
import deleteMeal from 'api/meal/deleteMeal';
import mealChangeQuantities from 'api/meal/mealChangeAmount';
import { updateStore } from 'api/login/verifyLogin';
import mealConfirm from 'api/meal/mealConfirm';
import getMealWarnings from 'api/meal/getMealWarnings';
import { mealDeleteIngredient, mealModifyIngredient } from 'api/meal/mealIngredient';

import { getRecipeNutrition } from 'subsystems/nutrition/nutrition.js';
import {
    getMealAmountNoExtra,
    getMealBrand,
    getMealAmountMultiplier,
    getMealName,
    getMealPortionTypeName,
    isRTE,
    changeMealAmount,
    changeMealServings,
    getMealAmountWithExtra,
    moveRTEIngredientAmountToMealAmount,
    getIngredientFactorWithExtra,
    isAltered,
    isIngredientAltered,
    isAnyProhibited,
    isFoodProhibited
} from 'utils/mealUtils';
import getFractionDisplay from 'utils/getFractionDisplay';

import {
    findMealInWeeklyData,
    isDayTargetExceeded,
    onMealWarningModal
} from 'storeUtils/storeUtils';
import layoutStore, { fixCapitalizeName } from 'storeUtils/layoutStore';
import { createReplaceModalProps } from 'screens/ReplaceModal/ReplaceModal';

class RecipeModal extends Component {
    static class = 'modal-medium';
    recipeStore = store({
        meal: {},
        errors: {
            amount: false,
        },
    })

    originalMealInfo = null;
    constructor(props) {
        super(props);
        this.originalMealInfo = props.item;
        this.assignDialogData(this.originalMealInfo);
    }

    assignDialogData = (itemInfo) => {
        const extra_portions = itemInfo.extra_portions || 0;
        this.recipeStore.meal = {
            ...itemInfo,
            extra_portions
        }
    }

    findAndAssignDialogData = () => {
        this.originalMealInfo = findMealInWeeklyData(this.props.item.id);
        this.assignDialogData(this.originalMealInfo);
    }

    validateAmount = (value) => {
        return this.recipeStore.errors.amount = !value || value == 0;
    }

    validateServings = (value) => {
        return this.recipeStore.errors.servings = !value || value == 0;
    }

    onShowNutrients = () => {
        this.recipeStore.meal.nutrition = getRecipeNutrition(this.recipeStore.meal, true);
        // mainStore.debugData.push(this.recipeStore.meal);
        addModal(NutrientsModal, { data: this.recipeStore.meal.nutrition, isMealInfo: true, offCalendar: true, offTimeSelector: true, title: fixCapitalizeName(getMealName(this.recipeStore.meal)), selectedRecipe: this.recipeStore.meal });
    }

    onAmountChange = (value) => {
        // value === meal.amount (in case of RTE value * (ingr[0].amount/portion_count))
        if (isRTE(this.recipeStore.meal)) {
            // we need to scale the value back to get the amount field in person meal
            const multiplier = getMealAmountMultiplier(this.recipeStore.meal);
            value = value / multiplier;
        }
        changeMealAmount(this.recipeStore.meal, value);
    }

    onServingsChange = (value) => {
        // value === meal.amount + meal.extra_portions
        changeMealServings(this.recipeStore.meal, value);
    }

    amountChanged = () => {
        return this.originalMealInfo.amount != this.recipeStore.meal.amount || this.originalMealInfo.extra_portions != this.recipeStore.meal.extra_portions;
    }

    onSubmitQuantities = async () => {
        if (this.recipeStore.isLoading) return;

        if (!this.validateAmount(this.recipeStore.meal.amount) && !this.validateServings(this.recipeStore.meal.extra_portions + this.recipeStore.meal.amount)) {
            this.recipeStore.isLoading = true;
            const result = await mealChangeQuantities(this.originalMealInfo.id, mainStore.profile.id, this.recipeStore.meal.amount, this.recipeStore.meal.extra_portions);
            if (result) {
                updateStore(result);
                addNotification('Your Daily Menu nutritional calculations have been updated with your new MY SERVINGS amount', { timeout: 7000 })
            }

            this.recipeStore.isLoading = false;
            this.props.closeAction()
        }
    }

    onMealReplace = async (originalPersonMealId, meal_id, personMeal, food_id, recipe_id) => {
        const result = await getMealWarnings(
            mainStore.selectedDay.date,
            mainStore.selectedDay.index,
            mainStore.profile.id,
            personMeal,
            meal_id,
        );

        const onMealConfirm = async (modalResult) => {
            layoutStore.bigLoading = true;
            clearModals()

            const baseInfo = await mealConfirm(
                modalResult?.needRebalance,
                modalResult?.keepOriginal,
                mainStore.selectedDay.date,
                mainStore.profile.id,
                meal_id,
                personMeal,
                originalPersonMealId
            )
            if (baseInfo) {
                updateStore(baseInfo);
            }
            layoutStore.bigLoading = false;
        }

        const alteredIngredients = personMeal.ingredients.filter(foodIngr => foodIngr.originalSelectedFood);
        if (Array.isArray(result?.mealWarnings) && result?.mealWarnings.length > 0) {
            onMealWarningModal(result.mealWarnings, alteredIngredients, result.includedRestrictions, onMealConfirm);
        } else {
            await onMealConfirm()
        }
    }

    replaceItem = () => {
        const actionFunction = async (obj) => {
            if (!obj)
                return;

            let recipe_id, food_id;
            if (obj.recipe_id && obj.food_id) {
                food_id = obj.food_id;
                // if (isRTE(obj)) {
                // adjustRTEAmountBeforeSave(obj);
                // }
            } else {
                recipe_id = obj.recipe_id;
            }

            await this.onMealReplace(this.originalMealInfo.id, this.props.section.id, obj, food_id, recipe_id)

        }
        addModal(ReplaceModal, { currentItem: this.recipeStore.meal, isReplaceMode: true, actionFunction });
    }

    deleteItem = () => {
        addModal(InfoModal, { title: 'Are you sure?', text: 'Your item will be removed.', confirm: 'continue', cancel: 'cancel', closeOnBg: true }
            , async (data) => {
                if (data) {
                    this.props.closeAction();
                    const res = await deleteMeal(mainStore.profile.id, this.originalMealInfo.id)

                    if (res) {
                        updateStore(res);
                    } else {
                        addNotification('Meal Delete Failed', { timeout: 7000 })
                    }
                }
            });
    }

    addItem = async () => {
        const selected = {
            recipe_id: this.originalMealInfo.recipe_id,
            food_id: this.props.food_id,
            amount: this.recipeStore.meal.amount,
            extra_portions: this.recipeStore.meal.extra_portions,
            name: fixCapitalizeName(getMealName(this.recipeStore.meal))
        }

        if (selected.recipe_id) {
            selected.type = 'recipe';
            selected.id = selected.recipe_id;
        } else if (selected.food_id) {
            selected.type = 'food';
            selected.id = selected.food_id;
        }

        addModal(SettingsPatternModalMyMenu, {
            selected
        }, async (data) => {
            if (data) {
                this.props.closeAction()
            }
        });


    }

    saveIngredient = async (rteMeal, oldIngredient) => {
        if (rteMeal) {
            const date = mainStore.selectedDay.date;
            const amountDivisor = 1 / getIngredientFactorWithExtra(this.recipeStore.meal);
            const response = await mealModifyIngredient(
                this.recipeStore.meal.id,
                mainStore.profile.id,
                rteMeal.food_id,
                rteMeal.amount * amountDivisor,
                rteMeal.portionTypeId,
                date,
                oldIngredient?.id);
            if (response) {
                updateStore(response);
            }
            this.findAndAssignDialogData();
        } else {
            console.log('[RecipeModal.js] ingredient update canceled');
        }
    }

    escapeQuotes(string) {
        if (string && string[0] === '"') {
            string = string.slice(1);
        }
        if (string && string[string.length - 1] === '"') {
            string = string.slice(0, string.length - 1)
        }
        return string;
    }

    isPersonMeal = () => {
        return !this.originalMealInfo.recipe_id && this.originalMealInfo.id
    }

    deleteIngredient = async (meal_ingredient) => {
        const date = mainStore.selectedDay.date;
        const response = await mealDeleteIngredient(mainStore.profile.id, date, meal_ingredient.id);
        if (response) {
            updateStore(response);
        }
        this.findAndAssignDialogData();
    }

    renderRecipeSiteUrl() {
        if (this.recipeStore.meal.recipe_site_url && this.recipeStore.meal.recipe_site_name) {
            return <a href={this.recipeStore.meal.recipe_site_url} target="_blank" rel="noopener noreferrer">(Source: {this.recipeStore.meal.recipe_site_name})</a>
        }
        return this.recipeStore.meal.recipe_site_name ? <Text>
            (Source: {this.recipeStore.meal.recipe_site_name})
        </Text> : ''
    }

    renderPreparationTime(minutes) {
        if (!minutes) return '0 hrs  0 mins';
        return `${Math.floor(minutes / 60)} hrs  ${minutes % 60} mins`
    }

    render() {
        return (
            <Container fill>
                <div className="recipe-item-grid">
                    <div className="image no">
                        <Icon className={isRTE(this.recipeStore.meal) ? 'rte-icon' : 'recipe-icon'} icon={isRTE(this.recipeStore.meal) ? icons.rteIcon : icons.recipe} />
                    </div>
                    <div className='item-header'>
                        <div className="item-name pr-3">
                            <Text uppercase>{getMealName(this.recipeStore.meal)}</Text><br />
                            <i className="ingredient-brand-name font-large">{getMealBrand(this.recipeStore.meal) || (isRTE(this.recipeStore.meal) ? '(Generic)' : '')}</i>
                            <Text normal hoverUnderline>{this.renderRecipeSiteUrl()}</Text>
                            <br />
                        </div>

                        <div className={'item-info ' + (isRTE(this.recipeStore.meal) ? 'rte' : '')}>
                            {!isRTE(this.recipeStore.meal) ? <React.Fragment>
                                <Button blue block className="time-display-button">
                                    <Icon icon={icons.preparation} />
                                    <div className="button-content"><b>PREP TIME</b> <span>{this.renderPreparationTime(this.recipeStore.meal.preparation_time)}</span></div>
                                </Button>
                                <Button yellow block className="time-display-button">
                                    <Icon icon={icons.meal} />
                                    <div className="button-content"><b>COOK TIME</b> <span>{this.renderPreparationTime(this.recipeStore.meal.cooking_time)}</span></div>
                                </Button>
                                <Button red block className="time-display-button">
                                    <Icon icon={icons.clock} />
                                    <div className="button-content"><b>TOTAL TIME</b> <span>{this.renderPreparationTime(this.recipeStore.meal.time_to_eat)}</span></div>
                                </Button>
                            </React.Fragment> : ""}
                            {<div className="servings-change-container">

                                {/* IF PREVIEW*/}
                                {((!this.recipeStore.meal.is_from_menu || this.props.readonly) && !isRTE(this.recipeStore.meal) && !this.props.isFromRecipesPage) && <Button block className="serving-button">
                                    <div className="button-content">
                                        <div className="servings-label">
                                            <FA icon="fa fa-fw fa-utensils" />
                                            <b className="d-inline-block">Recipe Serving(s)</b>
                                        </div>
                                        <Text large inlineBlock className="readonly-value">{getFractionDisplay(getMealAmountWithExtra(this.recipeStore.meal))}</Text>
                                    </div>
                                </Button>}

                                {/* ELSE */}
                                {((this.recipeStore.meal.is_from_menu && !isRTE(this.recipeStore.meal) && !this.props.readonly) || this.props.isFromRecipesPage) && <Button block className="serving-button">
                                    <div className="button-content">
                                        <div className="servings-label">
                                            <FA icon="fa fa-fw fa-utensils" />
                                            <b className="d-inline-block">Recipe serving(s)</b>
                                        </div>
                                        <FractionControllInput
                                            // roundSourceToFraction
                                            minValue={0.25}
                                            stepAcceleration
                                            value={getMealAmountWithExtra(this.recipeStore.meal)}
                                            onChange={value => this.onServingsChange(value)}
                                        />
                                    </div>
                                </Button>}


                                {/* IF PREVIEW*/}
                                {((!this.recipeStore.meal.is_from_menu || this.props.readonly) && !this.props.isFromRecipesPage) && <Button block className="serving-button">
                                    <div className="button-content">
                                        <div className="servings-label">
                                            <FA icon="fa fa-fw fa-utensils" />
                                            <b className="d-inline-block">My serving(s)</b>
                                        </div>
                                        <Text large inlineBlock className="readonly-value">{getFractionDisplay(getMealAmountNoExtra(this.recipeStore.meal))} {isRTE(this.recipeStore.meal) ? getMealPortionTypeName(this.recipeStore.meal) : ""}</Text>
                                    </div>
                                </Button>}

                                {/* ELSE */}
                                {((this.recipeStore.meal.is_from_menu && !this.props.readonly) || this.props.isFromRecipesPage) && <Button block className="serving-button">
                                    <div className="button-content">
                                        <div className="servings-label">
                                            <FA icon="fa fa-fw fa-utensils" />
                                            <b className="d-inline-block">My serving(s)</b>
                                        </div>
                                        <div className="control-input-with-portion">
                                            <FractionControllInput
                                                // roundSourceToFraction
                                                minValue={0.25}
                                                stepAcceleration
                                                value={getMealAmountNoExtra(this.recipeStore.meal)}
                                                onChange={value => {
                                                    this.onAmountChange(value)
                                                }}
                                            />
                                            {isRTE(this.recipeStore.meal) ? <span className="portion-type-display">{getMealPortionTypeName(this.recipeStore.meal)}</span> : ""}
                                        </div>

                                    </div>
                                </Button>}

                            </div>}

                            <div className={'servings-save-panel ' +
                                (this.recipeStore.meal.is_from_menu && this.amountChanged() ? 'open ' : '') +
                                (this.originalMealInfo.amount < this.recipeStore.meal.amount && isDayTargetExceeded(this.recipeStore.meal.id, this.recipeStore.meal.amount) ? 'warning-visible ' : '')
                            }>
                                <div className="text-red kcal-warning">
                                    <div>
                                        <FA icon="fa fa-fw fa-exclamation-triangle"></FA>
                                        <span>OVER DAILY TARGET</span>
                                    </div>
                                </div>
                                <Button disabled={this.recipeStore.isLoading} red block onClick={() => {
                                    this.recipeStore.meal.amount = this.originalMealInfo.amount;
                                    changeMealAmount(this.recipeStore.meal, this.originalMealInfo.amount)
                                    this.recipeStore.meal.extra_portions = this.originalMealInfo.extra_portions || 0;
                                    this.recipeStore.errors = {};
                                }} ><FA icon="fa fa-fw fa-ban" /> CANCEL </Button>
                                <Button disabled={this.recipeStore.isLoading} green block onClick={() => this.onSubmitQuantities()}>
                                    {this.recipeStore.isLoading ? <FA icon="fas fa-sync fa-spin" /> : <FA icon="fad fa-fw fa-save" />} SAVE
                                </Button>
                            </div>

                        </div>
                    </div>

                    <div className="details">
                        {/* IF PREVIEW*/}
                        {!this.recipeStore.meal.is_from_menu && !this.props.readonly
                            ? <div className="buttons">
                                <Tooltip className="nutrients-tooltip mr-auto" mobileHidden small inlineBlock text="Nutrients">
                                    <Button green onClick={this.onShowNutrients}>
                                        <Text inlineBlock className="nutrients-title pr-2">Nutrients</Text>
                                        <FA icon="far fa-chart-bar" />
                                    </Button>
                                </Tooltip>
                                {!this.props.noAddToMeal ? <Button style={{ gridColumn: '2/span 2' }} green onClick={() => this.addItem()}>
                                    Add to your meal <Icon className="pl-1" icon={icons.add} />
                                </Button> : ""}
                            </div> : ""}

                        {/* ELSE */}
                        {this.recipeStore.meal.is_from_menu && !this.props.readonly
                            ? <div className="buttons">
                                <Tooltip className="nutrients-tooltip mr-auto" mobileHidden small inlineBlock text="Nutrients">
                                    <Button green onClick={this.onShowNutrients}>
                                        <Text inlineBlock className="nutrients-title pr-2">Nutrients</Text>
                                        <FA icon="far fa-chart-bar"></FA>
                                    </Button>
                                </Tooltip>
                                <Button green onClick={() => this.replaceItem()}>
                                    Compare and Replace</Button>
                                <Button green onClick={() => this.deleteItem()}>
                                    Remove <Icon className="pl-2" icon={icons.delete} />
                                </Button>
                            </div> : ""}

                        {this.props.readonly
                            ? <div className="buttons">
                                <Tooltip className="mr-auto" mobileHidden small inlineBlock text="Nutrients">
                                    <Button green onClick={this.onShowNutrients}><FA icon="far fa-chart-bar" /></Button>
                                </Tooltip>
                            </div>
                            : ""
                        }

                        {this.recipeStore.meal.ingredients && this.recipeStore.meal.ingredients.length > 0 && <Collapse className="mb-1 ingredients text-left" noPadding collapsed title="Ingredients">
                            <div className="pad">
                                <table>
                                    <tbody>
                                        {/* {recalculatePortions(this.recipeStore.meal.ingredients, this.recipeStore.meal.portion_count, this.recipeStore.meal.amount + this.recipeStore.meal.extra_portions).map((i, index) => */}
                                        {this.recipeStore.meal.ingredients.map((ingr, index) => {
                                            const ingredientPrescaler = getIngredientFactorWithExtra(this.recipeStore.meal);
                                            return <tr key={index}>
                                                <td>
                                                    <TableRecipeIngredientRow
                                                        isFoodProhibited={isFoodProhibited(ingr)}
                                                        isSubstitute={isIngredientAltered(ingr)}
                                                        originalName={ingr?.originalSelectedFood?.name}
                                                        name={ingr.selectedFood.name}
                                                        brand={ingr.brand}
                                                        prefix={ingr.prefix}
                                                        amount={Math.round((ingredientPrescaler * ingr.amount * 1000)) / 1000}
                                                        portionType={ingr.portionType.name} />
                                                </td>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle', width: '27px' }}>
                                                    {this.recipeStore.meal.is_from_menu && !isRTE(this.recipeStore.meal) && !this.props.readonly
                                                        ?
                                                        <Tooltip mobileHidden small inlineBlock text="Edit">
                                                            <Icon icon={icons.edit} onClick={async () => {
                                                                layoutStore.bigLoading = true;
                                                                const foodQuantity = ingr.selectedFood?.quantity || 1
                                                                const mealProportions = getMealAmountWithExtra(this.recipeStore.meal) / this.recipeStore.meal.portion_count;
                                                                // we need to divide by food.quantity to avoid getting wrong data from getAssembledFood (as it defaults to foodQuantity in ingredient amount)
                                                                const { recipe: afood, isFoodProhibited } = await getAssembledFood(mainStore.profile.id, ingr.food_id, (mealProportions * ingr.amount) / foodQuantity, ingr.portionType.id);
                                                                afood.nutrition = getRecipeNutrition(afood);
                                                                afood.amount *= afood.portion_count;
                                                                moveRTEIngredientAmountToMealAmount(afood);
                                                                let deleteFunction = null;
                                                                if (this.recipeStore.meal.ingredients.length > 1) {
                                                                    deleteFunction = () => { this.deleteIngredient(ingr) }
                                                                }
                                                                layoutStore.bigLoading = false;

                                                                addModal(ReplaceModal, {
                                                                    ...createReplaceModalProps(afood, true, true, true, null, deleteFunction),
                                                                    actionFunction: async (data, closeReplaceAction) => {
                                                                        layoutStore.bigLoading = true;
                                                                        if (typeof closeReplaceAction === 'function') {
                                                                            closeReplaceAction()
                                                                        }
                                                                        if (data) {
                                                                            data.amount /= data.portion_count;
                                                                            await this.saveIngredient(data, ingr);
                                                                        }
                                                                        layoutStore.bigLoading = false;
                                                                    },
                                                                    isFoodProhibited: isFoodProhibited
                                                                });
                                                            }} />
                                                        </Tooltip>
                                                        : ""
                                                    }
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {isAltered(this.recipeStore.meal) ? <Text large inlineBlock fullWidth className="altered-warning">
                                <span>*</span> The algorithm has altered the original Recipe to meet your Food Restriction Preferences.
                            </Text> : ''}
                            {isAnyProhibited(this.recipeStore.meal) ? <Text large inlineBlock fullWidth className="altered-warning">
                                <span>**</span> This food violates your Food Restriction Preferences.
                            </Text> : ''}
                            {this.recipeStore.meal.is_from_menu && !isRTE(this.recipeStore.meal) && !this.props.readonly
                                ? <div className="mx-2">
                                    <Button className="mb-1" right green onClick={() => {
                                        addModal(ReplaceModal, {
                                            ...createReplaceModalProps(undefined, false, false, true, 'Add Ingredient'),
                                            actionFunction: async (mealData, closeReplaceAction) => {
                                                layoutStore.bigLoading = true;
                                                if (typeof closeReplaceAction === 'function') {
                                                    closeReplaceAction()
                                                }
                                                // const mealProportions = getMealServings(this.recipeStore.meal);
                                                // data.amount /= mealProportions;
                                                if (isRTE(mealData)) {
                                                    mealData.amount /= mealData.portion_count;
                                                }
                                                await this.saveIngredient(mealData);
                                                layoutStore.bigLoading = false;
                                            }
                                        });
                                    }}>Add Ingredient</Button>
                                </div> : ""}

                        </Collapse>}

                        {this.recipeStore.meal.description && !isRTE(this.recipeStore.meal) ? <Collapse className="mb-1 text-left" noPadding title="Instructions">
                            <div className="pad respectNewlines">
                                {this.recipeStore.meal.description.indexOf('\\n' > -1) ?
                                    this.escapeQuotes(this.recipeStore.meal.description).split('\\n').map((x, index) => <Text key={index}>{x}<br /></Text>)
                                    : this.recipeStore.meal.description
                                }
                            </div>
                        </Collapse> : ''}
                    </div>

                </div>
            </Container >
        );
    }
}

RecipeModal.propTypes = {
    item: PropTypes.object.isRequired,
    section: PropTypes.object,
    readonly: PropTypes.bool,
    closeAction: PropTypes.func,
    food_id: PropTypes.any,
    noAddToMeal: PropTypes.bool,
    isFromRecipesPage: PropTypes.bool
}

export default view(RecipeModal);
