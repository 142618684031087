
import React, { Component } from 'react';
import { batch, store, view } from '@risingstack/react-easy-state';
import PropTypes from 'prop-types';

import './AddShoppingItemModal.scss';

import { Text } from 'components/Text';
import { FA, Icon, icons } from 'components/Icon';
import Button, { ProgressButton } from 'components/Button';
import Label from 'components/Label';

import { validateRequiredField } from 'utils/forms';
import searchWrapper from 'utils/flexSearchWrapper';
import { recalculateMealNutrition } from 'utils/mealUtils';

import { mainStore } from 'storeUtils/mainStore';
import layoutStore, { fixCapitalizeName } from 'storeUtils/layoutStore';
import { shopUtil } from 'storeUtils/shoppingUtils';
import { getAssembledItem } from 'storeUtils/storeUtils';

import FractionControllInput from 'components/FractionControllInput';
import { getAdditionalFoodPoritionType } from 'api/shopping';
import UnitPicker from 'components/UnitPicker/UnitPicker';
import InputSearchAdvanced from 'components/InputSearchAdvanced';
import { addModal } from 'components/ModalWrapper';

import RecipeModal from 'screens/RecipeModal';


/**
 * 
 * @typedef AddShoppingItemModalProps
 * @type {object}
 * @property {object} item food || recipe || custom
 * @property {bool} readonly
 * @property {string} type 'update' || 'add'
 * @property {function} saveAction
 * @property {function} closeAction
 * 
*/

/**
 * @param {AddShoppingItemModalProps} props
 */
class RawAddShoppingItemModal extends Component {
    static class = 'modal-medium';

    portionTypes = {};

    ezState = store({
        searchValue: '',
        item: undefined,
        assembledItem: undefined,
        amount: 1,
        unit: '',
        portion_type_id: 0,
        brand: '',
        loading: false,
        step: 0.5,
        minValue: 0.5
    })

    constructor(props) {
        super(props);

        if (props.item) {
            const unit = shopUtil.getPortionName([props.item], mainStore.portionTypes)

            let source = '';
            let type = '';
            if (props.item.food_id) {
                type = 'food';
                source = shopUtil.getBrand([props.item], props.rangeData.foods);
            }
            if (props.item.recipe_id) {
                type = 'recipe';
                source = shopUtil.getRecipeSiteName([props.item], props.rangeData.recipes);
            }

            batch(() => {
                this.ezState.searchValue = '';
                this.ezState.update = '';
                this.ezState.item = {
                    ...props.item,
                    type: type,
                    brand: source
                };
                this.ezState.assembledItem = undefined;
                this.ezState.amount = props.item.amount;
                this.ezState.unit = unit;
                this.ezState.portion_type_id = props.item.food_id ? props.item.portion_type_id : 0

            })
        }
    }

    componentWillUnmount() {
        layoutStore.profileWizard.errors = {}
    }

    /* ======================================== UTILS ======================================== */

    isRecipe = () => {
        if (this.props.item) {
            return this.props.item.recipe_id;
        }
        return this.ezState.item && this.ezState.item.type === 'recipe';
    }

    validateAll(newItem) {
        let isValid = true;
        let item = newItem ? newItem : this.ezState.item

        if (!validateRequiredField({
            fieldName: 'item',
            value: item && this.ezState.amount && !isNaN(this.ezState.amount) && this.ezState.amount > 0,
            validationErrorObject: layoutStore.profileWizard.errors
        })
        ) {
            isValid = false;
        }

        return isValid;
    }

    getType() {
        if (!this.ezState.item) return false;

        let type = this.ezState.item.type && this.ezState.item.type !== 'update' ? this.ezState.item.type : ''

        if (!type) {
            if (this.ezState.item.food_id) {
                type = 'food'
            } else if (this.ezState.item.recipe_id) {
                type = 'recipe'
            }
        }
        return type;
    }

    /* ======================================== EVENT HANDLERS ======================================== */

    onOpenRecipeModal = async (recipe_id) => {
        if (this.ezState.assembledItem && this.ezState.assembledItem.id === recipe_id) {
            addModal(RecipeModal, { item: this.ezState.assembledItem, index: 0, noAddToMeal: true });
        }
    }

    onSearchChange = (value) => {
        this.ezState.searchValue = value;
        if (value !== '') {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = setTimeout(async () => {
                const results = await searchWrapper.search(value, {
                    globalCount: 500
                });
                this.ezState.searchResults = results;
                this.searchTimeout = null;
            }, 300);
        } else {
            this.ezState.searchResults = [];
        }
    }

    onSubmit() {
        if (this.ezState.item) {
            console.log('[AddShoppingItemModal.js] plain', this.ezState.item)
            if (this.validateAll()) {
                const item = { ...this.ezState.item, amount: this.ezState.amount, unit: this.ezState.unit, portion_type_id: this.ezState.portion_type_id };

                if (item.type === 'recipe') {
                    item.recipe_id = item.id
                }
                if (item.type === 'food') {
                    item.food_id = item.id
                }

                if (item.portion_type_id) {
                    item.unit = '';
                }

                this.props.saveAction(item, this.ezState.assembledItem, this.props.closeAction);
            }
        } else {
            if (this.ezState.searchValue) {
                const newItem = { name: this.ezState.searchValue, id: -1, unit: this.ezState.unit, amount: this.ezState.amount }
                this.props.saveAction(newItem, this.ezState.assembledItem, this.props.closeAction);
                this.props.closeAction();
            } else {
                this.validateAll();
            }
        }
    }

    onClearSelection() {
        if (this.props.readonly) return;

        validateRequiredField({
            fieldName: 'item',
            value: undefined,
            validationErrorObject: layoutStore.profileWizard.errors
        })

        batch(() => {
            this.ezState.item = undefined;
            this.ezState.searchValue = '';
        })
    }

    onFoodSelected = async (food) => {
        if (this.ezState.loading) return false;

        this.ezState.loading = true;
        const assembledItem = await getAssembledItem(food.id, 'food', this.ezState.amount);

        if (this.portionTypes[food.id] === undefined) {
            const result = await getAdditionalFoodPoritionType(food.id);
            if (result && result?.portionType) {
                const portionTypeElement = mainStore.portionTypes.find(x => x.id === result.portionType.portion_type_id)
                this.portionTypes[food.id] = portionTypeElement;
            }
        }

        batch(() => {
            this.ezState.item = food;
            this.ezState.assembledItem = assembledItem;
            this.ezState.unit = this.portionTypes[food.id].name;
            this.ezState.portion_type_id = this.portionTypes[food.id].id;
            this.ezState.brand = food.brand;
            this.ezState.loading = false;
        })
    }

    onRecipeSelected = async (recipe) => {
        if (this.ezState.loading) return false;

        this.ezState.loading = true;
        const assembledItem = await getAssembledItem(recipe.id, 'recipe', 1);
        const amount = assembledItem.extra_portions + assembledItem.amount;
        assembledItem.amount = amount;
        assembledItem.extra_portions = 0;
        recalculateMealNutrition(assembledItem);


        batch(() => {
            this.ezState.item = recipe;
            this.ezState.amount = 1;
            this.ezState.baseAmount = amount;
            this.ezState.assembledItem = assembledItem;
            this.ezState.unit = undefined;
            this.ezState.portion_type_id = 0;
            this.ezState.loading = false;
        })
    }

    /* ======================================== RENDER ======================================== */

    renderLabel() {
        return <Label backText><span>Selected</span></Label>
    }

    renderIcon(type) {
        if (type === 'food') {
            return <Icon icon={icons.rteIcon} className="rte-icon" />
        } else if (type === 'recipe') {
            return <Icon icon={icons.recipe} className="recipe-icon" />
        }
        return <Text large className='custom-icon'></Text>
    }

    renderBrand() {
        if (this.ezState.item?.brand) {
            return <i className="pl-1 ingredient-brand-name">{fixCapitalizeName(this.ezState.item.brand)}</i>
        }
        return '';
    }

    renderSiteName() {
        if (this.ezState.item?.brand) {
            if (this.ezState.item.recipe_site_url) {
                return <a href={this.ezState.item.recipe_site_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pl-1 ingredient-site-name">
                    {this.ezState.item.brand}
                </a>
            }
            return <i className="pl-1 ingredient-site-name">{this.ezState.item.brand}</i>
        }
        return '';
    }

    /* ======================================== MAIN RENDER ======================================== */

    render() {
        const type = this.getType();
        return (
            <div className={'shopping-item-modal relative ' + (this.ezState.loading ? 'disabled' : '')}>
                <Text header className="has-title">
                    <Icon icon={icons.edit} />
                    {this.props.type === 'update' ? 'Edit' : 'Add'} Shopping Item
                </Text>

                {this.ezState.item && this.ezState.item.id && <div className="relative label-relative selected-item ">
                    {this.renderLabel()}

                    <span className="name">
                        {this.renderIcon(type)}
                        <span>
                            <span>{fixCapitalizeName(this.ezState.item.name)}</span>
                            {this.ezState.item.type === 'food' ? this.renderBrand() : ''}
                            {this.ezState.item.type === 'recipe' ? this.renderSiteName() : ''}
                        </span>
                    </span>

                    {!this.props.readonly ? <Icon className="close-icon" onClick={() => this.onClearSelection()} icon={icons.closeCircle} /> : ''}
                </div>}

                {this.ezState.item && !this.ezState.item.shopping_id && this.ezState.item.id && this.ezState.item.type === 'recipe' && <Button clear block transformNormal className="show-recipe-button"
                    onClick={() => {
                        if (this.ezState.item.recipe_id) {
                            this.onOpenRecipeModal(this.ezState.item.recipe_id)
                        } else {
                            this.onOpenRecipeModal(this.ezState.item.id)
                        }
                    }}
                >
                    Show recipe
                </Button>}

                <div className={"search " + (this.ezState.item ? 'visible-hidden' : '')}>
                    {!this.ezState.item ? <div className="fill-empty-space">
                        <FA icon='far fa-search' />
                        <Text normal inlineBlock>Search</Text>
                    </div> : ''}

                    <InputSearchAdvanced placeholder="Enter..."
                        searchFunction={async (query, onlyRecipe, onlyRTE, skip) => {
                            if ((!onlyRecipe && !onlyRTE) || (onlyRecipe && onlyRTE)) {
                                return await searchWrapper.search(query, {
                                    globalCount: 500,
                                    skip: skip
                                });
                            }
                            if (onlyRecipe) {
                                return await searchWrapper.search(query, {
                                    recipeCount: 500,
                                    skip: skip
                                });
                            }
                            if (onlyRTE) {
                                return await searchWrapper.search(query, {
                                    foodCount: 500,
                                    skip: skip
                                });
                            }
                        }}
                        label="Search"
                        onSearchChanged={((value) => {
                            batch(() => {
                                this.ezState.item = undefined;
                                this.ezState.assembledItem = undefined;
                                this.ezState.searchValue = value;
                            })
                        })}
                        onElement={(obj) => {
                            this.ezState.searchValue = undefined;

                            if (obj.type === 'food') {
                                this.onFoodSelected(obj);
                            } else {
                                this.onRecipeSelected(obj);
                            }
                        }} delayed
                    />
                </div>

                <div className={"relative amount-controll " + (this.isRecipe() ? 'is-recipe' : '')}>
                    <div>
                        {/* <Label>Amount: </Label> */}
                        <FractionControllInput
                            value={this.ezState.amount}
                            onChange={value => {
                                this.ezState.amount = value;

                                if (this.ezState.assembledItem) { // recalculate stuff for recipe
                                    this.ezState.assembledItem.amount = this.ezState.amount * this.ezState.baseAmount;
                                    recalculateMealNutrition(this.ezState.assembledItem);
                                }
                            }}
                            step={this.ezState.step}
                            minValue={this.ezState.minValue}
                            changeValueByStep
                            bordered
                        />
                    </div>
                    {!this.isRecipe()
                        ? <UnitPicker initSearch={this.ezState.unit}
                            onUnitChange={(unit) => {
                                batch(() => {
                                    this.ezState.unit = unit.name
                                    this.ezState.portion_type_id = unit.portion_type_id
                                })
                            }}
                            onSearchChange={(text) => {
                                batch(() => {
                                    this.ezState.unit = text;
                                    this.ezState.portion_type_id = 0;
                                })
                            }}
                            foodId={this.ezState.item?.type === 'food' ? (this.ezState.item.food_id || this.ezState.item.id) : null} />
                        : ''}
                </div>

                <ProgressButton
                    inProgress={this.ezState.loading}
                    className="ml-auto" extraProps={{ right: true }} onClick={() => {
                        if (this.ezState.loading) return false;
                        this.onSubmit();
                    }}>
                    Confirm
                </ProgressButton>

                <Button onClick={this.props.closeAction}>CANCEL</Button>
            </div>
        );
    }
}

RawAddShoppingItemModal.propTypes = {
    readonly: PropTypes.bool,
    item: PropTypes.object,
    type: PropTypes.string,
    closeAction: PropTypes.func.isRequired,
    saveAction: PropTypes.func.isRequired,
    rangeData: PropTypes.object.isRequired
};


/**
 * 
 * @component
 * @type {{ new(props: AddShoppingItemModalProps): {
    *   props: { 
        *      }
        *  }
        * }}
        */
const AddShoppingItemModal = view(RawAddShoppingItemModal)

export default view(AddShoppingItemModal);