import { store } from '@risingstack/react-easy-state';
import moment from 'moment';
import prepareStoreData from 'utils/prepareStoreData';

/**
 * @typedef RangeType
 * @type {object}
 * @property {string} id
 * @property {string} from - YYYY-MM-DD
 * @property {string} to - YYYY-MM-DD
 * @property {string} className - ugabuga
 * @example {from: 1, to: 2}
 */

const defaults = {
    // list of all ranges in shopping calendar
    shoppingLists: [],

    month: moment().get('month'),
    year: moment().get('year'),
    selectedDate: moment().format('YYYY-MM-DD'),

    /** @type {RangeType} */
    selectedRange: {},

    keyMap: {},
    preparedKeyMap: {},
    unsavedChanges: 0,

    shoppingCategories: [],

    additionalLoading: false,
    loading: false
}


const storeData = prepareStoreData({ ...defaults })

const shoppingStore = store(storeData);


function resetShoppingStoreToDefaults() {
    const newDefaults = JSON.parse(JSON.stringify(defaults));
    for (let i in newDefaults) {
        shoppingStore[i] = newDefaults[i]
    }
}

export default shoppingStore;
export { shoppingStore, defaults, resetShoppingStoreToDefaults };
