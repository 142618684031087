import PropTypes from "prop-types";
import React, { Component } from 'react';
import './Input.scss'
import { view } from '@risingstack/react-easy-state';
import Label from 'components/Label';

class Input extends Component {

    constructor(props) {
        super(props);
        if (this.props.forwardedRef) {
            this.inputRef = this.props.forwardedRef;
        } else {
            this.inputRef = React.createRef();
        }

    }

    componentDidUpdate() {
        if (this.inputRef.current && (this.props.cursorPos !== undefined)) {
            this.inputRef.current.selectionStart = this.props.cursorPos;
            this.inputRef.current.selectionEnd = this.props.cursorPos;
        }
    }

    onKeyUp = (e) => {
        if (this.props.onSubmit) {
            if (e.key === 'Enter') {
                this.props.onSubmit(e);
            }
        }
        if (this.props.onKeyUp) {
            this.props.onKeyUp(e);
        }
    }

    onKeyDown = (e) => {
        if (this.props.onSubmit) {
            if (e.key === 'Enter') {
                this.props.onSubmit(e);
            }
        }
        if (this.props.onKeyDown) {
            this.props.onKeyDown(e);
        }
    }

    onFocus = (e) => {
        if (this.props.onFocus) {
            this.props.onFocus(e);
        }
    }

    onBlur = (e) => {
        if (this.props.onBlur) {
            this.props.onBlur(e);
        }
    }

    render() {
        const leftIconStyle = this.props.leftIcon ? 'left-icon ' : '';
        const leftIcon = this.props.leftIcon ? this.props.leftIcon : function () { };

        const rightIconStyle = this.props.rightIcon ? 'right-icon ' : '';
        const rightIcon = this.props.rightIcon ? this.props.rightIcon : function () { };

        const inputType = this.props.type ? this.props.type : 'text';
        const min = this.props.min ? this.props.min : undefined;
        const max = this.props.max ? this.props.max : undefined;
        const hasError = this.props.hasError;
        const customErrorText = this.props.customErrorText;
        if (this.props.disabled) {
            return (
                <div className={"input-container input input-disabled " +
                    (this.props.className ? ` ${this.props.className} ` : '') +
                    (this.props.inline ? ' inline ' : '') +
                    (this.props.label ? 'label ' : '') +
                    (this.props.mono ? 'monospace ' : '') +
                    leftIconStyle +
                    rightIconStyle
                } placeholder={this.props.placeholder}>
                    {this.props.label && <Label for={this.props.name} backText largeBackText={this.props.largeLabel} hasError={hasError}>
                        {this.props.label}
                    </Label>}
                    {leftIcon()}
                    {this.props.backText && !this.props.value ? <div className={(this.props.mono ? 'monospace backtext ' : 'backtext ')}>{this.props.backText}</div> : undefined}
                    {!this.props.valueHidden ? this.props.value : ''}
                    {this.props.children}
                    {rightIcon()}
                </div>
            )
        }
        if (this.props.password) {
            return (
                <div className={
                    "input-container " +
                    leftIconStyle +
                    rightIconStyle +
                    (hasError ? 'has-error ' : '') +
                    (this.props.inline ? 'inline ' : '') +
                    (this.props.containerClassName ? this.props.containerClassName : '')
                }>
                    <div className={'relative ' + (this.props.label ? 'label-relative ' : '')}>
                        {this.props.label && <Label for={this.props.name} backText largeBackText={this.props.largeLabel} hasError={hasError}>
                            {this.props.label}
                        </Label>}
                        {leftIcon()}
                        {this.props.backText && !this.props.value ? (
                            <div className={(this.props.mono ? 'monospace backtext ' : 'backtext ')}>{this.props.backText}</div>
                        ) : undefined}
                        <input type="password" className={
                            (this.props.className ? this.props.className : '') +
                            (this.props.mono ? 'monospace ' : '') +
                            leftIconStyle
                        }
                            readOnly={this.props.readOnly}
                            onChange={this.props.onChange}
                            onKeyDown={this.onKeyDown}
                            onKeyUp={this.onKeyUp}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            value={this.props.value}
                            placeholder={this.props.placeholder}
                            ref={this.inputRef}
                            autoComplete={this.props.autoComplete}
                            min={min}
                            max={max}
                            maxLength={this.props.maxLength}
                            pattern={this.props.pattern}
                            id={this.props.id}
                            name={this.props.name}
                            lang={this.props.lang}>{this.props.children}


                        </input>
                        {rightIcon()}
                    </div>
                    {hasError ? <div className="validation-error">
                        {customErrorText ? customErrorText : ''}
                    </div> : ''}
                </div>
            )
        }
        return (
            <div className={
                "input-container " +
                leftIconStyle +
                rightIconStyle +
                (hasError ? 'has-error ' : '') +
                (this.props.inline ? 'inline ' : '') +
                (this.props.containerClassName ? this.props.containerClassName : '')
            }>
                <div className={'relative ' + (this.props.label ? 'label-relative ' : '')}>
                    {this.props.label && <Label for={this.props.name} backText largeBackText={this.props.largeLabel} hasError={hasError}>
                        {this.props.label}
                    </Label>}
                    {leftIcon()}
                    {this.props.backText && <div className={(this.props.mono ? 'monospace backtext ' : 'backtext ')}>
                        {this.props.backText}
                    </div>}
                    <input className={
                        (this.props.className ? this.props.className : '') +
                        (this.props.mono ? 'monospace ' : '')
                    } onChange={this.props.onChange}
                        onKeyDown={this.onKeyDown}
                        onKeyUp={this.onKeyUp}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        value={this.props.value}
                        type={inputType}
                        min={min}
                        max={max}
                        maxLength={this.props.maxLength}
                        pattern={this.props.pattern}
                        placeholder={this.props.placeholder}
                        ref={this.inputRef}
                        autoComplete={this.props.autoComplete}
                        id={this.props.id}
                        name={this.props.name}
                        lang={this.props.lang}
                        readOnly={this.props.readOnly}>
                    </input>
                    {rightIcon()}
                    {this.props.children}
                </div>
                {hasError ? <div className="validation-error">
                    {customErrorText && typeof customErrorText === 'string' ? customErrorText : ''}
                </div> : ''}
            </div>
        )
    }
}

Input.propTypes = {
    readOnly: PropTypes.any,
    autoComplete: PropTypes.any,
    backText: PropTypes.any,
    children: PropTypes.any,
    className: PropTypes.any,
    containerClassName: PropTypes.any,
    cursorPos: PropTypes.any,
    customErrorText: PropTypes.any,
    disabled: PropTypes.any,
    forwardedRef: PropTypes.any,
    hasError: PropTypes.any,
    id: PropTypes.any,
    inline: PropTypes.any,
    label: PropTypes.any,
    lang: PropTypes.any,
    largeLabel: PropTypes.any,
    leftIcon: PropTypes.any,
    max: PropTypes.any,
    maxLength: PropTypes.any,
    min: PropTypes.any,
    mono: PropTypes.any,
    name: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.any,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    onSubmit: PropTypes.func,
    password: PropTypes.any,
    pattern: PropTypes.any,
    placeholder: PropTypes.any,
    rightIcon: PropTypes.any,
    type: PropTypes.any,
    value: PropTypes.any,
    valueHidden: PropTypes.bool
}

export default view(Input);
