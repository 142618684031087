import { fetchJSON } from 'utils/fetchJSON';

export function readMessage(messageId) {
  return fetchJSON('/api/message/read', { id: messageId })
    .then((json) => {
      return json;
    })
    .catch(error => {
      return error;
    });
}
