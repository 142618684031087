import React from 'react';
import PropTypes from 'prop-types';
import { view } from '@risingstack/react-easy-state';
import moment from 'moment';

import './WeekCalendar.scss';

import Icon, { icons } from '../Icon';
import Text from 'components/Text';
import { getStartOfWeek } from 'utils/date/getStartOfWeek';

/**
 * @typedef WeekCalendarProps
 * @type {object}
 * @property {object} selectedDay selectedDay object from api
 * @property {bool} lightBlue apply light blue style colors
 * @property {bool} withWeekArrows should week change be visible
 * @property {function} onDaySelect
 * @property {function} onWeekChange
*/

/**
 * @param {WeekCalendarProps} props
 */
const RawWeekCalendar = (props) => {
    const days = [];
    const sow = getStartOfWeek(props.selectedDay?.date);
    for (let i = 0; i < 7; i++) {
        days.push({
            name: sow.format('dd'),
            caption: sow.format('MMM DD'),
            weekend: i > 4,
            date: sow.format('YYYY-MM-DD')
        })
        sow.add(1, 'day');
    }

    const onSelectDay = (index) => {
        if (typeof props.onDaySelect === 'function') {
            props.onDaySelect(index)
        }
    }

    const onWeekChange = (offset = 0) => {
        if (typeof props.onWeekChange === 'function') {
            const firstWeekDay = moment(days[0].date, 'YYYY-MM-DD');

            const dateFrom = firstWeekDay.add(offset, 'week').format('YYYY-MM-DD')
            props.onWeekChange(dateFrom)
        }
    }

    return (
        <div className={
            'week-calendar ' +
            (props.lightBlue ? 'light-blue ' : '') +
            (props.withWeekArrows ? 'with-arrows ' : '')
        }>
            {props.withWeekArrows ? <Icon icon={icons.arrowHeadLeft} className="week-calendar-arrow" onClick={() => onWeekChange(-1)} /> : ''}
            {days.map((day, index) => (days[index].date === props.selectedDay.date)
                ?
                <div key={index} className={"box day active" + (day.weekend ? ' day-weekend' : '')} active="true">
                    <Text large inlineBlock fullWidth>{day.name}</Text>
                    <Text className="day-caption" inlineBlock fullWidth>{day.caption}</Text>
                </div>
                :
                <div key={index} className={"box day clickable" + (day.weekend ? ' day-weekend' : '')} onClick={() => onSelectDay(index)}>
                    <Text large inlineBlock fullWidth>{day.name}</Text>
                    <Text className="day-caption" inlineBlock fullWidth>{day.caption}</Text>
                </div>
            )}
            {props.withWeekArrows ? <Icon icon={icons.arrowHeadLeft} className="week-calendar-arrow right" onClick={() => onWeekChange(1)} /> : ''}
        </div>
    );
}

RawWeekCalendar.propTypes = {
    selectedDay: PropTypes.object.isRequired,
    onDaySelect: PropTypes.func.isRequired,
    onWeekChange: PropTypes.func.isRequired,
    lightBlue: PropTypes.bool,
    withWeekArrows: PropTypes.bool
};
/**
 * 
 * @component
 * @type {{ new(props: WeekCalendarProps): {
 *   props: { 
 *     selectedDay: object, 
 *     lightBlue: boolean, 
 *     withWeekArrows: boolean, 
 *     onDaySelect: function,
 *     onWeekChange: function,
 *     }
 *   }
 *  }}
 */
const WeekCalendar = view(RawWeekCalendar);
export default WeekCalendar;