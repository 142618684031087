import React from 'react';

const homeSecondIcon = () => (
    <svg x="0px" y="0px" viewBox="0 0 70 70" style={{ transform: 'scale(1.2)' }}>
        <path d="M14.309,37.551v24.312c0.013,0.927,0.61,1.507,1.089,1.783c0.512,0.294,1.064,0.419,1.651,0.422
			h35.903c0.587-0.003,1.134-0.127,1.647-0.422c0.485-0.276,1.076-0.86,1.093-1.783V37.551l-20.692-18.93L14.309,37.551z
			 M34.991,40.027v2.023c1.388-1.507,3.332-2.261,5.355-2.261c2.063,0,3.768,0.674,4.958,1.825c1.547,1.507,2.023,3.411,2.023,5.673
			v12.852h-4.046V47.881c0-3.134-1.785-4.482-4.046-4.482c-2.261,0-4.165,1.388-4.165,4.482v12.258h-4.046V40.027h1.003
			c0.047-0.293,0.103-0.621,0.173-0.971c0.127-0.633,0.514-1.906,0.829-2.902c-0.191,0.069-0.495,0.181-0.679,0.264
			c-0.283,0.128-1.494,1.183-1.602,1.366c-0.902,1.528-1.978,3.124-2.64,4.081c-0.799,1.256-1.499,2.376-1.639,2.667
			c-0.358,0.744-0.882,2.351-1.047,2.984s-0.358,2.626-0.358,2.626s-0.043-0.085-0.11-0.218c-0.002,0.137-0.002,0.223-0.002,0.223
			s-3.965-3.897-4.335-5.113c-1.04-3.42,1.213-6.445,2.153-7.233c0.477-0.327,2.703-2.585,6.062-1.911
			c1.025,0.206,1.384,0.51,1.759,0.553c0.375,0.043,0.904-0.151,0.904-0.151l1.773-0.886c0.126-0.388,0.212-0.646,0.212-0.646
			l0.05-1.03c0,0,1.306,0.075,1.507,1.005c-0.226,0.226-0.628,0.352-0.779,0.879c-0.151,0.528-0.553,2.186-0.553,2.186l-0.402,1.658
			l-0.098,0.57H34.991z"/>
        <path d="M63.741,30.847l-9.637-8.814V8.304c0-0.832-0.676-1.507-1.512-1.507h-3.641
			c-0.834,0-1.51,0.675-1.51,1.507v7.634L37.618,6.95c-1.487-1.359-3.751-1.359-5.238,0L6.261,30.847
			c-1.58,1.447-1.69,3.9-0.245,5.481c0.765,0.837,1.812,1.26,2.864,1.26c0.935,0,1.872-0.336,2.619-1.016l23.5-21.503l23.504,21.503
			c1.578,1.447,4.033,1.336,5.483-0.244C65.429,34.747,65.319,32.293,63.741,30.847z"/>
    </svg>
)

export default homeSecondIcon;