import React from 'react';

const percentIcon = () => (
    <svg viewBox="0 0 60 60">
        <path d="M41.201,30.358c-2.421,0-3.631,2.05-3.631,6.148c0,4.055,1.21,6.083,3.631,6.083
			c2.462,0,3.692-2.028,3.692-6.083C44.893,32.408,43.663,30.358,41.201,30.358z"/>
        <path d="M22.002,23.491c0-4.098-1.231-6.148-3.692-6.148c-2.38,0-3.569,2.05-3.569,6.148
			c0,4.012,1.189,6.017,3.569,6.017C20.771,29.508,22.002,27.503,22.002,23.491z"/>
        <path d="M30,0C13.431,0,0,13.431,0,30s13.431,30,30,30s30-13.431,30-30S46.569,0,30,0z M11.049,23.491
			c0-6.41,2.42-9.615,7.261-9.615c4.881,0,7.323,3.205,7.323,9.615c0,6.367-2.441,9.549-7.323,9.549
			C13.469,33.04,11.049,29.858,11.049,23.491z M22.002,46.186h-3.754l18.768-31.721h3.754L22.002,46.186z M41.201,46.121
			c-4.882,0-7.323-3.205-7.323-9.614c0-6.366,2.44-9.549,7.323-9.549c4.923,0,7.384,3.183,7.384,9.549
			C48.585,42.916,46.124,46.121,41.201,46.121z"/>
    </svg>
)

export default percentIcon;