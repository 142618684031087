import React, { Component } from 'react';
import { store, view } from '@risingstack/react-easy-state';
import PropTypes from 'prop-types';

import './NotificationWrapper.scss';

import Notification from '../Notification';
import { readMessage } from 'api/readMessage';

const notificationStore = store({
    notifications: [],
    toClose: null
})

/**
 * @typedef AddNotifiacationOptions
 * @type {object}
 * @property {number} timeout
*/

/**
 * 
 * @param {string} text 
 * @param {AddNotifiacationOptions} options 
 */
function addNotification(text, options, id = null) {
    notificationStore.notifications.push({
        text,
        options: options ? options : {},
        id
    })
}

class NotificationWrapper extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (Array.isArray(this.props.notifications) && this.props.notifications.length > 0) {
            this.props.notifications.forEach(n => {
                addNotification(n.text, n.options, n.id)
            })
        }
    }

    render() {
        return (
            <div className="notification-wrapper">
                {(notificationStore.notifications.length > 0) && notificationStore.notifications.map((el, index) => {
                    const closeFunction = async () => {
                        notificationStore.toClose = index;

                        setTimeout(async () => {
                            notificationStore.toClose = null;
                            notificationStore.notifications.splice(index, 1);
                            if (el.id) {
                                await readMessage(el.id);
                            }
                        }, 250)
                    }
                    return <Notification key={index} closeFunction={closeFunction} className={
                        '' +
                        (el?.options?.error ? 'with-error ' : '') +
                        (notificationStore.toClose === index ? 'closing' : '')
                    } notification={el} />
                })}
            </div>
        )
    }
}

export { notificationStore, addNotification };

NotificationWrapper.propTypes = {
    notifications: PropTypes.array
};

export default view(NotificationWrapper);