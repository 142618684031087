
import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';

import './SettingsPatternModalMyMenu.scss';

import SettingsPatternMyMenu from 'screens/SettingsPatternMyMenu';

class SettingsPatternModalMyMenu extends Component {
    static class = 'modal-extra-large';

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="settings-pattern-modal">
                <SettingsPatternMyMenu selected={this.props.selected} />
            </div>
        );
    }
}

export default view(SettingsPatternModalMyMenu);