import { fetchJSON } from 'utils/fetchJSON';
import layoutStore from 'storeUtils/layoutStore';

export function changePassword(oldPassword, newPassword) {
    layoutStore.bigLoading = true;
    return fetchJSON('/api/changePassword', { old: oldPassword, new: newPassword })
        .then((json) => {
            layoutStore.bigLoading = false;

            return json;
        })
        .catch(error => {
            console.log('[api/login/changePassword.js] Error: ', error)
            layoutStore.bigLoading = false;
            return undefined;
        });
}
