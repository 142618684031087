import React from 'react';

const sofaIcon = () => (
    <svg version="1.1" id="Isolation_Mode" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" viewBox="0 0 184.1 176.6" space="preserve">
        <g>
            <g>
                <path d="M156.4,162.9c6.5-2.5,6.6-8.9,6.6-11.6l0-52.6c2.5-1.6,4.8-3.7,6.5-6.2c2.7-3.8,4.2-8.5,4.2-13.6
			c0-4.1-1-7.9-2.8-11.2c-2.1-4-5.4-7.3-9.3-9.4v0c-3.1-1.6-6.6-2.6-10.3-2.6c-8.5,0-15.8,4.9-19.6,12C130,71.1,129,74.9,129,79
			v34.6c0,1.4-1.1,2.5-2.4,2.5H57.6c-1.4,0-2.4-1.1-2.4-2.5V79c0-4.1-1-7.9-2.8-11.2c-3.8-7.1-11.2-12-19.6-12
			c-3.7,0-7.2,0.9-10.3,2.6v0c-3.9,2.1-7.2,5.4-9.3,9.4c-1.8,3.3-2.8,7.1-2.8,11.2c0,5.1,1.6,9.8,4.2,13.6c1.7,2.5,4,4.6,6.5,6.2
			v54.4c0,5.5,4.3,9.8,9.5,9.8 M58.4,163"/>
            </g>
            <path d="M153.4,44.7c-0.6-0.1-1.3-0.1-2-0.1c-14.5,0-26.9,9.7-31.4,23.2c-1.2,3.5-1.8,7.3-1.8,11.2v23.6
		c0,1.3-1,2.4-2.3,2.4H68.3c-1.3,0-2.3-1.1-2.3-2.4V79c0-3.9-0.6-7.7-1.8-11.2c-4.5-13.5-16.9-23.2-31.4-23.2c-0.7,0-1.4,0-2,0.1
		c14.1-19,36.3-31.1,61.3-31.1C117,13.6,139.2,25.8,153.4,44.7z"/>
        </g>
    </svg>
)

export default sofaIcon;