import { fetchJSON } from 'utils/fetchJSON';

export default function setFirstPassword(forgot_code, user_id, password) {
    return fetchJSON('/api/setFirstPassword', {
        forgot_code: forgot_code, user_id: user_id, password: password
    }).then((json) => {
        return json;
    })
        .catch(error => {
            console.log('[/api/setFirstPassword.js] Error: ', error);
            return undefined;
        });
}
