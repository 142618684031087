import validateRequiredField from "./validateRequiredField";

/**
 * Email validation function
 * @param {string} fieldName 
 * @param {string} value 
 * @param {object} validationErrorObject 
 */
function validateEmail(fieldName, value, validationErrorObject) {
    const testRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let isValid = true;

    if (!validateRequiredField({
        fieldName: fieldName,
        value: value,
        validationErrorObject: validationErrorObject
    })
    ) {
        isValid = false;

    } else if (!testRegex.test(value)) {
        isValid = false;
        validationErrorObject[fieldName] = 'Typed email adress is invalid.'
    }

    return isValid;
}

export default validateEmail;