import shoppingStore from 'storeUtils/shoppingStore';
import { fetchJSON } from 'utils/fetchJSON';

export function getShoppingByPermalink(permalink) {
    return fetchJSON('/api/shopping-list/shared', {
        permalink
    }).then((json) => {
        return json;
    }).catch(error => {
        console.log("ERROR", error);
        return undefined;
    });
}

export function getShoppingForSelection({ id, from, to }) {
    return fetchJSON('/api/shopping-list', {
        id, from, to
    }).then((json) => {
        return json;
    }).catch(error => {
        console.log("ERROR", error);
        return undefined;
    });
}

export function confirmShoppingForSelection({ id, from, to }, person_id) {
    return fetchJSON('/api/shopping-list/confirm', {
        id, from, to, person_id
    }).then((json) => {
        return json;
    }).catch(error => {
        console.log("ERROR", error);
        return undefined;
    });
}

export function removeShoppingForSelection(shopping_id) {
    return fetchJSON('/api/shopping-list/remove', {
        shopping_id
    }).then((json) => {
        return json;
    }).catch(error => {
        console.log("ERROR", error);
        return undefined;
    });
}

export function updateShopping({ id, shopping_elements, elements_to_remove }) {
    return fetchJSON('/api/shopping-list/update', {
        shopping_id: id,
        shopping_elements,
        elements_to_remove,
    }).then((json) => {
        return json;
    }).catch(error => {
        console.log("ERROR", error);
        return undefined;
    });
}

export function setCurrentMenuShopping({ id, from, to, person_id }) {
    return fetchJSON('/api/shopping-list/current-menu', {
        shopping_id: id,
        from,
        to,
        person_id
    }).then((json) => {
        return json;
    }).catch(error => {
        console.log("ERROR", error);
        return undefined;
    });
}

export function refreshShopping({ id, from, to, person_id }) {
    return fetchJSON('/api/shopping-list/refresh', {
        shopping_id: id,
        from,
        to,
        person_id
    }).then((json) => {
        return json;
    }).catch(error => {
        console.log("ERROR", error);
        return undefined;
    });
}

export function getAdditionalFoodPoritionType(food_id) {
    return fetchJSON('/api/shopping-additional/unit', {
        food_id: food_id
    }).then((json) => {
        return json;
    }).catch(error => {
        console.log("ERROR", error);
        return undefined;
    });
}

export function addNewAdditionalShoppingElement(newItem) {
    return fetchJSON('/api/shopping-additional/new', newItem).then((json) => {
        return json;
    }).catch(error => {
        console.log("ERROR", error);
        return undefined;
    });
}

/**
 * 
 * @param {*} shopping_additional_id 
 * @param {*} shopping_id 
 * @param {*} amount 
 * @param {*} unit 
 * @param {*} portion_type_id 
 * @returns 
 */
export function updateAdditionalShoppingElement(person_id, shopping_additional_id, shopping_id, amount, unit, portion_type_id) {
    return fetchJSON('/api/shopping-additional/update', {
        person_id: person_id,
        shopping_additional_id: shopping_additional_id,
        shopping_id: shopping_id,
        amount: amount,
        unit: unit,
        portion_type_id: portion_type_id
    }).then((json) => {
        return json;
    }).catch(error => {
        console.log("ERROR", error);
        return undefined;
    });
}

export function removeAdditionalShoppingElement({ shopping_id, id }) {
    return fetchJSON('/api/shopping-additional/remove', { shopping_id, id }).then((json) => {
        return json;
    }).catch(error => {
        console.log("ERROR", error);
        return undefined;
    });
}

export function getSpecialDays() {
    return shoppingStore.shoppingLists;

}