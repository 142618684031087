import React, { Component } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import PropTypes from "prop-types";
import { view } from '@risingstack/react-easy-state';

import "./SharedShopping.scss"

import { shopUtil } from 'storeUtils/shoppingUtils';
import { mainStore } from 'storeUtils/mainStore';
import layoutStore from 'storeUtils/layoutStore';
import shoppingStore from 'storeUtils/shoppingStore';

import empoweredImg from 'images/empowered-logo.png';

import { FA, Icon, icons } from 'components/Icon';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Loading from 'components/Loading';
import Logo from 'components/Logo';
import HeaderBar, { HeaderBarButton, HeaderBarCaption } from 'components/HeaderBar';
import Tooltip from 'components/Tooltip';
import HeaderButton from 'components/HeaderButton';
import ModalWrapper, { addModal } from 'components/ModalWrapper';
import Text from 'components/Text';
import Button from 'components/Button';
import NotificationWrapper from 'components/NotificationWrapper';

import LogoutModal from 'screens/LogoutModal';
import ShoppingList from 'screens/ShoppingList';

import { getShoppingByPermalink } from 'api/shopping';

const SharedShopping = () => {
    const { id } = useParams();
    let navigate = useNavigate();
    const { pathname } = useLocation();

    return <RawSharedShoppingWithView permalink={id} navigate={navigate} pathname={pathname} />
}

class RawSharedShopping extends Component {
    constructor(props) {
        super(props);
        this.state = {}

        if (props.permalink && !shoppingStore.loading) {
            this.getShopping(props.permalink);
        } else {
            shoppingStore.message = 'Shared Shopping List not exist';

        }

    }

    componentWillUnmount() {
        layoutStore.request = {
            progress: false,
            message: false,
            error: false
        }

        shoppingStore.preparedKeyMap = {}
        shoppingStore.message = undefined
    }

    prepareResultList = (shopping) => {
        const rangeId = shoppingStore.selectedRange.id;
        shoppingStore.preparedKeyMap[rangeId] = shopUtil.createRangeData(shopping);
    }

    getShopping = async (permalink) => {
        shoppingStore.loading = true;
        const shoppingForSelection = await getShoppingByPermalink(permalink)
        if (mainStore.portionTypes.length === 0) {
            mainStore.portionTypes = shoppingForSelection.portionTypes;
        }
        if (shoppingStore.shoppingCategories.length === 0) {
            shoppingStore.shoppingCategories = shoppingForSelection.shoppingCategories;
        }

        if (shoppingForSelection && shoppingForSelection?.request?.success) {
            const { id, from, to, permalink, done, obsolete } = shoppingForSelection
            shoppingStore.selectedRange = { id, from, to, permalink, done, obsolete }

            this.prepareResultList(shoppingForSelection)

            shoppingStore.message = shoppingForSelection.request.message;
        }
        shoppingStore.loading = false;
    }

    getDefaultHeader() {
        return <Header />
    }

    getLoggedHeader() {
        return <Header>
            <HeaderButton to={"/"}><Icon icon={icons.homeSecond} />Home</HeaderButton>
            <HeaderButton to={"/MyMenu/"}><Icon icon={icons.myMenu} />My Menu</HeaderButton>
            <HeaderButton to={"/Shopping/"}><Icon icon={icons.shopping} />Shopping</HeaderButton>
            <HeaderButton to={"recipes"}><Icon icon={icons.recipe} />Recipes</HeaderButton>
            <HeaderButton to={"/History/"}><Icon icon={icons.foodLog} />History</HeaderButton>
        </Header>
    }

    render() {
        return (
            <div className="relative">
                <Loading invisible={!layoutStore.bigLoading} />
                <NotificationWrapper notifications={mainStore.notifications} />
                <ModalWrapper />

                <div className={'appWrapper ' + (!layoutStore.loggedIn ? 'not-logged ' : '')}>
                    {layoutStore.loggedIn ? this.getLoggedHeader() : this.getDefaultHeader()}


                    <HeaderBar>
                        <Tooltip className="float-left" text="Help">
                            <HeaderBarButton darker left to={"/Help/"}><Icon icon={icons.help} /></HeaderBarButton>
                        </Tooltip>

                        <HeaderBarCaption className="hide-xs">Shopping List</HeaderBarCaption>

                        {layoutStore.loggedIn ?
                            <Tooltip className="float-right" text="Logout">
                                <HeaderBarButton darker right onClick={() => {
                                    addModal(LogoutModal, { closeOnBg: true, navigate: this.props.navigate, pathname: this.props.pathname })

                                }} ><FA color="white" icon="fa fa-sign-out-alt" /></HeaderBarButton>
                            </Tooltip>
                            :
                            <Tooltip className="float-right" text="Log in">
                                <HeaderBarButton dark right onClick={() => this.props.navigate('/')}> <Icon icon={icons.password} color="#FFF" /></HeaderBarButton>
                            </Tooltip>
                        }

                    </HeaderBar>

                    <div className="content">
                        <div className="shared-shopping">
                            {shoppingStore.message && <div className="shared-message">
                                <Text large accent textCenter fullWidth >
                                    <FA icon="fas fa-lg fa-info-circle" className="pr-1" />
                                    {shoppingStore.message}
                                </Text>
                                <Button outline bordered noShadow transformNormal to="/" className="mt-3 back-login-btn">
                                    <FA icon="fas fa-sm fa-chevron-left" className="pr-1" />
                                    Back to Home
                                </Button>
                            </div>}
                            <ShoppingList
                                static={true}
                                prepareResultList={this.prepareResultList}
                            />
                        </div>
                    </div>

                    <div className="logoContainer empowered">
                        <Logo><img src={empoweredImg} /></Logo>
                    </div>

                    <Footer />
                </div>
            </div>
        );
    }
}

RawSharedShopping.propTypes = {
    pathname: PropTypes.string.isRequired,
    navigate: PropTypes.func.isRequired,
    permalink: PropTypes.string.isRequired
};

const RawSharedShoppingWithView = view(RawSharedShopping)

export default view(SharedShopping);
