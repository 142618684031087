import React from 'react';

const searchIcon = () => (
    <svg viewBox="0 0 59.996 60">
        <path d="M40.066,6.865c-9.153-9.153-24.05-9.153-33.203,0c-9.151,9.155-9.151,24.05,0,33.205
    c8.151,8.149,20.846,9.022,29.993,2.659c0.192,0.911,0.633,1.78,1.341,2.488l13.33,13.33c1.942,1.939,5.081,1.939,7.014,0
    c1.941-1.941,1.941-5.079,0-7.014l-13.33-13.334c-0.704-0.702-1.575-1.145-2.486-1.337C49.092,27.712,48.219,15.02,40.066,6.865z
      M35.858,35.861c-6.833,6.833-17.955,6.833-24.786,0c-6.829-6.833-6.829-17.953,0-24.786c6.831-6.831,17.953-6.831,24.786,0
    C42.691,17.909,42.691,29.028,35.858,35.861z"/>
    </svg>
)

export default searchIcon;