
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { view } from '@risingstack/react-easy-state';

import { logout } from 'api/login/verifyLogin';

import './LogoutModal.scss';

import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { FA } from 'components/Icon';
import layoutStore from 'storeUtils/layoutStore';

class LogoutModal extends Component {

    static class = 'modal-medium modal-mobile-transparent modal-mobile-center';

    render() {

        return (
            <div className="logout-modal" >
                <div className="modal-content">
                    <Text header>Logout</Text>
                    <Text normal>{layoutStore.routerBlocked ? 'You are leaving the page without saving changes. Do you really want to logout?' : 'Do you really want to logout?'}</Text>
                </div>

                <Button className="logout-button-mobile" onClick={e => this.onSubmit()}>
                    <FA className="logout-icon" icon="fa fa-sign-out-alt" /><br /> Logout
                </Button>

                <div className="modal-footer">
                    <Button className="logout-button-cancel" transformNormal large inline onClick={e => this.props.closeAction()}>Cancel</Button>
                    <Button green transformNormal noShadow large inline onClick={e => this.onSubmit()}><FA color="white" className="pr-1" icon="fa fa-sign-out-alt" />Logout</Button>
                </div>
            </div>
        );
    }

    onSubmit() {
        if (typeof logout === "function") {
            this.props.closeAction()
            logout()
            this.props.navigate('/')
        }
    }
}

LogoutModal.propTypes = {
    pathname: PropTypes.string.isRequired,
    navigate: PropTypes.func.isRequired,
};

export default view(LogoutModal);