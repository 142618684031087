import React from 'react';

import './FooterButton.scss';
import { view } from '@risingstack/react-easy-state';

const FooterButton = view((props) => {
    return <a className="footer-button" href={props.to} target="_blank" rel="noreferrer">
        {props.children}
    </a>
});

export default FooterButton;