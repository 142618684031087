import React, { Suspense } from 'react';
import { Route, Routes, useNavigate, useLocation, useParams } from 'react-router-dom';
import { view } from '@risingstack/react-easy-state';

import './App.scss';

import { Loading } from 'components/Loading';
import IosPwaInstallPrompt from 'components/IosPwaInstallPrompt';
import ModalWrapper from 'components/ModalWrapper';

import LoginScreen from 'screens/LoginScreens';
import Register from 'screens/LoginScreens/Register';
import ForgotPassword from 'screens/LoginScreens/ForgotPassword';
import ForgotCode from 'screens/LoginScreens/ForgotCode';
import Activation from 'screens/Activation';
import SharedShopping from 'screens/SharedShopping';

import layoutStore from 'storeUtils/layoutStore';
import mainStore from 'storeUtils/mainStore';

const Client = React.lazy(() => import('./Client'));
const Admin = React.lazy(() => import('screens/Admin'));
const AccountSetup = React.lazy(() => import('screens/AccountSetup'));

// TOOD: Wsplit mainStore to proper store subdomains

function App() {
    let navigate = useNavigate();
    const { pathname } = useLocation();

    if (layoutStore.loggedIn === false && layoutStore.initialLoading) {
        return <Loading className="fadeIn one" transparent logo spinnerHidden />
    }

    if (pathname.includes('/s/')) {
        return <Routes>
            <Route path="s/:id" element={<SharedShopping />} />
        </Routes>
    }

    if (layoutStore.loggedIn === false) {
        return <div className="relative">
            <Loading
                invisible={!layoutStore.selectUserProfileLoading}
                logoClassName={layoutStore.selectUserProfileLoading ? 'fadeIn one' : ''}
                withoutTransition
                white logo
                spinnerHidden
            />
            <ModalWrapper />
            <Routes>
                <Route index exact path="/" element={<LoginScreen />} />
                <Route path="register" element={<Register />} />
                <Route path="forgotpassword" element={<ForgotPassword />} />
                <Route path="forgotcode" element={<ForgotCode />} />
                <Route path="setPassword" element={<ForgotCode setPassword />} />
                <Route path="activation" element={<Activation />} />
                <Route path="register" element={<Register />} />
                <Route path='*' element={<LoginScreen />} />
            </Routes>
            <IosPwaInstallPrompt />
        </div>
    }

    if (mainStore.profile.ready === false) {
        if (pathname && !pathname.startsWith('/account-wizard')) {
            navigate('/account-wizard/personal/');
        }
        return <Suspense fallback={<Loading className="fadeIn one" transparent logo spinnerHidden />}>
            <AccountSetup />
        </Suspense>
    }

    if (pathname.includes('/admin') && layoutStore.isAdmin) {
        // TODO: after getBaseInformation I need to receive flag about user role
        // TODO: check if we can protect admin chunk files to admin user only
        // TODO: write permission checks to admin server endpoints
        return <Suspense fallback={<Loading className="fadeIn one" transparent logo spinnerHidden />}>
            <Admin />
        </Suspense>
    }

    return <Suspense fallback={<Loading className="fadeIn one" transparent logo spinnerHidden />}>
        <Client />
    </Suspense>
}

export default view(App);
