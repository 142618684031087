import React, { Component } from 'react';
import { view, store } from '@risingstack/react-easy-state';
import ReactResizeDetector from 'react-resize-detector';

import './TutorialWrapper.scss';

import Container from 'components/Container';
import Text from 'components/Text';
import Icon, { icons } from 'components/Icon';

// EXAMPLE USAGE:
// addTutorial(this.ref, { text: 'Example text', showOnly: true allowNeverShowAgain: true, onNext: () => addTutorial(...) })

const tutorialStore = store({
    windows: [],
    tutRefs: [],
    className: ''
})

function clearTutorial() {
    tutorialStore.windows.map((el) => el.ref.current ? el.ref.current.removeAttribute('data-tutorial') : '')
    tutorialStore.windows = [];
    tutorialStore.tutRefs = [];
    tutorialStore.className = '';
}

function addTutorial(ref, props, /*onClose*/) {
    if (ref && ref.current) {
        ref.current.setAttribute('data-tutorial', 'active');
        const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = ref.current;
        if (props.showOnly) {
            clearTutorial();
            tutorialStore.windows = [{
                ref: ref,
                props: props,
                top: offsetTop,
                left: offsetLeft,
                width: offsetWidth,
                height: offsetHeight
            }]

            if (props && props.className) {
                tutorialStore.className = props.className
            }

            if (props.timeout && !isNaN(props.timeout)) {
                setTimeout(() => {
                    clearTutorial();
                }, props.timeout);
            }
        } else {
            tutorialStore.windows.push({
                ref: ref,
                props: props,
                top: offsetTop,
                left: offsetLeft,
                width: offsetWidth,
                height: offsetHeight
            })
        }
    }
}

const ARROW_SIZE = 24
class TutorialWrapper extends Component {
    constructor(props) {
        super(props)
    }

    onResize() {
        for (let index = 0; index < tutorialStore.windows.length; index++) {
            const tutElement = tutorialStore.windows[index];
            if (tutElement.ref.current) {
                const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = tutElement.ref.current;

                tutElement.top = offsetTop
                tutElement.left = offsetLeft
                tutElement.width = offsetWidth
                tutElement.height = offsetHeight
            }
        }
    }

    render() {
        if (tutorialStore.windows.length === 0) {
            return null;
        }

        return (
            <div className={'tutorialWrapper ' + tutorialStore.className}>
                <ReactResizeDetector
                    handleWidth
                    handleHeight
                    onResize={(/*width, height*/) => this.onResize()}
                />
                { (tutorialStore.windows.length > 0) && tutorialStore.windows.map((el, index) => {
                    const closeFunction = (data) => {
                        if (el.ref.current) {
                            el.ref.current.removeAttribute('data-tutorial');
                        }
                        tutorialStore.windows.splice(index, 1);
                        tutorialStore.tutRefs = tutorialStore.tutRefs.splice(index, 1)
                        tutorialStore.className = '';

                        if (el.onClose) {
                            el.onClose(data);
                        }
                    }

                    const getBaseTop = (el, index) => tutorialStore.tutRefs[index] ?
                        el.top - tutorialStore.tutRefs[index].clientHeight
                        : undefined;

                    const getBaseLeft = (el, index) => tutorialStore.tutRefs[index] ?
                        el.left + (el.width / 2) - (tutorialStore.tutRefs[index].clientWidth / 2)
                        : undefined

                    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
                    const isOverRightViewport = (left, tutWidth) => (left + tutWidth) > vw

                    const getTop = (el, index) => {
                        return getBaseTop(el, index)
                    }


                    const getLeft = (el, index) => {
                        if (tutorialStore.tutRefs[index]) {
                            const left = getBaseLeft(el, index)

                            if (left < 0) {
                                return el.left + (el.width / 2) - (ARROW_SIZE / 2)
                            } else if (isOverRightViewport(el.left, tutorialStore.tutRefs[index].clientWidth)) {
                                return el.left + el.width - tutorialStore.tutRefs[index].clientWidth - (el.width / 2) + (ARROW_SIZE / 2);
                            }
                            return left
                        }
                    }

                    const getClassName = (el, index) => {
                        if (tutorialStore.tutRefs[index]) {
                            if (getBaseLeft(el, index) < 0) {
                                return 'arrow-left '
                            }

                            if (isOverRightViewport(el.left, tutorialStore.tutRefs[index].clientWidth)) {
                                return 'arrow-right '
                            }
                        }
                        return ''
                    }

                    return (
                        <div key={index} className={'tutorial-element ' + getClassName(el, index)}
                            ref={el => tutorialStore.windows[index] && !tutorialStore.tutRefs[index] && el ? tutorialStore.tutRefs.push(el) : ''}
                            style={{ zIndex: 999 + index, top: getTop(el, index), left: getLeft(el, index) }}
                        >
                            <Container>
                                {el.props.allowClose ? <Icon icon={icons.closeCircle} className="modal-close-button clickable" onClick={() => clearTutorial()} /> : ''}

                                <Text textCenter fullWidth inlineBlock><b>{el.props.text}</b></Text>
                                {el.props.allowNeverShowAgain || (el.props.onNext && typeof el.props.onNext === 'function') ?
                                    <div className="actions pt-2">
                                        {el.props.allowNeverShowAgain ? <Text inlineBlock hoverUnderline cursorPointer onClick={() => clearTutorial()}>never show this again</Text> : ''}
                                        {el.props.onNext && typeof el.props.onNext === 'function' ? <Text inlineBlock hoverUnderline cursorPointer right onClick={() => {
                                            closeFunction()
                                            el.props.onNext()
                                        }} >next</Text> : ''}
                                    </div>
                                    : ''
                                }
                            </Container>
                        </div>
                    )
                })
                }
            </div >
        )
    }
}

export { tutorialStore, addTutorial, clearTutorial };

export default view(TutorialWrapper);