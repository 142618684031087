import layoutStore from 'storeUtils/layoutStore';
import { fetchJSON } from 'utils/fetchJSON';

export function activationResend({ mail, userId }) {
    layoutStore.request = {
        progress: true,
        error: false,
        message: '',
    }
    return fetchJSON('/api/activation/resend', { mail: mail, user_id: userId }, false)
        .then((json) => {
            if (json?.request) {
                return {
                    success: json.request.success,
                    message: json.request.message,
                    progress: false
                }
            }
            return {
                error: true,
                message: json?.message ? json?.message : 'Server is currently offline.',
                progress: false
            }
        })
        .catch(error => {
            console.log('[api/login/activationResend.js] Error: ', error)
            if (error?.request) {
                return {
                    error: error.request.error,
                    message: error.request.message,
                    progress: false
                }
            }
            return {
                error: true,
                message: error?.message ? error?.message : 'Server is currently offline.',
                progress: false
            }
        });
}