import layoutStore from 'storeUtils/layoutStore';
import { fetchJSON } from 'utils/fetchJSON';

export default function getAssembledRecipe(person_id, recipe_id, amount, assembleAnywayIfProhibited) {
    layoutStore.bigLoading = true;
    layoutStore.request.error = false
    return fetchJSON('/api/recipe/get-assembled', { person_id, recipe_id, amount, assembleAnywayIfProhibited })
        .then((json) => {
            layoutStore.bigLoading = false;
            return json;
        })
        .catch(error => {
            layoutStore.bigLoading = false;
            layoutStore.request.error = true;

            console.log('[api/recipe/getAssembledRecipe.js] Error: ', error);
            return undefined;
        });
}
