import React from 'react';
import { view } from '@risingstack/react-easy-state';

import './Footer.scss';

import FooterCopyButton from './FooterCopyButton';

const Footer = view((props) => [
    <div key={1} className="footer-content">
        {props.children}
    </div>,
    <div key={2} className="footer-copy" onClick={props.onClick}>
        <span className="no-print-d-none"><FooterCopyButton to="/tou/">Terms of Use</FooterCopyButton> · <FooterCopyButton to="/privacy">Privacy Statement</FooterCopyButton> ·</span> © {new Date().getFullYear()} NutriStyle, Inc.
    </div>
]);

export default Footer;