import searchQuery from "../../src/api/searchQuery";

/**
 * call setup(datasource, flexOptions) to initialize at startup
 */
class flexSearchWrapper {

    /**
     * Searches by text
     * @param {*} query text to search
     */
    search = async (query, { globalCount, recipeCount, foodCount, skip }) => {
        return await searchQuery(query, globalCount, recipeCount, foodCount, skip);
    };
}

const searchWrapper = new flexSearchWrapper()

export default searchWrapper;
