
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { view, store } from '@risingstack/react-easy-state';

import './MicroNutrients.scss';

import Select from 'components/Select';
import Text from 'components/Text';
import Button from 'components/Button';
import { FA } from 'components/Icon';

import { microNutrientsInfo } from 'utils/constants/microNutrientsInfo';
import recommendedNutrition from 'subsystems/MyDB/recommendedNutrition';
import { getMealName, recalculateMealNutrition } from 'utils/mealUtils';
import { getVariability, isMissingNutrient, newNutritionObject, sumNutrition } from 'subsystems/nutrition/nutrition.js';
import formatNumber from 'utils/formatNumber';
import mainStore from 'storeUtils/mainStore';
import { fixCapitalizeName } from 'storeUtils/layoutStore';


function areSomeNutrientsMissing(data) {
    if (data._undefineds) {
        return Object.values(data._undefineds).some(count => count > 0);
    }
    return false;
}

const isVarHidden = (targetHidden, targetValue) => {
    return targetHidden || !targetValue
}

class MicroNutrients extends Component {
    myStore = store({
        ...microNutrientsInfo,
        currentNutrition: undefined,
        currentOption: undefined,
        currentOptionKey: 0,
        currentSelectedDayIndex: undefined,
        averageWeeklyData: { _undefineds: {} },
        selectOptions: [{ key: 0, value: 'Daily totals' }]
    });

    constructor(props) {
        super(props);
        this.prepareSelectOptions();
        this.prepareAverageDailyTotals();
    }

    componentDidMount() {
        this.prepareSelectOptions();
        this.prepareAverageDailyTotals();
    }

    componentDidUpdate() {
        this.prepareSelectOptions();
    }


    onSelectChange = (key) => {
        if (key === '0') {
            this.myStore.currentOptionKey = key;
            this.myStore.currentOption = undefined;
            this.myStore.currentNutrition = this.props.data;
        } else {
            const filter = this.myStore.selectOptions.filter(el => `${el.key}` === `${key}`)
            if (filter.length > 0) {
                this.myStore.currentOptionKey = key;
                this.myStore.currentOption = filter[0];
                this.myStore.currentNutrition = filter[0].nutrition;
            }
        }
    }

    onTableNutrientClickAction = (nutrient) => {
        if (!this.props.clickable || !nutrient || !this.props.data || !this.myStore) return;

        if (this.props.onTableNutrientClickAction && typeof this.props.onTableNutrientClickAction === 'function') {
            const data = {
                nutrient,
                data: this.props.data,
                myStore: this.myStore,
                selectedDay: this.props.selectedDay ? this.props.selectedDay : [],
                selectedRecipe: this.props.selectedRecipe ? this.props.selectedRecipe : undefined
            }

            if (this.myStore.currentOption && this.myStore.currentOption.person_meal) {
                data.selectedRecipe = this.myStore.currentOption.person_meal
            }
            this.props.onTableNutrientClickAction(data)
        }
    }

    prepareAverageDailyTotals = () => {
        if (this.props.weeklyData) {
            const allMeals = this.props.weeklyData.map(day => day.menu.map(x => x.recipes).flat()).flat();
            const nutritionSum = newNutritionObject();
            for (const meal of allMeals) {
                sumNutrition(nutritionSum, meal.nutrition, 1 / 7, true);
            }
            this.myStore.averageWeeklyData = nutritionSum;
        }
    }

    prepareSelectOptions = () => {
        if (this.props.selectedDay.index !== this.myStore.currentSelectedDayIndex) {
            const selectedDayMenu = this.props.selectedDay ? this.props.selectedDay.menu : []
            const selectOptions = [{ key: 0, value: 'Daily totals', nutrition: this.props.data }];

            for (let i = 0; i < selectedDayMenu.length; i++) {
                const meals = selectedDayMenu[i].recipes;

                for (let j = 0; j < meals.length; j++) {
                    const person_meal = meals[j];
                    recalculateMealNutrition(meals[j], true);

                    selectOptions.push({ key: selectOptions.length, value: fixCapitalizeName(getMealName(person_meal)), nutrition: person_meal.nutrition, person_meal: person_meal })
                }
            }
            this.myStore.currentSelectedDayIndex = this.props.selectedDay.index;
            this.myStore.selectOptions = selectOptions;
            this.myStore.currentNutrition = this.props.data;
            this.myStore.currentOptionKey = 0;
            this.myStore.currentOption = undefined;
        }
    }

    renderNutrientName = (nutrientDef) => {
        const unit = nutrientDef.unit ? ` (${nutrientDef.unit})` : "";
        const className = nutrientDef.className ? nutrientDef.className : '';

        return <td className={className}>{nutrientDef.name}{unit}</td>
    }

    renderNutrientTotal = (nutrientDef, nutritionData) => {
        const asterisk = isMissingNutrient(nutritionData, nutrientDef.propName) ? '* ' : '';
        const value = formatNumber(nutrientDef.getValue(nutritionData), nutrientDef.precision);
        return <td>{asterisk}{value}</td>
    }

    renderNutrientTarget = (nutrientDef, nutritionTarget) => {
        return <td>{nutritionTarget[nutrientDef.propName] && !nutrientDef.targetHidden ? formatNumber(nutritionTarget[nutrientDef.propName], nutrientDef.precision) : '**'}</td>
    }

    renderNutrientVariation = (nutrientDef, nutritionData, nutritionTarget) => {
        if (isVarHidden(nutrientDef.targetHidden, nutritionTarget[nutrientDef.propName])) {
            return <td></td>
        }
        return <td>{getVariability(nutrientDef.getValue(nutritionData), nutritionTarget[nutrientDef.propName], nutrientDef.precision)}</td>
    }

    renderWeeklyAverage = (nutrientDef) => {
        if (!this.props.weeklyData) {
            return null;
        }
        const avg = nutrientDef.getValue(this.myStore.averageWeeklyData);
        const asterisk = isMissingNutrient(this.myStore.averageWeeklyData, nutrientDef.propName) ? '* ' : '';
        const value = avg ? formatNumber(avg, nutrientDef.precision) : "";
        return <td>{asterisk}{value}</td>
    }

    renderNutritionColumns = (nutrientDef, nutritionData, nutritionTarget) => {
        return <React.Fragment>
            {this.renderNutrientName(nutrientDef)}
            {this.renderNutrientTotal(nutrientDef, nutritionData)}
            {this.renderNutrientTarget(nutrientDef, nutritionTarget)}
            {this.renderNutrientVariation(nutrientDef, nutritionData, nutritionTarget)}
            {this.renderWeeklyAverage(nutrientDef)}
        </React.Fragment>
    }

    render() {
        const nutritionData = this.myStore.currentNutrition ? this.myStore.currentNutrition : this.props.data;
        const preset = mainStore.presets.find((e) => e.id === mainStore.profile.preset);
        const nutritionTarget = recommendedNutrition.getNutrients(this.props.profile, this.myStore.currentSelectedDayIndex, preset);

        nutritionTarget.kcal -= nutritionTarget.weightLoss.kcalDiff;

        return <div className={'micro-nutrients ' + (this.myStore.isVitaminHidden ? 'right-hidden' : '')}>
            <Button transformNormal className="clear-btn mb-2" inline onClick={() => {
                this.myStore.isVitaminHidden = !this.myStore.isVitaminHidden;
            }}>
                <Text className="mr-2">Vitamins Visible</Text>
                <FA icon={"fad " + (this.myStore.isVitaminHidden ? 'fa-eye-slash' : 'fa-eye')} />
            </Button>
            <div className="mn-table">
                {this.props.offTimeSelector ? "" : <div className="time-selector">
                    <Select
                        apperance
                        options={this.myStore.selectOptions}
                        value={this.myStore.currentOptionKey}
                        accessor={(e) => e}
                        onChange={(e) => this.onSelectChange(e.target.value)}
                    />
                </div>}
                <div className="vitamin-selector">
                    <div className="header">Vitamins</div>
                    {this.myStore.vits.map((e, i) =>
                        e.selected ?
                            <div className="selected" key={i} onClick={() => this.myStore.vits[i].selected = false}>{e.name}</div>
                            :
                            <div key={i} onClick={() => this.myStore.vits[i].selected = true}>{e.name}</div>
                    )}

                    <div className="header">Minerals</div>
                    {this.myStore.mins.map((e, i) =>
                        e.selected ?
                            <div className="selected" key={i} onClick={() => this.myStore.mins[i].selected = false}>{e.name}</div>
                            :
                            <div key={i} onClick={() => this.myStore.mins[i].selected = true}>{e.name}</div>
                    )}
                </div>

                <div className={'details ' + (this.props.clickable ? 'clickable ' : '')}>
                    {this.props.clickable ? <Text large accent inlineBlock fullWidth textCenter className="pb-2 user-select-none">
                        Click on a nutrient to compare individual menu items
                    </Text> : null}
                    <table>
                        <thead>
                            <tr>
                                <th className="left">Nutrient</th>
                                <th className="right">{this.props.isMealInfo ? 'Meal' : 'Daily'} Total</th>
                                <th className="right">Daily Target</th>
                                <th className="right">Var.</th>
                                {this.props.weeklyData ? <th className="right">Avg. Daily Total<br /><span className="mobile-hidden">(for this week)</span></th> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {this.myStore.mainNutrients.map((nutrientDef, i) => (
                                <tr onClick={() => this.onTableNutrientClickAction(nutrientDef)} key={i}>
                                    {this.renderNutritionColumns(nutrientDef, nutritionData, nutritionTarget)}
                                </tr>
                            ))}

                            {this.myStore.vits.some(nutrient => nutrient.selected) ? <tr className="nutrients-section">
                                <td>Vitamins</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                {this.props.weeklyData ? <td></td> : ""}
                            </tr> : null}
                            {this.myStore.vits.map((nutrientDef, i) => nutrientDef.selected && (
                                <tr onClick={() => this.onTableNutrientClickAction(nutrientDef)} key={i}>
                                    {this.renderNutritionColumns(nutrientDef, nutritionData, nutritionTarget)}
                                </tr>
                            ))}

                            {this.myStore.mins.some(nutrient => nutrient.selected) ? <tr className="nutrients-section">
                                <td>Minerals</td>
                                <td></td>
                                <td></td>
                                {this.props.weeklyData ? <td></td> : ""}
                            </tr> : null}
                            {this.myStore.mins.map((nutrientDef, i) => nutrientDef.selected && (
                                <tr onClick={() => this.onTableNutrientClickAction(nutrientDef)} key={i}>
                                    {this.renderNutritionColumns(nutrientDef, nutritionData, nutritionTarget)}
                                </tr>
                            ))}

                        </tbody>
                    </table>

                    <Text fullWidth inlineBlock textCenter className="pt-3 extra-micro-info">
                        {areSomeNutrientsMissing(nutritionData) ? <Text fullWidth inlineBlock className="pb-2">
                            * One or more food(s) in your menu for this day is missing data for this nutrient
                        </Text> : null}
                        ** multiple recommendations
                    </Text>
                </div>
            </div>
        </div>
    }

}

MicroNutrients.propTypes = {
    clickable: PropTypes.bool,
    data: PropTypes.any,
    isMealInfo: PropTypes.bool,
    offTimeSelector: PropTypes.bool,
    onTableNutrientClickAction: PropTypes.func,
    profile: PropTypes.any,
    selectedDay: PropTypes.shape({
        index: PropTypes.any,
        menu: PropTypes.any
    }),
    selectedRecipe: PropTypes.any,
    weeklyData: PropTypes.array
}
export default view(MicroNutrients);
