import moment from 'moment';
import mainStore from 'storeUtils/mainStore';
import { pageNeedsReloading } from 'storeUtils/storeUtils';
import { fetchJSON } from 'utils/fetchJSON';

export default function recalculateMenuForDates(person_id, selected_days, mealsToAdjustIds = []) {
    return fetchJSON('/api/menu/recalculateForDates', {
        person_id,
        selected_days,
        mealsToAdjustIds,
        baseInfoTimeUTC: mainStore.baseInfoTimeUTC,
        userLocalDate: moment().format('YYYY-MM-DD'),
    })
        .then((json) => {
            if (json.request?.error) {
                pageNeedsReloading();
            }
            return json;
        })
        .catch(error => {
            console.log('[api/menu/recalculateMenuForDates.js] Error: ', error);
            return undefined;
        });
}
