import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { view } from '@risingstack/react-easy-state';


import Input from 'components/Input';
import Text from 'components/Text';
import Icon, { icons } from 'components/Icon';
import { LoadingDots } from 'components/Loading';

import { fixCapitalizeName, removeDefaultBrand } from 'storeUtils/layoutStore';

import './InputSearchAdvanced.scss';
import Button from 'components/Button';

/**
 * @typedef InputSearchAdvancedProps
 * @type {object}
 * @property {boolean} dalayed delay searchFunction with setTimeout
 * @property {string} searchValue
 * @property {array} searchResults
 * @property {array} recentResults component show recentResults on input focus
 * @property {function} searchFunction
 * @property {function} onSearchChanged when text changes
 * @property {function} onElement when onClick result element
 * @property {string} className
 * @property {string} placeholder
 * @property {bool} onlyRecipe
 * @property {string} label
 * @property {any} children
 * 
*/
class RawInputSearchAdvanced extends Component {
    searchTimeout;

    constructor(props) {
        super(props);
        this.state = {
            searchValue: props.searchValue || '',
            searchResults: this.props.searchResults || [],
            selected: null,
            onlyRTE: props.onlyFood !== undefined ? props.onlyFood : true,
            onlyRecipe: props.onlyRecipe !== undefined ? props.onlyRecipe : true,
            page: 1,
            limit: 50
        }

        this.ref = React.createRef();
    }

    render() {
        const { searchResults, loading } = this.state;

        return (
            <div className={'input-search-advanced ' + (this.props.className ? this.props.className : '')
                + (loading ? ' in-progress' : '')}>
                <Input
                    placeholder={this.props.placeholder ? this.props.placeholder : 'Search'}
                    label={this.props.label ? this.props.label : 'Search'}
                    rightIcon={icons.search}
                    onBlur={e => {
                        if (this.state.searchValue !== e.target.value && e.target.value !== '') {
                            this.onSearch(e.target.value, this.state.onlyRecipe, this.state.onlyRTE, this.state.skip)
                        }
                    }}
                    onKeyUp={(e) => {
                        // this also catches text selection changes like shift+left/right
                        this.onSearch(e.target.value, this.state.onlyRecipe, this.state.onlyRTE, this.state.skip)
                    }}
                    autoComplete="off"
                />
                {!this.props.optionsHidden ? <div className="options">
                    <Text large inlineBlock className="option" onClick={() => {
                        const onlyRecipe = !this.state.onlyRecipe
                        this.setState({ onlyRecipe: onlyRecipe })
                        this.onSearch(this.state.searchValue, onlyRecipe, this.state.onlyRTE, this.state.skip)
                    }}>
                        {this.state.onlyRecipe ?
                            <Icon className="checkbox" icon={icons.checkboxSelected} />
                            :
                            <Icon className="checkbox" icon={icons.checkboxEmpty} />
                        }
                        <Text inlineBlock className="pl-1">
                            <Icon className="recipe-icon mr-1" icon={icons.recipe} />
                            Recipes
                        </Text>
                    </Text>
                    <Text large inlineBlock className="option" onClick={() => {
                        const onlyRTE = !this.state.onlyRTE
                        this.setState({ onlyRTE: onlyRTE })
                        this.onSearch(this.state.searchValue, this.state.onlyRecipe, onlyRTE, this.state.skip)
                    }}>
                        {this.state.onlyRTE ?
                            <Icon className="checkbox" icon={icons.checkboxSelected} />
                            :
                            <Icon className="checkbox" icon={icons.checkboxEmpty} />
                        }
                        <Text inlineBlock className="pl-1">
                            <Icon className="rte-icon mr-1" icon={icons.rteIcon} />
                            Foods
                        </Text>
                    </Text>
                </div>
                    : ''
                }

                <div className="result-wrapper" ref={this.ref}>
                    <div className={'results visible'}>
                        {loading ? <LoadingDots /> : ''}
                        {!loading && searchResults && searchResults.length > 0 ? <div>
                            <Text normal inlineBlock textRight fullWidth >found {searchResults.length > 499 ? "more than " + searchResults.length : searchResults.length} results</Text>
                            {this.renderResultElements(searchResults)}
                        </div> : ''}
                        {!loading && this.state.searchValue && searchResults.length === 0 ? <Text normal inlineBlock fullWidth className="pt-2" >
                            There are no results matching your query.
                        </Text> : ''}

                    </div>
                </div>
            </div>
        )
    }

    renderResultElements(iterable, caption = '') {
        const { selected } = this.state;
        let _iterable = [];

        if (selected && iterable.length === 0) {
            _iterable = [selected];
        } else {
            const end = this.state.limit * (this.state.page);
            _iterable = [...iterable.slice(0, end)];
        }

        const isLimitReached = (this.state.page + 1) * this.state.limit < this.state.searchResults.length;
        return (
            <div className="result-wrapper">
                {caption ? <h3 className="result-caption">{caption}</h3> : ''}
                {_iterable.map((element, index) =>
                    <span key={index}
                        tabIndex={0}
                        className={'result-item ' +
                            (element.type === 'recipe' ? 'recipe ' : '') +
                            (element.type === 'food' ? 'food ' : '') +
                            (selected && element.id === selected.id ? 'selected ' : '')
                        }
                        onClick={() => this.processResult(element)}
                    >
                        {element.type === 'recipe' ? <Icon className="recipe-icon mr-1" icon={icons.recipe} /> : <Icon className="rte-icon mr-1" icon={icons.rteIcon} />}
                        {fixCapitalizeName(element.name)}
                        {removeDefaultBrand(element.brand) ? <Text className="brand">
                            <i className={element.type === 'recipe' ? 'ingredient-site-name' : 'ingredient-brand-name'}>
                                {removeDefaultBrand(element.brand)}
                            </i>
                        </Text> : ''}
                    </span>)}

                {isLimitReached ? <Button className='load-more-button'
                    green largeHeight
                    onClick={() => {
                        if (isLimitReached) {
                            this.setState({ page: this.state.page + 1 })
                        }
                    }}>Load More</Button> : ''}

            </div>
        );
    }

    onSearch = async (value, onlyRecipe, onlyRTE, skip) => {
        // if (value === this.state.searchValue) return;
        // console.log(value, onlyRecipe, onlyRTE, skip)
        this.setState({ searchValue: value, selected: null, loading: true, page: 1 }, () => {
            this.props.onSearchChanged && this.props.onSearchChanged(value);
        })

        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }

        if (value !== '') {
            this.searchTimeout = setTimeout(async () => {
                console.log("[InputSearchAdvanced.js] - searching for recipes results with:", value)

                if (this.props.searchFunction && typeof this.props.searchFunction === 'function') {
                    const results = await this.props.searchFunction(value, onlyRecipe, onlyRTE, skip);
                    if (value === this.state.searchValue) {
                        this.setState({ searchResults: results, loading: false, skip })
                    }
                }

                this.searchTimeout = null;
            }, 750);
        } else {
            this.setState({ loading: false })
        }
    }

    processResult(obj) {
        const props = this.props;
        if (props.onElement && props.onElement instanceof Function) {
            props.onElement(obj);
        }

        this.setState({ selected: obj });
    }
}

RawInputSearchAdvanced.propTypes = {
    delayed: PropTypes.bool,
    searchValue: PropTypes.string,
    searchResults: PropTypes.array,
    recentResults: PropTypes.array,
    searchFunction: PropTypes.func.isRequired,
    onElement: PropTypes.func.isRequired,
    onSearchChanged: PropTypes.func,
    loadMore: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.any,
    onlyRecipe: PropTypes.bool,
    onlyFood: PropTypes.bool,
    optionsHidden: PropTypes.bool
};

/**
* 
* @component
* @param {InputSearchAdvancedProps} props
* @type {{ new(props: InputSearchAdvancedProps): {
*   props: { 
*     delayed: boolean,
*     searchValue: string,
*     searchResults: Array,
*     recentResults: Array,
*     searchFunction: Function,
*     loadMore: Function,
*     onElement: Function,
*     onSearchChanged: Function,
*     className: string,
*     placeholder: string,
*     label: string,
*     children: React.element,
*     onlyRecipe: bool,
*     onlyFood: bool,
*     optionsHidden: bool
*   }
*  }
* }}
*/
const InputSearchAdvanced = view(RawInputSearchAdvanced)

export default InputSearchAdvanced
