import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';

import '../LoginScreen.scss';

import Container from 'components/Container/Container';
import { Input } from 'components/Input';
import { Icon, icons } from 'components/Icon';
import Button, { ProgressButton } from 'components/Button';
import Space from 'components/Space';
import Message from 'components/Message';

import { forgotPassword } from 'api/login/forgotPassword';

import { validateRequiredField, validateEmail } from 'utils/forms';
import getLogoImg from 'utils/getLogoImg';

import { mainStore } from 'storeUtils/mainStore';
import layoutStore from 'storeUtils/layoutStore';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mail: ''
        }
    }

    componentWillUnmount() {
        layoutStore.request = {}
        layoutStore.profileWizard.errors = {}
    }

    render() {
        return (
            <div className="login forgot-form">
                <div className="login-panel">
                    <Container>
                        <div className="login-header">
                            {getLogoImg()}
                        </div>
                        <form ref={(elem) => this.formElem = elem}
                            autoComplete='on'
                            className={"login-body" + (layoutStore.request.message && !layoutStore.request.error ? ' fadeout' : '')}
                            onSubmit={this.onSubmit}
                            method="post"
                        >
                            <Input placeholder="E-Mail address" name="mail"
                                leftIcon={icons.user}
                                value={this.state.mail}
                                onChange={(e) => this.setState({ mail: e.target.value })}
                                onKeyDown={(e) => this._onKeyDown(e)}
                                onBlur={() => {
                                    if (layoutStore.request.success) return;
                                    validateEmail('mail', this.state.mail, layoutStore.profileWizard.errors);
                                }}
                                autoComplete="email"
                                customErrorText={layoutStore.profileWizard.errors.mail}
                                hasError={layoutStore.profileWizard.errors.mail}
                            />
                            <Space />
                        </form>
                        <div className="login-message pb-2">
                            <Message className="resist-fadeout" request={layoutStore.request} />
                        </div>
                        <div className="login-footer">
                            <Button green transformNormal to="/" type="submit" ><Icon icon={icons.arrowHeadLeft} color="#FFF" /> Go Back</Button>
                            <ProgressButton
                                inProgress={layoutStore.request && layoutStore.request.progress}
                                className={(layoutStore.request.message && !layoutStore.request.error) ? 'single-fadeout' : ''}
                                extraProps={{ green: true, transformNormal: true, largeHeight: true }}
                                onClick={(e) => this.onSubmit(e)} >Submit </ProgressButton>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }

    _onKeyDown(e) {
        if (e.key === 'Enter') {
            this.onSubmit(e)
        }
    }

    validateAll() {
        let isValid = true;

        if (!validateRequiredField({
            fieldName: 'mail',
            value: this.state.mail,
            validationErrorObject: layoutStore.profileWizard.errors
        })) {
            isValid = false
        }

        if (!validateEmail('mail', this.state.mail, layoutStore.profileWizard.errors)) {
            isValid = false
        }

        return isValid
    }

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        layoutStore.request.message = '';

        if (layoutStore.request.success || layoutStore.request.progress) return;

        if (this.validateAll()) {
            const result = await forgotPassword(this.state.mail);

            if (result) {
                layoutStore.request = result;
            }
            this.setState({ mail: '' })
        }
    }
}

export default view(ForgotPassword);
