import moment from 'moment';
import { fetchJSON } from 'utils/fetchJSON';

export function fetchBaseInfo(person_id) {

    return fetchJSON('/api/login/getBaseInformation/', { person_id, current_date: moment().format('YYYY-MM-DD') })
        .then((json) => {
            return json;
        })
        .catch(error => {
            return undefined;
        });
}