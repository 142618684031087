import React from 'react';

const runningIcon = () => (
    <svg version="1.1" id="Isolation_Mode" xmlns="http://www.w3.org/2000/svg" x="0px"
        y="0px" viewBox="0 0 184.1 176.6">
        <g>
            <path d="M80,53.9l-12.2,0.6L48.6,71.8L47,71.6c-7.3-1-9.1-6.9-5.7-12.6l0.3-0.4l20-18.4l0.7-0.2
		c2.9-0.9,6.4-1.3,9.4-1.5c4-0.3,7.9-0.4,11.9-0.4c3.7,0,7.4,0,11.1,0c1.7,0,3.4,0,5.1,0l2.3,0l0.9,2.2c1.1,2.8,2.8,5.1,5.5,6.5
		c6,3.2,15.7,1.7,22.3,0.8c1.4-0.2,2.8-0.4,4.2-0.6c0.5-0.1,0.9-0.1,1.4-0.2c0.4,0,1.3-0.1,1.7-0.3c0.4-0.2,0.9-0.9,1.2-1.2
		c0.3-0.4,0.7-0.8,1-1.2l11.8-13.6l1-0.3c3.3-1,6.7-0.6,9.1,2c1.5,1.5,2.3,3.6,2.7,5.7l0.4,1.8l-23.5,24l-26.7,0.6l-17.2,38.8l20,19
		l0.1,1.4c0.1,2.3-0.2,4.7-0.5,7c-1.1,7.9-3.4,16.7-6.4,24.1c-1.8,4.6-5.8,13.9-11.5,14.6c-0.6,0.1-0.5,0.1-1.2,0
		c-4.3-0.6-8-3.1-9.6-7.1l-0.5-1.2l10.9-32.1l-22.5-17.8l-8.6,22l-0.5,0.5c-7.2,7.1-15.5,13.1-24.6,17.4c-4.3,2-9.2,4-13.9,5
		l-0.5,0.1l-0.5-0.1c-8.7-0.8-10-8-7-14.7l0.6-1.4l1.4-0.5c8.9-2.9,19.3-7.4,26.1-14.2c1.9-1.9,4.5-5.1,5.1-7.8l0.1-0.3L80,53.9z"/>
            <path d="M104.6,24.3c0-4.5,1.8-8.7,4.9-11.9c3.2-3.2,7.4-4.9,11.9-4.9s8.7,1.8,11.9,4.9c3.2,3.2,4.9,7.4,4.9,11.9
		c0,4.5-1.8,8.7-4.9,11.9c-3.2,3.2-7.4,4.9-11.9,4.9s-8.7-1.8-11.9-4.9C106.4,33,104.6,28.8,104.6,24.3z"/>
        </g>
    </svg>
)

export default runningIcon;