import React from 'react';
import { view } from '@risingstack/react-easy-state';
import PropTypes from 'prop-types';

import './Loading.scss'

/**
 * @typedef LoadingDotsProps
 * @type {object}
 * @property {boolean} hidden
*/

/**
 * @param {LoadingDotsProps} props
 */
const RawLoadingDots = props => <div className={
    'loading-dots ' +
    (props.hidden ? 'hidden ' : '')
}>
    <div>.</div>
    <div>.</div>
    <div>.</div>
</div>


RawLoadingDots.propTypes = {
    hidden: PropTypes.bool
};

/**
 * inline dots
 * 
 * @component
 * @type {{ new(props: LoadingDotsProps): {
 *   props: { 
 *     hidden: boolean
 *     }
 *   }
 *  }}
 */
const LoadingDots = view(RawLoadingDots);

export default LoadingDots