import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { view } from '@risingstack/react-easy-state';

// moved scss import to index.js due to css build conflict
// import './Collapse.scss';

import { Button } from 'components/Button';
import { Icon, icons } from 'components/Icon';
import InfoModal from 'components/InfoModal';
import { addModal } from 'components/ModalWrapper';

/**
 * @typedef CollapseProps
 * @type {object}
 * @property {boolean} collapsed is collapsed at entry
 * @property {boolean} disabled collapse toggle is disabled
 * @property {boolean} noPadding style property no-padding for collapse container
 * @property {boolean} inlineBlock style property display: inline-block
 * @property {boolean} help show help icon in collapse button
 * @property {string} className
 * @property {any} onClick
 * @property {string} title collapse button text
 * @property {object} titleProps collapse button property setting for <Button /> componentg
 * @property {object} children
*/

class RawCollapse extends Component {
    constructor(props) {
        super(props);

        const isEntryCollapsed = props.collapsed ? props.collapsed : false;
        this.state = {
            collapsed: isEntryCollapsed
        }
    }

    toggle() {
        const { collapsed } = this.state;
        this.setState({ collapsed: !collapsed });
    }

    render() {
        const { collapsed } = this.state;
        return (
            <div
                onClick={this.props.onClick}
                className={
                    "collapse " +
                    (!collapsed ? 'hidden ' : '') +
                    (this.props.noPadding ? 'no-padding ' : '') +
                    (this.props.inlineBlock ? 'inline-block ' : '') +
                    (this.props.className ? this.props.className : '')
                }>
                <div className={
                    'collapse-button ' +
                    (collapsed ? 'active' : '')
                } onClick={() => !this.props.disabled ? this.toggle() : ''}>
                    <Button disabled={this.props.disabled} disableFocusOutline block transformNormal green noShadow {...this.props.titleProps} >{this.props.title}</Button>
                    {this.props.help ? <div className="help">
                        <Icon icon={icons.info} onClick={this.showHelp} />
                    </div> : ''}
                    <div className="arrow">
                        <Icon icon={collapsed ? icons.arrowHeadTop : icons.arrowHeadBottom} className="cursor-pointer" />
                    </div>
                </div>

                <div className={`collapse-content ${collapsed ? 'open' : ''}`}>
                    {this.props.children}
                </div>
            </div>
        );
    }

    showHelp = (evt) => {
        addModal(InfoModal, { title: 'Description', text: this.props.help, icon: icons.info })
        evt.stopPropagation();
    }
}

RawCollapse.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    collapsed: PropTypes.bool,
    disabled: PropTypes.bool,
    help: PropTypes.any,
    inlineBlock: PropTypes.bool,
    noPadding: PropTypes.bool,
    onClick: PropTypes.any,
    title: PropTypes.string,
    titleProps: PropTypes.object
}

/**
* Component with expandable section on button click (expand/collapse)
* 
* @component
* @param {CollapseProps} props
* @type {{ new(props: CollapseProps): {
*   props: { 
*     collapsed: boolean,
*     disabled: boolean,
*     noPadding: boolean,
*     inlineBlock: boolean,
*     help: any,
*     className: string,
*     onClick: any,
*     title: string,
*     titleProps: Object,
*     children: React.element,
*   }
*  }
* }}
*/
const Collapse = view(RawCollapse)

export default Collapse