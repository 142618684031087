import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';

import "./TermsOfUse.scss"

import { Text } from 'components/Text';

import layoutStore from 'storeUtils/layoutStore';

class TermsOfUse extends Component {
  constructor(props) {
    super(props);

    layoutStore.pageCaption = 'Terms Of Use';
  }

  static class = 'modal-medium';

  render() {
    return (
      <div>
        <Text header fullWidth className="text-center mt-2 mb-3"><strong>TERMS OF USE</strong></Text>
        <h3 className="text-center mb-2">By using the NutriStyle Services you agree to these terms and conditions.</h3>
        <p className="mb-2">The NutriStyle website and web application (together, the “Services”) are offered for informational purposes only and
        any recommendations do not constitute medical or dietary advice. Our Services are not intended to be a substitute for advice from
        a qualified healthcare professional such as a dietitian or physician.</p>
        <p className="mb-2">Always seek the advice of a qualified healthcare professional with any questions you may have regarding a medical condition.
        Never disregard the advice of a qualified healthcare professional or delay seeking advice from a qualified healthcare professional
          because of something you have read while using the Services. YOU RELY ON ANY INFORMATION PROVIDED WHILE USING THE SERVICES SOLELY AT YOUR OWN RISK</p>
        <p className="mb-2">By using the NutriStyle Services, you agree that NutriStyle the company, its directors, officers, employees, contractors, affiliates, shareholders, and partners
        shall not have any liability for any use or misuse of the information which you obtain from using such Services. While NutriStyle has made every reasonable effort
        to ensure the accuracy of any information presented, NutriStyle makes no representations that the information will be complete or accurate and accept
        no liability for any omissions or inaccuracies in the information obtained while using the Services.</p>
      </div>
    );
  }
}

export default view(TermsOfUse);
