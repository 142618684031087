import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { store, view } from '@risingstack/react-easy-state';

import './ReplaceModal.scss';

import { Text } from 'components/Text';
import { FA, Icon, icons } from 'components/Icon';
import { Button } from 'components/Button';
import { addModal } from 'components/ModalWrapper';

import mainStore from 'storeUtils/mainStore';
import CompareModal from 'screens/CompareModal';


import getAssembledFood from 'api/recipe/recipeGetAssembledFood';
import getAssembledRecipe from 'api/recipe/recipeGetAssembled';

import searchWrapper from 'utils/flexSearchWrapper';
import RecipeModal from '../RecipeModal';
import FractionControllInput from 'components/FractionControllInput';
import MealInfo from './MealInfo';
import {
    getRTEMealPortionTypeId,
    getMealPortionTypeName,
    isRTE,
    getMealAmountMultiplier,
    changeMealAmount,
    moveRTEIngredientAmountToMealAmount,
    getMealAmountNoExtra,
    changeRTEFoodPortion,
    isMealAmountValid,
    recalculateMealNutrition,
    matchMealKcal,
    getMealKcal,
    matchRTEPortionAndAmount,
    getMealPreparationDifference,
    getMealName,
    mealWarningEnum,
    getMealBrand
} from 'utils/mealUtils';

import InputSearchAdvanced from 'components/InputSearchAdvanced';
import Select from 'components/Select';
import LoadingDots from 'components/Loading/LoadingDots';
import { getFoodPortionTypesForDropdown } from 'api/food/getPortionTypes';
import NutrientsModal from '../NutrientsModal';
import { getRecipeNutritionKcal } from 'subsystems/nutrition/nutrition.js';
import { onMealWarningModal } from 'storeUtils/storeUtils';
import layoutStore, { fixCapitalizeName } from 'storeUtils/layoutStore';

class ReplaceModal extends Component {

    /* ======================================== CTOR ======================================== */

    constructor(props) {
        super(props);

        this.ezState = store({
            newItem: undefined,
            loading: false,
            allowedPortionsList: [],
            isRecipeOutOfRestrictions: false
        });

        if (isRTE(this.props.currentItem)) {
            const baseIngredient = this.props.currentItem?.ingredients[0];
            this.storePortionInfo(baseIngredient);
        }
        if (this.props.currentItem) {
            recalculateMealNutrition(this.props.currentItem);
            this.addDebugInfo(this.props.currentItem);
        }
    }


    /* ======================================== PROPERTIES ======================================== */

    static class = 'modal-medium nopadding modal-light noborder';
    debug = false;
    ezState;
    isReplaceMode = () => this.props.isReplaceMode
    isEditIngredientMode = () => this.props.isEditIngredientMode


    /* ======================================== COMPONENT EVENTS ======================================== */

    componentWillUnmount() {
        layoutStore.profileWizard.errors = {}
    }


    /* ======================================== UTILS ======================================== */

    storeReset = () => {
        this.ezState.newItem = undefined;
        this.ezState.loading = false;
        this.ezState.allowedPortionsList = [];
    }

    storePortionInfo = (baseIngredient) => {
        this.ezState.currentPortionWeight = baseIngredient?.food_portion?.portion_weight;
        this.ezState.currentPortionType = baseIngredient?.portionType?.id;
    }

    storeNewRTEMeal = async (newMeal, isRecipeOutOfRestrictions = false) => {
        this.ezState.isRecipeOutOfRestrictions = isRecipeOutOfRestrictions;
        moveRTEIngredientAmountToMealAmount(newMeal);
        const baseIngredient = newMeal.ingredients[0];
        const allowedPortionsList = await getFoodPortionTypesForDropdown(baseIngredient.selectedFood.id, mainStore.portionTypes);

        if (this.props.currentItem && !(matchRTEPortionAndAmount(newMeal, this.props.currentItem))) {
            // fallback to kcal matching on portion type mismatch
            matchMealKcal(newMeal, getMealKcal(this.props.currentItem));
        }
        this.ezState.allowedPortionsList = allowedPortionsList ? allowedPortionsList : [];
        this.storePortionInfo(baseIngredient);
        this.ezState.newItem = newMeal;
    }

    storeNewMeal = (newMeal, isRecipeOutOfRestrictions = false) => {
        this.ezState.isRecipeOutOfRestrictions = isRecipeOutOfRestrictions;
        recalculateMealNutrition(newMeal);
        const oldKcal = getMealKcal(this.props.currentItem);
        matchMealKcal(newMeal, oldKcal);
        this.ezState.newItem = newMeal;
    }

    addDebugInfo = (meal) => {
        if (this.debug)
            mainStore.debugData.push({ debugName: `${meal.name}[replace]`, ...JSON.parse(JSON.stringify(meal)) });
    }

    /* ======================================== VALIDATION ======================================== */

    validateAmount = (meal, errorField) => {
        const isValid = isMealAmountValid(meal);
        if (errorField) {
            layoutStore.profileWizard.errors['amount'] = isValid ? undefined : true
        }
        return isValid
    }

    isStateInvalid = () => {
        this.ezState.loading || ((!this.isEditIngredientMode() && !this.validateAmount(this.ezState.newItem))) || ((this.isEditIngredientMode() && this.ezState.newItem && !this.validateAmount(this.ezState.newItem)));
    }


    /* ======================================== EVENT HANDLERS ======================================== */

    onSubmit = async () => {
        const mealToSave = this.ezState.newItem ?? this.props.currentItem;
        const portionTypeId = getRTEMealPortionTypeId(mealToSave);

        if (this.validateAmount(mealToSave)) {
            const dialogResult = {
                ...mealToSave,
                portionTypeId,
            };

            const closeAction = this.props.closeAction;
            if (typeof this.props.actionFunction === 'function') {
                this.props.actionFunction(dialogResult, closeAction);
            } else {
                this.props.closeAction()
            }
        }
    }

    onCompareModal = () => {
        if (!(this.ezState.newItem && this.validateAmount(this.ezState.newItem))) return;

        addModal(CompareModal, {
            currentItem: this.props.currentItem, newItem: this.ezState.newItem, type: 'item', onSubmit: () => {
                this.onSubmit();
            }
        })
    }

    onShowDetails = () => {
        if (!this.validateAmount(this.ezState.newItem)) return;
        const recipe = { ...this.ezState.newItem };
        if (recipe) {
            addModal(RecipeModal, { item: recipe, index: 0, noAddToMeal: true });
        }
    }

    onChangeIngredientPortion = (newPortionId) => {
        const isNewItem = this.ezState.newItem !== undefined;
        const workItem = isNewItem ? this.ezState.newItem : this.props.currentItem;

        if (isRTE(workItem)) {
            const newPortionInfo = this.ezState.allowedPortionsList.find(x => `${x.key}` === `${newPortionId}`);
            if (newPortionInfo) {
                changeRTEFoodPortion(workItem, this.ezState.currentPortionWeight, newPortionInfo)
                this.storePortionInfo(workItem.ingredients[0])
            }
        }
    }

    onSetNewItem = async (searchItem) => {
        this.ezState.loading = true;
        this.ezState.allowedPortionsList = [];

        const validReplace = this.props.currentItem && searchItem;
        const validAdd = !this.isReplaceMode() && searchItem;
        if (validReplace || validAdd) {
            if (searchItem.type === 'recipe') {
                const result = await getAssembledRecipe(mainStore.profile.id, searchItem.id, undefined);
                if (result?.recipe) {
                    this.storeNewMeal(result.recipe, result.isFoodProhibited);
                }
                if (result?.recipe === false) {
                    const resultWithoutRestrictions = await getAssembledRecipe(mainStore.profile.id, searchItem.id, undefined, true);

                    if (resultWithoutRestrictions?.recipe) {
                        this.storeNewMeal(resultWithoutRestrictions.recipe);
                        this.ezState.isRecipeOutOfRestrictions = true;
                    } else {
                        this.storeReset();
                        onMealWarningModal([mealWarningEnum.CANT_ADD]);
                    }
                }
            } else {
                const previousPortionType = this.props.currentItem ? getRTEMealPortionTypeId(this.props.currentItem) : undefined;
                const previousKcal = this.props.currentItem ? getRecipeNutritionKcal(this.props.currentItem) : undefined;
                const result = await getAssembledFood(mainStore.profile.id, searchItem.id, 1, previousPortionType);
                if (result?.recipe) {
                    this.storeNewRTEMeal(result.recipe, result.isFoodProhibited);
                } else {
                    this.storeReset();
                    onMealWarningModal([mealWarningEnum.CANT_ADD]);
                }
            }
            this.ezState.timeDifference = getMealPreparationDifference(this.props.currentItem, this.ezState.newItem);
            this.addDebugInfo(this.ezState.newItem);
        }
        this.ezState.loading = false;
    }

    /* ======================================== RENDERING COMPONENT PARTS ======================================== */

    renderTitle = () => {
        if (this.props.title) return this.props.title;

        if (this.isEditIngredientMode()) {
            return this.ezState.newItem ? 'Replace Ingredient' : 'Edit Ingredient';
        }
        if (this.isReplaceMode()) {
            return 'Compare and Replace';
        }
        return 'Add Food item'
    }

    renderButtonTitle = () => {
        if (this.isEditIngredientMode()) {
            return this.ezState.newItem ? 'Replace' : 'Save';
        }
        if (this.isReplaceMode()) {
            return 'Replace';
        }
        return 'Add item'
    }

    renderRestrictionWarning = () => {
        if (!this.ezState.isRecipeOutOfRestrictions) return '';

        return <div className="replace-modal-restriction-warning">
            <FA className="icon" icon="fas fa-exclamation-circle" />
            <Text red inlineBlock fullWidth>This food/Recipe does not match your Menu Preference Restrictions. Therefore, it will not adhere to your dietary nutrition plan.</Text>
        </div>
    }

    renderDebugInfo = () => {
        const currentItem = this.props.currentItem;
        const newItem = this.ezState.newItem;
        return <React.Fragment>
            {`CURITEM kcal: ${currentItem?.nutrition?.kcal?.toFixed(2)} | amnt: ${currentItem?.amount} | f_qty: ${currentItem?.ingredients[0].selectedFood.quantity} | i_amnt: ${currentItem?.ingredients[0].amount}`}
            <br />
            {`NEWITEM kcal: ${newItem?.nutrition?.kcal?.toFixed(2)} | amnt: ${newItem?.amount} | f_qty: ${newItem?.ingredients[0].selectedFood.quantity} | i_amnt: ${newItem?.ingredients[0].amount}`}
            <br />
        </React.Fragment>
    }

    renderPortionType = (item) => {
        if (this.ezState.loading) return <Text textCenter className="d-flex"><Text inlineBlock>Loading</Text><LoadingDots /></Text>;

        if (this.ezState.allowedPortionsList && this.ezState.allowedPortionsList.length > 0) {
            const numbered = Number.parseInt(this.ezState.currentPortionType);
            const currentPortionType = isNaN(numbered) ? this.ezState.allowedPortionsList[0].key : numbered;
            return <Select
                className="portion-type-select"
                options={this.ezState.allowedPortionsList}
                value={currentPortionType}
                accessor={x => x}
                onChange={(e) => {
                    const newPortionId = Number.parseInt(e.target.value);
                    this.onChangeIngredientPortion(newPortionId);
                }} />
        }
        return <Text textCenter>{getMealPortionTypeName(item)}</Text>
    }

    renderAmountInput = (item) => {
        return <div className={'meal-amount-input ' + (this.ezState.allowedPortionsList ? 'with-select' : '')}>
            {!this.ezState.loading ? <FractionControllInput
                minValue={0.25}
                value={getMealAmountNoExtra(item)}
                onBlur={() => this.validateAmount(item, 'amount')}
                onChange={(value) => {
                    const valueToSet = value / getMealAmountMultiplier(item);
                    changeMealAmount(item, valueToSet)
                }}
                hasError={layoutStore.profileWizard.errors.amount}
                customErrorText={(Math.abs(getMealAmountNoExtra(item)) < 0.12) ? `value < 1/4` : ''}
                bordered
            /> : ''}

            {this.renderPortionType(item)}
        </div>
    }

    renderSiteName = (item) => {
        if (item.recipe_site_name && item.recipe_site_url) {
            return <Text inlineBlock className="full-width py-1">
                <a href={item.recipe_site_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pl-1 ingredient-site-name">
                    {item.recipe_site_name}
                </a>
            </Text>
        }
        if (item.recipe_site_name) {
            return <Text inlineBlock className="full-width py-1">
                <i className="ingredient-site-name">{item.recipe_site_name}</i>
            </Text>
        }
        return '';
    }

    renderAddContext = (item) => {
        if (this.ezState.loading) return <div className="loading-single-item">
            <LoadingDots />
        </div>;

        const icon = isRTE(item) ? icons.rteIcon : icons.recipe;
        const iconClass = isRTE(item) ? 'rte-icon' : 'recipe-icon';

        if (item) {
            return <React.Fragment>
                {this.renderRestrictionWarning()}
                <div className="pb-3 single-item">
                    <Text className="mt-2 sizing-border-box" fullWidth normal textCenter>
                        <Icon className={`mx-1 ${iconClass}`} icon={icon} />
                        <b>{fixCapitalizeName(item.name)}<br /></b>

                        {isRTE(item) ?
                            <Text inlineBlock className="full-width py-1">
                                <i className="ingredient-brand-name">{fixCapitalizeName(getMealBrand(item))}</i>
                            </Text>
                            : ''}

                        {this.renderSiteName(item)}

                        {this.renderAmountInput(item)}

                        (current item)
                    </Text>
                </div>
            </React.Fragment>
        }
        return <div className="fill-empty-space">
            <FA icon='far fa-search' />
            <Text normal inlineBlock>Search</Text>
        </div>
    }

    renderReplaceContext = () => {
        return <div>
            <div className={'replace-info ' + (this.ezState.loading ? 'in-progress' : '')}>
                <MealInfo className="left-column" meal={this.props.currentItem} description={'(current item)'} />

                <div className="center-column replace-icon">
                    <Icon icon={icons.replace} />
                </div>

                {this.ezState.newItem
                    ?
                    <MealInfo className="right-column" meal={this.ezState.newItem} description={'(new item)'}>
                        {this.renderAmountInput(this.ezState.newItem)}
                    </MealInfo>
                    :
                    <div className="right-column full-height"> <Text fullWidth normal textCenter>
                        In the search box below, type the name of a new food or recipe item you want to compare and/or replace,
                        then click on the item you&apos;re interested in.</Text>
                    </div>
                }
            </div>

            {this.debug ? this.renderDebugInfo() : ""}

            {this.ezState.timeDifference
                ? <Text normal textCenter inlineBlock className="pb-3">
                    Preparation Time Difference:
                    <Text large bold
                        className={'difference ' +
                            (this.ezState.timeDifference > 50 ? 'red' : '') +
                            (this.ezState.timeDifference < -20 ? 'green ' : '')}
                    >
                        {this.ezState.timeDifference} min
                    </Text>
                </Text>
                : ""
            }
            {this.renderRestrictionWarning()}
        </div >
    }


    /* ======================================== MAIN BUTTONS ======================================== */

    renderButtons = () => {
        const isButtonSectionDisabled = this.isStateInvalid();
        return <div className="buttons">
            <Button disabled={isButtonSectionDisabled} green block transformNormal onClick={() => this.onSubmit()}>
                {this.renderButtonTitle()}
            </Button>

            {this.props.currentItem && this.ezState.newItem && <Button disabled={isButtonSectionDisabled} transformNormal blue block onClick={() => this.onCompareModal()}>
                Compare
            </Button>}
            {this.props.deleteFunction ? <Button block transformNormal red
                disabled={this.ezState.loading}
                onClick={() => { this.props.deleteFunction(); this.props.closeAction() }}>
                Delete
            </Button>
                : ""}

            {(this.ezState.newItem && !isRTE(this.ezState.newItem)) ?
                <Button clear block transformNormal className="back"
                    disabled={isButtonSectionDisabled}
                    onClick={() => this.onShowDetails()}>
                    Show recipe</Button> : ""
            }
            {this.isReplaceMode() ? <Button clear block transformNormal className="back"
                onClick={() => this.props.closeAction()}>
                Back
            </Button> : ''}

            {this.ezState.newItem && !this.isReplaceMode() ? <Button className='nutrients-tooltip' green onClick={async () => {
                recalculateMealNutrition(this.ezState.newItem);
                addModal(NutrientsModal, { data: this.ezState.newItem.nutrition, isMealInfo: true, offCalendar: true, offTimeSelector: true, title: getMealName(this.ezState.newItem), selectedRecipe: this.ezState.newItem });
            }}>
                <FA icon="far fa-chart-bar" />
                <Text inlineBlock className="nutrients-title pr-2">Nutrients</Text>
            </Button>
                : ''}
        </div>
    }

    /* ======================================== DIALOG CONTEXT FACTORY ======================================== */

    renderItemContext = () => {
        if (this.isEditIngredientMode()) { // Edit Ingredient context
            if (this.ezState.newItem) {
                return this.renderReplaceContext()
            }
            return this.renderAddContext(this.props.currentItem)
        }

        if (this.isReplaceMode()) { // Replace Context
            return this.renderReplaceContext();
        }
        return this.renderAddContext(this.ezState.newItem);
    }

    /* ======================================== MAIN RENDER ======================================== */

    render() {
        return (
            <div className="replace-modal">
                <div className="replace-header">
                    <Text large uppercase textCenter>{this.renderTitle()}</Text>
                </div>

                <div className="replace-inner">
                    {this.renderItemContext()}
                    {this.renderButtons()}

                    <InputSearchAdvanced placeholder="Enter..."
                        searchFunction={async (query, onlyRecipe, onlyRTE, skip) => {
                            if ((!onlyRecipe && !onlyRTE) || (onlyRecipe && onlyRTE)) {
                                return await searchWrapper.search(query, {
                                    globalCount: 500,
                                    skip: skip
                                });
                            }
                            if (onlyRecipe) {
                                return await searchWrapper.search(query, {
                                    recipeCount: 500,
                                    skip: skip
                                });
                            }
                            if (onlyRTE) {
                                return await searchWrapper.search(query, {
                                    foodCount: 500,
                                    skip: skip
                                });
                            }
                        }}
                        optionsHidden={this.props.excludeRecipes}
                        recentResults={this.ezState.recentResults}
                        label="Search"
                        onElement={(obj) => {
                            if (this.ezState.newItem && obj.id === this.ezState.newItem.food_id) return;
                            layoutStore.profileWizard.errors['amount'] = undefined;

                            if (this.ezState.loading) return;
                            this.onSetNewItem(obj)

                        }} delayed
                    />
                </div>
            </div>
        );
    }
}

ReplaceModal.propTypes = {
    actionFunction: PropTypes.func,
    closeAction: PropTypes.func,
    currentItem: PropTypes.any,
    isEditIngredientMode: PropTypes.bool,
    isReplaceMode: PropTypes.bool,
    excludeRecipes: PropTypes.bool,
    title: PropTypes.string,
    deleteFunction: PropTypes.func,
}
export function createReplaceModalProps(currentItem, isReplaceMode, isEditIngredientMode, excludeRecipes, title, deleteFunction) {
    return { currentItem, isReplaceMode, isEditIngredientMode, excludeRecipes, title, deleteFunction }
}

export default view(ReplaceModal);
