
/**
 * validate number to 0 if undefined, cut number to given precision
 * @param {number} value
 * @param {number} precision
 * @returns {string} toFixed
 */
function formatNumber(value, precision) {
    if (value === undefined || value === 0) return 0;
    const prec = !isNaN(precision) ? precision : 0
    return value.toFixed(prec);
}
export default formatNumber;