import { store } from '@risingstack/react-easy-state';
import moment from 'moment';
import prepareStoreData from 'utils/prepareStoreData';

const defaults = {
    loadingBaseData: false,
    appReady: false,

    currentSearch: '',
    searchParams: {},

    translatorProgram: '',
    // brandProgram: '',

    myValue: 130,

    websocks: {
        connected: false,
        errors: [],
        pendingCount: 0,
    },

    login: {
        progress: false,
        message: ''
    },

    FoodLog: [],

    menu: [],

    homeData: [],

    recipes: [],

    forceProcessUpdate: 0,

    profiles: [],

    adminCache: {
        portions: {},
        ingredients: {},
        shoppingCategories: {},
        tags: {}
    },

    pgsql: undefined,
};



const storeData = prepareStoreData({ ...defaults })

const adminStore = store(storeData);

const prefixes = [
    'Creamy',
    'Trader Joe\'s',
    'Mint',
    'Hot',
    'Thai',
    'Tomato',
    'Cheese',
    'Non-fat',
    'Gluten free'
]

const ingredients = [
    'Pumpkin',
    'Strawberry',
    'Chicken',
    'Beef',
    'Mango',
    'Cocoa'
]

const things = [
    'Soup',
    'Drink',
    'Pancakes',
    'Cake',
    'Patty',
    'Dinner'
]

const ingredientList = [
    { name: 'hot water', unit: 'cup' },
    { name: 'ginger', unit: 'tablespoon' },
    { name: 'yellow onion', unit: '' },
    { name: 'garlic cloves', unit: '' },
    { name: 'salt', unit: 'teaspoon' },
    { name: 'vegetable oil', unit: 'tablespoon' },
    { name: 'pumpkin', unit: 'oz.' },
    { name: 'coconut milk', unit: 'cup' },
    { name: 'lime', unit: '' },
    { name: 'salt', unit: 'tablespoon' },
]

function getRandom(array) {
    return array[Math.floor(Math.random() * array.length)];
}

function randBool() {
    return Math.random() >= 0.5;
}

function newRecipe() {
    const thing = {
        name: [getRandom(prefixes), getRandom(prefixes), getRandom(ingredients), getRandom(things)].join(' '),
        attributes: {
            vegan: randBool(),
            gluten: randBool(),
            nuts: randBool(),
            strawberries: randBool(),
            citrus: randBool()
        },
        ingredients: []
    }
    const ingCount = Math.floor(Math.random() * 5) + 5;
    for (let i = 0; i < ingCount; i++) {
        const ing = getRandom(ingredientList);
        thing.ingredients.push({
            name: ing.name,
            unit: ing.unit,
            amount: Math.floor(Math.random() * 6) / 2
        })
    }
    return thing;
}

for (let i = 0; i < 10; i++) {
    adminStore.recipes.push(newRecipe());
}

adminStore.FoodLog.push({
    date: moment().subtract(1, 'days'),
    formattedDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    contents: adminStore.menu
})

adminStore.FoodLog.push({
    date: moment().subtract(2, 'days'),
    formattedDate: moment().subtract(2, 'days').format('YYYY-MM-DD'),
    contents: adminStore.menu
})

adminStore.FoodLog.push({
    date: moment().subtract(3, 'days'),
    formattedDate: moment().subtract(3, 'days').format('YYYY-MM-DD'),
    contents: adminStore.menu
})

function resetAdminStoreToDefaults() {
    const newDefaults = JSON.parse(JSON.stringify(defaults));
    for (let i in newDefaults) {
        adminStore[i] = newDefaults[i]
    }
}

export default adminStore;
export { adminStore, resetAdminStoreToDefaults };
