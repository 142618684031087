import React from 'react';

const mealIcon = () => (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 70 70">
        <g>
            <path d="M34.11,5.28c0.77,0.34,1.32,0.4,2.17,0.43C35.51,5.38,34.96,5.32,34.11,5.28z" />
            <path d="M30.64,8.75v2.6C31.05,10.36,31.05,9.74,30.64,8.75z" />
            <path d="M39.75,8.75v1.74C40.06,9.72,40.06,9.51,39.75,8.75z" />
            <path d="M28.61,12.51c0.19-0.19-0.27-0.17-0.29-0.14C28.13,12.56,28.59,12.53,28.61,12.51z" />
            <path d="M29.48,12.51c0.19-0.19-0.27-0.17-0.29-0.14C29,12.56,29.46,12.53,29.48,12.51z" />
            <path d="M31.07,12.66c-0.54-0.3-0.67-0.32-1.3-0.43C30.31,12.52,30.44,12.55,31.07,12.66z" />
            <path d="M41.2,12.51c0.19-0.19-0.27-0.17-0.29-0.14C40.71,12.56,41.17,12.53,41.2,12.51z" />
            <path d="M25.58,12.94c0.19-0.19-0.27-0.17-0.29-0.14C25.1,12.99,25.55,12.97,25.58,12.94z" />
            <path d="M43.8,12.94c0.19-0.19-0.27-0.17-0.29-0.14C43.32,12.99,43.78,12.97,43.8,12.94z" />
            <path d="M44.09,12.66c0.54,0.3,0.67,0.32,1.3,0.43C44.85,12.79,44.72,12.76,44.09,12.66z" />
            <path d="M46.4,13.38c0.19-0.19-0.27-0.17-0.29-0.14C45.92,13.43,46.38,13.4,46.4,13.38z" />
            <path d="M15.02,19.16H25h36.01c-0.59-0.25-1.23-0.37-1.88-0.43h1.45c-0.6-0.37-1.2-0.51-1.82-0.55
		c-1.09-1.47-3-1.88-4.69-2.51c-1.94-0.72-3.97-1.35-6.04-1.84c0.05,0,0.1-0.01,0.11-0.02c0.19-0.19-0.27-0.17-0.29-0.14
		c-0.05,0.05-0.06,0.08-0.04,0.11c-0.85-0.2-1.7-0.37-2.55-0.52c-0.19-0.07-0.39-0.1-0.73-0.16c0.04,0.02,0.08,0.04,0.11,0.06
		c-0.59-0.09-1.18-0.18-1.78-0.25c-0.28-0.13-0.48-0.17-0.94-0.25c0.14,0.08,0.26,0.14,0.36,0.18c-0.99-0.1-1.98-0.16-2.96-0.18
		c0.02-0.13,0.04-0.27,0.05-0.4c0.49,0.27,0.64,0.3,1.25,0.4c-0.52-0.29-0.67-0.32-1.24-0.42c0.21-1.47,0.48-2.93-0.26-4.33
		c-1.32-2.51-5.48-3.07-7.29-0.74c-1.13,1.45-0.76,3.77-0.76,5.49c-1.06,0.01-2.18,0.06-3.31,0.16c-0.08-0.05-0.29-0.04-0.3-0.02
		c-0.02,0.02-0.03,0.04-0.04,0.05c-0.2,0.02-0.4,0.04-0.59,0.06c-0.3-0.14-0.49-0.17-0.96-0.26c0.27,0.15,0.44,0.23,0.63,0.29
		c-0.79,0.09-1.58,0.22-2.36,0.36c-0.25-0.1-0.44-0.14-0.86-0.21c0.24,0.13,0.4,0.21,0.57,0.27c-0.47,0.09-0.94,0.19-1.4,0.3
		c-0.08-0.01-0.17,0-0.18,0.01c-0.01,0.01-0.01,0.02-0.02,0.03c-1.15,0.27-2.26,0.6-3.29,1c-0.94,0.37-1.82,0.82-2.69,1.28
		c-2.05-0.07-4.33,1.34-6,2.31c0.32,0.14,0.66,0.23,1,0.3c-0.63,0.03-1.25,0.07-1.87,0.14C11.1,19.45,13.17,19.16,15.02,19.16z
		 M55.75,18.3c-2.87,0.05-5.75,0-8.62,0H55.75z M12.55,18.06c0.43,0.12,0.88,0.2,1.34,0.24h-1.91L12.55,18.06z"/>
            <path d="M63.61,21.33c-0.08,0-0.16,0-0.24,0h2.84c-1.83-0.77-4.11-0.43-6.07-0.43H47.56H4.17
		c0.61,0.26,1.27,0.39,1.95,0.45c-1.09,0.07-1.94,0.39-2.38,1.72h0.43c0.68,0.29,1.4,0.4,2.12,0.43c-0.7-0.03-1.41,0-2.12,0
		c1.37,0.58,2.86,0.43,4.34,0.43c-0.68-0.29-1.4-0.4-2.12-0.43c0.7,0.03,1.41,0,2.12,0c-0.7-0.29-1.42-0.4-2.16-0.43h2.16
		c0.02,0.96,0.08,1.92,0.17,2.88c-0.01,0.01-0.01,0.01-0.02,0.02c-0.02,0.02-0.04,0.31,0.06,0.33c0.02,0.17,0.03,0.33,0.05,0.5
		c-0.02-0.02-0.06-0.01-0.11,0.04c-0.02,0.02-0.05,0.48,0.14,0.29c0.19,1.67,0.45,3.33,0.74,4.99c-0.04-0.1-0.09-0.21-0.16-0.36v1.3
		c0.13-0.29,0.2-0.44,0.22-0.58c0.15,0.85,0.3,1.71,0.45,2.56c-0.02,0-0.05,0-0.09,0.04c-0.02,0.02-0.05,0.48,0.14,0.29c0,0,0,0,0,0
		c0.4,2.27,0.77,4.53,0.98,6.8c0.07,0.77,0.13,1.6,0.2,2.44c-0.01,0.01-0.02,0.01-0.03,0.02c-0.02,0.02-0.04,0.31,0.06,0.33
		c0.02,0.17,0.03,0.33,0.05,0.5c-0.02-0.01-0.06-0.01-0.1,0.04c-0.02,0.02-0.05,0.47,0.14,0.29c0.07,0.61,0.15,1.23,0.26,1.84
		c-0.03-0.07-0.07-0.15-0.11-0.25v1.3c0.11-0.26,0.19-0.41,0.21-0.53c0.37,1.78,1,3.46,2.19,4.73c1.25,1.34,2.75,1.82,4.35,1.97
		c0.39,0.14,0.8,0.26,1.22,0.35h-0.6c2.9,1.22,7.28,0.43,10.41,0.43H51.9c-0.46-0.19-0.96-0.33-1.48-0.43h1.92
		c-0.19-0.08-0.39-0.15-0.6-0.22c1.17-0.07,2.27-0.28,3.19-0.81c1.8-1.03,3.04-2.78,3.48-4.79v0.18c0.25-0.55,0.29-0.62,0.13-0.99
		c0.06-0.62,0.03-1.21,0.07-1.77c0.07-0.26,0.1-0.5,0.1-0.75c0.06-0.26,0.14-0.51,0.26-0.76c0.01,0.1,0.05,0.18,0.16,0.08
		c0.02-0.02,0.03-0.25-0.03-0.32c0.05-0.09,0.11-0.17,0.17-0.26c-0.12-0.58-0.15-1.15-0.12-1.73c0,0,0-0.01,0-0.01
		c0.02-0.38,0.06-0.77,0.12-1.15v1.15c0.3-0.66,0.3-0.63,0.02-1.26c0.11-0.67,0.27-1.33,0.42-2v0.23c0.23-0.52,0.28-0.61,0.16-0.94
		c0.09-0.41,0.16-0.82,0.22-1.23c0.07-0.52,0.15-1.04,0.23-1.57c0.02,0.03,0.06,0.04,0.12-0.02c0.02-0.02,0.04-0.34-0.07-0.33
		c0.08-0.53,0.16-1.07,0.24-1.6c0.21-0.47,0.25-0.57,0.13-0.88c0.14-0.96,0.28-1.92,0.41-2.88c0.02,0.09,0.06,0.15,0.15,0.06
		c0.02-0.02,0.04-0.43-0.12-0.31c0.09-0.7,0.18-1.4,0.26-2.09v0.52c0.28-0.61,0.3-0.64,0.07-1.15c0.02-0.14,0.03-0.28,0.05-0.42
		c0.12-0.27,0.14-0.39,0.06-0.61c0.02-0.23,0.04-0.45,0.06-0.68c0.01-0.01,0.03-0.02,0.05-0.03c0.02-0.02,0.03-0.22-0.02-0.3
		c0.07-0.82,0.13-1.63,0.16-2.44c0.68,0.29,1.4,0.4,2.12,0.43c-0.7-0.03-1.41,0-2.12,0v1.74c0.3-0.54,0.32-0.67,0.43-1.3h3.9
		c-0.68-0.29-1.4-0.4-2.12-0.43c0.7,0.03,1.41,0,2.12,0c-0.19-0.08-0.39-0.14-0.58-0.2c0.34,0.05,0.67,0.12,1.02,0.2
		C66.98,21.36,65.44,21.35,63.61,21.33z"/>
            <path d="M3.31,23.07c0.3-0.67,0.3-0.63,0-1.3V23.07z" />
            <path d="M8.95,30.88c0.3-0.67,0.3-0.63,0-1.3V30.88z" />
            <path d="M61.15,31.17c-0.02,0.02-0.05,0.48,0.14,0.29C61.32,31.43,61.35,30.97,61.15,31.17z" />
            <path d="M9.52,33.34c-0.02,0.02-0.05,0.48,0.14,0.29C9.69,33.6,9.72,33.14,9.52,33.34z" />
            <path d="M60.72,34.64c-0.02,0.02-0.05,0.48,0.14,0.29C60.89,34.9,60.91,34.45,60.72,34.64z" />
            <path d="M9.96,35.94c-0.02,0.02-0.05,0.48,0.14,0.29C10.13,36.21,10.15,35.75,9.96,35.94z" />
            <path d="M9.96,36.81c-0.02,0.02-0.05,0.48,0.14,0.29C10.13,37.07,10.15,36.61,9.96,36.81z" />
            <path d="M60.29,36.81c-0.02,0.02-0.05,0.48,0.14,0.29C60.46,37.07,60.48,36.61,60.29,36.81z" />
            <path d="M10.25,37.39v1.3C10.55,38.01,10.55,38.06,10.25,37.39z" />
            <path d="M60.29,37.68c-0.02,0.02-0.05,0.48,0.14,0.29C60.46,37.94,60.48,37.48,60.29,37.68z" />
            <path d="M10.39,38.98c-0.02,0.02-0.05,0.48,0.14,0.29C10.56,39.24,10.58,38.78,10.39,38.98z" />
            <path d="M10.39,39.85c-0.02,0.02-0.05,0.48,0.14,0.29C10.56,40.11,10.58,39.65,10.39,39.85z" />
            <path d="M59.85,40.28c-0.02,0.02-0.05,0.48,0.14,0.29C60.02,40.54,60.05,40.09,59.85,40.28z" />
            <path d="M10.68,40.42v1.3C10.98,41.05,10.98,41.1,10.68,40.42z" />
            <path d="M10.68,41.73v1.3C10.98,42.35,10.98,42.4,10.68,41.73z" />
            <path d="M10.83,43.32c-0.02,0.02-0.05,0.48,0.14,0.29C10.99,43.58,11.02,43.12,10.83,43.32z" />
            <path d="M59.42,43.32c-0.02,0.02-0.05,0.48,0.14,0.29C59.59,43.58,59.61,43.12,59.42,43.32z" />
            <path d="M59.13,46.21c0.02-0.02,0.05-0.48-0.14-0.29C58.96,45.94,58.94,46.4,59.13,46.21z" />
            <path d="M11.26,46.35c-0.02,0.02-0.05,0.48,0.14,0.29C11.43,46.62,11.45,46.16,11.26,46.35z" />
            <path d="M58.99,46.79c-0.02,0.02-0.05,0.48,0.14,0.29C59.15,47.05,59.18,46.59,58.99,46.79z" />
            <path d="M58.55,49.82c-0.02,0.02-0.05,0.48,0.14,0.29C58.72,50.09,58.74,49.63,58.55,49.82z" />
            <path d="M18.92,55.61c-0.54-0.3-0.67-0.32-1.3-0.43C18.16,55.47,18.29,55.5,18.92,55.61z" />
            <path d="M52.19,55.32c-0.19,0.19,0.27,0.17,0.29,0.14C52.67,55.27,52.21,55.3,52.19,55.32z" />
            <path d="M19.79,57.34c0.54,0.3,0.67,0.32,1.3,0.43C20.55,57.48,20.42,57.45,19.79,57.34z" />
            <path d="M26.73,57.34c0.54,0.3,0.67,0.32,1.3,0.43C27.49,57.48,27.36,57.45,26.73,57.34z" />
            <path d="M34.54,57.34c0.54,0.3,0.67,0.32,1.3,0.43C35.3,57.48,35.17,57.45,34.54,57.34z" />
            <path d="M43.37,57.63c0.19-0.19-0.27-0.17-0.29-0.14C42.88,57.68,43.34,57.66,43.37,57.63z" />
            <path d="M49.3,57.34c0.54,0.3,0.67,0.32,1.3,0.43C50.06,57.48,49.93,57.45,49.3,57.34z" />
            <path d="M20,59.96c0.46,1,2.8,4.77,4.28,3.82c0.59-0.38,0.27-1.19,0.04-1.67c-0.48-0.98-2.77-4.77-4.24-3.82
		C19.49,58.66,19.78,59.47,20,59.96z"/>
            <path d="M29.94,63.78c1.42-0.95-1.46-6.48-2.95-5.49C25.57,59.24,28.45,64.77,29.94,63.78z" />
            <path d="M44.09,57.78c-2.82,0.48-3.77,3.97-3.9,6.51C42.95,63.47,43.95,60.45,44.09,57.78z" />
            <path d="M49.95,60.82c0.37-0.59,1.24-1.95,0.38-2.53c-0.71-0.48-1.52,0.4-1.95,0.86c-1.39,1.49-2.33,3.12-2.55,5.14
		C47.81,63.95,48.9,62.49,49.95,60.82z"/>
            <path d="M25.87,59.51c0.3-0.67,0.3-0.63,0-1.3V59.51z" />
            <path d="M36.28,59.8c-0.04-0.26-0.09-0.48-0.14-0.65c-0.17-0.58-0.68-1.29-1.36-0.86c-0.43,0.27-0.65,0.92-0.73,1.67
		c-0.08-0.45-0.2-0.89-0.37-1.31v4.77c0.19-0.46,0.32-0.95,0.39-1.46c0.05,0.39,0.11,0.72,0.18,0.96c0.17,0.58,0.68,1.29,1.36,0.86
		c0.35-0.22,0.56-0.69,0.67-1.26v1.34c0.69-1.64,0.69-4,0-5.64V59.8z"/>
            <path d="M44.38,58.79c0.02-0.02,0.05-0.48-0.14-0.29C44.21,58.53,44.19,58.98,44.38,58.79z" />
            <path d="M51.32,58.79c0.02-0.02,0.05-0.48-0.14-0.29C51.15,58.53,51.13,58.98,51.32,58.79z" />
            <path d="M45.39,63.85c0.3-0.67,0.3-0.63,0-1.3V63.85z" />
            <path d="M30.78,63.27c-0.02,0.02-0.05,0.48,0.14,0.29C30.95,63.54,30.98,63.08,30.78,63.27z" />
            <path d="M39.89,63.27c-0.02,0.02-0.05,0.48,0.14,0.29C40.06,63.54,40.09,63.08,39.89,63.27z" />
            <path d="M24.56,64.72c-0.54-0.3-0.67-0.32-1.3-0.43C23.8,64.58,23.93,64.61,24.56,64.72z" />
            <path d="M30.21,64.72c-0.54-0.3-0.67-0.32-1.3-0.43C29.45,64.58,29.58,64.61,30.21,64.72z" />
            <path d="M35.85,64.72c-0.54-0.3-0.67-0.32-1.3-0.43C35.09,64.58,35.22,64.61,35.85,64.72z" />
            <path d="M41.49,64.72c-0.54-0.3-0.67-0.32-1.3-0.43C40.73,64.58,40.86,64.61,41.49,64.72z" />
            <path d="M45.82,64.29c0.54,0.3,0.67,0.32,1.3,0.43C46.58,64.42,46.45,64.4,45.82,64.29z" />
        </g>
    </svg>
)

export default mealIcon;