
import moment from 'moment';
import { POUND_TO_KG, INCH_TO_CM, MAX_DAILY_KCAL_LOSS, getRecipeNutritionKcal } from '../../subsystems/nutrition/nutrition.js';
import DRI from './dri.js';

const nutrientMap = {
    vitaminARAE: 'vitA',
    vitaminB12: 'vitB12',
    vitaminB6: 'vitB6',
    vitaminC: 'vitC',
    vitaminD: 'vitD',
    vitaminE: 'vitE',
    vitaminK: 'vitK'
}

const pregnancyBonuses = {
    1: 0,
    2: 340,
    3: 452,
    4: 330,
    5: 400
}

function transformNutrition(nutritionObject) {
    const keys = Object.keys(nutritionObject);
    const result = {};
    keys.forEach((key) => {
        if (nutrientMap[key]) {
            result[nutrientMap[key]] = parseFloat(nutritionObject[key]);
        } else {
            result[key] = parseFloat(nutritionObject[key]);
        }
    })
    return result;
}

const kcalProfiles = [
    {
        gender: 'male',
        minAge: 3,
        maxAge: 8,
        isOverweight: false,
        ageCoefficient: 61.9,
        weightCoefficient: 26.7,
        heightCoefficient: 903,
        kcalConstant: 108.5,
        activityLevels: [1, 1.13, 1.26, 1.42]
    },
    {
        gender: 'male',
        minAge: 8,
        maxAge: 18,
        isOverweight: false,
        ageCoefficient: 61.9,
        weightCoefficient: 26.7,
        heightCoefficient: 903,
        kcalConstant: 113.5,
        activityLevels: [1, 1.13, 1.26, 1.42]
    },
    {
        gender: 'male',
        minAge: 18,
        maxAge: 120,
        isOverweight: false,
        ageCoefficient: 9.53,
        weightCoefficient: 15.91,
        heightCoefficient: 539.6,
        kcalConstant: 662,
        activityLevels: [1, 1.11, 1.25, 1.48]
    },
    {
        gender: 'male',
        minAge: 3,
        maxAge: 18,
        isOverweight: true,
        ageCoefficient: 50.9,
        weightCoefficient: 19.5,
        heightCoefficient: 1161.4,
        kcalConstant: 114,
        activityLevels: [1, 1.12, 1.24, 1.45]
    },
    {
        gender: 'male',
        minAge: 18,
        maxAge: 120,
        isOverweight: true,
        ageCoefficient: 9.72,
        weightCoefficient: 14.2,
        heightCoefficient: 503,
        kcalConstant: 846,
        activityLevels: [1, 1.12, 1.27, 1.54]
    },
    {
        gender: 'female',
        minAge: 3,
        maxAge: 8,
        isOverweight: false,
        ageCoefficient: 30.8,
        weightCoefficient: 10,
        heightCoefficient: 934,
        kcalConstant: 155.3,
        activityLevels: [1, 1.16, 1.31, 1.56]
    },
    {
        gender: 'female',
        minAge: 8,
        maxAge: 18,
        isOverweight: false,
        ageCoefficient: 30.8,
        weightCoefficient: 10,
        heightCoefficient: 934,
        kcalConstant: 160.3,
        activityLevels: [1, 1.16, 1.31, 1.56]
    },
    {
        gender: 'female',
        minAge: 18,
        maxAge: 120,
        isOverweight: false,
        ageCoefficient: 6.91,
        weightCoefficient: 9.36,
        heightCoefficient: 726,
        kcalConstant: 354,
        activityLevels: [1, 1.12, 1.27, 1.45]
    },
    {
        gender: 'female',
        minAge: 3,
        maxAge: 18,
        isOverweight: true,
        ageCoefficient: 41.2,
        weightCoefficient: 15,
        heightCoefficient: 701.6,
        kcalConstant: 389,
        activityLevels: [1, 1.18, 1.35, 1.60]
    },
    {
        gender: 'female',
        minAge: 18,
        maxAge: 120,
        isOverweight: true,
        ageCoefficient: 7.31,
        weightCoefficient: 10.9,
        heightCoefficient: 660.7,
        kcalConstant: 387,
        activityLevels: [1, 1.14, 1.27, 1.45]
    },
]

const calsCarb = 4;
const calsProtein = 4;
const calsFat = 9;
const calsAlcohol = 7;

const defPreset = {
    preset: {
        fatTarget: 30,
        carbTarget: 70
    }
};

const debugNutrition = false;

class RecommendedNutrition {
    nutrientData = {
        male: {},
        female: {},
        pregnant: {},
        lactating: {}
    }

    kcalData = {
        male: {
            overweight: {},
            not: {}
        },
        female: {
            overweight: {},
            not: {}
        }
    }

    constructor() {
        DRI.forEach((nd) => {
            //first extract the object
            const transformed = {
                minAge: parseFloat(nd.minAge),
                maxAge: nd.maxAge ? parseFloat(nd.maxAge) : 120,
                nutrientsPerWeight: transformNutrition(nd.nutrientsPerWeight),
                nutrientsPerKcal: transformNutrition(nd.nutrientsPerKcal),
                nutrients: transformNutrition(nd.nutrients),
            }

            let groups = ['male', 'female', 'pregnant', 'lactating'];
            if (nd.group) {
                groups = [nd.group];
            }
            for (let age = transformed.minAge; age < transformed.maxAge; age += 0.5) {
                for (let i = 0; i < groups.length; i++) {
                    this.nutrientData[groups[i]][age] = transformed;
                }
            }
        })

        kcalProfiles.forEach((kp) => {
            for (let age = kp.minAge; age < kp.maxAge; age++) {
                this.kcalData[kp.gender][kp.isOverweight ? 'overweight' : 'not'][age] = kp;
            }
        })
    }

    getNutrients = (person, dayNumber, preset = defPreset) => {
        if (debugNutrition) console.log(person);

        let fatTarget = preset?.preset?.fatTarget;
        let carbTarget = preset?.preset?.carbTarget;

        if (fatTarget === undefined) fatTarget = preset?.fatTarget;
        if (carbTarget === undefined) carbTarget = preset?.carbTarget;

        if (fatTarget === undefined) fatTarget = defPreset.preset.fatTarget;
        if (carbTarget === undefined) carbTarget = defPreset.preset.carbTarget;

        const personHeightInMeters = person.height * INCH_TO_CM * 0.01;
        const bmi = person.weight * POUND_TO_KG / (personHeightInMeters * personHeightInMeters);
        const isOverweight = bmi >= 25;

        let age = person.age;
        let iage = Math.floor(person.age);
        if (((age % 1) !== 0) && ((age % 1) !== 0.5)) {
            age = iage;
        }
        iage = Math.max(3, iage);
        if (debugNutrition) console.log('iage', iage)

        const activityLevel = person.activityLevel.general > -1 ? person.activityLevel.general : person.activityLevel.specific[dayNumber];
        if (debugNutrition) console.log('activityLevel', activityLevel)

        const lookupGender = person.gender === 'Female' ? 'female' : 'male';
        if (debugNutrition) console.log('lookupGender', lookupGender)
        const kcalProfile = this.kcalData[lookupGender][isOverweight ? 'overweight' : 'not'][iage];
        if (debugNutrition) console.log('kcalProfile', kcalProfile)

        const activityModifier = kcalProfile.activityLevels[activityLevel];
        if (debugNutrition) console.log('activityModifier', activityModifier)

        //const eerWeight = (person.targetWeight >= person.weight) ? person.weight : (person.targetWeight === 0 ? person.weight : person.targetWeight);
        const eerWeight = person.weight;
        if (debugNutrition) console.log('eerWeight', eerWeight)

        let eer = kcalProfile.kcalConstant
            - kcalProfile.ageCoefficient * iage
            + activityModifier * (kcalProfile.weightCoefficient * eerWeight * POUND_TO_KG + kcalProfile.heightCoefficient * personHeightInMeters)
            + ((person.pregnant && pregnancyBonuses[person.pregnant]) ? pregnancyBonuses[person.pregnant] : 0);
        if (debugNutrition) console.log('eer', eer)

        let availableKcalDiff = Math.max(0, eer - MAX_DAILY_KCAL_LOSS);
        let targetWeightLossKcalDiff = 0;
        if (person.targetWeight != person.weight) {
            targetWeightLossKcalDiff = MAX_DAILY_KCAL_LOSS * person.targetWeightPerWeek * POUND_TO_KG;
            if (person.weight < person.targetWeight) targetWeightLossKcalDiff = -targetWeightLossKcalDiff;
        }

        const nutrientProfile = this.nutrientData[lookupGender][person.age];
        if (debugNutrition) console.log('nutrientProfile', nutrientProfile)

        if ((person.activityLevel.override) && (person.activityLevel.override[dayNumber] === 0 || person.activityLevel.override[dayNumber] > 0)) {
            targetWeightLossKcalDiff = eer - person.activityLevel.override[dayNumber];
        }

        const weightLoss = {
            availableKCal: availableKcalDiff,
            kcalDiff: Math.min(targetWeightLossKcalDiff, availableKcalDiff),
            targetKcalDiff: targetWeightLossKcalDiff
        };

        const proteinActivityLevels = [
            1, 
            1.2, 
            1.4, 
            1.6
        ]

        const actualProtein = Math.max(proteinActivityLevels[activityLevel], nutrientProfile.nutrientsPerWeight.protein);
        const protein = actualProtein * eerWeight * POUND_TO_KG;
        const missingKCAL = eer - weightLoss.kcalDiff - protein * calsProtein;

        const carbKCAL = missingKCAL * (carbTarget / (fatTarget + carbTarget))
        const carbs = carbKCAL / calsCarb;

        const fatKCAL = missingKCAL * (fatTarget / (fatTarget + carbTarget))
        const fat = fatKCAL / calsFat;

        //console.log('check', carbKCAL, carbs, fatKCAL, fat, fatTarget, carbTarget, missingKCAL)

        //now check the menu presets.
        return {
            ...nutrientProfile.nutrients,
            kcal: eer,
            weightLoss: weightLoss,
            fiber: nutrientProfile.nutrientsPerKcal.fiber * eer / 1000,
            protein: protein,
            fat: fat,
            carbs: carbs
        }
    }

    getNutrientsForWeek = (person, preset) => {
        const result = [];
        for (let i = 0; i < 7; i++) {
            result.push(this.getNutrients(person, i, preset));
        }
        return result;
    }

    getSupportedWeightLoss = (person, preset) => {
        const weeklyNutrition = this.getNutrientsForWeek(person, preset);
        const maxDailyKcalDeficit = 2 * POUND_TO_KG * MAX_DAILY_KCAL_LOSS; // MAX DAILY KCAL DEFICIT (2 pounds of fat loss per week === 1100 kcal daily loss)
        const sum = weeklyNutrition.reduce((acc, val, i) => acc + Math.min(val.weightLoss.availableKCal, maxDailyKcalDeficit), 0); // sumujemy kalorie dostępne każdego dnia wybieramy mniejszą wartość spośród(dzienną wartość lub maxKCal)
        return (sum / (maxDailyKcalDeficit * 7)) * 2
    }

    getAvailablewWeeksWeightLoss = (person, weeklyData, preset) => {
        const weeklyNutrition = this.getNutrientsForWeek(person, preset);

        const getCurrentKcal = (day) => {
            let summedKCal = 0;
            day.menu.forEach((meal) => meal.recipes.forEach((recipe) => summedKCal += (recipe.completed && !recipe.deleted ? getRecipeNutritionKcal(recipe) : 0)));
            return summedKCal;
        }

        let currentKcalSum = 0;
        let sum = 0;
        for (let d = 0; d < 7; d++) {
            const day = weeklyData[d];

            const currentKcal = getCurrentKcal(day);
            const dayNutrition = weeklyNutrition[d];

            const currentKcalDiff = dayNutrition.kcal - currentKcal;

            if (currentKcal > 0) {
                sum += currentKcalDiff;
                currentKcalSum += currentKcal; // sum only days to today
            }
        }

        if (currentKcalSum === 0) return 0;

        const currentloseWeightRatio = (sum / (2 * POUND_TO_KG * MAX_DAILY_KCAL_LOSS * 7)) * 2;
        return currentloseWeightRatio;
    }

    toMealNutrients = (nutrients, meal, mealCalBalanceSum) => {
        /*const mProteinCals = mealProtein * calsProtein;
        const mFatCals = mealFat * calsFat;
        const mCarbCals = mealCarbs * calsCarb;
        const mCals = mProteinCals + mFatCals + mCarbCals;
 
        //now let's check the proportion of protein in the nutrients we balance.
        const nProteinProportion = nutrients.protein * calsProtein / nutrients.kcal;
 
        let tCals = 
        let tProtein = mProteinCals
        //if meal protein is higher than suggested, we keep the meal balance. If lower, we put the recommendation forward.
 
        const tProteinProportion = Math.max(nProteinCals, mProteinCals);
        const tPreCals = tProteinCals + mFatCals + mCarbCals;
        const tFatCals = mFatCals / */

        //for now just use the meal balance and ignore the protein req in the person.
        // we also want to get the macro only
        // so kcal, protein, fat and carb targets for the meal.

        const sum = meal.proteinTarget * calsProtein + meal.fatTarget * calsFat + meal.carbTarget * calsCarb;
        const mealCals = nutrients.kcal * meal.calorieTarget / mealCalBalanceSum;
        return {
            protein: meal.proteinTarget / sum, //g
            fat: meal.fatTarget / sum, //g
            carb: meal.carbTarget / sum, //g
            kcal: mealCals //kcal
        }
    }


}

const recommendedNutrition = new RecommendedNutrition();

export default recommendedNutrition;