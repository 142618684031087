export const APP_TYPE_ID = {
    461880: "globalliver",
    829503: "caremoat"
}

export const APP_TYPE_HOSTNAMES = {
    GLOBALLIVER: ['gli.nutristyle.com', 'globalliverinstitute.nutristyle.com'],
    CAREMOAT: ['caremoat.nutristyle.com'],
}

export const DEFAULT_CONFIG = {
    type: "default",
    logo: {
        alt: "Nutristyle logo",
        src: "/img/NSLogo.png",
        width: "330px",
        height: "74px"
    },
    mailLogo: {
        alt: "Nutristyle logo",
        src: "/img/NSLogo.png",
        width: "300px",
        height: "67px"
    },
    mailHost: 'app.nutristyle.com'
}

export const GLOBALLIVER_CONFIG = {
    type: "globalliver",
    logo: {
        alt: "Globalliver logo",
        src: "/img/globalLiverLogo.png",
        width: "330px",
        height: "167px"
    },
    mailLogo: {
        alt: "Globalliver logo",
        src: "/img/globalLiverLogo.png",
        width: "227px",
        height: "115px"
    },
    mailHost: 'globalliverinstitute.nutristyle.com'
}

export const CAREMOAT_CONFIG = {
    type: "caremoat",
    logo: {
        alt: "Caremoat logo",
        src: "/img/careMoat.png",
        width: "330px",
        height: "103px"
    },
    mailLogo: {
        alt: "CareMoat logo",
        src: "/img/careMoat.png",
        width: "290px",
        height: "91px"
    },
    mailHost: 'caremoat.nutristyle.com'
}
