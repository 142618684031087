import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { view } from '@risingstack/react-easy-state';

import { getIngredientsNutrition, isMissingNutrient } from 'subsystems/nutrition/nutrition.js';
import mainStore from 'storeUtils/mainStore';

import './NutrientByIngredientModal.scss';
import Text from 'components/Text';
import { getMealName, isRTE } from 'utils/mealUtils';
import formatNumber from 'utils/formatNumber';
import { fixCapitalizeName } from 'storeUtils/layoutStore';

class NutrientByIngredientModal extends Component {
    constructor(props) {
        super(props);

        const preparedTableDataObject = this.prepareNutrientsTable();
        this.state = {
            sectionName: preparedTableDataObject.sectionName,
            ingredients: preparedTableDataObject.ingredients,
            nutrientsData: preparedTableDataObject.nutrientsData
        }
    }

    static class = 'modal-medium';


    prepareNutrientsTable = () => {
        const ingredients = [];
        let nutrientsData = mainStore.menuNutrition;
        let sectionName = 'Daily Totals';

        const selectedRecipe = this.props.selectedRecipe;
        const selectedDay = this.props.selectedDay

        // traverse only throught single recipe
        if (selectedRecipe !== undefined) {
            const recipeIngredients = getIngredientsNutrition(selectedRecipe, true);

            sectionName = fixCapitalizeName(getMealName(selectedRecipe))

            if (isRTE(selectedRecipe)) {
                if (recipeIngredients.length > 0) {
                    const rtefood = recipeIngredients[0].selectedFood;
                    nutrientsData = rtefood.nutrition;

                    ingredients.push({ name: rtefood.name, nutrition: rtefood.nutrition })
                }
            } else {
                recipeIngredients.forEach(ingr => {
                    ingredients.push({ name: ingr.selectedFood.name, nutrition: ingr.selectedFood.nutrition })
                })

                nutrientsData = selectedRecipe.nutrition;
            }
        } else if (selectedDay !== undefined) { // traverse throught whole menu of the day
            const menus = selectedDay.menu.sort((a, b) => a.order - b.order);
            nutrientsData = selectedDay.nutrition;

            menus.forEach(menu => {
                if (menu.recipes.length > 0) {
                    ingredients.push({ name: menu.name, className: 'bold' })

                    menu.recipes.forEach(recipe => {
                        if (isRTE(recipe) && recipe.ingredients.length > 0) {
                            const recipeIngredients = getIngredientsNutrition(recipe, true);

                            const rtefood = recipeIngredients[0].selectedFood;
                            ingredients.push({ name: rtefood.name, nutrition: rtefood.nutrition })
                        } else {
                            ingredients.push({ name: recipe.name, nutrition: recipe.nutrition })
                        }

                    })
                }
            })
        }

        return { ingredients, sectionName, nutrientsData }
    }

    renderNutritionValue = (selectedFood) => {
        const val = this.props.nutrient.getValue(selectedFood.nutrition);
        const formatedNumber = formatNumber(val, this.props.nutrient.precision);
        if (isMissingNutrient(selectedFood.nutrition, this.props.nutrient.propName)) {
            if (+formatedNumber === 0) {
                return '*'
            }
            return '* ' + formatedNumber;
        }
        return formatedNumber;
    }

    render() {
        return (
            <div className="nutrients-by-ingredient-modal">
                {this.props.nutrient ? <Text normal bold inlineBlock className="modal-title">
                    {this.props.nutrient ? this.props.nutrient.name : ''}
                    <span className="text-right">{this.props.nutrient.getValue(this.state.nutrientsData).toFixed(0)}</span>
                </Text> : ''}

                <div className={'details '}>
                    <table>
                        <thead>
                            <tr>
                                <th className="text-center">{this.state.sectionName}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {this.state.ingredients.map((selectedFood, i) => (
                                    <td className={'ingredient-element ' + (selectedFood.className ? selectedFood.className : '')} key={i}>
                                        <Text textLeft normal inlineBlock className="name">{selectedFood.name}</Text>
                                        {selectedFood.nutrition ? <Text normal inlineBlock textRight>
                                            {this.renderNutritionValue(selectedFood)}
                                        </Text> : ''}
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

NutrientByIngredientModal.propTypes = {
    data: PropTypes.any.isRequired,
    selectedRecipe: PropTypes.any,
    selectedDay: PropTypes.any,
    myStore: PropTypes.any.isRequired,
    nutrient: PropTypes.any.isRequired,
};

export default view(NutrientByIngredientModal);
