import React from 'react';

const skypeIcon = () => (
    <svg viewBox="0 0 59.999 60">
        <path d="M30,0C13.431,0,0,13.432,0,29.999C0,46.568,13.431,60,30,60c16.568,0,29.999-13.432,29.999-30.001
    C59.999,13.432,46.568,0,30,0z M37.763,46.895c-1.685,0-3.268-0.458-4.625-1.257c-1.07,0.227-2.183,0.348-3.317,0.348
    c-8.719,0-15.78-7.066-15.78-15.78c0-1.135,0.116-2.24,0.344-3.308c-0.813-1.365-1.281-2.959-1.281-4.666
    c0-5.042,4.085-9.128,9.126-9.128c1.869,0,3.607,0.561,5.051,1.525c0.827-0.135,1.675-0.207,2.541-0.207
    c8.714,0,15.779,7.068,15.779,15.784c0,0.866-0.073,1.718-0.207,2.549c0.949,1.438,1.502,3.159,1.502,5.011
    C46.896,42.809,42.805,46.895,37.763,46.895z"/>
        <path d="M34.85,29.043c-2.167-0.955-6.621-1.254-7.975-2.345c-0.854-0.688-0.717-1.952,0.165-2.691
    c1.174-0.974,4.417-1.139,6.088,0.638c0.804,0.86,1.306,1.792,2.77,1.456c1.017-0.236,1.564-1.046,1.406-2.069
    c-0.389-2.517-4.635-4.351-8.498-4.054c-2.252,0.172-4.67,0.918-5.915,2.78c-1.537,2.291-1.628,6.99,3.665,8.331
    c5.445,1.384,6.087,1.38,6.657,2.103c0.51,0.65,0.516,1.57,0.017,2.25c-1.233,1.697-5.055,1.904-6.841-0.207
    c-0.307-0.365-0.591-0.746-0.902-1.108c-1.355-1.585-3.943-0.777-3.538,1.494c0.263,1.471,1.707,3.312,4.927,4.081
    c2.289,0.547,5.547,0.522,7.741-0.471c2.288-1.038,3.495-2.795,3.512-5.332C38.144,31.794,37.097,30.032,34.85,29.043z"/>
    </svg>
)

export default skypeIcon;