import React, { Component } from 'react';
import { store, view } from '@risingstack/react-easy-state';

import "./Register.scss"

import { registration } from 'api/registration';

import { validateRequiredField, validateEmail } from 'utils/forms';
import { INVITATION_CODE_REQUIRED } from 'utils/constants/person';
import { isGlobalLiverWebsite } from 'utils/isGlobalLiverWebsite';

import Container from 'components/Container';
import Button, { ProgressButton } from 'components/Button';
import { Input } from 'components/Input';
import Checkbox from 'components/Checkbox';
import Message from 'components/Message';
import { icons } from 'components/Icon';
import Text from 'components/Text';
import ModalWrapper, { addModal } from 'components/ModalWrapper';

import TermsOfUse from 'screens/TermsOfUse/TermsOfUse';
import Privacy from 'screens/Privacy/Privacy';

import getLogoImg from 'utils/getLogoImg';

import { verifyLoginApiAction } from 'storeUtils/storeUtils';
import layoutStore from 'storeUtils/layoutStore';
import { isCareMoatWebsite } from 'utils/isCareMoatWebsite';

class Register extends Component {
    constructor(props) {
        super(props);
        const search = window.location.search;
        let code = new URLSearchParams(search).get("code") || '';

        if (!code && isGlobalLiverWebsite()) {
            code = 'GLOBALLIVER';
        }

        if (!code && isCareMoatWebsite()) {
            code = 'CareMoat21';
        }

        this.ezState = store({
            mail: '',
            password: '',
            repeatPassword: '',
            termsAccepted: false,
            confirmedWeakPassword: false,
            invitation_code: code,
            errors: {},
            loading: false
        })
    }

    componentWillUnmount() {
        layoutStore.request.message = ''
        this.resetState();
    }

    resetState() {
        this.ezState = store({
            mail: '',
            password: '',
            repeatPassword: '',
            termsAccepted: false,
            confirmedWeakPassword: false,
            invitation_code: '',
            errors: {},
            loading: false
        })
    }

    testPasswordStrength(value) {
        const passwordRegexPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
        const isPasswordStrength = passwordRegexPattern.test(value);

        if (!isPasswordStrength) {
            this.ezState.errors.repeatPassword = 'Your password should contain at least six characters, at least one upper and lower case letter, one digit and special character'
            this.ezState.errors.password = ' '
        }
        return isPasswordStrength
    }

    testPasswordMismatch(v1, v2) {
        const isPasswordMismatch = this.ezState[v1] === this.ezState[v2];

        if (!isPasswordMismatch) {
            this.ezState.errors.repeatPassword = 'Passwords do not match'
            this.ezState.errors.password = ' '
        }
        return isPasswordMismatch;
    }

    validatePassword(fieldName) {
        const { password, repeatPassword } = this.ezState;

        const testPasswordValidation = (fieldName, fieldValue) => {
            const isValidRequired = validateRequiredField({
                fieldName: fieldName,
                value: fieldValue,
                validationErrorObject: this.ezState.errors
            });
            if (isValidRequired) {
                return this.testPasswordMismatch('password', 'repeatPassword')
            }
            return isValidRequired
        }

        if (fieldName === 'password') {
            return testPasswordValidation('password', password)
        }
        if (fieldName === 'repeatPassword') {
            return testPasswordValidation('repeatPassword', repeatPassword)
        }
    }

    validateInvitationCode() {
        const validated = validateRequiredField({
            fieldName: 'invitation_code',
            value: this.ezState.invitation_code,
            validationErrorObject: this.ezState.errors
        });
        return validated;
    }

    onCheckboxToggle(fieldName) {
        this.ezState[fieldName] = !this.ezState[fieldName]
        validateRequiredField({
            fieldName: fieldName,
            value: this.ezState[fieldName],
            validationErrorObject: this.ezState.errors
        })
    }

    validateAllPasswords() {
        let isValid = true;
        if (!this.validatePassword('password')) {
            isValid = false;
        }

        if (!this.validatePassword('repeatPassword')) {
            isValid = false;
        }

        if (isValid && !this.testPasswordStrength(this.ezState.password) && !this.ezState.confirmedWeakPassword) {
            isValid = false;
            this.ezState.confirmWeakPasswordDialog = true;
        } else if (isValid && this.ezState.confirmedWeakPassword) {
            this.ezState.errors.repeatPassword = ''
            this.ezState.errors.password = ''
        }

        return isValid;
    }

    validateAll() {
        let isValid = true;

        if (!validateEmail('mail', this.ezState.mail, this.ezState.errors)) {
            isValid = false;
        }

        if (!this.validateAllPasswords()) {
            isValid = false;
        }

        if (INVITATION_CODE_REQUIRED && !this.validateInvitationCode()) {
            isValid = false;
        }

        if (!validateRequiredField({
            fieldName: 'termsAccepted',
            value: this.ezState.termsAccepted,
            validationErrorObject: this.ezState.errors
        })
        ) {
            isValid = false;
        }

        return isValid;
    }

    async onSubmit() {
        layoutStore.request.message = ''

        if (layoutStore.request.success || layoutStore.request.progress) return;

        if (this.validateAll()) {
            layoutStore.request.progress = true;
            this.ezState.loading = true;
            const result = await registration(this.ezState.mail, this.ezState.password, this.ezState.invitation_code);

            if (result) {
                layoutStore.request.message = result.message
                layoutStore.request.error = result.error
            }
            layoutStore.request.progress = false;

            if (result.error) {
                this.ezState.loading = false;
            } else if (result.success) {
                await verifyLoginApiAction(this.ezState.mail, this.ezState.password);
            }
        }
    }

    render() {
        return (
            <div className="login register-form">
                <div className="login-panel">
                    <Container>
                        <ModalWrapper />
                        <div className="login-header">
                            {getLogoImg()}
                        </div>
                        <div className={"login-body" + ((layoutStore.request.message && !layoutStore.request.error) ? ' fadeout' : '')}>
                            {(isGlobalLiverWebsite() || isCareMoatWebsite()) ? '' :
                                <div className="mt-2">
                                    {INVITATION_CODE_REQUIRED ? <Text bold inlineBlock accent className="invitation-text pb-2" >
                                        Please enter a valid invitation code below to register:
                                    </Text> : ""}
                                    {INVITATION_CODE_REQUIRED ? <Input
                                        placeholder="Invitation Code*"
                                        name="invitation_code"
                                        spaced
                                        leftIcon={icons.arrowHead}
                                        value={this.ezState.invitation_code}
                                        onBlur={() => this.validateInvitationCode()}
                                        onChange={(e) => {
                                            if (this.ezState.loading) return false;

                                            this.ezState.invitation_code = e.target.value
                                            this.validateInvitationCode();
                                        }}
                                        onSubmit={() => this.validateInvitationCode()}
                                        autoComplete="off"
                                        hasError={this.ezState.errors.invitation_code}
                                        customErrorText={this.ezState.errors.invitation_code}
                                        disabled={this.ezState.loading} /> : ""}
                                </div>}
                            <Input
                                placeholder="Email Address*"
                                name="mail"
                                leftIcon={icons.user}
                                value={this.ezState.mail}
                                onChange={(e) => {
                                    if (this.ezState.loading) return false;

                                    this.ezState.mail = e.target.value
                                    validateEmail('mail', e.target.value, this.ezState.errors)
                                }}
                                hasError={this.ezState.errors.mail}
                                autoComplete="email"
                                customErrorText={this.ezState.errors.mail}
                                disabled={this.ezState.loading} />
                            <Input
                                placeholder="Password*"
                                name="password"
                                spaced
                                leftIcon={icons.password}
                                value={this.ezState.password}
                                onBlur={(e) => this.validateAllPasswords()}
                                onChange={(e) => {
                                    if (this.ezState.loading) return false;

                                    this.ezState.password = e.target.value
                                    this.validateAllPasswords();
                                }}
                                onSubmit={() => this.validatePassword('password')}
                                password
                                autoComplete="off"
                                hasError={this.ezState.errors.password}
                                customErrorText={this.ezState.errors.password}
                            />
                            <Input
                                placeholder="Repeat Password*"
                                name="repeatedPassword"
                                spaced
                                leftIcon={icons.password}
                                value={this.ezState.repeatPassword}
                                onBlur={(e) => this.validateAllPasswords()}
                                onChange={(e) => {
                                    if (this.ezState.loading) return false;

                                    this.ezState.repeatPassword = e.target.value
                                    this.validateAllPasswords();
                                }}
                                onSubmit={() => this.onSubmit()}
                                password
                                autoComplete="off"
                                hasError={this.ezState.errors.repeatPassword}
                                customErrorText={this.ezState.errors.repeatPassword} />
                            {this.ezState.confirmWeakPasswordDialog && <div className="checkbox cursor-pointer " onClick={() => {
                                this.ezState.confirmedWeakPassword = !this.ezState.confirmedWeakPassword
                                this.validateAllPasswords()
                            }} >
                                <Checkbox checked={this.ezState.confirmedWeakPassword} />
                                <span className="checkbox-text">This password is weaker than recommended. Use anyway.</span>
                            </div>}
                            <div className={'checkbox cursor-pointer ' + (this.ezState.errors.termsAccepted ? 'error' : '')} tabIndex="0" onClick={() => {
                                this.ezState.errors.termsAccepted = undefined;
                                this.onCheckboxToggle('termsAccepted')
                            }} >
                                <Checkbox checked={this.ezState.termsAccepted} disabled={this.ezState.loading} hasError={this.ezState.errors.termsAccepted} />
                                <span className="checkbox-text">
                                    I have read and accept the NutriStyle <a href="#" className="clickable" onClick={() => addModal(TermsOfUse)}>Terms Of Use</a> and <a className="clickable" onClick={() => addModal(Privacy)}>Privacy Statement.*</a>
                                </span>
                            </div>
                            <ProgressButton
                                inProgress={this.ezState.loading}
                                extraProps={{ largeHeight: true }}
                                onClick={async () => await this.onSubmit()}
                            >Create Account </ProgressButton>
                            <Message className="resist-fadeout" textCenter request={layoutStore.request} />
                        </div>
                        <div className="login-footer">
                            <Button clear transformNormal right to="/" type="submit" className="login-footer-back-btn">Back to login</Button>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }

}

export default view(Register);
