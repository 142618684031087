import React from 'react';

const printIcon = () => (
    <svg viewBox="0 0 60 44.911">
        <path d="M54.545,14.911H5.455C2.442,14.911,0,17.354,0,20.366v19.091c0,3.012,2.442,5.455,5.455,5.455
    h49.091c3.012,0,5.455-2.442,5.455-5.455V20.366C60,17.354,57.558,14.911,54.545,14.911z M53.912,40.923
    c0,0.827-0.41,1.498-0.916,1.498H7.004c-0.506,0-0.916-0.671-0.916-1.498v-2.024c0-0.827,0.41-1.498,0.916-1.498h45.993
    c0.506,0,0.916,0.671,0.916,1.498V40.923z M55.944,27.584c0,0.828-0.672,1.5-1.5,1.5H53.38c-0.828,0-1.5-0.672-1.5-1.5V26.52
    c0-0.828,0.672-1.5,1.5-1.5h1.064c0.828,0,1.5,0.672,1.5,1.5V27.584z M55.944,22.748c0,0.828-0.672,1.5-1.5,1.5H53.38
    c-0.828,0-1.5-0.672-1.5-1.5v-1.064c0-0.828,0.672-1.5,1.5-1.5h1.064c0.828,0,1.5,0.672,1.5,1.5V22.748z"/>
        <rect x="14.014" y="0" width="31.345" height="12.655" />
    </svg>
)

export default printIcon;