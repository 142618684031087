
import React, { Component } from 'react';
import { store, view } from '@risingstack/react-easy-state';
import PropTypes from "prop-types";

import './UnitPicker.scss';

import Input from 'components/Input';
import { icons } from 'components/Icon';
import LoadingWrapper from 'components/Loading/LoadingWrapper';
import { getFoodPortionTypesForDropdown } from 'api/food/getPortionTypes';
import mainStore from 'storeUtils/mainStore';

class UnitPicker extends Component {
    ezState = store({ allUnits: [], searchValue: '', open: false, loading: true });

    constructor(props) {
        super(props);
        this.ezState.searchValue = this.props.initSearch;
        if (props.foodId) {
            this.fetchPortions();
        } else {
            this.ezState.loading = false;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.foodId != this.props.foodId) {
            if (this.props.foodId)
                this.fetchPortions();
            else {
                this.ezState.allUnits = [];
                this.ezState.searchValue = '';
            }
        }

        if (prevProps.initSearch != this.props.initSearch) {
            this.ezState.searchValue = this.props.initSearch;
        }

    }

    /* ======================================== UTILS ======================================== */

    fetchPortions = async () => {
        const data = await getFoodPortionTypesForDropdown(this.props.foodId, mainStore.portionTypes)
        if (data instanceof Array) {
            this.ezState.allUnits = data;
            this.ezState.loading = false;
        }
    }

    /* ======================================== EVENT HANDLERS ======================================== */

    onSearchChange = (text) => {
        this.ezState.searchValue = text;
        this.props.onSearchChange(text);
    }

    onChangeIngredientPortion = (portion) => {
        this.ezState.searchValue = portion.name;
        this.props.onUnitChange(portion);
    }


    /* ======================================== MAIN RENDER ======================================== */

    render() {
        const portions = this.ezState.allUnits.filter(x => x.value.includes(this.ezState.searchValue));
        return (
            <LoadingWrapper loading={this.ezState.loading}>
                <div className="food-unit-picker-component">
                    <Input onChange={(evt) => this.onSearchChange(evt.target.value)}
                        value={this.ezState.searchValue}
                        placeholder='Enter portion type...'
                        rightIcon={icons.search}
                        onFocus={() => { this.onSearchChange(""); this.ezState.open = true; }}
                        onBlur={() => {
                            const filtered = this.ezState.allUnits.filter(portion => {
                                return portion.name.toLowerCase() === this.ezState.searchValue.toLowerCase()
                            });
                            if (filtered.length > 0) {
                                this.onChangeIngredientPortion(filtered[0])
                            }

                            if (!this.ezState.listHover) {
                                this.ezState.open = false;
                            }
                        }}
                    />
                    {this.ezState.open
                        ? <ul onMouseOver={() => { this.ezState.listHover = true }} onMouseOut={() => {
                            this.ezState.listHover = false
                        }} className={`picker-items`}>
                            {portions.map((el) => {
                                return <li key={el.key} onClick={() => { this.onChangeIngredientPortion(el); this.ezState.open = false; }}>{el.name}</li>
                            })}
                        </ul>
                        : ""}
                </div>
            </LoadingWrapper>
        );
    }
}

UnitPicker.propTypes = {
    onSearchChange: PropTypes.func,
    onUnitChange: PropTypes.func,
    initSearch: PropTypes.string,
    foodId: PropTypes.number,
}

export default view(UnitPicker);