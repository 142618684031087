import React from 'react';
import { view } from '@risingstack/react-easy-state';

import './Container.scss'

export default view((props) => (
  <div className={
    "container cf " + 
    (props.vscrolled ? 'container-vscrolled ' : '') + 
    (props.vfull ? 'container-vfull ' : '') + 
    (props.relative ? 'relative ' : '') + 
    (props.margins ? 'margins ' : '') + 
    (props.panel ? 'panel ' : '') + 
    (props.fill ? 'fill ' : '') + 
    (props.transparent ? 'transparent ' : '') + 
    (props.halfSpace ? 'halfSpace ' : '') + 
    (props.className ? props.className : '')
  }>{props.children}</div>
))