import layoutStore from 'storeUtils/layoutStore';
import { fetchJSON } from 'utils/fetchJSON';

export function forgotCode(forgot_code, user_id, password) {
    layoutStore.request = {
        progress: true,
        error: false,
        message: '',
    }
    console.log(forgot_code, password, user_id)
    return fetchJSON('/api/forgotCode/', { forgot_code: forgot_code, user_id: user_id, password: password }, false)
        .then((json) => {
            layoutStore.request = {
                ...json.request,
                progress: false
            }
            return json
        })
        .catch(error => {
            console.log('[api/login/forgotCode.js] Error: ', error)
            layoutStore.request = {
                ...error.request,
                progress: false
            }
            return undefined;
        });
}