import formatNumber from "../../utils/formatNumber.js";

const POUND_TO_KG = 0.45359237;
const KG_TO_POUND = 1 / 0.45359237;
const INCH_TO_CM = 2.54;
const MAX_DAILY_KCAL_LOSS = 1100;
const DAILY_KCAL_TOLERANCE_MULTIPLIER = 1.020; // 2 %

const RecipeType = {
    Recipe: 0,
    RTE: 1,
    Service: 2,
}

const nutritionSymbols = {
    carbs: 'A',
    protein: 'B',
    fat: 'C',
    kcal: 'D',
    alcohol: 'E',
    fiber: 'F',
    sugar: 'G',
    biotin: 'H',
    choline: 'I',
    folate: 'J',
    niacin: 'K',
    pantothenicAcid: 'L',
    riboflavin: 'M',
    thiamin: 'N',
    vitA: 'O',
    vitB6: 'P',
    vitB12: 'Q',
    vitC: 'R',
    vitD: 'S',
    vitE: 'T',
    vitK: 'U',
    calcium: 'V',
    chromium: 'W',
    copper: 'X',
    iron: 'Y',
    magnesium: 'Z',
    manganese: 'a',
    phosphorus: 'b',
    potassium: 'c',
    selenium: 'd',
    zinc: 'e',
    sodium: 'f',
};

const nutritionSymbolMap = {}

for (let i in nutritionSymbols) {
    nutritionSymbolMap[nutritionSymbols[i]] = i;
}

const defNutrition = {};
Object.keys(nutritionSymbols).forEach((k) => {
    defNutrition[k] = 0;
})

function newNutritionObject() {
    return {
        ...defNutrition
    }
}

function zeroNutrition(acc) {
    for (let i = 0; i < nutritionKeys.length; i++) {
        acc[nutritionKeys[i]] = 0;
    }
}

const nutritionKeys = Object.keys(nutritionSymbols);

function addUndefineds(acc, nk, amount) {
    if (!acc._undefineds) {
        acc._undefineds = {};
    }
    if (acc._undefineds[nk] === undefined) {
        acc._undefineds[nk] = 0;
    }
    acc._undefineds[nk] += amount;
}

function sumNutrition(acc, b, multiplier = 1, countUndefineds = false) {
    for (let i = 0; i < nutritionKeys.length; i++) {
        const nk = nutritionKeys[i];
        if (acc[nk] === undefined && b[nk] === undefined) {
            if (countUndefineds) addUndefineds(acc, nk, 1);
            continue;
        }
        if (b[nk] === undefined) {
            if (countUndefineds) addUndefineds(acc, nk, 1);
            continue;
        }
        if (acc[nk] === undefined) {
            if (countUndefineds) addUndefineds(acc, nk, 1);
            acc[nk] = 0;
        }
        if (b._undefineds && b._undefineds[nk] !== undefined) {
            if (countUndefineds) addUndefineds(acc, nk, 1);
        }

        acc[nk] += b[nk] * multiplier;
    }
}

function scaleNutrition(acc, multiplier = 1) {
    for (let i = 0; i < nutritionKeys.length; i++) {
        const nk = nutritionKeys[i];
        if (acc[nk] === undefined) continue;
        acc[nk] *= multiplier;
    }
}

function computeKCal(nutrition) {
    if (nutrition.alcohol) {
        return nutrition.fat * 9 + nutrition.carbs * 4 + nutrition.protein * 4 + nutrition.alcohol * 7
    } else {
        return nutrition.fat * 9 + nutrition.carbs * 4 + nutrition.protein * 4
    }
}

function getKCal(nutrition) {
    if (!nutrition) return 0;
    if (!nutrition.kcal) {
        const kcal = computeKCal(nutrition);
        return isNaN(kcal) ? 0 : kcal;
    }
    return nutrition.kcal;
}

function getDailyNutrition(day) {
    const summedNutrition = newNutritionObject();
    day.forEach((meal) =>
        meal.recipes.filter(recipe => !recipe.deleted).forEach((recipe) => {
            sumNutrition(summedNutrition, getRecipeNutrition(recipe, true), 1, true);
        }));
    return summedNutrition;
}

function scaleNutritionByAmount(nutrition, amount) {
    for (let i = 0; i < nutritionKeys.length; i++) {
        nutrition[nutritionKeys[i]] *= amount;
    }
}

function getIngredientNutritionKcal(ing, multiplier) {
    const foodKcal = ing.selectedFood ? getKCal(ing.selectedFood.nutrition) : 0;
    const sfn = ing.selectedFood ? ing.selectedFood.name : 'NO_SELECTED_FOOD';
    const ptn = ing.portionType ? ing.portionType.name : 'NO_PORTION_TYPE';
    if (ing.food_portion === undefined) {
        console.log('food portion is missing for ' + sfn + ' ' + ptn);
    }
    const actGram = ing.food_portion ? ing.amount * ing.food_portion.portion_weight * multiplier : 0;
    const actKcal = actGram * 0.01 * foodKcal;
    return actKcal;
}

function getRecipeNutritionKcal(recipe, debug = false, amountOverride = undefined) {
    let sum = 0;
    let multiplier = 0;
    if (amountOverride) {
        multiplier = recipe.portion_count > 0 ? amountOverride / recipe.portion_count : amountOverride;
    } else {
        multiplier = recipe.portion_count > 0 ? recipe.amount / recipe.portion_count : recipe.amount;
    }
    if (debug) console.log('recipe ' + recipe.name + '[' + recipe.amount + '/' + recipe.portion_count + '] nutrition calc multiplier:' + multiplier)
    for (let i = 0; i < recipe.ingredients.length; i++) {
        const ing = recipe.ingredients[i];
        const foodKcal = ing.selectedFood ? getKCal(ing.selectedFood.nutrition) : 0;
        const sfn = ing.selectedFood ? ing.selectedFood.name : 'NO_SELECTED_FOOD';
        const ptn = ing.portionType ? ing.portionType.name : 'NO_PORTION_TYPE';
        if (ing.food_portion === undefined) {
            console.log('food portion is missing for ' + sfn + ' ' + ptn, ing);
        }
        const actGram = ing.food_portion ? ing.amount * ing.food_portion.portion_weight * multiplier : 0;
        const actKcal = actGram * 0.01 * foodKcal;
        sum += actKcal;
        if (debug) console.log('Ingredient: ' + ptn + ' - ' + sfn + '[' + ing.amount + '] foodKCAL{100g}: ' + foodKcal + ' actKCAL:  {' + actGram.toFixed(1) + 'g} ' + actKcal.toFixed(2));
    }
    // console.log(recipe)
    if (recipe.portion_count === 0) {
        recipe.portion_count = 1;
    }
    return sum;
}

function getRecipeNutrition(recipe, countUndefineds = false, beginWithNaN = false) {
    let sum = beginWithNaN ? {} : newNutritionObject();
    const multiplier = recipe.portion_count > 0 ? recipe.amount / recipe.portion_count : recipe.amount;
    for (let i = 0; i < recipe.ingredients.length; i++) {
        const ing = recipe.ingredients[i];
        const foodNutrition = ing.selectedFood ? ing.selectedFood.nutrition : {};
        if (!foodNutrition.kcal) {
            const kcal = computeKCal(foodNutrition);
            foodNutrition.kcal = isNaN(kcal) ? 0 : kcal;
        }
        if (ing.food_portion) {
            sumNutrition(sum, foodNutrition, multiplier * ing.amount * ing.food_portion.portion_weight / 100, countUndefineds);
        }
    }
    return sum
}

function getIngredientsNutrition(recipe, countUndefineds = false) {
    const multiplier = recipe.portion_count > 0 ? recipe.amount / recipe.portion_count : recipe.amount;

    const result = JSON.parse(JSON.stringify(recipe.ingredients));
    for (const ingredient of result) {
        let sum = newNutritionObject();

        const foodNutrition = ingredient.selectedFood ? ingredient.selectedFood.nutrition : {};
        if (ingredient.food_portion) {
            sumNutrition(sum, foodNutrition, multiplier * ingredient.amount * ingredient.food_portion.portion_weight / 100, countUndefineds);
            ingredient.selectedFood.nutrition = sum;
        }
    }

    return result;
}

function recalculateNutritionalValue(nutrition, oldAmount, newAmount) {
    for (const property in nutrition) {
        nutrition[property] = (nutrition[property] * newAmount / oldAmount);
    }
    return nutrition;
}


function recalculatePortions(ingredients, oldAmount, newAmount) {
    const result = JSON.parse(JSON.stringify(ingredients));
    for (const ingredient of result) {
        ingredient.amount = (ingredient.amount * newAmount / oldAmount);
    }
    return result;
}

function getVariability(total, target, precision) {
    const totalNum = formatNumber(total, precision)
    const targetNum = formatNumber(target, precision);
    const diff = totalNum - targetNum;
    return (diff > 0 ? '+' : '') + (diff).toFixed(precision ? precision : 0)
}


function isMissingNutrient(data, nutrient) {
    if (data._undefineds && data._undefineds[nutrient]) {
        return data._undefineds[nutrient] > 0;
    }
    return false;
}

//const n = newNutritionObject();
//n.kcal = 155;
//n.fat = 11;
//n.protein = 13;
//n.carbs = 1.1;
//n.cholesterol = 373;
//n.sodium = 124;

//console.log(transformFromNutrition(n));

export {
    getVariability,
    isMissingNutrient,
    newNutritionObject, zeroNutrition, sumNutrition, getDailyNutrition, getKCal,
    nutritionKeys,
    scaleNutritionByAmount,
    scaleNutrition,
    getRecipeNutrition,
    getRecipeNutritionKcal,
    getIngredientsNutrition,
    getIngredientNutritionKcal,
    recalculateNutritionalValue,
    recalculatePortions,
    nutritionSymbols,
    nutritionSymbolMap,
    POUND_TO_KG,
    KG_TO_POUND,
    INCH_TO_CM,
    MAX_DAILY_KCAL_LOSS,
    DAILY_KCAL_TOLERANCE_MULTIPLIER,
    RecipeType,
}