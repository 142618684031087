import React, { Component, createRef } from 'react';
import { store, view } from '@risingstack/react-easy-state';

import './ModalWrapper.scss';

import delay from 'utils/delay';

import Container from 'components/Container';
import Icon, { icons } from 'components/Icon';

export const modalStore = store({
    modalWindows: []
})

export function addModal(component, props, onClose) {
    modalStore.isBgCloseAvailable = false
    delay(250).then(() => {
        modalStore.isBgCloseAvailable = true
    })
    modalStore.modalWindows.push({
        component: component,
        props: props,
        onClose: onClose
    })
}

export function clearModals() {
    modalStore.modalWindows = [];
}

class ModalWrapper extends Component {
    constructor(props) {
        super(props)
        this.ref = createRef()
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            modalStore.modalWindows.pop();
        }
    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    render() {
        if (modalStore.modalWindows.length === 0) {
            document.body.className = '';
            document.body.style.paddingRight = '0';
        } else {
            document.body.style.paddingRight = window.innerWidth - document.body.clientWidth + 'px';
            document.body.className = 'modal-body';
        }

        return (
            <div className='modal-wrapper-container'>
                {(modalStore.modalWindows.length > 0) && modalStore.modalWindows.map((el, index) => {
                    console.log(el)
                    const Component = el.component;
                    const closeFunction = (data) => {
                        modalStore.modalWindows.splice(index, 1);

                        if (el.onClose) {
                            el.onClose(data);
                        }
                    }

                    const onBgClose = (e) => {
                        if (el.props && el.props.closeOnBg && modalStore.isBgCloseAvailable) {
                            if (this.ref && this.ref.current && this.ref.current === e.target) {
                                modalStore.isBgCloseAvailable = false
                                closeFunction()
                            }
                        }
                    }

                    let addClass = Component.class ? Component.class : '';
                    const wrapperClass = Component.wrapperClass ? Component.wrapperClass : '';
                    if (el.props && el.props.modalClass) {
                        addClass = el.props.modalClass
                    }

                    return (
                        <div key={Component.name + '_' + index} ref={this.ref} className={'modal-wrapper ' + (wrapperClass)} style={{ zIndex: 100 + index }} onTouchStart={(e) => onBgClose(e)} onMouseDown={(e) => onBgClose(e)}>
                            <div className="modal-wrapper-bg" />
                            <Container panel relative className={addClass} onClick={(e) => e.preventDefault()}>
                                {el?.props?.closeHidden ? '' : <Icon icon={icons.closeCircle} className="modal-close-button clickable" onClick={() => closeFunction()} />}
                                <Component {...el.props} key={Component.name + '_' + index + '_cpmnt'} closeAction={closeFunction} />
                            </Container>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default view(ModalWrapper);