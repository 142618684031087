import moment from 'moment';

import mainStore from 'storeUtils/mainStore';
import { recalculateWeeklyNutrition, resetMainStoreToDefault, selectDay } from 'storeUtils/storeUtils';

import { cloneAndMarshalFromDBTypes } from 'subsystems/MyDB/marshalFromDBTypes';
import dbModel from 'subsystems/MyDB/dbModel';
import { getRecipeNutrition, newNutritionObject } from 'subsystems/nutrition/nutrition.js';

import { creds, fetchJSON } from 'utils/fetchJSON';
import arrayToMap from 'utils/arrayToMap';
import arrayToMultivalueMap from 'utils/arrayToMultivalueMap';
import { USER_PERSON_RELATIONSHIP } from 'utils/constants/person';
import { fetchBaseInfo } from './fetchBaseInfo';
import layoutStore, { resetLayoutStoreToDefaults } from 'storeUtils/layoutStore';
import shoppingStore, { resetShoppingStoreToDefaults } from 'storeUtils/shoppingStore';
import adminStore, { resetAdminStoreToDefaults } from 'storeUtils/adminStore';
import { batch } from '@risingstack/react-easy-state';

export function loadWeeklyData(weeklyMenu) {
    const weeklyData = [];
    for (let i = 0; i < 7; i++) {
        const menu = weeklyMenu[i];

        menu.menu = menu.menu.filter((me) => {
            if (me.order === 2 && mainStore.profile.extra?.snackTimes?.morning !== true) return false;
            if (me.order === 4 && mainStore.profile.extra?.snackTimes?.afternoon !== true) return false;
            if (me.order === 6 && mainStore.profile.extra?.snackTimes?.evening !== true) return false;
            return true;
        });

        menu.menu.forEach(x => x.recipes = x.recipes.filter(x => !x.deleted));
        menu.menu.forEach(meal => {
            meal.recipes.forEach(recipe => recipe.nutrition = getRecipeNutrition(recipe))
        })
        const newDay = {
            date: menu.date,
            nutrition: newNutritionObject(false),
            menu: menu.menu,
            index: i,
        }
        weeklyData.push(newDay);
    }
    mainStore.weeklyData = weeklyData;
}

export function updateStore(baseInfo) {
    if (baseInfo?.baseInformation) {
        if (baseInfo.baseInformation.baseInfoTimeUTC) mainStore.baseInfoTimeUTC = baseInfo.baseInformation.baseInfoTimeUTC;
        mainStore.backupWeeklyData = baseInfo.baseInformation.weeklyMenu;
        loadWeeklyData(baseInfo.baseInformation.weeklyMenu);
        if (!mainStore.selectedDay) {
            // no current day
            const index = getTodayWeeklyMenuIndex();
            selectDay(mainStore.weeklyData[index]);
        } else {
            // already have a day selected
            selectDay(mainStore.weeklyData[mainStore.selectedDay.index])
        }
        if (baseInfo.baseInformation.patternWeek) {
            mainStore.patternWeek = baseInfo.baseInformation.patternWeek;
        }
        shoppingStore.shoppingLists = baseInfo.baseInformation.shoppingLists

        mainStore.foodLog.selectedDate = '';
        mainStore.foodLog.selectedDateMenu = [];
        mainStore.foodLog.menuDateMap = {};
    }
}

function isAccountSetupComplete(json) {
    return json.person && (json.person.accountSetup === true || json.person.ready)
}

function getTodayWeeklyMenuIndex() {
    const todayDate = moment().format("YYYY-MM-DD");
    const index = mainStore.weeklyData?.findIndex(x => x.date === todayDate);
    return index;
}

export function mapUserDataAfterSuccess(json) {
    batch(() => {

        mainStore.baseInfoTimeUTC = json?.baseInfoTimeUTC;
        mainStore.invitationCode = json?.invitationCode
        
        mainStore.presets = json.presets;

        mainStore.profile = cloneAndMarshalFromDBTypes(json.person, dbModel.person);

        localStorage.setItem('APIKey', creds.id + "|" + creds.key);

        if (isAccountSetupComplete(json)) {
            mainStore.backupWeeklyData = JSON.parse(JSON.stringify(json.weeklyMenu)) // backup WeeklyData for preffered snack settings 
            loadWeeklyData(json.weeklyMenu);

            const index = getTodayWeeklyMenuIndex();
            selectDay(mainStore.weeklyData[index]);

            recalculateWeeklyNutrition();

            shoppingStore.shoppingLists = json.shoppingLists;
        }

        if (Array.isArray(json.notifications) && json.notifications.length > 0) {
            const notifications = json.notifications.map(n => n.message);
            mainStore.notifications = notifications;
        }

        mainStore.featuredRecipes = json.featuredRecipes.filter(x => x.is_featured);
        mainStore.mainSections = json.featuredRecipes.filter(x => x.parent_id === 0 && !x.is_featured);
        mainStore.recipeSubCategoriesMap = arrayToMultivalueMap(json.featuredRecipes.filter(x => x.id !== 0), 'parent_id');
        mainStore.recipeCategoriesMap = arrayToMap(json.featuredRecipes.filter(x => x.id !== 0), 'id');
        mainStore.recipeCategoriesStack = [];

        mainStore.restrictions = json.restrictions;
        mainStore.portionTypes = json.portionTypes;
        shoppingStore.shoppingCategories = json.shoppingCategories;
        mainStore.patternWeek = json.patternWeek;
        mainStore.manager = json.manager;
        mainStore.memberStatistics = json.memberStatistics;
        layoutStore.loggedIn = true;
        adminStore.loggedIn = true;
        layoutStore.initialLoading = false
        layoutStore.request.progress = false;
    })
}

/**
 * 
 * @param {number} profile_id 
 */
export async function selectUserProfile(profile_id, registration = false) {
    if (!profile_id && !registration) logout();

    const baseInfo = await fetchBaseInfo(profile_id);
    mapUserDataAfterSuccess(baseInfo);
    mainStore.temporaryProfile = JSON.parse(JSON.stringify(mainStore.profile));
}

export async function refreshUserProfile() {
    layoutStore.bigLoading = true;
    await selectUserProfile(mainStore.profile.id);
    layoutStore.bigLoading = false;
}

export async function exitFromProfile(navigate) {
    layoutStore.bigLoading = true;
    const ownerProfile = mainStore.availableProfiles.find(x => x.user_person_relationship === USER_PERSON_RELATIONSHIP.OWNER);
    await selectUserProfile(ownerProfile.person_id)
    navigate('/user/account');
    layoutStore.bigLoading = false;
}

function updateRequestAndCredentials(json, APIKey) {
    layoutStore.request = {
        progress: false,
        error: json.error,
        message: json.message,
        enabled: json.enabled !== undefined ? json.enabled : undefined,
        uid: json.uid ? json.uid : undefined
    };

    creds.key = APIKey;
    creds.id = json.user.id;
    mainStore.APIKey = APIKey;
    layoutStore.isAdmin = json.user.admin_enabled;

    localStorage.setItem('APIKey', creds.id + "|" + creds.key);
}

export function clearUserDataAfterLogout() {
    creds.key = undefined;
    creds.id = undefined;
    localStorage.removeItem('APIKey');
    mainStore.APIKey = undefined;
    layoutStore.initialLoading = undefined;

    resetMainStoreToDefault();
    resetShoppingStoreToDefaults();
    resetLayoutStoreToDefaults();
    resetAdminStoreToDefaults();
}

export function isExtraProfilesAvailable() {
    return false;
    // return Array.isArray(mainStore.availableProfiles) && mainStore.availableProfiles.length > 1; // only when connected more profiles than one owner
}

export function isUserManager() {
    return mainStore.currentUser?.user_roles?.manager?.enabled
}

export function isSuperManager() {
    return mainStore.currentUser?.user_roles?.super_manager?.enabled
}

export function isSelectedProfileOwner() {
    if (!isUserManager()) return true;
    if (!mainStore?.profile?.id) return false;

    const currentPersonId = mainStore.profile.id
    return mainStore.availableProfiles?.find(x => {
        return x.person_id === currentPersonId && x.user_person_relationship === USER_PERSON_RELATIONSHIP.OWNER
    });
}

export function verifyLogin(mail, password) {
    layoutStore.request.progress = true;
    layoutStore.request.message = '';

    return fetchJSON('/api/login/login', { mail, password })
        .then(async (json) => {
            updateRequestAndCredentials(json, json.APIKey);
            mainStore.availableProfiles = json.availableProfiles;
            mainStore.currentUser = json.user;

            layoutStore.initialLoading = false
            return json;
        })
        .catch(error => {
            clearUserDataAfterLogout();

            if (error?.error && error?.message) {
                layoutStore.request = {
                    progress: false,
                    error: error.error,
                    message: error.message,
                    enabled: error.enabled !== undefined ? error.enabled : undefined,
                    uid: error.uid ? error.uid : undefined
                };
            } else {
                layoutStore.request = {
                    progress: false,
                    error: true,
                    message: error?.message ? error?.message : 'Server is currently offline.'
                };
            }

            layoutStore.initialLoading = false;
            return undefined;
        });
}

export async function verifyAPILogin(userID, APIKey) {
    layoutStore.request = {
        progress: true,
        error: false,
        message: ''
    };

    let loggedIn = false;
    try {
        const json = await fetchJSON('/api/login/verifyApiKey/', { uid: userID, key: APIKey });

        if (json?.loggedIn) {
            loggedIn = json.loggedIn;

            updateRequestAndCredentials(json, json.APIKey);
            mainStore.availableProfiles = json.availableProfiles;
            mainStore.currentUser = json.user;
        } else {
            clearUserDataAfterLogout();
            layoutStore.request = {
                progress: false,
                error: json.error,
                message: json.message,
                enabled: json.enabled !== undefined ? json.enabled : undefined,
                uid: json.uid ? json.uid : undefined
            };
        }
    } catch (error) {
        clearUserDataAfterLogout();

        if (error?.error && error?.message) {
            layoutStore.request = {
                progress: false,
                error: error.error,
                message: '',
                enabled: error.enabled !== undefined ? error.enabled : undefined,
                uid: error.uid ? error.uid : undefined
            };
        } else {
            layoutStore.request = {
                progress: false,
                error: true,
                message: error?.message ? error?.message : 'Server is currently offline.'
            };
        }

        layoutStore.initialLoading = false;
        return undefined;
    }

    if (loggedIn) {
        const rememberedProfileId = JSON.parse(localStorage.getItem('nutristyle.rememberedProfileId') || '0');
        let person_id = '';

        const ownerProfile = mainStore.availableProfiles.find(x => x.user_person_relationship === USER_PERSON_RELATIONSHIP.OWNER);

        mainStore.currentUser.name = ownerProfile.name;
        mainStore.currentUser.surname = ownerProfile.surname;
        mainStore.currentUser.id = ownerProfile.id;

        if (isUserManager() && rememberedProfileId) {
            person_id = rememberedProfileId;
        } else {
            person_id = ownerProfile.id
        }

        const baseInfo = await fetchBaseInfo(person_id);
        mapUserDataAfterSuccess(baseInfo);
    }

    layoutStore.initialLoading = false;
}

export async function logout() {
    layoutStore.request = {
        progress: true,
        message: '',
        error: false
    };

    localStorage.removeItem('nutristyle.rememberedProfileId');

    return fetchJSON('/api/logout/')
        .then((json) => {
            clearUserDataAfterLogout()
            console.log(json)
            return json;
        })
        .catch(error => {
            clearUserDataAfterLogout()
            return undefined;
        });
}