import React from 'react';

const whatsappIcon = () => (
    <svg viewBox="0 0 60 60">
        <path d="M29.999,60C46.568,60,60,46.568,60,30c0-16.57-13.432-30-30.001-30C13.432,0,0,13.431,0,30
			C0,46.568,13.432,60,29.999,60z M13.279,29.553c0-0.247,0.008-0.494,0.016-0.741c0.399-8.997,7.864-16.167,17.019-16.167
			c9.268,0,16.797,7.338,17.029,16.477c0.008,0.143,0.008,0.287,0.008,0.431c0,9.34-7.625,16.909-17.036,16.909
			c-2.983,0-5.79-0.767-8.23-2.106l-9.435,2.999l3.07-9.068C14.172,35.734,13.279,32.751,13.279,29.553z"/>
        <path d="M16.932,43.168l5.503-1.755c2.265,1.491,4.969,2.353,7.879,2.353
			c7.896,0,14.324-6.372,14.324-14.212c0-0.375-0.016-0.741-0.047-1.108c-0.575-7.322-6.755-13.104-14.277-13.104
			c-7.424,0-13.55,5.639-14.252,12.833c-0.048,0.454-0.072,0.917-0.072,1.379c0,3.111,1.013,5.99,2.728,8.334L16.932,43.168z
			 M23.464,22.351c0.383-0.414,0.837-0.518,1.116-0.518c0.279,0,0.558,0.039,0.798,0.039c0.247,0,0.598-0.143,0.941,0.687
			c0.351,0.829,1.189,2.871,1.292,3.078c0.104,0.207,0.176,0.447,0.032,0.725c-0.095,0.199-0.167,0.344-0.263,0.495
			c-0.049,0.063-0.096,0.128-0.152,0.2c-0.215,0.239-0.438,0.534-0.63,0.725c-0.207,0.207-0.423,0.431-0.183,0.846
			c0.239,0.414,1.085,1.771,2.32,2.863c1.595,1.42,2.943,1.851,3.358,2.058c0.422,0.207,0.662,0.175,0.909-0.104
			c0.24-0.272,1.045-1.205,1.325-1.619c0.279-0.415,0.558-0.351,0.941-0.208c0.382,0.136,2.433,1.141,2.855,1.348
			c0.415,0.207,0.695,0.311,0.798,0.479c0.103,0.175,0.103,1.005-0.248,1.969c-0.343,0.973-2.05,1.907-2.815,1.97
			c-0.765,0.072-0.765,0.622-5.016-1.028c-4.243-1.667-6.922-5.99-7.13-6.261c-0.16-0.207-1.069-1.412-1.491-2.871
			c-0.128-0.455-0.216-0.933-0.216-1.413C22.004,23.771,23.081,22.765,23.464,22.351z"/>
    </svg>
)

export default whatsappIcon;