import { getRecipeNutrition, RecipeType } from "../../src/subsystems/nutrition/nutrition.js";


// class Meal {

export function isRTE(meal) {
    return meal?.recipe_type === RecipeType.RTE || meal?.recipe_type === RecipeType.Service;
}

/**
 * Checks if meal have any ingredient altered
 * @param {*} meal 
 * @returns {bool}
 */
export function isAltered(meal) {
    return meal?.ingredients?.some(ingr => isIngredientAltered(ingr))
}
 
/**
 * Checks if meal have any ingredient prohibited
 * @param {*} meal 
 * @returns {bool}
 */
export function isAnyProhibited(meal) {
    return meal?.ingredients?.some(ingr => isFoodProhibited(ingr))
}

/**
 * Checks whether ingredient subsitute
 * @param {*} meal 
 * @returns {bool}
 */
export function isIngredientAltered(ingr) {
    return ingr?.originalSelectedFood
}

/**
 * Checks whether ingredient is prohibited
 * @param {*} meal 
 * @returns {bool}
 */
 export function isFoodProhibited(ingr) {
    return ingr?.isFoodProhibited && !ingr?.originalSelectedFood
}




/* ======================================== RENDERING ======================================== */

export function getMealName(meal) {
    if (isRTE(meal)) {
        return meal?.ingredients[0]?.selectedFood?.name || '';
    }
    return meal?.name || '';
}

export function getMealBrand(meal) {
    if (isRTE(meal)) {
        return meal?.ingredients[0]?.brand || '';
    }
    return '';
}

export function getMealSiteName(meal) {
    return meal?.recipe_site_name || '';
}

export function getMealSiteUrl(meal) {
    return meal?.recipe_site_url || '';
}

export function getMealPortionTypeName(meal, fallbackName = 'Serving(s)') {
    if (isRTE(meal)) {
        return meal.ingredients[0]?.portionType?.name || fallbackName;
    }
    return fallbackName;
}

export function getMealPreparationDifference(oldMeal, newMeal) {
    let timeDiff = 0;
    if ((isRTE(newMeal) && isRTE(oldMeal)) || !oldMeal || !newMeal) {
        timeDiff = 0;
    } else if (isRTE(newMeal)) {
        timeDiff = oldMeal ? -oldMeal.preparation_time : null;
    } else if (isRTE(oldMeal)) {
        timeDiff = oldMeal ? +newMeal.preparation_time : null;
    } else {
        timeDiff = oldMeal ? (+newMeal.preparation_time - +oldMeal.preparation_time) : null;
    }
    return timeDiff;
}


/* ======================================== VALIDATION ======================================== */

export function isMealAmountValid(meal) {
    if (isRTE(meal)) {
        return isRTEAmountValid(meal);
    }
    return isAmountValid(meal);
}

function isRTEAmountValid(meal) {
    return getMealAmountNoExtra(meal) >= 0.13;
}

function isAmountValid(meal) {
    return meal?.amount >= 0.13;
}


/* ======================================== NUTRITION UTILS ======================================== */

export function getMealKcal(meal) {
    return meal?.nutrition?.kcal || 0;
}

export function recalculateMealNutrition(meal, countUndefineds = false) {
    meal.nutrition = getRecipeNutrition(meal, countUndefineds);
}

export function matchMealKcal(meal, targetKcal) {
    recalculateMealNutrition(meal);
    if (meal.nutrition?.kcal) {
        const proportions = targetKcal / meal.nutrition.kcal;
        if (proportions && proportions != 1) {
            let newAmount = meal.amount * proportions;
            // align to min/max portions
            newAmount = Math.min(meal.max_portions, Math.max(meal.min_portions, newAmount));
            if (isRTE(meal)) {
                newAmount = getRTERoundedAmount(meal, newAmount)
            } else {
                newAmount = Math.max(0.25, Math.round(newAmount / 0.25) * 0.25)
            }
            changeMealAmount(meal, newAmount);
        }
        return meal;
    }
    return false;
}


/* ======================================== MEAL AMOUNT MATH ======================================== */

export function getMealPortionCount(meal) {
    const portionCount = meal?.portion_count ?? 1;
    return portionCount;
}

export function getMealAmountMultiplier(meal) {
    let unit = 1;
    if (isRTE(meal)) {
        unit = getRTEIngredientAmountMultiplier(meal);
        unit /= getMealPortionCount(meal);
    }
    return unit;
}

export function getMealAmountNoExtra(meal) {
    const multiplier = getMealAmountMultiplier(meal);
    const amount = meal?.amount ?? 0;
    return amount * multiplier;
}

export function getMealAmountWithExtra(meal) {
    const multiplier = getMealAmountMultiplier(meal);
    const amount = meal?.amount ?? 0;
    const extra = meal?.extra_portions ?? 0;
    return (amount + extra) * multiplier;
}

/**
 * Used for total meal nutrition calculations 
 * @param {*} meal 
 * @returns total amount / portion_count
 */
export function getIngredientFactorNoExtra(meal) {
    const portionCount = getMealPortionCount(meal);
    const amountNoExtra = meal?.amount ?? 0;
    const result = amountNoExtra / portionCount;
    return result;
}

/**
 * Used for personal nutrition calculations 
 * @param {*} meal 
 * @returns  amount / portion_count
 */
export function getIngredientFactorWithExtra(meal) {
    const portionCount = getMealPortionCount(meal);
    const extra = meal?.extra_portions ?? 0;
    const amount = meal?.amount ?? 0;
    const amountWithExtra = amount + extra;
    const result = amountWithExtra / portionCount;
    return result;
}

/**
 * Changes my_servings
 * @param {*} meal 
 * @param {*} newValue non-scaled meal amount (in case of RTE the param has to be divided by mealMultipier)
 */
export function changeMealAmount(meal, newValue) {
    if (!isRTE(meal)) {
        // only real recipes can have non-zero extra_portions
        const oldValue = meal.amount;
        const diff = newValue - oldValue;
        const newExtra = meal.extra_portions - diff;
        if (newExtra >= 0) {
            // my_servings is smaller than recipe_servings we can safely decrease extra portions
            meal.extra_portions = newExtra;
        }
        else {
            // my_servings exceeded recipe_servings
            meal.extra_portions = 0;
        }
    } else {
        meal.extra_portions = 0;
    }
    meal.amount = newValue;
    recalculateMealNutrition(meal);
}

/**
 * Changes recipe_servings (only non-RTE)
 * @param {*} meal 
 * @param {*} newValue 
 */
export function changeMealServings(meal, newValue) {
    const oldValue = meal.amount + meal.extra_portions;
    const diff = newValue - oldValue;
    const newExtra = meal.extra_portions + diff;
    if (newExtra >= 0) {
        // just increase extra servings
        meal.extra_portions = newExtra;
    }
    else {
        // newExtra is negative
        meal.extra_portions = 0;
        // to decrease servings further we need to decrease the amount
        meal.amount -= -newExtra;
    }
}




/* ======================================== RTE UTILS ======================================== */


export function getRTEMealPortionTypeId(meal) {
    if (isRTE(meal)) {
        return meal.ingredients[0]?.portionType?.id || undefined;
    }
    return undefined;
}

export function getRTEIngredientAmountMultiplier(meal) {
    if (isRTE(meal)) {
        const unit = meal.ingredients[0]?.amount || 1;
        return unit;
    }
    return 1;
}

export function changeRTEFoodPortion(meal, oldPortionWeight, newPortionInfo) {

    meal.ingredients[0].food_portion.portion_type_id = newPortionInfo.portion_type_id;
    meal.ingredients[0].food_portion.portion_weight = newPortionInfo.portion_weight || 100;
    meal.ingredients[0].portionType.multiplier = newPortionInfo.multiplier
    meal.ingredients[0].portionType.core_portion = newPortionInfo.core_portion
    meal.ingredients[0].portionType.name = newPortionInfo.value
    meal.ingredients[0].portionType.id = newPortionInfo.portion_type_id


    const newPortionWeight = newPortionInfo?.portion_weight ?? oldPortionWeight;
    const factor = oldPortionWeight / newPortionWeight
    let targetAmount = meal.amount *= factor;
    targetAmount = Math.max(0.25, Math.round((targetAmount) / 0.25) * 0.25);
    changeMealAmount(meal, targetAmount);
}

export function matchRTEPortionAndAmount(meal, otherMealData) {
    // both meals must be RTE and have the same portion type for main ingredient
    const portionsMatch = getRTEMealPortionTypeId(meal) === getRTEMealPortionTypeId(otherMealData);
    if (portionsMatch) {
        changeMealAmount(meal, meal.portion_count * getMealAmountWithExtra(otherMealData) / getRTEIngredientAmountMultiplier(meal))
        return true;
    }
    return false;
}

/**
 * 
 * @param {*} rteMeal Meal to get the value from
 * @returns default food.quanity for RTE ingredient
 */
export function getRTEIngredientDefaultAmount(rteMeal) {
    const unit = rteMeal?.ingredients[0]?.selectedFood?.quantity || 1;
    return unit;
}

export function adjustRTEAmountBeforeSave(rteMeal) {
    moveRTEIngredientAmountToMealAmount(rteMeal);
    const defaultAmount = getRTEIngredientDefaultAmount(rteMeal);
    changeMealAmount(rteMeal, rteMeal.amount / defaultAmount);
}

export function moveRTEIngredientAmountToMealAmount(rteMeal) {
    const tempAmount = rteMeal.ingredients[0].amount ?? 1;
    rteMeal.ingredients[0].amount = 1;
    changeMealAmount(rteMeal, rteMeal.amount * tempAmount);
}


export function getRTERoundedAmount(rteMeal, newAmount) {
    // get portion scale multiplier
    const multiplier = getRTEIngredientAmountMultiplier(rteMeal);

    // go to portion scale
    const toRound = newAmount * multiplier;

    //round in that scale
    const roundedAmount = Math.max(0.25, Math.round(toRound / 0.25) * 0.25);

    // go back to meal amount scale
    return roundedAmount / multiplier;
}

export const mealWarningEnum = {
    DAILY_TARGET: 'daily-target',
    SUBSTITUTE: 'recipe-with-altered-ingredients',
    RESTRICTION: 'restriction',
    REBALANCE: 'rebalance',
    REBALANCE_TODAY: 'rebalance-today', // user is required to manually day rebalance
    CANT_FIT: 'cant-fit',
    CANT_ADD: 'cant-add'
}