import React from 'react';
import { NavLink } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state';

import './FooterCopyButton.scss';

function isActive(match, location, to) {
    return location.pathname === to;
}

const FooterCopyButton = view((props) => {
    if (props.to) {
        return (
            <NavLink
                to={props.to}
                className={
                    "footer-copy-button " +
                    (props.left ? 'left ' : '') +
                    (props.right ? 'right ' : '') +
                    (props.dark ? 'dark ' : '') +
                    (props.darker ? 'darker ' : '') +
                    (props.animated ? 'animated ' : '') +
                    (props.className ? props.className : '')
                }
                exact={props.exact}
                isActive={(match, location) => isActive(match, location, props.to)} 
                >
                    {props.children}
                </NavLink>
        );
    }
    return (
        <div className={
            "footer-copy-button " +
            (props.left ? 'left ' : '') +
            (props.right ? 'right ' : '') +
            (props.dark ? 'dark ' : '') +
            (props.darker ? 'darker ' : '') +
            (props.animated ? 'animated ' : '') +
            (props.className ? props.className : '')
        } onClick={props.onClick} >{props.children}</div>
    );
});

export default FooterCopyButton;