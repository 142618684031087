import React from 'react';
import { view } from '@risingstack/react-easy-state';
import PropTypes from 'prop-types';

import './Button.scss'

import { FA } from '../Icon';
import Button from 'components/Button';

/**
 * @typedef ProgressButtonProps
 * @type {object}
 * @property {boolean} inProgress determine button progress state
 * @property {string} inProgressText extra text
 * @property {boolean} disabled make button disabled
 * @property {object} extraProps apply extra props as in Button component
 * @property {string} className
 * @property {any} children
 * @property {function} onClick
*/

/**
 * @param {ProgressButtonProps} props
 */
const RawProgressButton = (props) => {
    return <Button green block {...props.extraProps}
        type="submit"
        onClick={props.onClick && typeof props.onClick === 'function' ? props.onClick : ''}
        className={'progress d-inline-block ' + (props.className ? props.className : '')}
        disabled={props.inProgress || props.disabled}
    >
        {props.inProgress ? <div className={'progress-button-icon d-inline-block'}>
            <FA icon="fas fa-sync fa-spin" />
            {props.inProgressText ? <span className="pl-2">{props.inProgressText}</span> : ''}
        </div> : props.children}
    </Button>
};

RawProgressButton.propTypes = {
    inProgress: PropTypes.bool,
    inProgressText: PropTypes.string,
    disabled: PropTypes.bool,
    extraProps: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func
};

/**
 * button that render spinner effect if in progress state
 * 
 * @component
 * @type {{ new(props: ProgressButtonProps): {
 *   props: { 
 *     inProgress: boolean, 
 *     disabled: boolean,
 *     extraProps: Object,
 *     className: string,
 *     inProgressText: string,
 *     children: any,
 *     onClick: Function,
 *     }
 *   }
 *  }}
 */
const ProgressButton = view(RawProgressButton);
export default ProgressButton;