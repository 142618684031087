import { fetchJSON } from 'utils/fetchJSON';
import layoutStore from 'storeUtils/layoutStore';

export default function searchQuery(query, resultCount, justRecipes, justFoods, skip = 1) {
    return fetchJSON('/api/search', {
        query: query,
        resultCount: resultCount,
        justRecipes: justRecipes,
        justFoods: justFoods
    })
        .then((json) => {
            if (!json?.request?.success) {
                layoutStore.request.error = json.error
                layoutStore.request.message = json.message
                return [];
            }

            console.log('[searchQuery.js] - found', json.result.length, 'records')
            return json?.result ? json.result : [];
        })
        .catch(error => {
            console.error('[searchQuery.js] - error result', error)
            return [];
        });
}
