import React, { Component } from 'react';
import './SmartInput.scss'
import { view } from '@risingstack/react-easy-state';
import { Input } from 'components/Input';
import { FA } from 'components/Icon';
import PropTypes from 'prop-types';

function prepareSearch(props, state) {
    if (state.searchArrayInstance !== props.options) {
        const searchArrayInstance = props.options;
        const searchMap = {};
        const searchArray = searchArrayInstance.map((r) => {
            const o = props.accessor(r);
            o.dvalue = o.value;
            o.value = o.value.toLowerCase();
            searchMap[o.key] = o;
            return o;
        });
        searchArray.sort(function (a, b) {
            return (a.value).localeCompare(b.value);
        });
        //console.log(searchArray, searchMap)
        return {
            searchArrayInstance: searchArrayInstance,
            searchMap: searchMap,
            searchArray: searchArray
        }
    }
    return {};
}

class SmartInput extends Component {

    state = {
        typedValue: '',
        bestSuggestion: {},
        first: [],
        middle: [],
        selectedIndex: -1,

        searchMap: {},
        searchArray: [],
    }

    findRest = () => {
        if (this.state.typedValue == '') return [];
        const s = this.state.typedValue.toLowerCase();
        let c = 0;
        let results = [];
        for (let i = 0; i < this.state.searchArray.length; i++) {
            let p = this.state.searchArray[i].value.indexOf(s);
            if (p > 0) {
                //console.log('io', this.state.searchArray[i].value);
                this.state.searchArray[i].s = p;
                results.push(this.state.searchArray[i]);
                c++;
                if (c > this.props.resultCount) {
                    break;
                }
            }
        }
        return results;
    }

    findFirst = () => {
        if (this.state.typedValue == '') return [];
        const s = this.state.typedValue.toLowerCase();
        let c = 0;
        let results = [];
        for (let i = 0; i < this.state.searchArray.length; i++) {
            if (this.state.searchArray[i].value.startsWith(s)) {
                //console.log('sw', this.state.searchArray[i].value);
                this.state.searchArray[i].s = 0;
                results.push(this.state.searchArray[i]);
                c++;
                if (c > this.props.resultCount) {
                    break;
                }
            }
        }
        return results;
    }

    bestSuggestion(a1, a2) {
        //now for the best suggestion: we check the input length vs item length
        for (let i = 0; i < a1.length; i++) {
            if (a1[i].value.length <= this.state.typedValue.length + 1) {
                //if we have almost full coverage of a something typed in, we suggest it.
                return a1[i];
            }
        }
        for (let i = 0; i < a2.length; i++) {
            if (a2[i].value.length <= this.state.typedValue.length + 2) {
                //if we have almost full coverage of a something typed in, we suggest it.
                return a2[i];
            }
        }
        if (a1.length == 1) {
            return a1[0];
        }
        if (a2.length == 1) {
            return a2[0];
        }
        return { key: -1, value: '' };
    }

    doSearch = () => {
        let first = this.findFirst();
        let middle = this.findRest();
        let bestSuggestion = this.bestSuggestion(first, middle);
        this.setState({
            first: first,
            middle: middle,
            bestSuggestion: bestSuggestion,
            selectedIndex: -1
        }, () => {
            if ((this.state.typedValue !== '') && (bestSuggestion.value.toLowerCase() === this.state.typedValue.toLowerCase()) && (middle.length + first.length === 1)) {
                this.updateValue(bestSuggestion);
            }
        });
    }

    static getDerivedStateFromProps(props, state) {
        //console.log('gdsfp', props, state);
        const resObject = prepareSearch(props, state);
        if (!state.focused && props.value === undefined) {
            resObject.typedValue = '';
        } else {
            let o;
            if (resObject.searchMap) {
                o = resObject.searchMap[props.value];
            } else {
                o = state.searchMap[props.value];
            }
            if (o !== undefined) {
                if (!state.focused) {
                    if (o.value.toLowerCase() !== state.typedValue.toLowerCase()) {
                        //we have a new value
                        resObject.typedValue = o.dvalue;
                    }
                }
            } else {
                if (!state.focused) {
                    if (state.searchArray.length > 0) {
                        resObject.typedValue = state.searchArray[0].dvalue;
                    } else {
                        resObject.typedValue = '';
                    }
                }
            }
        }
        return resObject;
    }

    updateValue(valueObject) {
        // console.log('uv', valueObject)
        this.props.onChange(valueObject.key);
        this.setState({
            first: [],
            middle: [],
            bestSuggestion: valueObject,
            selectedIndex: -1,
            typedValue: valueObject.dvalue
        })
    }

    onChange = (e) => {
        const ns = prepareSearch(this.props, { ...this.state, typedValue: e.target.value });
        this.setState({ ...ns, typedValue: e.target.value }, () => {
            this.doSearch();
        });
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.updateValue(this.state.bestSuggestion);
        }
        if (this.state.first.length > 0 || this.state.middle.length > 0) {
            if (e.key == 'ArrowUp') {
                e.preventDefault();
                let bestSuggestion = { key: -1, value: '' };
                if (this.state.middle.length > 0) {
                    bestSuggestion = this.state.middle[this.state.middle.length - 1];
                } else {
                    bestSuggestion = this.state.first[this.state.first.length - 1];
                }
                if (this.state.selectedIndex === -1) {
                    this.setState({
                        selectedIndex: this.state.first.length + this.state.middle.length - 2,
                        bestSuggestion: bestSuggestion
                    })
                } else {
                    let bestSuggestion = { key: -1, value: '' };
                    let newIndex = this.state.selectedIndex - 1;
                    if (newIndex >= 0) {
                        if (newIndex < this.state.first.length) {
                            bestSuggestion = this.state.first[newIndex];
                        } else {
                            bestSuggestion = this.state.middle[newIndex - this.state.first.length];
                        }
                    }
                    this.setState({
                        selectedIndex: newIndex,
                        bestSuggestion: bestSuggestion
                    })
                }
            }
            if (e.key == 'ArrowDown') {
                e.preventDefault();
                let bestSuggestion = { key: -1, value: '' };
                if (this.state.first.length > 0) {
                    bestSuggestion = this.state.first[0];
                } else {
                    bestSuggestion = this.state.middle[0];
                }
                if (this.state.selectedIndex === -1) {
                    this.setState({
                        selectedIndex: 0,
                        bestSuggestion: bestSuggestion
                    })
                } else {
                    let bestSuggestion = { key: -1, value: '' };
                    let newIndex = this.state.selectedIndex + 1;
                    if (newIndex > this.state.first.length + this.state.middle.length - 1) {
                        newIndex = -1;
                    } else {
                        if (newIndex >= this.state.first.length) {
                            bestSuggestion = this.state.middle[newIndex - this.state.first.length];
                        } else {
                            bestSuggestion = this.state.first[newIndex];
                        }
                    }
                    this.setState({
                        selectedIndex: newIndex,
                        bestSuggestion: bestSuggestion
                    })
                }
            }
        }
    }

    onBlur = () => {
        const o = this.state.searchMap[this.props.value];
        if (o !== undefined) {
            if (o.dvalue !== this.state.typedValue) {
                this.setState({
                    typedValue: o.dvalue,
                    focused: false,
                    first: [],
                    middle: [],
                    bestSuggestion: {}
                });
            } else {
                this.setState({
                    focused: false,
                    first: [],
                    middle: [],
                    bestSuggestion: {}
                });
            }
        } else {
            this.setState({
                focused: false,
                first: [],
                middle: [],
                bestSuggestion: {}
            });
        }
    }

    onFocus = (e) => {
        e.target.select();
        this.setState({ focused: true });
    }

    onIconClick = (e) => {
        let check = false;
        if (this.state.searchMap) {
            const o = this.state.searchMap[this.props.value];
            if (o !== undefined && o.value.toLowerCase() === this.state.typedValue.toLowerCase()) {
                check = true;
            }
        }
        if (this.props.onIconClick) {
            this.props.onIconClick(e, this.state.typedValue, check);
        }
    }

    render() {
        let o = undefined;
        if (this.state.searchMap) {
            o = this.state.searchMap[this.props.value];
        }
        return (
            <div className={"smartInput " + (this.props.inline ? ' inline ' : '') + (this.props.className ? this.props.className : '')}>
                <Input autoComplete={this.props.autoComplete ? this.props.autoComplete : ''} name={this.props.name ? this.props.name : ''} placeholder={this.props.placeholder} value={this.state.typedValue} backText={this.state.bestSuggestion.dvalue} onChange={this.onChange} onKeyDown={this.onKeyDown} onFocus={this.onFocus} onBlur={this.onBlur} />
                {(this.state.first.length > 0 || this.state.middle.length > 0) &&
                    <div className="smartInputResultsWrapper">
                        <div className="smartInputResults">
                            {this.state.first.map((r) => {
                                return (<div key={r.key} className={r.key === this.state.bestSuggestion.key ? "smartInputResult selected" : "smartInputResult"} onMouseDown={() => this.updateValue(r)}><b><u>{r.dvalue.substr(0, this.state.typedValue.length)}</u></b>{r.dvalue.substr(this.state.typedValue.length)}</div>);
                            })}
                            {this.state.middle.map((r) => {
                                return (<div key={r.key} className={r.key === this.state.bestSuggestion.key ? "smartInputResult selected" : "smartInputResult"} onMouseDown={() => this.updateValue(r)}>{r.dvalue.substr(0, r.s)}<b><u>{r.dvalue.substr(r.s, this.state.typedValue.length)}</u></b>{r.dvalue.substr(r.s + this.state.typedValue.length)}</div>);
                            })}
                        </div>
                    </div>
                }
                {o !== undefined && o.value.toLowerCase() === this.state.typedValue.toLowerCase() ?
                    <div className={"smartInputIndicator" + (this.props.onIconClick ? " clickable" : "")} onMouseDown={this.onIconClick}><FA icon={this.props.checkIcon ? ("fa-fw fad " + this.props.checkIcon) : "fa-fw fad fa-check"} color="green" /></div>
                    :
                    <div className={"smartInputIndicator" + (this.props.onIconClick ? " clickable" : "")} onMouseDown={this.onIconClick}><FA icon={this.props.searchIcon ? ("fa-fw fad " + this.props.searchIcon) : "fa-fw fad fa-search"} color="red" /></div>
                }
            </div>
        );
    }
}

SmartInput.propTypes = {
    autoComplete: PropTypes.any,
    checkIcon: PropTypes.any,
    inline: PropTypes.any,
    name: PropTypes.any,
    onChange: PropTypes.func,
    onIconClick: PropTypes.func,
    placeholder: PropTypes.any,
    resultCount: PropTypes.any,
    className: PropTypes.string,
    searchIcon: PropTypes.any,
    value: PropTypes.any
}

export default view(SmartInput)