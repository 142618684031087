import {
    id,
    bigint,
    int,
    string,
    text,
    decimal,
    nutrition,
    bool,
    date,
    datetime,
    json,
    gtin,
    tinyint,

    // transformToInt,
    // transformToString,
    // transformToDecimal,
    // transformToBool,
    // transformToNutrition,

    // transformFromInt,
    // transformFromString,
    // transformFromDecimal,
    // transformFromBool,
    // transformFromNutrition,

    // transformTo,
    // transformFrom,
    // transformDefault
} from './myTypes.js';

/**
 * @typedef AllTables
 * @type {"brand" | "food" | "food_portion" | "portion_type" | "portion_type_transform" | "food_ingredient" | "ingredient" | "food_tag" | "tag" | "meal" | "meal_tag" | "recipe" | "recipe_tag" | "recipe_ingredient" | "recipe_preparation_section" | "recipe_preparation_section_ingredients" | "restriction" | "restriction_ingredient" | "menu_preset" | "menu_preset_meal" | "menu_preset_prohibited_ingredient" | "menu_preset_prohibited_tag" | "menu_preset_musthave_tag" | "featured_recipe_section" | "featured_recipe" | "shopping" | "shopping_category" | "shopping_element" | "shopping_additional" | "import_ruleset" | "import_shopping_category" | "master_import_rule" | "import_rule_disable" | "import_food" | "import_brand" | "import_tag" | "import_ingredient" | "user" | "mail" | "message" | "user_message" | "user_people" | "person" | "person_meal" | "person_meal_ingredient" | "changelog" | "invitation_code"}
 */

const AccountDBModel = {
    changelog: {
        id: id(),
        user_id: id('user'),
        change: json,
        when: int,
        reverted: bool,
    },

    mail: {
        id: id(),
        type: string,
        user_id: id('user'),
        meta: string,
        expire: string
    },

    message: {
        id: id(),
        text: string,
        options: string
    },

    person: {
        id: id(),
        name: string,
        surname: string,
        age: int,
        gender: string,
        pregnancy: int,
        lactating: int,
        height: decimal,
        weight: decimal,
        ready: bool,

        // menu
        preset: id('menu_preset'),
        restrictions: json,
        extra: json,

        // shopping
        zip: string,
        groceryStore: string,
        pharmacy: string,
        extraPharmacy: string,
        phone: string,

        // wellness
        targetDate: string,
        targetWeight: decimal,
        targetWeightPerWeek: decimal,
        activityLevel: json
    },

    person_meal: {
        id: id(),
        date: date,
        meal_id: id('meal'),
        recipe_id: id('recipe'),
        person_id: id('person'),
        pattern_meal_override_id: id('pattern_meal_override'), //if this is nonzero AND this has no person_meal_ingredients, use the recipe to generate a new one every time.
        variation_index: int,
        consumed: bool,
        deleted: bool,
        amount: decimal,
        portion_count: int,
        extra_portions: decimal,
    },

    // person_info: {
    //     _key: ['person_id'],
    //     person_id: id('person'),
    //     note: text,
    // },

    person_meal_ingredient: {
        id: id(),
        person_meal_id: id('person_meal'),
        recipe_ingredient_id: id('recipe_ingredient'),
        food_id: id('food'),
        amount: decimal,
        portion_type_id: id('portion_type'),
        org_food_id: id('food'),
    },

    person_saved_menu: {
        id: id(),
        name: string,
        person_id: id('person'),
        type: int,
        contents: json
    },

    test_preset: {
        id: id(),
        name: string,
        day: int,
        count: int,
        dim_data: json
    },

    test_preset_profile: {
        id: id(),
        test_preset_id: id('test_preset'),
        profile_id: id('profile')
    },

    person_daily_target: {
        id: id(),
        date: date,
        nutrients: nutrition
    },

    pattern_day_override: {
        id: id(),
        date: date, //01.01.1970 = monday, 02.01.1970 = tuesday, 12.05.2019 = particular day
        pattern_day_id: id('pattern_day'),
        person_id: id('person'),
    },

    // if exists with 
    pattern_meal_override: {
        id: id(),
        date: date, //01.01.1970 = monday, 02.01.1970 = tuesday, 12.05.2019 = particular day
        meal_id: id('meal'),
        pattern_meal_id: id('pattern_meal'), //If 0, query person_meal for all person_meals where pattern_meal_override_id = this.id
        person_id: id('person'),
    },

    user_message: {
        _key: ['user_id', 'message_id'],
        user_id: id('user'),
        message_id: id('message'),
        active: bool,
        response: string
    },

    sms_outbound: {
        id: id(),
        sender_person_id: id('person'),
        recipient_person_id: id('person'),
        date_created_utc: datetime,
        recipient_phone: string,
        message_status: int,
        message_body: string,
    },

    sms_inbound: {
        id: id(),

    },

    user_people: {
        _key: ['user_id', 'person_id'],
        user_id: id('user'),
        person_id: id('person'),
        user_person_relationship: int,
        enabled: bool,
        note: string,
    },

    user_activity: {
        id: id(),
        user_id: id('user'),
        person_id: id('person'),
        date_created_utc: datetime,
        activity_type: int,
        target_item_id: int,
        message: string,
    },

    nutrition_history: {
        id: id(),
        person_id: id('person'),
        date: date,
        created_on: datetime,
        target_nutrition: nutrition,
        actual_nutrition: nutrition,
        consumed_nutrition: nutrition,

        // from person
        age: int,
        pregnancy: int,
        lactating: int,
        height: decimal,
        weight: decimal,
        preset_id: id('menu_preset'),
        restrictions: json,
        activity_level: int
    },

    source_food: {
        id: id(),
        source_origin: int, // SYSTEM === OVERRIDE === 0
        origin_id: string, // food.id dla source_origin = SYSTEM
        final_food_id: id('final_food'),
        gtin_code: bigint,
        brand: string,
        nutrition_data: nutrition,
        entry_name: string,
        portion_weight: decimal,
        portion_count: int,
        max_quantity: decimal,
        quantity: decimal,
        source_portion_type_id: id('source_portion_type'),
        shopping_category_id: int,
        variables: json
    },

    final_food: {
        id: id(), //  food.id dla source_origin = SYSTEM
        gtin_code_value: bigint, // GTIN
        public: bool, // if it's ready to be viewed

        gtin_code: tinyint, // 0,1,2 
        brand: tinyint,
        nutrition_data: tinyint,
        entry_name: tinyint,
        portion_weight: tinyint,
        portion_count: tinyint,
        max_quantity: tinyint,
        quantity: tinyint,
        source_portion_type_id: tinyint,
        shopping_category_id: tinyint,
    },

    final_food_ingredient: {
        _key: ['final_food_id', 'ingredient_id'],
        final_food_id: id('final_food'),
        ingredient_id: id('ingredient'),
    },

    final_food_final_portion: {
        _key: ['final_food_id', 'final_portion_id'],
        final_food_id: id('final_food'),
        final_portion_id: id('portion_type'),
        portion_weight: decimal,
        package_portions: decimal,
        // ...
    },

    final_food_final_tag: {
        _key: ['final_food_id', 'final_tag_id'],
        final_food_id: id('final_food'),
        final_tag_id: id('tag'),
    },

    source_food_source_tag: {
        _key: ['source_food_id', 'source_tag_id'],
        source_food_id: id('source_food'),
        source_tag_id: id('source_tag'),
        // origin_id: int, // in pg they're combined
    },

    source_tag: {
        id: id(),
        source_origin: int,
        origin_id: int,
        tag_type: tinyint,
        tag_name: string,
    },

    source_portion_type: {
        id: id(),
        source_food_id: id('source_food'),
        name: string,
        abbreviation: string,
        weight: decimal,
    },

    paypal_events: {
        id: id(),
        paypal_id: string,
        when: datetime,
        type: string,
        plan_id: string,
        event: string,
        mail: string,
        name: string,
        surname: string,
        price: decimal,
        quantity: decimal,
        payment_payload: json
    },

    paypal_plans: {
        id: id(),
        paypal_id: string,
        name: string,
        limit: int
    }
}

const DBModel = {
    brand: {
        id: id(),
        name: string,
    },
    food: {
        id: id(),
        name: string,
        brand_id: id('brand'),
        generic_id: id('food'),
        isWet: bool,
        barcode: bigint,
        nutrition: nutrition,
        common: bool,
        portion_count: int,
        min_portion_to_make: int,
        quantity: decimal,
        max_portions: int,
        shopping_category_id: id('shopping_category'),
        default_portion_type_id: id('portion_type')
    },
    food_ingredient: {
        _key: ['food_id', 'ingredient_id'],
        food_id: id('food'),
        ingredient_id: id('ingredient'),
    },
    food_portion: {
        id: id(),
        parent: id('food'),
        portion_type_id: id('portion_type'),
        portion_weight: decimal,
        package_portions: decimal,
        barcode: bigint,
    },
    food_tag: {
        _key: ['food_id', 'tag_id'],
        food_id: id('food'),
        tag_id: id('tag'),
    },
    portion_type: {
        id: id(),
        core_portion: int,
        multiplier: decimal,
        name: string,
    },
    portion_type_transform: {
        _key: ['from_portion_type', 'to_portion_type'],
        from_portion_type: id('portion_type'),
        to_portion_type: id('portion_type'),
        multiplier: decimal,
    },
    ingredient: {
        id: id(),
        name: string,
        source_name: string,
        is_allergic: bool,
    },
    tag: {
        id: id(),
        name: string,
    },
    meal: {
        id: id(),
        name: string,
        order: int,
    },
    meal_tag: {
        _key: ['meal_id', 'tag_id'],
        meal_id: id('meal'),
        tag_id: id('tag'),
    },
    recipe: {
        id: id(),
        creator: id('user'),
        active: bool,
        name: string,
        recipe_site_name: string,
        recipe_site_url: string,
        description: text,
        portion_count: int,
        preparation_time: int,
        cooking_time: int,
        time_to_eat: int,
        min_portion_to_make: int,
        recipe_type: int,
    },
    recipe_tag: {
        _key: ['recipe_id', 'tag_id'],
        recipe_id: id('recipe'),
        tag_id: id('tag'),
    },
    recipe_ingredient: {
        id: id(),
        recipe_id: id('recipe'),
        slot_index: int,
        variation_index: int,
        amount: decimal,
        prefix: string,
        tag: id('tag'),
        food: id('food'),
        portion_type: id('portion_type'),
        optional: bool,
    },
    recipe_preparation_section: {
        id: id(),
        recipe_id: id('recipe'),
        section_index: int,
        time: int,
        text: string,
    },
    recipe_preparation_section_ingredients: {
        _key: ['recipe_preparation_section_id', 'recipe_ingredient_id'],
        recipe_preparation_section_id: id('recipe_preparation_section'),
        recipe_ingredient_id: id('recipe_ingredient'),
    },
    restriction: {
        id: id(),
        order: int,
        name: string,
        description: string,
    },
    restriction_ingredient: {
        _key: ['restriction_id', 'ingredient_id'],
        restriction_id: id('restriction'),
        ingredient_id: id('ingredient'),
    },
    //////////////////////////////////////////////////////////// USER
    invitation_code: {
        id: id(),
        is_subscription: bool,
        created_date: date,
        code: string,
        user_limit: int,
    },

    user: {
        id: id(),
        password: string,
        mail: string,
        enabled: bool,
        admin_enabled: bool,
        date_of_registration: datetime,
        forgot_code: string,
        forgot_timeout: datetime,
        activation_code: string,
        invitation_code_id: id('invitation_code'),
        user_roles: json,
    },
    ////////////////////////////////////////////////////////////

    menu_preset: {
        id: id(),
        name: string,
        description: text,
        order: int,
        enabled: bool,
        fatTarget: decimal,
        carbTarget: decimal,
        pattern_week_id: id('pattern_week_id')
    },

    menu_preset_meal: {
        id: id(),
        menu_preset_id: id('menu_preset'),
        meal_id: id('meal'),
        order: int,
        calorieTarget: decimal,
        proteinTarget: decimal,
        fatTarget: decimal,
        carbTarget: decimal,
    },

    menu_preset_prohibited_ingredient: {
        _key: ['menu_preset_meal_id', 'ingredient_id'],
        menu_preset_meal_id: id('menu_preset_meal'),
        ingredient_id: id('ingredient')
    },

    menu_preset_prohibited_tag: {
        _key: ['menu_preset_meal_id', 'tag_id'],
        menu_preset_meal_id: id('menu_preset_meal'),
        tag_id: id('tag'),
    },

    menu_preset_musthave_tag: {
        _key: ['menu_preset_meal_id', 'tag_id'],
        menu_preset_meal_id: id('menu_preset_meal'),
        tag_id: id('tag'),
    },

    featured_recipe_section: {
        id: id(),
        parent_id: id('featured_recipe_section'),
        is_featured: bool,
        search_flags: int,
        search_tag_id: id('tag'),
        name: string,
        order: int,
        image: string
    },

    featured_recipe: {
        id: id(),
        recipe: id('recipe'),
        order: int,
        section: id('featured_recipe_section')
    },

    shopping: {
        id: id(),
        user_id: id('user'),
        from: date,
        to: date,
        name: string,
        obsolete: bool,
        done: bool,
        permalink: string
    },

    shopping_category: {
        id: id(),
        order: int,
        name: string,
        description: string,
    },

    shopping_element: {
        id: id(),
        recipe_id: id('recipe'),
        food_id: id('food'),
        person_meal_id: id('person_meal'),
        shopping_additional_id: id('shopping_additional'),
        shopping_id: id('shopping'),
        amount: decimal,
        portion_type_id: id('portion_type'),
        original_portion_type_id: id('portion_type'),
        unit: string,
        is_offset: bool,
        dont_buy: bool,
        deleted: bool
    },

    shopping_additional: {
        id: id(),
        shopping_id: id('shopping'),
        recipe_id: id('recipe'),
        food_id: id('food'),
        name: string,
        amount: decimal,
        portion_count: decimal,
        unit: string,
        portion_type_id: id('portion_type'),
    },

    import_ruleset: {
        id: id(),
        query: string,
        mapping: text,
        output: text
    },

    master_import_rule: {
        id: id(),
        rule: string
    },

    import_rule_disable: {
        _key: ['import_ruleset_id', 'master_import_rule_id'],
        import_ruleset_id: id('import_ruleset'),
        master_import_rule_id: id('master_import_rule'),
    },

    import_food: {
        id: id(),
        source: int,
        source_food_id: string,
        food_id: id('food'),
        source_map: json,
    },

    // import_brand: { unused
    //     id: id(),
    //     source: int,
    //     source_brand_id: string,
    //     brand_id: id('brand'),
    //     source_map: json,
    // },

    import_tag: {
        id: id(),
        source: int,
        source_tag_id: string,
        tag_id: id('tag'),
        source_map: json,
    },

    import_shopping_category: {
        id: id(),
        source_id: int,
        shopping_category_id: int,
    },

    import_ingredient: {
        id: id(),
        source: int,
        source_ingredient_id: string,
        ingredient_id: id('ingredient'),
        source_map: json,
    },

    menu_week_pattern: {
        id: id(),
        name: string,
    },

    //////////////////////////////////////////////////////////// PATTERN

    pattern_meal: {
        id: id(),
        name: string,
    },

    pattern_meal_tag: {
        _key: ['pattern_meal_id', 'tag_id', 'tier'],
        pattern_meal_id: id('pattern_meal'),
        tag_id: id('tag'),
        tier: int
    },

    pattern_day: {
        id: id(),
        name: string
    },

    pattern_day_to_pattern_meal: { //for a particular pattern_day, and it's particular meal, set pattern_meal to x
        _key: ['pattern_meal_id', 'pattern_day_id', 'meal_id'],
        pattern_meal_id: id('pattern_meal'),
        pattern_day_id: id('pattern_day'),
        meal_id: id('meal'),
        order: int,
        group: int
    },

    pattern_week: {
        id: id(),
        name: string,
    },

    pattern_week_day: {
        _key: ['pattern_week_id', 'pattern_day_id', 'day_number'],
        pattern_week_id: id('pattern_week'),
        pattern_day_id: id('pattern_day'),
        day_number: int
    }
}

const syncedTables = {};
Object.keys(DBModel).forEach((key) => syncedTables[key] = true);

export {
    syncedTables
};

//=alltables (almost ;)
// console.log(Object.keys(DBModel).join("\" | \""), Object.keys(AccountDBModel).join("\" | \""));

export default Object.assign(DBModel, AccountDBModel);