import React from 'react';
import PropTypes from 'prop-types';
import { view } from '@risingstack/react-easy-state';

import './RawGrid.scss';
import Button from '../Button';
import { FA } from '../Icon';
import Text from '../Text';

/**
 * @typedef RawGridProps
 * @type {object}
 * @property {array} topLeft
 * @property {array} top
 * @property {array} left
 * @property {array} content
 * @property {function} getTopLeft
 * @property {function} getTop
 * @property {function} getLeft
 * @property {function} getContent
 * @property {function} onClick
*/


/**
 * @param {RawGridProps} props
 */
const RawRawGrid = (props) => {
    const wideLength = Array.isArray(props.top) ? props.top.length : 1;
    const isTopLeft = Array.isArray(props.topLeft) && props.topLeft?.length > 0;
    const isLeft = Array.isArray(props.left) && props.left?.length > 0;

    const isMbVisible = (i) => {
        if (props.count === undefined || props.offset === undefined) return true;
        const start = props.count * (props.offset);
        const end = props.count * (props.offset + 1);
        return i >= start && i < end;
    }

    const renderContent = (contentArr, i, j, getContent) => {
        const content = [];

        const arr = Array.isArray(contentArr[i]) ? contentArr[i] : [contentArr[i]];
        for (let k = 0; k < j; k++) {
            const el = arr[k] !== undefined ? arr[k] : '';

            content.push(<div key={i + '_' + k} data-mb={isMbVisible(k) ? 1 : 0} className={'item value-item ' + (props.getContentClass ? props.getContentClass(el, i, k) : '')}
                onClick={(event) => props.onClick ? props.onClick(el, i, k, event) : ''}>
                {getContent ? getContent(el, i, k) : el}
            </div>)
        }
        return content;
    }

    const getColumnStyleProperty = (wideLength) => {
        let leftSize = '';
        if (isLeft) {
            leftSize = props.leftSize !== undefined ? props.leftSize : 'minmax(5rem, 10rem)';
        }
        return `${leftSize} repeat(${wideLength}, 1fr)`
    }


    let wideLengthMobile = wideLength - (props.count ? props.count + 1 : 0)
    if (props.offset === 2) {
        wideLengthMobile = 1;
    }

    return <div className="relative raw-grid-container">
        {props.withNavigation ? <div className="arrow-navigation">
            <Button disabled={props.offset === 0} className="clear-btn back" inline onClick={() => {
                if (typeof props.goPreviousPage === 'function') {
                    props.goPreviousPage()
                }
            }}>
                <FA icon="fad fa-long-arrow-left" /> back
            </Button>
            <Text>
                {props.offset + 1} of 3
            </Text>
            <Button disabled={props.offset === 2} className="clear-btn next" inline onClick={() => {
                if (typeof props.goNextPage === 'function') {
                    props.goNextPage()
                }
            }}>
                next <FA icon="fad fa-long-arrow-right" />
            </Button>
        </div> : ''}

        <div className={'raw-grid relative ' + (props.withNavigation ? 'with-navigation' : '')}
            onTouchStart={touchStartEvent => {
                if (typeof props.handleTouchStart === 'function') {
                    props.handleTouchStart(touchStartEvent)
                }
            }}
            onTouchMove={touchMoveEvent => {
                if (typeof props.handleTouchMove === 'function') {
                    props.handleTouchMove(touchMoveEvent)
                }
            }}
            onTouchEnd={() => {
                if (typeof props.handleTouchEnd === 'function') {
                    props.handleTouchEnd()
                }
            }}
        >
            <div className="raw-grid-row" style={{ gridTemplateColumns: getColumnStyleProperty(wideLength) }}
                data-mb-length={wideLengthMobile}>
                {isTopLeft || isLeft ? <div>
                    {Array.isArray(props.topLeft) ? props.topLeft.map((el, index) =>
                        <div key={'topLeftLabel_' + index}
                            className={'item top-left-label ' + (props.getTopLeftClass ? props.getTopLeftClass(el) : '')}>
                            {props.getTopLeft ? props.getTopLeft(el) : el}
                        </div>
                    ) : ''}
                </div> : ''}

                {Array.isArray(props.top) ? props.top.map((el, index) =>
                    <div key={'topLabel_' + index} data-mb={isMbVisible(index) ? 1 : 0}
                        className={'item top-label ' + (props.getTopClass ? props.getTopClass(el) : '')}>
                        {props.getTop ? props.getTop(el) : el}
                    </div>
                ) : ''}
            </div>


            {isLeft ? props.left.map((el, index) =>
                <div className="raw-grid-row" key={'row_' + index} style={{ gridTemplateColumns: getColumnStyleProperty(wideLength) }}
                    data-mb-length={wideLengthMobile}>
                    <div key={'leftLabel_' + index}
                        className={'item left-label ' + (props.getLeftClass ? props.getLeftClass(el) : '')}>
                        {props.getLeft ? props.getLeft(el) : el}
                    </div>
                    {Array.isArray(props.content) ? renderContent(props.content, index, wideLength, props.getContent) : ''}
                </div>
            ) : Array.isArray(props.content) ? props.content.map((row, index) => {
                return <div className="raw-grid-row" key={'row_' + index} style={{ gridTemplateColumns: getColumnStyleProperty(wideLength) }}
                    data-mb-length={wideLengthMobile}>
                    {renderContent(props.content, index, wideLength, props.getContent)}
                </div>
            }) : ''}


        </div>
    </div>
}

RawRawGrid.propTypes = {
    topLeft: PropTypes.array,
    top: PropTypes.array,
    left: PropTypes.array,
    content: PropTypes.array,
    getTopLeft: PropTypes.func,
    getTopLeftClass: PropTypes.func,
    getTop: PropTypes.func,
    getTopClass: PropTypes.func,
    getLeft: PropTypes.func,
    getLeftClass: PropTypes.func,
    getContent: PropTypes.func,
    getContentClass: PropTypes.func,
    onClick: PropTypes.func,
    leftSize: PropTypes.string,
    count: PropTypes.number,
    offset: PropTypes.number,
    handleTouchStart: PropTypes.func,
    handleTouchMove: PropTypes.func,
    handleTouchEnd: PropTypes.func,
    goPreviousPage: PropTypes.func,
    goNextPage: PropTypes.func,
    withNavigation: PropTypes.bool,
};
/**
 * 
 * @component
 * @type {{ new(props: RawGridProps): {
 *   props: { 
 *     topLeft: Array,
 *     top: Array,
 *     left: Array,
 *     content: Array,
 *     getTopLeft: function, 
 *     getTop: function, 
 *     getLeft: function, 
 *     getContent: function, 
 *     onClick: function, 
 *     }
 *   }
 *  }}
 */
const RawGrid = view(RawRawGrid);
export default RawGrid;