function arrayToMap(array, field = 'id') {
    if (!Array.isArray(array)) return {};
    const result = {};
    for (let i = 0; i < array.length; i++) {
        result[array[i][field]] = array[i];
    }
    return result;
}

function flatArrayToMap(array) {
    if (!Array.isArray(array)) return {};
    const result = {};
    for (let i = 0; i < array.length; i++) {
        result[array[i]] = true;
    }
    return result;
}

export default arrayToMap;

export {
    flatArrayToMap
}