import React from 'react';
import PropTypes from 'prop-types';
import { view } from '@risingstack/react-easy-state';

import './Loading.scss'

import LoadingDots from './LoadingDots';

/**
 * @typedef LoadingWrapperProps
 * @type {object}
 * @property {boolean} loading shows LoadingDots instead of children
 * @property {boolean} hidden loading-dots hidden
 * @property {object} children
*/

/**
 * @param {LoadingWrapperProps} props
 */
const RawLoadingWrapper = (props) => {
    return (
        props.loading
            ? <LoadingDots hidden={props.hidden}></LoadingDots>
            : <React.Fragment>
                {props.children}
            </React.Fragment>
    );
}

RawLoadingWrapper.propTypes = {
    loading: PropTypes.bool,
    hidden: PropTypes.any,
    children: PropTypes.any,
}

/**
 * LoadingWrapper
 * 
 * @component
 * @type {{ new(props: LoadingWrapperProps): {
 *   props: { 
 *     loading: boolean,
 *     hidden: boolean,
 *     children: object, 
 *     }
 *   }
 *  }}
 */
const LoadingWrapper = view(RawLoadingWrapper)

export default LoadingWrapper;