import {
    transformTo,
    transformDefault
} from './myTypes.js';


function marshalFromDBTypes(obj, model) {
    for (let field in model) {
        if (field === '_key') continue;
        if (field === '_index') continue;
        if (obj[field] === undefined) {
            obj[field] = transformDefault[model[field][0]]();
            continue;
        }
        obj[field] = transformTo[model[field][0]](obj[field]);
    }
}

function marshalPartialFromDBTypes(obj, model) {
    for (let field in obj) {
        if (field === '_key') continue;
        if (field === '_index') continue;
        if (obj[field] === undefined) {
            obj[field] = transformDefault[model[field][0]]();
            continue;
        }
        obj[field] = transformTo[model[field][0]](obj[field]);
    }
}

function cloneAndMarshalFromDBTypes(obj, model) {
    const clone = { ...obj }
    marshalFromDBTypes(clone, model);
    return clone;
}

export {
    cloneAndMarshalFromDBTypes,
    marshalPartialFromDBTypes,
    marshalFromDBTypes
}

export default marshalFromDBTypes;