import React from 'react';

const pinterestIcon = () => (
    <svg viewBox="0 0 60.001 60">
        <path d="M30,0C13.432,0,0,13.432,0,29.999C0,46.568,13.432,60,30,60c16.569,0,30.001-13.432,30.001-30.001
    C60.001,13.432,46.568,0,30,0z M33.845,40.289c-2.33,0-4.522-1.259-5.271-2.689c0,0-1.254,4.972-1.519,5.931
    c-0.934,3.39-3.683,6.785-3.896,7.063c-0.149,0.192-0.477,0.133-0.513-0.125c-0.06-0.432-0.76-4.711,0.064-8.2
    c0.414-1.752,2.777-11.759,2.777-11.759s-0.69-1.379-0.69-3.415c0-3.198,1.855-5.586,4.163-5.586c1.963,0,2.91,1.474,2.91,3.241
    c0,1.974-1.256,4.925-1.906,7.658c-0.54,2.289,1.149,4.156,3.407,4.156c4.088,0,6.842-5.251,6.842-11.472
    c0-4.73-3.184-8.272-8.979-8.272c-6.545,0-10.626,4.882-10.626,10.335c0,1.88,0.555,3.208,1.424,4.234
    c0.399,0.47,0.457,0.66,0.311,1.202c-0.105,0.398-0.342,1.354-0.441,1.733c-0.143,0.546-0.586,0.74-1.08,0.538
    c-3.014-1.23-4.419-4.531-4.419-8.244c0-6.129,5.169-13.48,15.423-13.48c8.24,0,13.661,5.961,13.661,12.362
    C45.489,33.965,40.783,40.289,33.845,40.289z"/>
    </svg>
)

export default pinterestIcon;