import React from 'react';

const shoppingIcon = () => (
<svg viewBox="0 0 44.804 60">
  <path style={ {fillRule: "evenodd", clipRule: "evenodd"} } d="M42.664,60H2.14c-1.254,0-2.21-0.893-2.136-1.988l3.059-44.95
    c0.055-0.811,0.968-1.465,2.039-1.465h4.071C9.305,5.172,15.259,0,22.586,0C29.913,0,35.868,5.172,36,11.596h3.702
    c1.071,0,1.984,0.654,2.039,1.465l3.059,44.95C44.874,59.107,43.918,60,42.664,60z M22.586,4.487c-4.513,0-8.183,3.164-8.314,7.109
    h16.629C30.77,7.651,27.099,4.487,22.586,4.487z"/>
  </svg>
)

export default shoppingIcon;