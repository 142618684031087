import React from 'react';

const replaceIcon = () => (
    <svg viewBox="0 0 70 70">
        <path d="M25.513,56.941l13.212-12.323L25.513,32.296v6.766C11.019,39.337,5,25.561,5,25.561
    C6.82,45.81,21.734,49.594,25.513,50.23V56.941z"/>
        <path d="M44.487,13.059L31.276,25.382l13.212,12.323v-6.766C58.981,30.663,65,44.439,65,44.439
    C63.18,24.19,48.266,20.406,44.487,19.77V13.059z"/>
    </svg>
)

export default replaceIcon;