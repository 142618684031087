import React from 'react';
import { getFractionObject, renderFractionTextFromFractionObject, renderIntegerTextFromFractionObject, renderTextFromFractionObject } from './fractionUtils';


function getFractionDisplay(amount, collapse = true) {
    const fractionObject = getFractionObject(amount)

    if (collapse) {
        return <span className="fraction-display collapse">
            {renderTextFromFractionObject(fractionObject)}
        </span>
    }

    return <span className="fraction-display">
        <span className="integer-part">
            {renderIntegerTextFromFractionObject(fractionObject)}
        </span>
        <span className="fraction-part">
            {renderFractionTextFromFractionObject(fractionObject)}
        </span>
    </span>
}

export default getFractionDisplay;