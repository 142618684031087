import { view } from '@risingstack/react-easy-state';
import PropTypes from 'prop-types';
import { getMealAmountNoExtra, getMealBrand, getMealName, getMealPortionTypeName, getMealSiteName, getMealSiteUrl, isRTE } from 'utils/mealUtils';
import React, { Component } from 'react';
import Icon, { icons } from 'components/Icon';
import Text from 'components/Text';
import FractionControllInput from 'components/FractionControllInput';
import { fixCapitalizeName } from 'storeUtils/layoutStore';

class MealInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderServings(meal) {
        const portionTypeName = getMealPortionTypeName(meal);
        const amount = getMealAmountNoExtra(meal);
        return (<div className="meal-amount-input">
            <FractionControllInput
                value={amount}
                bordered
                disabled
            />
            <Text textCenter>{portionTypeName}</Text>
        </div>);
    }

    renderIcon(meal) {
        const icon = isRTE(meal) ? icons.rteIcon : icons.recipe;
        const iconClass = isRTE(meal) ? "rte-icon" : "recipe-icon";

        return <Icon className={`mx-1 ${iconClass}`} icon={icon} />
    }

    renderBrand(meal) {
        const brand = getMealBrand(meal);
        return brand ? <i className="ingredient-brand-name">{brand}</i> : '';
    }

    renderSiteName(meal) {
        const siteName = getMealSiteName(meal);
        const siteUrl = getMealSiteUrl(meal);

        if (siteName && siteUrl) {
            return <a href={siteUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="pl-1 ingredient-site-name">
                {siteName}
            </a>
        }
        if (siteName) {
            return <i className="ingredient-site-name">{siteName}</i>;
        }
        return '';
    }

    render() {
        return (<React.Fragment>
            <div className={`${this.props.className} meal-info-element`}>
                <Text className="name">
                    {this.renderIcon(this.props.meal)}
                    {fixCapitalizeName(getMealName(this.props.meal))}

                </Text>
                <div className="additional-meal-fields pb-1">
                    {this.renderBrand(this.props.meal)}
                    {this.renderSiteName(this.props.meal)}
                </div>
            </div>

            <div className={`${this.props.className} text-center o-1`}>
                {this.props.children ? this.props.children : this.renderServings(this.props.meal)}
                <Text block textCenter>{this.props.description}</Text>
            </div>
        </React.Fragment>);
    }
}

MealInfo.propTypes = {
    meal: PropTypes.object,
    description: PropTypes.any,
    className: PropTypes.string,
    children: PropTypes.any
}

export default view(MealInfo);
