import React from 'react';

const homeIcon = () => (
    <svg viewBox="0 0 60 58.138">
        <path d="M9.309,31.62v24.312c0.013,0.927,0.61,1.507,1.089,1.783c0.512,0.295,1.064,0.419,1.651,0.422
    l11.02,0.001c0.379,0,0.751-0.153,1.022-0.423c0.268-0.269,0.421-0.64,0.421-1.019V45l10.974,0.001v11.695
    c0,0.379,0.158,0.751,0.425,1.019c0.27,0.27,0.639,0.422,1.018,0.422h11.022c0.587-0.003,1.134-0.127,1.647-0.422
    c0.485-0.276,1.076-0.86,1.093-1.783V31.62L29.999,12.69L9.309,31.62z"/>
        <path d="M58.741,24.916l-9.637-8.814V2.374c0-0.832-0.676-1.507-1.512-1.507h-3.641
    c-0.834,0-1.51,0.675-1.51,1.507v7.634l-9.823-8.989c-1.487-1.359-3.751-1.359-5.238,0L1.261,24.916
    c-1.58,1.447-1.69,3.9-0.245,5.481c0.765,0.837,1.812,1.26,2.864,1.26c0.935,0,1.872-0.336,2.619-1.016l23.5-21.503l23.504,21.503
    c1.578,1.447,4.033,1.335,5.483-0.244C60.429,28.816,60.319,26.363,58.741,24.916z"/>
    </svg>
)

export default homeIcon;