export const INVITATION_CODE_REQUIRED = true;

export const USER_PERSON_RELATIONSHIP = {
    OWNER: 0,
    MANAGER: 1,
    SUPER_MANAGER: 2
}


export const USER_STATUS = {
    CLIENT: 0,
    NO_PROFILE: 10,
    NO_MANAGER: 20,
    INACTIVE_CLIENT: 30,
    MANAGER: 40,
    INACTIVE_MANAGER: 50,
    SUPERMANAGER: 60,
}


export const defaultProfile = {
    name: '',
    surname: '',
    age: 0,
    gender: '',
    pregnancy: 0,
    lactating: 0,
    weight: 0,
    height: 0,

    preset: 0,
    restrictions: {},
    extra: {},
    phone: '',

    zip: '',
    groceryStore: '',
    pharmacy: '',
    extraPharmacy: '',

    // wellness
    targetDate: '',
    targetWeight: 0,
    targetWeightPerWeek: 0,

    activityLevel: {
        general: 0,
        specific: {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
        },
        override: {
            0: "",
            1: "",
            2: "",
            3: "",
            4: "",
            5: "",
            6: "",
        }
    }
}