import React from 'react';

const addIcon = () => (
  <svg viewBox="0 0 60 60">
    <path d="M30,0C13.5,0,0,13.5,0,30c0,16.5,13.5,30,30,30c16.5,0,30-13.5,30-30C60,13.5,46.5,0,30,0z
      M46.096,34.35H34.887c-0.296,0-0.537,0.24-0.537,0.537v11.209H25.65V34.887c0-0.296-0.24-0.537-0.537-0.537H13.904V25.65h11.209
      c0.296,0,0.537-0.24,0.537-0.537V13.904h8.701v11.209c0,0.296,0.24,0.537,0.537,0.537h11.209V34.35z"/>
  </svg>
)

export default addIcon;