import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { view } from '@risingstack/react-easy-state';


import Input from 'components/Input';
import { icons } from 'components/Icon';
import LoadingDots from '../Loading/LoadingDots';

// moved scss import to index.js due to css build conflict
// import './InputSearch.scss';

/**
 * @typedef InputSearchProps
 * @type {object}
 * @property {boolean} dalayed delay searchFunction with setTimeout
 * @property {string} searchValue
 * @property {array} searchResults
 * @property {array} recentResults component show recentResults on input focus
 * @property {function} searchFunction
 * @property {function} onElement when onClick result element
 * @property {string} className
 * @property {string} placeholder
 * @property {string} label
 * @property {string} emptyCaption
 * @property {any} children
 * @property {bool} offRecentResults disable recent results show
 * @property {bool} showSelected show selected Value
 * 
*/
class RawInputSearch extends Component {
    searchTimeout;

    constructor(props) {
        super(props);
        this.state = {
            isResultsVisible: false,
            searchValue: props.searchValue ? props.searchValue : '',
            searchResults: this.props.searchResults || [],
            selected: this.props.selected,
            addType: this.props.addType
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
        this.searchTimeout = null;
    }

    componentDidUpdate(prevProps) {
        if (!this.props.selected &&
            (this.props.onlyRTE !== prevProps.onlyRTE || this.props.onlyRecipe !== prevProps.onlyRecipe)
            && this.props.searchValue !== '' && this.props.searchValue?.trim()?.length > 0) {
            this.onSearch(this.state.searchValue, true);
        }
        if (!this.props.selected && prevProps.searchValue && !this.props.searchValue) {
            this.onSearch(this.props.searchValue);
        }
        if (this.props.searchValue === '' && prevProps.searchValue !== this.props.searchValue) {
            this.setState({ searchValue: '', isResultsVisible: false })
        }
        if (!this.props.selected && prevProps.selected !== this.props.selected) {
            this.onClear();
        }
    }

    /* ======================================== UTILS ======================================== */

    handleClickOutside = e => {
        const domNode = ReactDOM.findDOMNode(this);
        if ((!domNode || !domNode.contains(e.target)) && this.state.isResultsVisible) {
            this.setState({
                isResultsVisible: false
            });
        }
    }

    determineResultSource() {
        if (this.props.offRecentResults) {
            return this.renderResultElements(this.state.searchResults);
        }
        return this.state.searchValue ? this.renderResultElements(this.state.searchResults) :
            this.renderResultElements(this.props.recentResults, 'Most Recent:')
    }


    /* ======================================== ACTION HANDLE ===================================== */

    onClear = () => {
        this.setState({ selected: null })
    }

    renderElement(element) {
        if (typeof this.props.getElement === 'function') {
            return this.props.getElement(element)
        }
        return element;
    }

    onSearch = async (value, omitValue = false, isResultsVisible = true) => {
        if (value === this.state.searchValue && !omitValue) return;

        this.setState({ searchValue: value, selected: null, loading: true, isResultsVisible: isResultsVisible })

        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }

        if (value !== '' || omitValue) {
            this.searchTimeout = setTimeout(async () => {
                if (!this.props.searchFunction) return;
                console.log("[InputSearch.js] - searching for recipes results with:", value)

                const results = await this.props.searchFunction(value);
                if (value === this.state.searchValue) {
                    this.setState({ searchResults: results, loading: false, isResultsVisible: isResultsVisible })
                }

                this.searchTimeout = null;
            }, 750);
        } else {
            this.setState({ loading: false, isResultsVisible: isResultsVisible })
        }
    }

    onResultClick(element) {
        if (element && typeof this.props.onElement === 'function') {
            this.props.onElement(element);
        }
        this.setState({ isResultsVisible: false, selected: element });
    }

    /* ======================================== RENDER ======================================== */

    renderEmptyCaption() {
        if (typeof this.props.emptyCaption === 'string') {
            return this.props.emptyCaption;
        }
        return 'No results';
    }

    renderResultElements(iterable, caption = '') {
        if (!iterable) return;

        const { selected } = this.state;
        const _iterable = selected && iterable?.length === 0 ? [selected] : iterable;

        if (_iterable?.length === 0 && !this.state.loading) return <div><h3 className="result-caption mb-1">{this.renderEmptyCaption()}</h3></div>;
        return (
            <div className="result-wrapper">
                {caption ? <h3 className="result-caption">{caption}</h3> : ''}
                {_iterable.map((element, index) =>
                    <span key={index}
                        tabIndex={0}
                        className={(selected && (element.id === selected.id)) ? 'selected' : ''}
                        onClick={() => this.onResultClick(element)}>
                        {this.renderElement(element)}
                    </span>)}
            </div>
        );
    }

    render() {
        const { isResultsVisible } = this.state;

        return (
            <div ref={node => this.node = node} className={'input-search ' + (this.props.className ? this.props.className : '')
                + (this.state.loading ? ' in-progress' : '')}>
                <Input id="inputSearch"
                    placeholder={this.props.placeholder ? this.props.placeholder : 'Search'}
                    label={this.props.label}
                    rightIcon={icons.search}
                    onClick={() => {
                        if (this.props.offRecentResults &&
                            this.state.searchValue === '' &&
                            this.state.searchResults?.length === 0) {
                            return;
                        }

                        if (!this.state.isResultsVisible) {
                            this.setState({ isResultsVisible: true })
                        }
                    }}
                    onFocus={() => {
                        if (this.props.offRecentResults &&
                            this.state.searchValue === '' &&
                            this.state.searchResults?.length === 0) {
                            return;
                        }

                        if (!this.state.isResultsVisible) {
                            this.setState({ isResultsVisible: true })
                        }
                    }}
                    onBlur={e => {
                        if (this.state.searchValue !== e.target.value && e.target.value !== '') {
                            this.onSearch(e.target.value)
                        }
                    }}
                    onKeyUp={(e) => {
                        this.onSearch(e.target.value, this.props.omitValueCheck)
                    }}
                    autoComplete="off"
                    valueHidden={true}
                    disabled={(this.state.selected && this.props.disableBySelected) || this.props.disabled}
                    className={this.state.selected ? 'bordered' : ''}
                >
                    {this.props.showSelected && this.state.selected ? <div className="selected">
                        {this.renderElement(this.state.selected)}
                    </div> : ''}
                </Input>

                <div className="result-wrapper">
                    <div className={'results ' + (isResultsVisible ? 'visible' : '')}>
                        {this.state.loading ? <LoadingDots /> : ''}
                        {this.determineResultSource()}
                    </div>
                </div>
            </div>
        )
    }
}

RawInputSearch.propTypes = {
    delayed: PropTypes.bool,
    omitValueCheck: PropTypes.bool,
    searchValue: PropTypes.string,
    searchResults: PropTypes.array,
    recentResults: PropTypes.array,
    searchFunction: PropTypes.func.isRequired,
    onElement: PropTypes.func.isRequired,
    getElement: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    emptyCaption: PropTypes.string,
    children: PropTypes.any,
    offRecentResults: PropTypes.bool,
    showSelected: PropTypes.bool,
    selected: PropTypes.any,
    disabled: PropTypes.bool,
    disableBySelected: PropTypes.bool,
    onlyRTE: PropTypes.bool,
    onlyRecipe: PropTypes.bool
};

/**
* Component with expandable bottom section for recentSearch and search results 
* 
* @component
* @param {InputSearchProps} props
* @type {{ new(props: InputSearchProps): {
*   props: { 
*     delayed: boolean,
*     searchValue: string,
*     searchResults: Array,
*     recentResults: Array,
*     searchFunction: Function,
*     onElement: Function,
*     className: string,
*     placeholder: string,
*     label: string,
*     emptyCaption: string,
*     children: React.element,
*     offRecentResults: boolean,
*     showSelected: boolean,
*   }
*  }
* }}
*/
const InputSearch = view(RawInputSearch)

export default InputSearch
