
import React, { Component } from 'react';
import { store, view } from '@risingstack/react-easy-state';
import mainStore from 'storeUtils/mainStore';

import './MealWarningModal.scss';

import { Text } from 'components/Text';
import Button from 'components/Button';
import { FA } from 'components/Icon';
import { mealWarningEnum } from 'utils/mealUtils';
import arrayToMap from 'utils/arrayToMap';
import Tooltip from 'components/Tooltip';

class MealWarningModal extends Component {
    static class = 'modal-medium';

    ezState = store({
        needRebalance: false,
        keepOriginal: false,
        warnings: [],
        isRestrictionWarning: false,
        restrictionWarningChecked: false
    });

    constructor(props) {
        super(props);

        this.ezState.warnings = Array.isArray(this.props.warnings) ? this.props.warnings : [];
        this.ezState.isRestrictionWarning = this.ezState.warnings.some(warning => warning === mealWarningEnum.RESTRICTION);
    }

    onSubmit = () => {
        const result = {};
        if (this.ezState.warnings.some(warning => warning === mealWarningEnum.REBALANCE)) {
            result.needRebalance = this.ezState.needRebalance;
        }
        if (this.ezState.warnings.some(warning => warning === mealWarningEnum.SUBSTITUTE)) {
            result.keepOriginal = this.ezState.keepOriginal;
        }
        this.props.closeAction(result);
    }

    setRebalance = () => {
        this.ezState.needRebalance = !this.ezState.needRebalance;
    }

    setKeepOriginal = (value = true) => {
        this.ezState.keepOriginal = value;
    }

    renderWarningCantFit = (key) => {
        return <div className="warning-section" key={key}>
            <FA className="icon" icon="fas fa-exclamation-circle" />
            <div className="warning-section-content">
                <Text normal inlineBlock>Adding this food/Recipe exceeds our ability to Rebalance your serving sizes, as they would be under the minimum serving amounts.</Text>
                <div className="agree-section disabled">
                    <Button noShadow>
                        <FA icon="far fa-times" />
                    </Button>
                    <Text tallLine normal>Can&apos;t Rebalance</Text>
                </div>
            </div>
        </div>
    }

    renderWarningRebalance(key) {
        return <div className="warning-section" key={key}>
            <FA className="icon" icon="fas fa-exclamation-circle" />
            <div className="warning-section-content">
                <Text normal inlineBlock>Adding this food/Recipe exceeds your daily Caloric target. Rebalance My Menu serving sizes to meet target.</Text>
            </div>
            <div className="agree-section cursor-pointer double-column" onClick={() => this.ezState.needRebalance = !this.ezState.needRebalance}>
                <Button noShadow className={this.ezState.needRebalance ? 'checked' : ''}>
                    <FA icon={this.ezState.needRebalance ? 'fas fa-check' : 'far fa-times'} />
                </Button>
                <Text normal>Rebalance</Text>
            </div>
        </div>
    }

    renderWaraningRebalanceToday = (key) => {
        return <div className="warning-section" key={key}>
            <FA className="icon" icon="fas fa-exclamation-circle" />
            <div className="warning-section-content">
                <Text normal inlineBlock>You are adjusting your My Menu on the current day. Adding this food/Recipe exceeds your daily Caloric target.</Text>
                <Text normal inlineBlock className="pt-2">If you want to rebalance your serving sizes for the day, first go to the My Menu page and log any foods you have already
                    eaten. Then click the REBALANCE DAY WITH MY CHANGES button to rebalance your My Menu.</Text>
            </div>
        </div>
    }

    renderRestrictionWarning = (restrArr) => {
        if (!Array.isArray(restrArr) || restrArr?.length === 0) return;

        return <div className="warning-section">
            <FA className="icon" icon="fas fa-exclamation-circle" />
            <div className="warning-section-content">
                {this.renderIncludedRestrictionsInfo(restrArr)}
            </div>
        </div>
    }

    renderIncludedRestrictionsInfo = (restrArr) => {
        if (!Array.isArray(restrArr) || restrArr?.length === 0) return;

        const copiedRestrictions = JSON.parse(JSON.stringify(mainStore.restrictions));
        const restrictionsMap = arrayToMap(copiedRestrictions)

        return <React.Fragment>
            <Text>This food/Recipe violates the following restrictions:</Text>
            <ul>
                {restrArr.map((restr, key) =>
                    restrictionsMap[restr] ? <li key={restr}>
                        {restrictionsMap[restr].name}
                    </li> : ''
                )}
            </ul>
        </React.Fragment>
    }

    renderWarningAltered = (key) => {
        const orgIngr = [];
        const alteredIngr = [];

        if (Array.isArray(this.props.alteredIngredients)) {
            for (let i = 0; i < this.props.alteredIngredients.length; i++) {
                const foodIngr = this.props.alteredIngredients[i];

                orgIngr.push(foodIngr.originalSelectedFood.name)
                alteredIngr.push(foodIngr.selectedFood.name)
            }
        }

        return <React.Fragment>
            {this.renderRestrictionWarning(this.props.includedRestrictions)}

            <div className="warning-section" key={key}>
                <FA className="icon" icon="fas fa-exclamation-circle" />
                <div className="warning-section-content">
                    <Text inlineBlock>
                        This Recipe has been altered to match your food restrictions and we replaced:
                    </Text>
                </div>

                <div className="two-columns">
                    <FA className="chevron-icon" icon='far fa-chevron-right' />
                    <div className="two-columns-ingredients">
                        {orgIngr.map((foodName, index) => <React.Fragment key={index}>
                            <Text key={index + 'org'} className="org" normal inlineBlock>
                                {foodName}
                            </Text>
                            {Array.isArray(alteredIngr) && alteredIngr[index] ? <Text key={index + 'altr'} className="altr" normal inlineBlock>
                                {alteredIngr[index]}
                            </Text> : <span></span>}
                        </React.Fragment>)}
                    </div>
                    <div className="two-columns-buttons">
                        <div className="keep-original-area-button">
                            <Button className={'option-btn rebalance ' +
                                (this.ezState.keepOriginal ? 'active' : '')}
                                onClick={() => this.setKeepOriginal(true)}
                            >
                                KEEP ORIGINAL
                            </Button>

                            <div className="warning-keep">
                                <Text normal inlineBlock>
                                    <span className="warning">Warning: </span>
                                    Selecting &quot;keep original&quot; will violate your food restrictions
                                </Text>
                            </div>
                        </div>
                        <div className="keep-changes-area-button">
                            <Button className={'option-btn no-rebalance ' +
                                (this.ezState.keepOriginal === false ? 'active' : '')}
                                onClick={() => this.setKeepOriginal(false)}
                            >
                                KEEP CHANGES
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    }

    renderWarningRestrictions = (key) => {
        return <React.Fragment>
            <div className="warning-section" key={key}>
                <FA className="icon" icon="fas fa-exclamation-circle" />
                <div className="warning-section-content">
                    {this.renderIncludedRestrictionsInfo(this.props.includedRestrictions)}

                    <div className="agree-section cursor-pointer" onClick={() => this.ezState.restrictionWarningChecked = !this.ezState.restrictionWarningChecked}>
                        <Button noShadow className={this.ezState.restrictionWarningChecked ? 'checked' : ''}>
                            <FA icon={this.ezState.restrictionWarningChecked ? 'fas fa-check' : 'far fa-times'} />
                        </Button>
                        <Text normal>I acknowledge the warning, add anyway.</Text>
                    </div>
                </div>
            </div>
        </React.Fragment >
    }

    renderWarningCantAdd = (key) => {
        return <div className="warning-section" key={key}>
            <FA className="icon" icon="fas fa-exclamation-circle" />
            <div className="warning-section-content">
                <Text normal inlineBlock>This Recipe contains an error, sorry we can&apos;t add it.</Text>
            </div>
        </div>
    }

    renderConfirmButton = (restrictionCheck) => {
        if (restrictionCheck) {
            return <Tooltip text="Confirm restriction warning first" v className="tooltip-button">
                <Button disabled={restrictionCheck} onClick={() => this.onSubmit()} green>Confirm</Button>
            </Tooltip>
        }
        return <Button disabled={restrictionCheck} onClick={() => this.onSubmit()} green>Confirm</Button>
    }

    render() {

        const restrictionCheck = this.ezState.isRestrictionWarning && !this.ezState.restrictionWarningChecked;
        return (
            <div className="meal-warning-modal-modal">
                <div className="modal-content">
                    <Text large className="description">MEAL WARNING</Text>
                    {Array.isArray(this.ezState.warnings) ? this.ezState.warnings.map((warning, key) => {
                        if (warning === mealWarningEnum.CANT_FIT) {
                            return this.renderWarningCantFit(key);
                        }
                        if (warning === mealWarningEnum.REBALANCE) {
                            return this.renderWarningRebalance(key);
                        }
                        if (warning === mealWarningEnum.REBALANCE_TODAY) {
                            return this.renderWaraningRebalanceToday(key);
                        }
                        if (warning === mealWarningEnum.SUBSTITUTE) {
                            return this.renderWarningAltered(key);
                        }
                        if (warning === mealWarningEnum.RESTRICTION) {
                            return this.renderWarningRestrictions(key);
                        }
                        if (warning === mealWarningEnum.CANT_ADD) {
                            return this.renderWarningCantAdd(key);
                        }
                    }) : ''}
                </div>

                <div className="modal-buttons">
                    <Button className="cancel-btn" onClick={() => this.props.closeAction()}>Back</Button>
                    {this.renderConfirmButton(restrictionCheck)}
                </div>
            </div>
        );
    }
}

export default view(MealWarningModal);