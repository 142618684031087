import mainStore from 'storeUtils/mainStore';
import { pageNeedsReloading } from 'storeUtils/storeUtils';
import { fetchJSON } from 'utils/fetchJSON';

export default function mealChangeAmount(person_meal_id, person_id, amount, extra_portions) {
    return fetchJSON('/api/meal/change-amount', { person_meal_id, person_id, amount, extra_portions, baseInfoTimeUTC: mainStore.baseInfoTimeUTC })
        .then((json) => {

            if (json.request?.error) {
                pageNeedsReloading();
            }
            return json;
        })
        .catch(error => {
            console.log('[api/meal/mealChangeAmount.js] Error: ', error);
            return undefined;
        });
}
