import React from 'react';

const preparationIcon = () => (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 70 70">
        <g>
            <path d="M37.87,5.51c0.06,0.03,0.07,0.04,0.14,0.05C37.95,5.52,37.93,5.52,37.87,5.51z" />
            <path d="M45.5,5.56c-0.07-0.03-0.11-0.04-0.19-0.05C45.38,5.54,45.42,5.55,45.5,5.56z" />
            <path d="M63.44,54.97V34.48v-4.33c0-0.62,0.06-1.27-0.01-1.88c-0.1-0.78-0.41-1.5-0.88-2.12c-1.01-1.33-2.46-1.79-3.96-2.31
            c-1.85-0.64-3.67-1.33-5.51-1.99c-0.77-0.28-1.62-0.48-2.36-0.85c-0.73-0.36-1.36-0.89-1.86-1.54c-0.47-0.6-0.78-1.29-0.99-2.01
            c0,0,0,0.01,0.01,0c0,0,0.01-0.05-0.02-0.03c0,0,0,0,0,0c-0.09-0.32-0.15-0.64-0.19-0.97c0.01-0.03,0.01-0.05-0.01-0.1
            c-0.01-0.1-0.03-0.19-0.03-0.29c0.04-0.14,0.04-0.28,0-0.42c0.01-0.11,0.02-0.22,0.03-0.33v0.09c0.03-0.07,0.03-0.07,0-0.13
            c0-0.05,0.01-0.1,0.01-0.14c0,0.01,0.01,0.02,0.02,0.01c0,0,0-0.04-0.01-0.03c0.02-0.17,0.03-0.34,0.03-0.5v0.24
            c0.05-0.13,0.05-0.24,0-0.37c0,0,0-0.01,0-0.01c0.04-0.11,0.04-0.2,0-0.3c0-0.01,0-0.03,0-0.04c0.04-0.11,0.04-0.21,0-0.32
            c0-0.03,0-0.06,0-0.09c0.03-0.11,0.05-0.22,0.04-0.35c0.04-0.11,0.04-0.19-0.01-0.3v0.27c-0.01-0.07-0.02-0.13-0.03-0.19
            c0.01-0.53,0.02-1.07,0.03-1.6v0.06c0.05-0.12,0.05-0.22,0.01-0.34c0.01-0.4,0.02-0.8,0.04-1.21v0.08
            c0.05-0.11,0.04-0.21,0.01-0.31c0.01-0.13,0.02-0.27,0.03-0.4v0.34c0.05-0.13,0.05-0.24,0-0.36c0-0.01,0-0.03,0-0.04
            c0.02-0.07,0.03-0.13,0.02-0.19c0.01-0.12,0.02-0.24,0.02-0.36v0.07c0.08-0.19,0.08-0.47,0-0.66v0.36c0-0.09,0-0.18-0.01-0.26
            c0-0.04-0.01-0.09-0.02-0.13c0,0.01,0,0.01,0.01,0.01c0,0,0.01-0.05-0.02-0.03c0,0,0,0,0,0.01c-0.01-0.1-0.03-0.2-0.05-0.31
            c0.01-0.03,0.01-0.05-0.01-0.11v0.07c-0.01-0.04-0.02-0.07-0.03-0.11c0.01-0.03,0.01-0.05-0.02-0.1v0.05
            c-0.27-0.91-0.89-1.7-1.84-1.98c-0.1-0.03-0.21-0.05-0.32-0.06c-0.03-0.02-0.06-0.02-0.11-0.03c0.02,0.01,0.03,0.02,0.05,0.02
            c-0.1-0.01-0.2-0.02-0.3-0.02h0.06c-0.21-0.09-0.48-0.05-0.71-0.05h-1.46h-5.13c0.04,0.02,0.09,0.03,0.14,0.04
            c-0.29,0.01-0.58,0.04-0.85,0.14c-0.27,0.1-0.53,0.24-0.75,0.42c-0.18,0.14-0.35,0.31-0.49,0.49c-0.71,0.92-0.58,1.91-0.56,2.97
            c-0.01-0.09-0.01-0.17-0.04-0.24v0.71c0.03-0.07,0.04-0.17,0.05-0.26c0,0.02,0,0.05,0,0.07c0,0.71,0.01,1.41,0.03,2.11
            c0-0.04-0.01-0.09-0.03-0.14v0.33c0.02-0.05,0.03-0.09,0.03-0.14c0,0.11,0,0.21,0.01,0.32c0-0.06-0.01-0.12-0.04-0.18v0.38
            c0.03-0.06,0.04-0.12,0.04-0.18c0.01,0.32,0.02,0.64,0.03,0.97c-0.01-0.03-0.01-0.05-0.02-0.08v0.33c0.01-0.04,0.02-0.07,0.02-0.1
            c0,0.08,0,0.15,0.01,0.23c-0.01-0.04-0.01-0.08-0.03-0.13v0.38c0.02-0.05,0.03-0.1,0.03-0.14c0.01,0.2,0.01,0.41,0.02,0.61
            c0,0.11,0.01,0.21,0.02,0.31c-0.01-0.03-0.01-0.05-0.02-0.08v0.33c0.02-0.04,0.02-0.08,0.03-0.11c0.01,0.17,0.03,0.33,0.04,0.5
            c0-0.01-0.01-0.03-0.02-0.06v0.14c0.01-0.03,0.02-0.05,0.02-0.06c0.01,0.14,0.02,0.29,0.02,0.43c0-0.08-0.01-0.16-0.04-0.23v0.52
            c0.03-0.07,0.04-0.15,0.04-0.23c0,0.09,0,0.18,0,0.27c0,0.06-0.02,0.12-0.02,0.18c0,0,0-0.01-0.01,0c0,0,0,0.02,0,0.03
            c-0.09,1.02-0.47,2.09-1.06,2.91c-0.5,0.7-1.16,1.28-1.92,1.68c-0.71,0.37-1.55,0.6-2.31,0.86c-1.79,0.61-3.54,1.33-5.32,1.97
            c-0.39,0.14-0.78,0.27-1.16,0.41c0-0.01-0.03-0.01-0.03-0.01c-0.02,0.02,0,0.02,0.02,0.02c-0.91,0.33-1.77,0.73-2.48,1.44
            c-1.82,1.82-1.38,4.52-1.38,6.86v16.06c1.16-0.79,2.25-1.74,3.34-2.62c1.6-1.28,3.24-2.54,4.85-3.81
            c5.44-4.28,10.87-8.58,16.34-12.81c1.91-1.48,3.78-3,5.7-4.46c0.47-0.36,1-0.93,1.6-0.95c0.05,0.01,0.09,0.01,0.15,0.01
            c0,0-0.01,0-0.01,0c0.13,0.01,0.25,0.04,0.39,0.12c0.12,0.07,0.23,0.16,0.32,0.26c0.18,0.21,0.25,0.43,0.27,0.66
            c-0.01-0.03-0.01-0.06-0.02-0.09v0.33c0.01-0.03,0.01-0.06,0.02-0.09c-0.04,0.54-0.39,1.11-0.62,1.59
            c-0.97,2.05-2.11,4.05-3.37,5.94c-3.46,5.18-7.59,9.83-12.33,13.88c-1.77,1.51-3.62,2.94-5.46,4.36c-0.62,0.48-1.23,0.96-1.84,1.44
            c-0.3,0.24-0.66,0.45-0.89,0.75c-0.52-0.54-0.92-1.18-1.41-1.74c-0.68,0.39-1.27,0.96-1.88,1.44c-1.05,0.82-2.11,1.64-3.16,2.47
            c-0.45,0.36-0.91,0.71-1.37,1.07c-0.18,0.14-0.45,0.28-0.56,0.49c-0.09,0.16-0.05,0.39-0.05,0.56v1.22c0,0.76,0,1.52,0.24,2.26
            c0.48,1.52,1.79,2.64,3.25,3.2c0.74,0.28,1.51,0.36,2.28,0.38h-0.25c0.26,0.11,0.62,0.05,0.9,0.05h1.93h6.64h23.03
            c-0.03-0.01-0.07-0.02-0.11-0.03c0.05,0,0.09-0.01,0.14-0.01c0.04,0.02,0.06,0.03,0.12,0.04c-0.04-0.02-0.06-0.03-0.09-0.04
            c0.03,0,0.07,0,0.1-0.01c0.05,0.03,0.07,0.03,0.13,0.04c-0.05-0.03-0.07-0.03-0.12-0.04c0.07,0,0.15-0.01,0.22-0.01
            c0.04,0,0.09-0.02,0.13-0.02c0.03,0.01,0.05,0.02,0.1,0.03c-0.03-0.02-0.06-0.03-0.08-0.03c1.02-0.13,2.02-0.51,2.86-1.1
            c0.83-0.58,1.55-1.47,1.85-2.45c0.23-0.77,0.18-1.56,0.18-2.36V54.97z"/>
            <path d="M35.46,7.82v0.14C35.5,7.89,35.5,7.89,35.46,7.82z" />
            <path d="M47.85,7.86v0.14C47.88,7.93,47.88,7.94,47.85,7.86z" />
            <path d="M35.43,7.99c0,0-0.01,0.05,0.02,0.03C35.45,8.02,35.45,7.97,35.43,7.99z" />
            <path d="M47.88,8.07c0,0,0.01-0.05-0.02-0.03C47.86,8.04,47.86,8.09,47.88,8.07z" />
            <path d="M35.43,8.08c0,0-0.01,0.05,0.02,0.03C35.45,8.11,35.45,8.06,35.43,8.08z" />
            <path d="M35.42,8.76c0.07-0.18,0.07-0.43,0-0.61v0.34c0-0.08-0.02-0.17-0.05-0.24v0.52c0.03-0.07,0.04-0.16,0.05-0.24V8.76z" />
            <path d="M35.42,8.76v0.19C35.45,8.87,35.45,8.84,35.42,8.76z" />
            <path d="M35.42,8.95v0.38C35.47,9.19,35.47,9.08,35.42,8.95z" />
            <path d="M35.42,9.32v0.33C35.47,9.54,35.47,9.44,35.42,9.32z" />
            <path d="M35.42,9.65v0.38C35.47,9.9,35.47,9.79,35.42,9.65z" />
            <path d="M47.85,9.75v0.38C47.91,10,47.91,9.88,47.85,9.75z" />
            <path d="M35.46,10.03v0.38C35.52,10.28,35.52,10.16,35.46,10.03z" />
            <path d="M47.8,10.13v0.38C47.86,10.37,47.86,10.26,47.8,10.13z" />
            <path d="M35.46,10.41v0.33C35.51,10.62,35.51,10.53,35.46,10.41z" />
            <path d="M47.8,10.5v0.33C47.85,10.71,47.85,10.62,47.8,10.5z" />
            <path d="M35.46,10.74v0.38C35.52,10.98,35.52,10.87,35.46,10.74z" />
            <path d="M47.8,10.83v0.38C47.86,11.08,47.86,10.96,47.8,10.83z" />
            <path d="M35.46,11.11v0.33C35.51,11.33,35.51,11.23,35.46,11.11z" />
            <path d="M47.8,11.21v0.38C47.86,11.46,47.86,11.34,47.8,11.21z" />
            <path d="M47.76,11.59v0.33C47.81,11.8,47.81,11.71,47.76,11.59z" />
            <path d="M47.76,11.92v0.38C47.81,12.16,47.81,12.05,47.76,11.92z" />
            <path d="M47.76,12.29v0.38C47.81,12.54,47.81,12.42,47.76,12.29z" />
            <path d="M35.51,12.53v0.33C35.56,12.74,35.56,12.65,35.51,12.53z" />
            <path d="M47.76,12.67v0.38C47.81,12.92,47.81,12.8,47.76,12.67z" />
            <path d="M35.56,13.94v0.33C35.61,14.15,35.61,14.06,35.56,13.94z" />
            <path d="M35.6,14.98v0.14C35.64,15.05,35.64,15.05,35.6,14.98z" />
            <path d="M35.62,15.15c0,0-0.01,0.05,0.02,0.03C35.64,15.18,35.64,15.13,35.62,15.15z" />
            <path d="M47.69,15.23c0,0,0.01-0.05-0.02-0.03C47.68,15.2,47.67,15.25,47.69,15.23z" />
            <path d="M35.65,15.45v0.14C35.68,15.52,35.68,15.52,35.65,15.45z" />
            <path d="M35.65,16.11v0.19C35.68,16.21,35.68,16.19,35.65,16.11z" />
            <path d="M35.6,16.67c0.03-0.07,0.03-0.07,0-0.14V16.67z" />
            <path d="M31.38,21.46c0.02-0.02-0.03-0.02-0.03-0.02C31.33,21.47,31.38,21.46,31.38,21.46z" />
            <path d="M53.66,22.03c0.02-0.02-0.03-0.02-0.03-0.02C53.61,22.03,53.66,22.03,53.66,22.03z" />
            <path d="M24.13,24.1c0.02-0.02-0.03-0.02-0.03-0.02C24.07,24.1,24.12,24.1,24.13,24.1z" />
            <path d="M51.4,25.61c0,0-0.01,0.05,0.02,0.03C51.42,25.64,51.42,25.59,51.4,25.61z" />
            <path d="M51.35,25.65c0,0-0.01,0.05,0.02,0.03C51.37,25.68,51.37,25.63,51.35,25.65z" />
            <path d="M27.65,50.02l0.67,0.85c0.08,0.1,0.18,0.27,0.31,0.28c0.14,0.01,0.27-0.13,0.37-0.2c0.3-0.23,0.6-0.47,0.89-0.71
            c1.18-0.92,2.36-1.85,3.53-2.77c5.13-4.02,9.72-8.73,13.46-14.07c1.25-1.79,2.34-3.65,3.4-5.56c0.27-0.49,0.52-1,0.77-1.51
            c0.1-0.2,0.27-0.44,0.22-0.66c-0.53,0.49-1.13,0.91-1.7,1.35c-0.9,0.7-1.79,1.4-2.68,2.1c-2.89,2.29-5.8,4.57-8.71,6.84
            c-2.83,2.21-5.68,4.4-8.48,6.64c-1,0.8-2.01,1.58-3.01,2.36c-0.61,0.48-1.19,1-1.84,1.43c0.2,0.45,0.61,0.84,0.91,1.22
            C26.4,48.42,27.03,49.22,27.65,50.02z"/>
            <path d="M20.11,27.74v0.14C20.14,27.81,20.14,27.82,20.11,27.74z" />
            <path d="M20.08,28.06c0,0-0.01,0.05,0.02,0.03C20.09,28.09,20.1,28.04,20.08,28.06z" />
            <path d="M20.08,28.15c0,0-0.01,0.05,0.02,0.03C20.09,28.18,20.1,28.13,20.08,28.15z" />
            <path d="M20.01,28.4v0.14C20.05,28.47,20.05,28.48,20.01,28.4z" />
            <path d="M20.01,28.54v0.14C20.05,28.61,20.05,28.62,20.01,28.54z" />
            <path d="M25.1,51.16c0.25-0.2,0.5-0.39,0.75-0.59c0.09-0.07,0.23-0.16,0.26-0.28c0.06-0.23-0.37-0.63-0.51-0.8l-1.15-1.46
            c-0.21-0.27-0.41-0.58-0.68-0.8c-0.75,0.66-1.57,1.23-2.36,1.86c-1.76,1.41-3.55,2.79-5.32,4.18l-4.99,3.91
            c-1.34,1.05-2.67,2.09-4,3.15c-0.33,0.26-0.66,0.51-0.99,0.77c-0.19,0.15-0.41,0.3-0.49,0.55C5.4,62.39,6.22,63,6.73,63.38
            c0.76,0.57,1.67,0.91,2.61,1.02c0.04,0.02,0.06,0.03,0.12,0.04c-0.04-0.02-0.06-0.03-0.08-0.03c0.37,0.04,0.74,0.04,1.11,0
            c-0.01,0.02,0.03,0.02,0.03,0.01c0.01-0.01,0-0.01-0.01-0.02c0.02,0,0.04,0,0.06,0c0.04,0.02,0.06,0.03,0.12,0.04
            c-0.04-0.02-0.06-0.03-0.09-0.04c0.07-0.01,0.15-0.03,0.22-0.04c0,0.01,0.03,0.01,0.03,0.01c0.01-0.01,0-0.01-0.01-0.02
            c0.38-0.06,0.76-0.16,1.12-0.31c0.78-0.33,1.41-0.85,2.07-1.36c0.22-0.17,0.66-0.4,0.77-0.67c0.03-0.07,0.05-0.16,0.06-0.26
            c0.01-0.05,0.01-0.11,0-0.16c0-0.08,0-0.15-0.01-0.23c0.02-0.08,0.02-0.16-0.01-0.25c0-0.02,0-0.04,0-0.06
            c0.04-0.11,0.04-0.22,0.01-0.34c0.01-0.16,0.03-0.32,0.05-0.48c0,0.01,0.01,0.02,0.02,0.01c0,0,0-0.04-0.01-0.03
            c0-0.02,0-0.04,0.01-0.07c0,0,0,0.01,0.01,0c0,0,0-0.02,0-0.03c0.03-0.19,0.08-0.37,0.14-0.54c0,0,0,0.01,0.01,0c0,0,0-0.01,0-0.02
            c0.09-0.25,0.2-0.49,0.37-0.7c0.66-0.81,1.65-1.42,2.47-2.06c1.44-1.11,2.85-2.25,4.29-3.37C23.16,52.7,24.13,51.93,25.1,51.16z"/>
            <path d="M20.01,59.63v0.19C20.05,59.74,20.05,59.71,20.01,59.63z" />
            <path d="M20.04,59.88c0,0,0.01-0.05-0.02-0.03C20.03,59.85,20.02,59.9,20.04,59.88z" />
            <path d="M20.04,59.98c0,0,0.01-0.05-0.02-0.03C20.03,59.95,20.02,60,20.04,59.98z" />
            <path d="M20.09,60.21c0,0,0.01-0.05-0.02-0.03C20.07,60.18,20.07,60.23,20.09,60.21z" />
            <path d="M20.14,60.54c0,0,0.01-0.05-0.02-0.03C20.12,60.51,20.12,60.56,20.14,60.54z" />
            <path d="M20.19,60.73c0,0,0.01-0.05-0.02-0.03C20.17,60.7,20.16,60.75,20.19,60.73z" />
            <path d="M5.55,62.03c0.04-0.1,0.04-0.14,0-0.24V62.03z" />
            <path d="M11.33,64.26c-0.02,0.02,0.03,0.02,0.03,0.02C11.38,64.26,11.33,64.26,11.33,64.26z" />
            <path d="M24.1,64.26c-0.02,0.02,0.03,0.02,0.03,0.02C24.15,64.26,24.1,64.26,24.1,64.26z" />
            <path d="M9.26,64.4c-0.02,0.02,0.03,0.02,0.03,0.02C9.31,64.4,9.26,64.4,9.26,64.4z" />
            <path d="M24.94,64.4c-0.02,0.02,0.03,0.02,0.03,0.02C25,64.4,24.95,64.4,24.94,64.4z" />
            <path d="M10.12,64.48c-0.11-0.05-0.21-0.05-0.33-0.05C9.9,64.48,10,64.48,10.12,64.48z" />
            <path d="M10.31,64.48c-0.07-0.03-0.11-0.04-0.19-0.05C10.19,64.47,10.23,64.47,10.31,64.48z" />
            <path d="M25.13,64.45c-0.02,0.02,0.03,0.02,0.03,0.02C25.18,64.45,25.13,64.45,25.13,64.45z" />
            <path d="M25.34,64.48c-0.06-0.03-0.07-0.04-0.14-0.05C25.25,64.47,25.27,64.47,25.34,64.48z" />
            <path d="M25.52,64.48c-0.07-0.03-0.11-0.04-0.19-0.05C25.41,64.47,25.44,64.47,25.52,64.48z" />
            <path d="M58.34,64.45c-0.02,0.02,0.03,0.02,0.03,0.02C58.39,64.45,58.34,64.45,58.34,64.45z" />
        </g>
    </svg>
);

export default preparationIcon;