import React from 'react'
import { view } from '@risingstack/react-easy-state';

function getColor(color, index) {
    if (Array.isArray(color)) {
        return color[index % color.length];
    } else {
        return color;
    }
}

export default view((props) => {
    let icon = false;
    let element = false;
    let addedClass = '';
    const iconEl = Array.isArray(props.icon) ? props.icon : [props.icon];
    const onClick = props.onClick ? props.onClick : () => { };
    const onMouseDown = props.onMouseDown ? props.onMouseDown : () => { };
    const onMouseUp = props.onMouseUp ? props.onMouseUp : () => { };

    if ((Array.isArray(props.icon) && props.icon.length > 1) || props.alwaysStack) {
        addedClass = ' fa-stack-1x';
    }
    if (props.onContentClick) {
        element = <span className="clickable" onClick={props.onContentClick}>{props.children}</span>
    } else if (props.children) {
        element = <span>{props.children}</span>
    }
    if (props.onIconClick) {
        if (props.color) {
            icon = iconEl.map((ie, i) => <i key={i} className={"clickable " + ie + addedClass} style={{ color: getColor(props.color, i) }} onClick={props.onIconClick} />);
        } else {
            icon = iconEl.map((ie, i) => <i key={i} className={"clickable " + ie + addedClass} onClick={props.onIconClick} />);
        }
    } else {
        if (props.onContentClick) {
            if (props.color) {
                icon = iconEl.map((ie, i) => <i key={i} className={"clickable " + ie + addedClass} style={{ color: getColor(props.color, i) }} onClick={props.onContentClick} />);
            } else {
                icon = iconEl.map((ie, i) => <i key={i} className={"clickable " + ie + addedClass} onClick={props.onContentClick} />);
            }
        } else {
            if (props.color) {
                icon = iconEl.map((ie, i) => <i key={i} className={ie + addedClass} style={{ color: getColor(props.color, i) }} />);
            } else {
                icon = iconEl.map((ie, i) => <i key={i} className={ie + addedClass} />);
            }
        }
    }
    return (
        <span className={props.className} onClick={onClick} onMouseDown={onMouseDown} onTouchStart={onMouseDown} onMouseUp={onMouseUp} onMouseLeave={onMouseUp} onTouchEnd={onMouseUp} onContextMenu={props.onContextMenu}>
            {
                (iconEl.length > 1) || props.alwaysStack
                    ?
                    <span className="fa-stack">
                        {icon}
                    </span>
                    :
                    icon[0]
            }
            {element !== false && element}
        </span>
    )
})
