import React from 'react';
import PropTypes from 'prop-types';
import { view } from '@risingstack/react-easy-state';

import moment from 'moment';

import './NutrientSnippet.scss';

import CircleChart from 'components/CircleChart';
import WeekCalendar from 'components/WeekCalendar';
import Text from '../Text';

const getCalendarDay = (selectedDay) => {
    return (<div className="calendar-day">
        {moment(selectedDay.date, 'YYYY-MM-DD').format('MMM DD')}
    </div>);
}

const getCircleChart = (data) => <CircleChart sections={data.alcohol ? [data.carbs * 4, data.protein * 4, data.fat * 9, data.alcohol * 7] : [data.carbs * 4, data.protein * 4, data.fat * 9]} >
    <span className="small">Menu</span>
    <span className="big">{data.kcal.toFixed(0)}</span>
    <span className="small">Cal</span>
</CircleChart>

/**
 * @typedef NutrientSnippetProps
 * @type {object}
 * @property {object} data
 * @property {object} children React.Element
 * @property {bool} offCalendar
 * @property {bool} withLegend display differnt style version of this component
 * @property {object} selectedDay
 * @property {function} onDaySelect
*/

/**
 * @param {NutrientSnippetProps} props
 */
const RawNutrientSnippet = props => {
    const data = props.data;
    const sum = Math.max(1, data.kcal);

    if (props.withLegend) {
        return <div className={"topleft-legend-charts " +
            (props.children ? 'extra ' : '')
        }>
            {getCalendarDay(props.selectedDay)}
            {getCircleChart(data)}

            <div className="right-column">
                <div className="nutrients">
                    <div className="nutrients-tint blue">
                        <div className="square" />
                        <Text normal>
                            <span className="percentage">{(data.protein * 4 * 100 / sum).toFixed(0)}%</span>
                            <b>Protein</b>
                        </Text>
                    </div>

                    <div className="nutrients-tint yellow">
                        <div className="square" />
                        <Text normal>
                            <span className="percentage">{(data.fat * 9 * 100 / sum).toFixed(0)}%</span>
                            <b>Fat</b>
                        </Text>
                    </div>

                    <div className="nutrients-tint green">
                        <div className="square" />
                        <Text normal>
                            <span className="percentage">{(data.carbs * 4 * 100 / sum).toFixed(0)}%</span>
                            <b>Carbohydrate</b>
                        </Text>
                    </div>

                    {
                        data.alcohol ? <div className="nutrients-tint reddish">
                            <div className="square" />
                            <Text normal>
                                <span className="percentage">{(data.alcohol * 7 * 100 / sum).toFixed(0)}%</span>
                                <b>Alcohol</b>
                            </Text>
                        </div> : ''
                    }
                </div>

                {props.children ? <div className="extra">{props.children}</div> : ''}
            </div>
        </div>
    }

    return (
        <div className={"topleft-charts " +
            (props.children ? 'extra ' : '') +
            (props.offCalendar ? 'offCalendar' : '')
        }>
            {!props.offCalendar ?
                getCalendarDay(props.selectedDay)
                : ''}
            {!props.offCalendar ?
                <WeekCalendar
                    selectedDay={props.selectedDay}
                    onDaySelect={(i) => {
                        if (typeof props.onDaySelect === 'function') {
                            props.onDaySelect(i);
                        }
                    }}
                    lightBlue
                />
                : ''}

            {getCircleChart(data)}

            <div className="nutrients">
                <div className="nutrients-tint">
                    <table>
                        <thead>
                            <tr>
                                <th></th><th>Cal</th><th>g</th><th>%</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="green">
                                <td>carbs</td><td>{(data.carbs * 4).toFixed(0)}</td><td>{data.carbs.toFixed(0)}</td><td>{(data.carbs * 4 * 100 / sum).toFixed(0)}</td>
                            </tr>
                            <tr className="blue">
                                <td>protein</td><td>{(data.protein * 4).toFixed(0)}</td><td>{data.protein.toFixed(0)}</td><td>{(data.protein * 4 * 100 / sum).toFixed(0)}</td>
                            </tr>
                            <tr className="yellow">
                                <td>fat</td><td>{(data.fat * 9).toFixed(0)}</td><td>{data.fat.toFixed(0)}</td><td>{(data.fat * 9 * 100 / sum).toFixed(0)}</td>
                            </tr>
                            {data.alcohol ?
                                <tr className="reddish">
                                    <td>alcohol</td><td>{(data.alcohol * 7).toFixed(0)}</td><td>{(data.alcohol).toFixed(0)}</td><td>{(data.alcohol * 7 * 100 / sum).toFixed(0)}</td>
                                </tr>
                                : <tr></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {props.children ? <div className="extra">{props.children}</div> : ''}
        </div>
    )
};

RawNutrientSnippet.propTypes = {
    data: PropTypes.object.isRequired,
    children: PropTypes.object,
    offCalendar: PropTypes.bool,
    withLegend: PropTypes.bool,
    selectedDay: PropTypes.object.isRequired,
    onDaySelect: PropTypes.func.isRequired,
};

/**
 * information about nutrients with circlechart
 * @component
 * @type {{ new(props: NutrientSnippetProps): {
 *   props: { 
 *     data: object,
 *     children: object,
 *     selectedDay: object,
 *     onDaySelect: function,
 *     withLegend: boolean,
 *     offCalendar: boolean,
 *    }
 *  }
 * }}
 */
const NutrientSnippet = view(RawNutrientSnippet);

export default NutrientSnippet;
