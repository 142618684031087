export default [
    {
        "minAge": 0,
        "maxAge": 0.5,
        "nutrientsPerWeight": {
            "protein": 1.52
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 5,
            "calcium": 200,
            "choline": 125,
            "chromium": 0.2,
            "copper": 200,
            "folate": 65,
            "iron": 0.27,
            "magnesium": 30,
            "manganese": 0,
            "niacin": 2,
            "pantothenicAcid": 1.7,
            "phosphorus": 100,
            "potassium": 400,
            "riboflavin": 0.3,
            "selenium": 15,
            "sodium": 120,
            "thiamin": 0.2,
            "vitaminARAE": 400,
            "vitaminB12": 0.4,
            "vitaminB6": 0.1,
            "vitaminC": 40,
            "vitaminD": 10,
            "zinc": 2,
            "vitaminE": 4,
            "vitaminK": 2
        }
    },
    {
        "minAge": 0.5,
        "maxAge": 1,
        "nutrientsPerWeight": {
            "protein": 1.2
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 6,
            "calcium": 260,
            "choline": 150,
            "chromium": 5.5,
            "copper": 220,
            "folate": 80,
            "iron": 11,
            "magnesium": 75,
            "manganese": 0.6,
            "niacin": 4,
            "pantothenicAcid": 1.8,
            "phosphorus": 275,
            "potassium": 860,
            "riboflavin": 0.4,
            "selenium": 20,
            "sodium": 370,
            "thiamin": 0.3,
            "vitaminARAE": 500,
            "vitaminB12": 0.5,
            "vitaminB6": 0.3,
            "vitaminC": 50,
            "vitaminD": 10,
            "zinc": 3,
            "vitaminE": 5,
            "vitaminK": 2.5
        }
    },
    {
        "minAge": 1,
        "maxAge": 4,
        "nutrientsPerWeight": {
            "protein": 1.05
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 8,
            "calcium": 700,
            "choline": 200,
            "chromium": 11,
            "copper": 340,
            "folate": 150,
            "iron": 7,
            "magnesium": 80,
            "manganese": 1.2,
            "niacin": 6,
            "pantothenicAcid": 2,
            "phosphorus": 460,
            "potassium": 2000,
            "riboflavin": 0.5,
            "selenium": 20,
            "sodium": 1500,
            "thiamin": 0.5,
            "vitaminARAE": 300,
            "vitaminB12": 0.9,
            "vitaminB6": 0.5,
            "vitaminC": 15,
            "vitaminD": 15,
            "zinc": 3,
            "vitaminE": 6,
            "vitaminK": 30
        }
    },
    {
        "minAge": 4,
        "maxAge": 9,
        "nutrientsPerWeight": {
            "protein": 0.95
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 12,
            "calcium": 1000,
            "choline": 250,
            "chromium": 15,
            "copper": 440,
            "folate": 200,
            "iron": 10,
            "magnesium": 130,
            "manganese": 1.5,
            "niacin": 8,
            "pantothenicAcid": 3,
            "phosphorus": 500,
            "potassium": 2300,
            "riboflavin": 0.6,
            "selenium": 30,
            "sodium": 1900,
            "thiamin": 0.6,
            "vitaminARAE": 400,
            "vitaminB12": 1.2,
            "vitaminB6": 0.6,
            "vitaminC": 25,
            "vitaminD": 15,
            "zinc": 5,
            "vitaminE": 7,
            "vitaminK": 55
        }
    },
    {
        "minAge": 9,
        "maxAge": 14,
        "group": "male",
        "nutrientsPerWeight": {
            "protein": 0.95
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 20,
            "calcium": 1300,
            "choline": 375,
            "chromium": 25,
            "copper": 700,
            "folate": 300,
            "iron": 8,
            "magnesium": 240,
            "manganese": 1.9,
            "niacin": 12,
            "pantothenicAcid": 4,
            "phosphorus": 1250,
            "potassium": 2500,
            "riboflavin": 0.9,
            "selenium": 40,
            "sodium": 2200,
            "thiamin": 0.9,
            "vitaminARAE": 600,
            "vitaminB12": 1.8,
            "vitaminB6": 1,
            "vitaminC": 45,
            "vitaminD": 15,
            "zinc": 8,
            "vitaminE": 11,
            "vitaminK": 60
        }
    },
    {
        "minAge": 14,
        "maxAge": 19,
        "group": "male",
        "nutrientsPerWeight": {
            "protein": 0.85
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 25,
            "calcium": 1300,
            "choline": 550,
            "chromium": 35,
            "copper": 890,
            "folate": 400,
            "iron": 11,
            "magnesium": 410,
            "manganese": 2.2,
            "niacin": 16,
            "pantothenicAcid": 5,
            "phosphorus": 1250,
            "potassium": 3000,
            "riboflavin": 1.3,
            "selenium": 55,
            "sodium": 2300,
            "thiamin": 1.2,
            "vitaminARAE": 900,
            "vitaminB12": 2.4,
            "vitaminB6": 1.3,
            "vitaminC": 75,
            "vitaminD": 15,
            "zinc": 11,
            "vitaminE": 15,
            "vitaminK": 75
        }
    },
    {
        "minAge": 19,
        "maxAge": 31,
        "group": "male",
        "nutrientsPerWeight": {
            "protein": 0.8
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 30,
            "calcium": 1000,
            "choline": 550,
            "chromium": 35,
            "copper": 900,
            "folate": 400,
            "iron": 8,
            "magnesium": 400,
            "manganese": 2.3,
            "niacin": 16,
            "pantothenicAcid": 5,
            "phosphorus": 700,
            "potassium": 3400,
            "riboflavin": 1.3,
            "selenium": 55,
            "sodium": 2300,
            "thiamin": 1.2,
            "vitaminARAE": 900,
            "vitaminB12": 2.4,
            "vitaminB6": 1.3,
            "vitaminC": 90,
            "vitaminD": 15,
            "zinc": 11,
            "vitaminE": 15,
            "vitaminK": 120
        }
    },
    {
        "minAge": 31,
        "maxAge": 50,
        "group": "male",
        "nutrientsPerWeight": {
            "protein": 0.8
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 30,
            "calcium": 1000,
            "choline": 550,
            "chromium": 35,
            "copper": 900,
            "folate": 400,
            "iron": 8,
            "magnesium": 420,
            "manganese": 2.3,
            "niacin": 16,
            "pantothenicAcid": 5,
            "phosphorus": 700,
            "potassium": 3400,
            "riboflavin": 1.3,
            "selenium": 55,
            "sodium": 2300,
            "thiamin": 1.2,
            "vitaminARAE": 900,
            "vitaminB12": 2.4,
            "vitaminB6": 1.3,
            "vitaminC": 90,
            "vitaminD": 15,
            "zinc": 11,
            "vitaminE": 15,
            "vitaminK": 120
        }
    },
    {
        "minAge": 50,
        "maxAge": 71,
        "group": "male",
        "nutrientsPerWeight": {
            "protein": 0.8
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 30,
            "calcium": 1000,
            "choline": 550,
            "chromium": 30,
            "copper": 900,
            "folate": 400,
            "iron": 8,
            "magnesium": 420,
            "manganese": 2.3,
            "niacin": 16,
            "pantothenicAcid": 5,
            "phosphorus": 700,
            "potassium": 3400,
            "riboflavin": 1.3,
            "selenium": 55,
            "sodium": 2300,
            "thiamin": 1.2,
            "vitaminARAE": 900,
            "vitaminB12": 2.4,
            "vitaminB6": 1.7,
            "vitaminC": 90,
            "vitaminD": 15,
            "zinc": 11,
            "vitaminE": 15,
            "vitaminK": 120
        }
    },
    {
        "minAge": 71,
        "group": "male",
        "nutrientsPerWeight": {
            "protein": 0.8
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 30,
            "calcium": 1200,
            "choline": 550,
            "chromium": 30,
            "copper": 900,
            "folate": 400,
            "iron": 8,
            "magnesium": 420,
            "manganese": 2.3,
            "niacin": 16,
            "pantothenicAcid": 5,
            "phosphorus": 700,
            "potassium": 3400,
            "riboflavin": 1.3,
            "selenium": 55,
            "sodium": 2300,
            "thiamin": 1.2,
            "vitaminARAE": 900,
            "vitaminB12": 2.4,
            "vitaminB6": 1.7,
            "vitaminC": 90,
            "vitaminD": 20,
            "zinc": 11,
            "vitaminE": 15,
            "vitaminK": 120
        }
    },
    {
        "minAge": 9,
        "maxAge": 14,
        "group": "female",
        "nutrientsPerWeight": {
            "protein": 0.95
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 20,
            "calcium": 1300,
            "choline": 375,
            "chromium": 21,
            "copper": 700,
            "folate": 300,
            "iron": 8,
            "magnesium": 240,
            "manganese": 1.6,
            "niacin": 12,
            "pantothenicAcid": 4,
            "phosphorus": 1250,
            "potassium": 2300,
            "riboflavin": 0.9,
            "selenium": 40,
            "sodium": 2200,
            "thiamin": 0.9,
            "vitaminARAE": 600,
            "vitaminB12": 1.8,
            "vitaminB6": 1,
            "vitaminC": 45,
            "vitaminD": 15,
            "zinc": 8,
            "vitaminE": 11,
            "vitaminK": 60
        }
    },
    {
        "minAge": 14,
        "maxAge": 19,
        "group": "female",
        "nutrientsPerWeight": {
            "protein": 0.85
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 25,
            "calcium": 1300,
            "choline": 400,
            "chromium": 24,
            "copper": 890,
            "folate": 400,
            "iron": 15,
            "magnesium": 360,
            "manganese": 1.6,
            "niacin": 14,
            "pantothenicAcid": 5,
            "phosphorus": 1250,
            "potassium": 2300,
            "riboflavin": 1,
            "selenium": 55,
            "sodium": 2300,
            "thiamin": 1,
            "vitaminARAE": 700,
            "vitaminB12": 2.4,
            "vitaminB6": 1.2,
            "vitaminC": 65,
            "vitaminD": 15,
            "zinc": 9,
            "vitaminE": 15,
            "vitaminK": 75
        }
    },
    {
        "minAge": 19,
        "maxAge": 31,
        "group": "female",
        "nutrientsPerWeight": {
            "protein": 0.8
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 30,
            "calcium": 1000,
            "choline": 425,
            "chromium": 25,
            "copper": 900,
            "folate": 400,
            "iron": 18,
            "magnesium": 310,
            "manganese": 1.8,
            "niacin": 14,
            "pantothenicAcid": 5,
            "phosphorus": 700,
            "potassium": 2600,
            "riboflavin": 1.1,
            "selenium": 55,
            "sodium": 2300,
            "thiamin": 1.1,
            "vitaminARAE": 700,
            "vitaminB12": 2.4,
            "vitaminB6": 1.3,
            "vitaminC": 75,
            "vitaminD": 15,
            "zinc": 8,
            "vitaminE": 15,
            "vitaminK": 90
        }
    },
    {
        "minAge": 31,
        "maxAge": 51,
        "group": "female",
        "nutrientsPerWeight": {
            "protein": 0.8
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 30,
            "calcium": 1000,
            "choline": 425,
            "chromium": 25,
            "copper": 900,
            "folate": 400,
            "iron": 18,
            "magnesium": 320,
            "manganese": 1.8,
            "niacin": 14,
            "pantothenicAcid": 5,
            "phosphorus": 700,
            "potassium": 2600,
            "riboflavin": 1.1,
            "selenium": 55,
            "sodium": 2300,
            "thiamin": 1.1,
            "vitaminARAE": 700,
            "vitaminB12": 2.4,
            "vitaminB6": 1.3,
            "vitaminC": 75,
            "vitaminD": 15,
            "zinc": 8,
            "vitaminE": 15,
            "vitaminK": 90
        }
    },
    {
        "minAge": 51,
        "maxAge": 71,
        "group": "female",
        "nutrientsPerWeight": {
            "protein": 0.8
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 30,
            "calcium": 1200,
            "choline": 425,
            "chromium": 20,
            "copper": 900,
            "folate": 400,
            "iron": 8,
            "magnesium": 320,
            "manganese": 1.8,
            "niacin": 14,
            "pantothenicAcid": 5,
            "phosphorus": 700,
            "potassium": 2600,
            "riboflavin": 1.1,
            "selenium": 55,
            "sodium": 2300,
            "thiamin": 1.1,
            "vitaminARAE": 700,
            "vitaminB12": 2.4,
            "vitaminB6": 1.5,
            "vitaminC": 75,
            "vitaminD": 15,
            "zinc": 8,
            "vitaminE": 15,
            "vitaminK": 90
        }
    },
    {
        "minAge": 71,
        "group": "female",
        "nutrientsPerWeight": {
            "protein": 0.8
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 30,
            "calcium": 1200,
            "choline": 425,
            "chromium": 20,
            "copper": 900,
            "folate": 400,
            "iron": 8,
            "magnesium": 320,
            "manganese": 1.8,
            "niacin": 14,
            "pantothenicAcid": 5,
            "phosphorus": 700,
            "potassium": 2600,
            "riboflavin": 1.1,
            "selenium": 55,
            "sodium": 2300,
            "thiamin": 1.1,
            "vitaminARAE": 700,
            "vitaminB12": 2.4,
            "vitaminB6": 1.5,
            "vitaminC": 75,
            "vitaminD": 20,
            "zinc": 8,
            "vitaminE": 15,
            "vitaminK": 90
        }
    },
    {
        "minAge": 14,
        "maxAge": 19,
        "group": "pregnant",
        "nutrientsPerWeight": {
            "protein": 1.1
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 30,
            "calcium": 1300,
            "choline": 450,
            "chromium": 29,
            "copper": 1000,
            "folate": 600,
            "iron": 27,
            "magnesium": 400,
            "manganese": 2,
            "niacin": 18,
            "pantothenicAcid": 6,
            "phosphorus": 1250,
            "potassium": 2600,
            "riboflavin": 1.4,
            "selenium": 60,
            "sodium": 2300,
            "thiamin": 1.4,
            "vitaminARAE": 750,
            "vitaminB12": 2.6,
            "vitaminB6": 1.9,
            "vitaminC": 80,
            "vitaminD": 15,
            "zinc": 12,
            "vitaminE": 15,
            "vitaminK": 75
        }
    },
    {
        "minAge": 19,
        "maxAge": 31,
        "group": "pregnant",
        "nutrientsPerWeight": {
            "protein": 1.1
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 30,
            "calcium": 1000,
            "choline": 450,
            "chromium": 30,
            "copper": 1000,
            "folate": 600,
            "iron": 27,
            "magnesium": 350,
            "manganese": 2,
            "niacin": 18,
            "pantothenicAcid": 6,
            "phosphorus": 700,
            "potassium": 2900,
            "riboflavin": 1.4,
            "selenium": 60,
            "sodium": 2300,
            "thiamin": 1.4,
            "vitaminARAE": 770,
            "vitaminB12": 2.6,
            "vitaminB6": 1.9,
            "vitaminC": 85,
            "vitaminD": 15,
            "zinc": 11,
            "vitaminE": 15,
            "vitaminK": 90
        }
    },
    {
        "minAge": 31,
        "maxAge": 51,
        "group": "pregnant",
        "nutrientsPerWeight": {
            "protein": 1.1
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 30,
            "calcium": 1000,
            "choline": 450,
            "chromium": 30,
            "copper": 1000,
            "folate": 600,
            "iron": 27,
            "magnesium": 360,
            "manganese": 2,
            "niacin": 18,
            "pantothenicAcid": 6,
            "phosphorus": 700,
            "potassium": 2900,
            "riboflavin": 1.4,
            "selenium": 60,
            "sodium": 2300,
            "thiamin": 1.4,
            "vitaminARAE": 770,
            "vitaminB12": 2.6,
            "vitaminB6": 1.9,
            "vitaminC": 85,
            "vitaminD": 15,
            "zinc": 11,
            "vitaminE": 15,
            "vitaminK": 90
        }
    },
    {
        "minAge": 14,
        "maxAge": 19,
        "group": "lactating",
        "nutrientsPerWeight": {
            "protein": 1.3
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 35,
            "calcium": 1300,
            "choline": 550,
            "chromium": 44,
            "copper": 1300,
            "folate": 500,
            "iron": 10,
            "magnesium": 360,
            "manganese": 2.6,
            "niacin": 17,
            "pantothenicAcid": 7,
            "phosphorus": 1250,
            "potassium": 2500,
            "riboflavin": 1.6,
            "selenium": 70,
            "sodium": 2300,
            "thiamin": 1.4,
            "vitaminARAE": 1200,
            "vitaminB12": 2.8,
            "vitaminB6": 2,
            "vitaminC": 115,
            "vitaminD": 15,
            "zinc": 13,
            "vitaminE": 19,
            "vitaminK": 75
        }
    },
    {
        "minAge": 19,
        "maxAge": 31,
        "group": "lactating",
        "nutrientsPerWeight": {
            "protein": 1.3
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 35,
            "calcium": 1000,
            "choline": 550,
            "chromium": 45,
            "copper": 1300,
            "folate": 500,
            "iron": 9,
            "magnesium": 310,
            "manganese": 2.6,
            "niacin": 17,
            "pantothenicAcid": 7,
            "phosphorus": 700,
            "potassium": 2800,
            "riboflavin": 1.6,
            "selenium": 70,
            "sodium": 2300,
            "thiamin": 1.4,
            "vitaminARAE": 1300,
            "vitaminB12": 2.8,
            "vitaminB6": 2,
            "vitaminC": 120,
            "vitaminD": 15,
            "zinc": 12,
            "vitaminE": 19,
            "vitaminK": 90
        }
    },
    {
        "minAge": 31,
        "maxAge": 50,
        "group": "lactating",
        "nutrientsPerWeight": {
            "protein": 1.3
        },
        "nutrientsPerKcal": {
            "fiber": 14
        },
        "nutrients": {
            "biotin": 35,
            "calcium": 1000,
            "choline": 550,
            "chromium": 45,
            "copper": 1300,
            "folate": 500,
            "iron": 9,
            "magnesium": 320,
            "manganese": 2.6,
            "niacin": 17,
            "pantothenicAcid": 7,
            "phosphorus": 700,
            "potassium": 2800,
            "riboflavin": 1.6,
            "selenium": 70,
            "sodium": 2300,
            "thiamin": 1.4,
            "vitaminARAE": 1300,
            "vitaminB12": 2.8,
            "vitaminB6": 2,
            "vitaminC": 120,
            "vitaminD": 15,
            "zinc": 12,
            "vitaminE": 19,
            "vitaminK": 90
        }
    }
]
