import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { view } from '@risingstack/react-easy-state';
import moment from 'moment';

import "./ShoppingList.scss";

import { shopUtil } from 'storeUtils/shoppingUtils';
import mainStore from 'storeUtils/mainStore';
import layoutStore, { fixCapitalizeName } from 'storeUtils/layoutStore';
import shoppingStore from 'storeUtils/shoppingStore';

import Text from 'components/Text';
import Table from 'components/Table';
import Tooltip from 'components/Tooltip';
import Icon, { FA, icons } from 'components/Icon';
import { addModal } from 'components/ModalWrapper';
import { addTutorial } from 'components/TutorialWrapper';
import Button, { ProgressButton } from 'components/Button';
import { addNotification } from 'components/NotificationWrapper';
import { getInfoProps } from 'components/InfoModal/InfoModal';
import InfoModal from 'components/InfoModal';

import AddShoppingItemModal from 'screens/AddShoppingItemModal';
import ChangeAmountModal from 'screens/ChangeAmountModal';

import getFractionDisplay from 'utils/getFractionDisplay';

import { addNewAdditionalShoppingElement, removeAdditionalShoppingElement, updateAdditionalShoppingElement } from 'api/shopping';
import { onMealSubstituteWarnings } from 'storeUtils/storeUtils';
/**
 * 
 * @typedef ShoppingListProps
 * @type {object}
 * @property {function} updateShopping
 * @property {function} refreshShopping
 * @property {function} prepareResultList
 * @property {bool} static show as static page (for readonly subpage share shopping)
*/

/**
 * @param {ShoppingListProps} props
 */
class RawShoppingList extends Component {

    static debug = false;

    constructor(props) {
        super(props)

        layoutStore.pageCaption = 'Shopping';
        layoutStore.routerBlockedModalFunction = (tx, callback) => {
            addModal(InfoModal, {
                title: 'Are you sure?',
                text: 'You are leaving the page without saving changes.',
                confirm: 'continue',
                cancel: 'cancel',
                closeOnBg: true
            },
                (data) => {
                    if (data) {
                        layoutStore.routerBlocked = false;
                        tx?.retry();
                        if (callback) {
                            callback();
                        }
                    }
                });
        }
    }

    componentDidMount() {
        layoutStore.request.progress = false
        this.ref = createRef();
    }

    componentDidUpdate() {
        if (shoppingStore.selectedRange?.id && shoppingStore.preparedKeyMap[shoppingStore.selectedRange?.id]) {
            const rangeId = shoppingStore.selectedRange.id;
            const rangeData = shoppingStore.preparedKeyMap[rangeId];

            const isDirty = Object.values(rangeData?.dictionary ?? {}).some(x => shopUtil.getIsDirty(x));

            if (isDirty && !this.isReadonly()) {
                layoutStore.routerBlocked = true;

            } else {
                layoutStore.routerBlocked = false;
            }
        }
    }

    componentWillUnmount() {
        layoutStore.routerBlockedModalFunction = undefined;
        layoutStore.routerBlocked = false;
    }


    /* ======================================== HELPERS ======================================== */

    updateAdditionalItem = async (shopping_additional_id, item) => {
        return await updateAdditionalShoppingElement(
            mainStore.profile.id,
            shopping_additional_id,
            shoppingStore.selectedRange.id,
            item.amount,
            item.unit,
            item.portion_type_id
        );
    }

    isReadonly = () => shoppingStore.selectedRange && shoppingStore.selectedRange.done || shoppingStore.selectedRange.obsolete || this.isStatic()

    isObsolete() {
        return (shoppingStore.selectedRange && shoppingStore.selectedRange.obsolete)
    }

    isInCurrentWeek() {
        if (mainStore.weeklyData && mainStore.weeklyData.length === 0) return false;

        const menufrom = mainStore.weeklyData[0];
        const menuTo = mainStore.weeklyData[mainStore.weeklyData.length - 1];
        const { from, to } = shoppingStore.selectedRange;

        return moment(from).isSameOrAfter(menufrom.date) && moment(to).isSameOrBefore(menuTo.date) ||
            moment(menufrom.date).isBetween(from, to) || moment(menuTo.date).isBetween(from, to);
    }

    isBeforeCurrentWeek() {
        // if (mainStore.weeklyData && mainStore.weeklyData.length === 0) return false;

        // const menufrom = mainStore.weeklyData[0];
        const { to } = shoppingStore.selectedRange;
        return moment(to).isBefore(moment().format('YYYY-MM-DD'))
    }

    isStatic = () => this.props.static;

    /* ======================================== EVENT HANDLERS ======================================== */

    onBeforeAddAdditional = (item, assembledItem, closeAction) => {
        if (!assembledItem) { // when item is custom item
            return this.onAddAdditionalItem(item)
        }

        onMealSubstituteWarnings(assembledItem,
            mainStore.profile.id,
            (modalResult) => {
                let keepOriginal = true;
                if (modalResult) {
                    keepOriginal = modalResult.keepOriginal;
                }

                this.onAddAdditionalItem(item, assembledItem, keepOriginal)

                if (closeAction) {
                    closeAction();
                }
            }
        )
    }

    onAddAdditionalItem = async (item, assembledItem, keepOriginal) => {
        if (shoppingStore.additionalLoading || !item) return false;
        shoppingStore.additionalLoading = true;

        if (shoppingStore.unsavedChanges > 0) {
            await this.props.updateShopping()
        }

        const result = await addNewAdditionalShoppingElement({
            name: item.name,
            amount: +item.amount,
            portion_count: assembledItem?.portion_count ? assembledItem?.portion_count : 0,
            portion_type_id: item.portion_type_id,
            unit: item.unit,
            shopping_id: shoppingStore.selectedRange.id,
            food_id: item.food_id,
            recipe_id: item.recipe_id,
            person_id: mainStore.profile.id,
            assembledItem: assembledItem,
            keepOriginal: keepOriginal
        })

        if (result && result?.request?.success) {
            if (result.request.message) {
                addNotification(result.request.message, { timeout: 7000 })
            }
            if (result.shopping) {
                this.props.prepareResultList(result.shopping)
            }
        }

        shoppingStore.additionalLoading = false;
    }

    onUpdateAdditionalItem = async (item, extra) => {
        if (shoppingStore.additionalLoading || !item || !extra) return false;
        shoppingStore.additionalLoading = true;

        if (shoppingStore.unsavedChanges > 0) {
            await this.props.updateShopping()
        }
        const shopping_additional_id = extra?.id ? extra?.id : undefined;
        const result = await this.updateAdditionalItem(shopping_additional_id, item);

        if (result && result?.request?.success) {
            if (result.request.message) {
                addNotification(result.request.message, { timeout: 7000 })
            }

            if (result.shopping) {
                this.props.prepareResultList(result.shopping)
            }
        }

        shoppingStore.additionalLoading = false;
    }

    onRemoveAdditionalItem = async (item) => {
        if (shoppingStore.additionalLoading || !item) return false;
        shoppingStore.additionalLoading = true;

        if (shoppingStore.unsavedChanges > 0) {
            await this.props.updateShopping()
        }
        const result = await removeAdditionalShoppingElement({
            id: item.id,
            shopping_id: shoppingStore.selectedRange.id
        });
        if (result && result?.request?.success) {
            if (result.request.message) {
                addNotification(result.request.message, { timeout: 7000 })
            }
            if (result.shopping) {
                this.props.prepareResultList(result.shopping)
            }
        }

        shoppingStore.additionalLoading = false;
    }


    /* ======================================== GROUPED RESULT OPERATIONS ======================================== */

    onEditElement = (displayGroup) => {
        addModal(ChangeAmountModal, { displayGroup }, () => { });
    }

    onDeleteElement = (displayGroup) => {
        shopUtil.setDeleted(displayGroup, true);
    }

    onRestoreElement = (displayGroup) => {
        shopUtil.setDeleted(displayGroup, false);
    }

    onAmountChange = (displayGroup, value) => {
        shopUtil.setAmount(displayGroup, value);
    }

    onItemSelected = (displayGroup) => {
        shopUtil.toggleDontBuy(displayGroup);
    }

    isMenuRequiresInfoNeeded(displayGroup) {
        const sumNoOffset = shopUtil.getMenuSum(displayGroup);
        const sumOffset = shopUtil.getSum(displayGroup);
        if (!sumNoOffset) return false;

        const origPort = shopUtil.getOriginalPortionTypeId(displayGroup)
        return sumOffset < (sumNoOffset - 0.15) || (origPort && shopUtil.getPortionTypeId(displayGroup) !== origPort);
    }

    /* ======================================== TABLE COLUMNS ======================================== */

    additionalColsDef = [{
        caption: (section) => <div className='shopping-header additional'>
            <Text bold>{section.name}</Text>
            {section.type === 2 && !section.done ? <div className="add-item"
                onClick={() => {
                    if (shoppingStore.additionalLoading || this.isReadonly() || this.isStatic()) return false;
                    addModal(AddShoppingItemModal, { saveAction: this.onBeforeAddAdditional });
                }}
            >
                {!this.isReadonly() ? <span>+ Add<span className="hide-md"> Item</span></span> : ''}
            </div> : ''}
        </div>,
        get: (row) => {
            if (row.id === -1) {
                return <Text block fullWidth textCenter>{this.isStatic() ? 'No data' : 'Click to add items.'}</Text>
            }
            try {
                const rangeId = shoppingStore.selectedRange.id;
                const rangeData = shoppingStore.preparedKeyMap[rangeId];

                const brand = shopUtil.getBrand([row], rangeData.foods);
                const recipe_site_name = shopUtil.getRecipeSiteName([row], rangeData.recipes);
                const recipe_site_url = shopUtil.getRecipeSiteUrl([row], rangeData.recipes);
                const portionType = shopUtil.getPortionName([row], mainStore.portionTypes);

                return (<div className={`selected-cols selected-additional`}>
                    <Text className="text name" block textLeft bold>
                        {this.renderIcon(row)}
                        <Text>{fixCapitalizeName(row.name)}</Text>
                    </Text>

                    <Text className="text brand" block textLeft>
                        {this.renderSource(row, brand, recipe_site_name, recipe_site_url)}
                    </Text>

                    <Text larger block textCenter className="fraction">
                        {getFractionDisplay(row.amount, false)}
                    </Text>

                    <Text block textLeft
                        className={'text portion ' +
                            (portionType && !shopUtil.isPortionType([row]) ? 'not-real-portion-type' : '')
                        }
                    >
                        {portionType ? portionType : <span className="faded no-print"></span>}
                    </Text>

                    {!this.isReadonly() ? <div className="shopping-row-buttons no-print">
                        <div>
                            <React.Fragment>
                                <Text className="trash-icon no-print" inlineBlock onClick={(e) => {
                                    if (this.isReadonly()) return false;

                                    addModal(AddShoppingItemModal, {
                                        item: {
                                            ...row,
                                            brand: brand
                                        },
                                        readonly: true,
                                        rangeData: rangeData,
                                        type: 'update',
                                        saveAction: (item, assembled, closeAction) => {
                                            if (this.isStatic() || this.isReadonly()) return false;

                                            this.onUpdateAdditionalItem(item, { id: row.id });

                                            if (closeAction) {
                                                closeAction();
                                            }
                                        }
                                    });

                                    e.stopPropagation();
                                }}>
                                    <FA icon="fad fa-pencil" className="medium" />
                                </Text>
                                <Text className="trash-icon no-print" inlineBlock onClick={(e) => {
                                    if (shoppingStore.additionalLoading || this.isReadonly()) return false;

                                    addModal(InfoModal, { title: 'Are you sure?', text: 'Your added shopping item will be removed.', confirm: 'continue', cancel: 'cancel', closeOnBg: true }
                                        , (data) => {
                                            if (this.isReadonly() || this.isStatic() || !data) return false;

                                            this.onRemoveAdditionalItem(row)
                                        });
                                    e.stopPropagation();
                                }}>
                                    <FA icon="fad fa-trash" className="medium" />
                                </Text>
                            </React.Fragment>
                        </div>
                    </div> : ''}
                </div>);
            } catch (error) {
                return <div className="py-1">
                    <span className="px-1 text-red">Corrupted entry - please remove</span>
                    <Text className="trash-icon no-print  text-right" inlineBlock onClick={(e) => {
                        addModal(InfoModal, { title: 'Are you sure?', text: 'Your added shopping item will be removed.', confirm: 'continue', cancel: 'cancel', closeOnBg: true }
                            , (data) => {
                                if (this.isReadonly() || this.isStatic() || !data) return false;

                                this.onRemoveAdditionalItem(row)
                            });
                        e.stopPropagation();
                    }}>
                        <FA icon="fa fa-trash" className="medium" />
                    </Text></div>
            }

        },
        getClass: () => 'list-no-padding',
        onClick: (e) => {
            if (this.isReadonly() || shoppingStore.additionalLoading) return;

            const index = e.currentTarget.dataset.index;

            const rangeId = shoppingStore.selectedRange.id;
            const rangeData = shoppingStore.preparedKeyMap[rangeId]

            if (rangeData.additional.length > 0) {
                return false;
            }

            if (rangeData && rangeData.additional && index) {
                const item = rangeData.additional[index]
                const brand = shopUtil.getBrand([item], rangeData.foods);
                const recipe_site_name = shopUtil.getRecipeSiteName([item], rangeData.recipes);

                if (item) {
                    const extra = { id: item.id };
                    addModal(AddShoppingItemModal, {
                        item: {
                            ...item,
                            brand: brand,
                            recipe_site_name: recipe_site_name
                        },
                        readonly: true,
                        type: 'update',
                        saveAction: (item, assembled, closeAction) => {
                            this.onUpdateAdditionalItem(item, extra)

                            if (closeAction) {
                                closeAction();
                            }
                        }
                    }, () => { });
                } else {
                    addModal(AddShoppingItemModal, {
                        saveAction: (item, assembled, closeAction) => {
                            this.onBeforeAddAdditional(item, assembled, closeAction)

                            if (closeAction) {
                                closeAction();
                            }
                        }
                    });
                }
            }
        }
    }];

    getRowClass = (group) => {
        const dirty = shopUtil.getIsDirty(group);
        const deleted = shopUtil.getIsDeleted(group);
        const dont_buy = shopUtil.getDontBuy(group);
        return `${dirty ? 'is-dirty ' : ''}${(dont_buy && !deleted) ? ' dont-buy' : ''}${deleted ? ' deleted' : ''}`
    }

    tableColsDef = [
        {
            caption: (section) => {
                return <div className={'shopping-header selected ' + (section.deleted ? 'deleted ' : '')}>
                    <Text bold> {section.name}</Text>
                </div>
            },
            get: (group) => {
                try {
                    const rangeId = shoppingStore.selectedRange.id;
                    const rangeData = shoppingStore.preparedKeyMap[rangeId];
                    const dont_buy = shopUtil.getDontBuy(group);
                    const deleted = shopUtil.getIsDeleted(group);
                    const portionType = shopUtil.getPortionName(group, mainStore.portionTypes)
                    const origPortionType = shopUtil.getOriginalPortionName(group, mainStore.portionTypes)
                    const brand = shopUtil.getBrand(group, rangeData.foods)
                    const name = shopUtil.getName(group, rangeData.foods)
                    if (group.filter(x => x.is_offset).length > 1) {
                        throw 'offset overflow';
                    }

                    return <div key={shopUtil.getKey(group)} className={`selected-cols ${this.getRowClass(group)}`} >
                        {deleted ?
                            <div className="select-log posr deleted">
                                <FA icon="fa fa-times" />
                            </div>
                            :
                            <div className={"select-log posr " + (this.isReadonly() ? '' : 'clickable ')}
                                onClick={() => { if (!deleted && !this.isStatic() && !this.isReadonly()) this.onItemSelected(group) }}>
                                <span>
                                    {dont_buy ? <Icon icon={icons.checkboxSelected} /> :
                                        <Icon green icon={icons.checkboxEmpty} />}
                                </span>
                            </div>
                        }

                        <Text className="text name" block textLeft bold>
                            {fixCapitalizeName(name)}
                        </Text>

                        <Text className="text brand" block textLeft>
                            {brand ? <i className="ingredient-brand-name">{brand}</i> : ''}
                        </Text>

                        <Text className="text fraction" block larger textCenter>
                            {getFractionDisplay(shopUtil.getSum(group), false)}
                        </Text>

                        <Text block textLeft className="text only-print">amount</Text>

                        <Text className={'text portion ' +
                            (portionType && !shopUtil.isPortionType(group) ? 'not-real-portion-type' : '')}
                            block textLeft
                        >
                            {portionType ? portionType : <span className="faded no-print"></span>}
                        </Text>

                        <div className="shopping-row-buttons">
                            {!this.isReadonly() ? <div>
                                {deleted ?
                                    <Text className="restore no-print" inlineBlock onClick={() => { if (!this.isStatic() && !this.isReadonly()) this.onRestoreElement(group) }}>
                                        <Tooltip text="Add back">
                                            <FA icon="fad fa-trash-restore" className="medium" />
                                        </Tooltip>
                                    </Text>
                                    :
                                    <React.Fragment>
                                        <Text className="trash-icon no-print" inlineBlock onClick={() => { if (!this.isStatic() && !this.isReadonly()) this.onEditElement(group) }}>
                                            <FA icon="fad fa-pencil" className="medium" />
                                        </Text>
                                        <Text className="trash-icon no-print" inlineBlock onClick={() => { if (!this.isStatic() && !this.isReadonly()) this.onDeleteElement(group) }}>
                                            <FA icon="fad fa-trash" className="medium" />
                                        </Text>
                                    </React.Fragment>
                                }
                            </div> : <div></div>}


                        </div>

                        {this.isMenuRequiresInfoNeeded(group) ? <Text className="menu-requires-text">Your menu requires {getFractionDisplay(shopUtil.getMenuSum(group))} {origPortionType}</Text> : ''}
                    </div>
                }
                catch (e) {
                    console.error(e);
                    return <div className="py-1">
                        <span className="text-red px-1">Corrupted entry - please recreate your shopping list</span>
                        <Text className="trash-icon no-print" inlineBlock onClick={() => {
                            addModal(InfoModal, getInfoProps("Error info", <Text breakWord>{JSON.stringify(group)}</Text>))
                        }}>
                            <FA icon="fa fa-info" className="small" />
                        </Text>
                    </div>
                }
            },
            getClass: () => 'list-no-padding',
            onClick: () => { }
        },
    ]

    defaultRowAdditionalShopping = { id: -1 };

    getSelectedRange() {
        const rangeId = shoppingStore.selectedRange.id
        if (!rangeId || !shoppingStore.preparedKeyMap[rangeId]) {
            return false;
        }

        const list = shopUtil.getPopulatedCategories(shoppingStore.preparedKeyMap[rangeId], shoppingStore.shoppingCategories);

        // when list is empty array
        if (Array.isArray(list) && list.length === 0) {
            return false
        }

        // when list is object and no items in it
        if (!Array.isArray(list) && (!list.items || list.items.length === 0)) {
            return false
        }

        return list;
    }

    getAdditionalSection() {
        const rangeId = shoppingStore.selectedRange.id
        if (!rangeId) {
            return [];
        }

        let items = [];
        const rangeData = shoppingStore.preparedKeyMap[rangeId]

        if (rangeData) {
            items = [...rangeData.additional].sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
        }

        const additional = {
            type: 2,
            name: 'My Added Items',
            items: items
        }

        if (!additional.items || additional.items.length === 0) {
            additional.items = [this.defaultRowAdditionalShopping];
        }

        return [additional];
    }

    /* ======================================== RENDERS ======================================== */

    renderLoader() {
        return <div className="shopping-loading-list">
            <FA icon="fas fa-sync fa-spin" />
            <Text large block fullWidth textCenter>Loading</Text>
        </div>
    }

    renderIcon(row) {
        const isRTE = row.food_id && !row.recipe_id;
        const isRecipe = !row.food_id && row.recipe_id;

        if (isRTE) {
            return <Icon icon={icons.rteIcon} className='rte-icon' />;
        } else if (isRecipe) {
            return <Icon icon={icons.recipe} className='recipe-icon' />
        }
        return <Text large className='custom-icon'></Text>
    }

    renderSource(row, brand, recipe_site_name, recipe_site_url) {
        const isRTE = row.food_id && !row.recipe_id;
        const isRecipe = !row.food_id && row.recipe_id;

        if (isRTE) {
            return <i className="ingredient-brand-name">{brand}</i>
        }
        if (isRecipe) {
            if (recipe_site_url) {
                return <a href={recipe_site_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pl-1 ingredient-site-name">
                    {recipe_site_name}
                </a>
            }
            return <i className="ingredient-site-name">{recipe_site_name}</i>
        }
        return '';
    }

    renderSaveChangesButton(isDirty) {
        if (isDirty && !this.isReadonly()) {
            return <div className="progress-save ">
                <ProgressButton
                    inProgress={layoutStore.request && layoutStore.request.progress}
                    className="ml-auto" extraProps={{ right: true }} onClick={() => {
                        if (shoppingStore.additionalLoading) return false;

                        if (this.props.updateShopping) {
                            this.props.updateShopping()
                        }
                    }}>
                    <span>Save Changes</span> <FA icon="fal fa-save" />
                </ProgressButton>
            </div>
        }
        return '';
    }

    renderRefreshInfo() {
        if (this.isBeforeCurrentWeek()) {
            return <div className="refresh generate mt-3">
                <Text inlineBlock large>Your shopping list is outdated</Text>
                {!this.isStatic() ? <ProgressButton
                    className="wider"
                    inProgress={layoutStore.request && layoutStore.request.progress}
                    onClick={() => {
                        if (this.props.setCurrentMenuShoppingList) {
                            this.props.setCurrentMenuShoppingList()
                        }
                    }}>
                    <span>Generate this week&apos;s shopping list</span> <FA icon="fas fa-sync" />
                </ProgressButton> : ''}
            </div>
        }

        if (this.isObsolete()) {
            return <div className="refresh mt-3">
                <Text inlineBlock large>You made changes to your My Menu</Text>
                {!this.isStatic() ? <ProgressButton
                    inProgress={layoutStore.request && layoutStore.request.progress}
                    onClick={() => {
                        if (this.props.refreshShopping) {
                            this.props.refreshShopping()
                        }
                    }}>
                    <span>Refresh</span> <FA icon="fas fa-sync" />
                </ProgressButton> : ''}
            </div>
        }
    }

    render() {
        if (shoppingStore.loading) {
            return this.renderLoader()
        }

        try {
            const selectedRange = this.getSelectedRange();
            const rangeId = shoppingStore.selectedRange.id;

            const fromDateString = shoppingStore.selectedRange.from ? moment(shoppingStore.selectedRange.from).format("ddd M/DD") : '';
            const toDateString = shoppingStore.selectedRange.to ? moment(shoppingStore.selectedRange.to).format("ddd M/DD") : '';

            const rangeData = shoppingStore.preparedKeyMap[rangeId];
            const isDirty = Object.values(rangeData?.dictionary ?? {}).some(x => shopUtil.getIsDirty(x));

            return <div className={'shopping-list-grid ' +
                (this.isStatic() ? 'static ' : '') +
                (this.isObsolete() ? 'obsolete ' : '')
            }>
                {this.renderRefreshInfo()}
                {this.renderSaveChangesButton(isDirty)}

                {rangeId ? <div className={
                    'shopping-list-buttons ' +
                    (this.isObsolete() ? 'readonly ' : '')
                }>
                    {!this.isStatic() ? <div className="left-side">
                        <Button onClick={() => {
                            if (shoppingStore.additionalLoading) return false;

                            addModal(InfoModal, {
                                title: 'Sharing Shopping List', text: <div>
                                    <Text>
                                        Your shopping list shared link:
                                        <br />
                                        <br />
                                        <Text underline className="word-break-all">
                                            <a href={`/s/${shoppingStore.selectedRange.permalink}`} target="_blank" rel="noopener noreferrer">
                                                {window.location.origin}/s/{shoppingStore.selectedRange.permalink}
                                            </a>
                                        </Text>
                                    </Text>
                                </div>, closeOnBg: true
                            })
                        }} block noShadow className="share-button">Share</Button>

                        <div ref={this.ref}>
                            <Button onClick={() => {
                                if (!shoppingStore.activeInfo) {
                                    shoppingStore.activeInfo = true;
                                    addTutorial(
                                        this.ref,
                                        {
                                            timeout: 2000,
                                            text: <span>LINK TO GROCERS COMING SOON <span className="exclamation-mark">!</span></span>,
                                            className: 'no-bg with-shadow small',
                                            showOnly: true,
                                        })
                                    setTimeout(() => {
                                        shoppingStore.activeInfo = false;
                                    }, 2000);
                                }

                            }} block noShadow blue nolrpadding className="cart-button">
                                <FA icon='fa fa-shopping-cart' />
                            </Button>
                        </div>
                    </div> : ''}

                    <Button block noShadow blue onClick={() => window.print()}>Print List</Button>
                </div> : ''
                }

                <div className="shopping-table-extra-space">
                    <Table
                        className={'shopping-table ' + (this.isReadonly() ? 'readonly' : '')}
                        sections={this.getAdditionalSection()}
                        cols={this.additionalColsDef}
                        sectionAccessor={(section) => section.items}
                        addMore={true}
                    />
                </div>

                {selectedRange && rangeId ? <div className="shopping-table-container pt-2">
                    <div className={"shopping-header shopping " + (this.isReadonly() ? 'readonly' : '')}>
                        <Text inlineBlock fullWidth textCenter large bold>
                            <span>Shopping List</span>
                            <Text inlineBlock className="pl-1 date">{fromDateString !== toDateString ?
                                `(${fromDateString} to ${toDateString})` : `(${fromDateString})`}
                            </Text>
                        </Text>

                    </div>
                    <div className="shopping-table-extra-space">
                        <Table
                            className={'shopping-table ' + (this.isReadonly() ? 'readonly' : '')}
                            sections={selectedRange}
                            cols={this.tableColsDef}
                            sectionAccessor={(section) => section.items}
                            addMore={true}
                        />
                    </div>
                </div> : ''}

                {!selectedRange && rangeId && !this.isObsolete() ?
                    <div className="shopping-no-data">
                        <div className="shopping-header"><Text bold>Shopping List</Text></div>
                        <Text className="shopping-no-data-text" block normal fullWidth inlineBlock textCenter>No data</Text>
                    </div> : ''}

                {this.renderSaveChangesButton(isDirty)}
            </div>
        } catch (error) {
            console.error(error);
            return <Text header inlineBlock fullWidth textCenter className='pt-3 pb-2 pl-2 pr-2'>Shopping list is corrupted. Please create a new shopping list.</Text>
        }

    }
}

RawShoppingList.propTypes = {
    updateShopping: PropTypes.func.isRequired,
    setCurrentMenuShoppingList: PropTypes.func.isRequired,
    refreshShopping: PropTypes.func.isRequired,
    prepareResultList: PropTypes.func.isRequired,
    static: PropTypes.bool
};


/**
 * Render table with shopping list
 * 
 * @component
 * @type {{ new(props: ShoppingListProps): {
 *   props: { 
 *     updateShopping: function, 
 *     refreshShopping: function,
 *     prepareResultList: function,
 *     static: boolean
 *      }
 *  }
 * }}
 */
const ShoppingList = view(RawShoppingList)

export default ShoppingList
