import React from 'react';
import PropTypes from 'prop-types';

import './Notification.scss';

import Icon, { icons } from 'components/Icon';
import Text from 'components/Text';
import { view } from '@risingstack/react-easy-state';

/**
 * @typedef NotificationProps
 * @type {object}
 * @property {number} key
 * @property {object} notification
 * @property {function} closeFunction
*/

/**
 * @param {NotificationProps} props
 */
const RawNotification = props => {
    const options = props.notification?.options ? props.notification.options : {};
    const text = props.notification?.text ? props.notification.text : '';

    if (options.timeout && !isNaN(options.timeout)) {
        setTimeout(() => {
            props.closeFunction()
        }, options.timeout);
    }

    return (
        <div key={props.index} className={
            'notification ' +
            (options.hiddenClose ? 'hidden-close ' : '') +
            (props.className ? props.className : '')
        }>
            <Text className="text">{text}</Text>
            <Text textCenter>
                <Icon icon={icons.closeCircle} className="clickable" onClick={() => props.closeFunction()} />
            </Text>
        </div>
    )
};

RawNotification.propTypes = {
    index: PropTypes.number,
    notification: PropTypes.object.isRequired,
    closeFunction: PropTypes.func.isRequired,
    className: PropTypes.string,
};

/**
 * ui component that show notification message in upper right corner as absolute position element 
 * 
 * @component
 * @type {{ new(props: NotificationProps): {
 *   props: { 
 *     index: number, 
 *     notification: {
 *        text: string,
 *        timeout: number
 *     },
 *     closeFunction: Function,
 *    }
 *  }
 * }}
 */
const Notification = view(RawNotification);

export default Notification