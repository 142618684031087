import React, { Component } from 'react';
import './Table.scss'
import PropTypes from 'prop-types';
import { Icon } from 'components/Icon';
import Button from 'components/Button';
import 'components/Mono/Mono.scss';
import { view } from '@risingstack/react-easy-state';
import { NavLink } from 'react-router-dom';

function getAlignClass(align) {
    if (align === 'left') {
        return 'text-left';
    }
    if (align === 'right') {
        return 'text-right';
    }
    if (align === 'center') {
        return 'text-center';
    }
    return '';
}

function getCaptionClass(captionClass) {
    return captionClass ? ' ' + captionClass : '';
}

function getMaximizeClass(maximize) {
    return maximize ? ' table-column-maximize' : '';
}

function getMonoClass(mono) {
    return mono ? ' font-monospace' : '';
}


/**
 * @typedef TableProps
 * @type {object}
 * @property {array} cols 
 * @property {array} sections 
 * @property {array} data 
 * @property {object} selected 
 * @property {*} selectedID   
 * @property {string} editRowClick 
 * @property {function} newRowClick  
 * @property {function} onClick  
 * @property {function} idAccess 
 * @property {*} eventIndexPrefix 
 * @property {boolean} edit 
 * @property {function} sectionAccessor  
 * @property {boolean} loadMore 
 * @property {boolean} addmore  
 * @property {number} limit    
 * @property {string} className    
 * @property {string} noDataMessage    
*/

class TableRaw extends Component {

    /**
     * @param {TableProps} props 
     */
    constructor(props) {
        super(props);
        let colID = 0;
        for (let i = 0; i < props.cols.length; i++) {
            if (props.cols[i].id) {
                colID = i;
                break;
            }
        }

        this.state = {
            limitSteps: 0,
            colID: colID
        }
    }

    rowClicked = (event) => {
        const index = event.target.dataset.index;
        const data = this.props.data ? this.props.data : [];
        const row = data[index];
        if ((this.props.selected && row === this.props.selected) ||
            (this.props.selectedID && (typeof (row) === 'object') && this.props.selectedID === row.id)
        ) return;
        if (this.props.onClick) {
            this.props.onClick(row, index);
        }
    }

    colClicked(/*colHeader, index*/) {
        // console.log('col-clicked')
    }

    newRowClick = () => {
        if (this.props.newRowClick) {
            this.props.newRowClick();
        }
    }

    editRowClick = (row) => {
        if (this.props.editRowClick) {
            this.props.editRowClick(row);
        }
    }

    getCaption(caption, colHeader, index) {
        if (typeof (caption) === 'function') {
            return caption(colHeader, index);
        } else {
            return caption;
        }
    }

    getRow(row, index, sectionIndex = 0, section = null) {
        const eventIndexPrefix = this.props.eventIndexPrefix ? this.props.eventIndexPrefix : '';
        return <tr
            key={this.props.idAccess ? this.props.idAccess(row, index) : index}
            onClick={this.rowClicked}
            data-index={index}
            className={
                (this.props.onClick ? 'clickable ' : ' ') +
                ((this.props.selected === row || (this.props.selectedID && (typeof (row) === 'object') && this.props.selectedID === row.id)) ? ' selected' : '')}>
            {this.props.cols.map((colHeader, colIndex) => (
                colHeader.linkTo ?
                    <td key={colIndex} className={'linked ' + getAlignClass(colHeader.align) + getMonoClass(colHeader.mono) + (colHeader.getClass ? ' ' + colHeader.getClass(row, index) : '')}>
                        <NavLink to={colHeader.linkTo(row)}>
                            {colHeader.get(row, index, section)}
                        </NavLink>
                    </td>
                    :
                    <td
                        key={colIndex}
                        onClick={colHeader.onClick}
                        data-section={sectionIndex}
                        data-index={eventIndexPrefix + index}
                        data-colindex={colIndex}
                        className={getAlignClass(colHeader.align) + getMonoClass(colHeader.mono) + (colHeader.getClass ? ' ' + colHeader.getClass(row, index) : '')}>
                        {colHeader.get(row, index, section)}
                    </td>
            ))}
            {this.props.edit && <td className="fill">
                <Button small red fill onClick={() => this.editRowClick(row)}><Icon icon="fal fa-pen" /></Button>
            </td>}
        </tr>
    }

    getData(section) {
        if (this.props.sectionAccessor) {
            return this.props.sectionAccessor(section);
        }
        return section;
    }

    render() {
        const props = this.props;
        let data = props.data ? props.data : [];
        let loadMore = false;
        if (props.loadMore) {
            if (data.length > props.limit * (this.state.limitSteps + 1)) {
                loadMore = true;
            }
        }
        if (props.limit) {
            data = data.slice(0, props.limit * (this.state.limitSteps + 1));
        }
        return (
            <table className={"table " +
                (props.condensed ? 'condensed ' : ' ') +
                (props.className ? props.className : '')
            } cellPadding="0" cellSpacing="0">
                {this.props.sections
                    ? this.props.sections.map((section, sectionindex) => {
                        const data = this.getData(section);
                        return [<thead key={'h' + sectionindex}>
                            <tr>
                                {props.cols.map((colHeader, index) => (
                                    <th
                                        key={index}
                                        onClick={() => this.colClicked(colHeader, index, section)}
                                        className={getAlignClass(colHeader.align) + getMaximizeClass(colHeader.maximize) + getMonoClass(colHeader.mono) + getCaptionClass(colHeader.captionClass)}>
                                        {this.getCaption(colHeader.caption, section, sectionindex)}
                                    </th>
                                ))}
                            </tr>
                        </thead>,
                        <tbody key={'b' + sectionindex}>
                            {(data.length > 0) && data.map((row, index) => this.getRow(row, index, sectionindex, section))}
                            {(data.length === 0) && (
                                <tr>
                                    <td colSpan={props.cols.length} className="no-data-message">
                                        {props.noDataMessage ? props.noDataMessage : 'No data.'}
                                    </td>
                                </tr>
                            )}
                        </tbody>]
                    })
                    : ([<thead key="tablehead">
                        <tr>
                            {props.cols.map((colHeader, index) => (
                                <th key={index} onClick={() => this.colClicked(colHeader, index)} className={getAlignClass(colHeader.align) + getMaximizeClass(colHeader.maximize) + getMonoClass(colHeader.mono) + getCaptionClass(colHeader.captionClass)}>{this.getCaption(colHeader.caption, data, index)}</th>
                            ))}
                            {props.edit && <th />}
                        </tr>
                    </thead>,
                    <tbody key="tablebody">
                        {(data.length > 0) && data.map((row, index) => this.getRow(row, index))}
                        {(data.length === 0) && (
                            <tr>
                                <td colSpan={props.cols.length} className="no-data-message">
                                    {props.noDataMessage ? props.noDataMessage : 'No data.'}
                                </td>
                            </tr>
                        )}
                        {props.edit && (
                            <tr>
                                <td className="fill" colSpan={props.cols.length}><Button fill small red block onClick={this.newRowClick}><Icon icon="fa fa-plus" /></Button></td>
                                <td className="fill"><Button small block><Icon fill icon="fa" /></Button></td>
                            </tr>
                        )}
                        {props.addmore && (
                            <tr>
                                <td className="fill" colSpan={props.cols.length}><Button fill small red block onClick={this.newRowClick}><Icon icon="fa fa-plus" /></Button></td>
                            </tr>
                        )}
                        {loadMore && (
                            <tr>
                                <td className="fill" colSpan={props.cols.length}><Button red block onClick={() => this.setState({ limitSteps: this.state.limitSteps + 1 })}><Icon icon="fa fa-plus" />Load More</Button></td>
                            </tr>
                        )}
                    </tbody>])
                }

            </table>
        )
    }
}

TableRaw.propTypes = {
    cols: PropTypes.array, // {align, maximize?, mono?, captionClass, caption, getClass(), linkTo?, get()}
    sections: PropTypes.array, // sectionAccessor => getData() => getRow()
    data: PropTypes.array, // [row {id, ...} ] 
    selected: PropTypes.object,
    selectedID: PropTypes.any,
    editRowClick: PropTypes.func,
    newRowClick: PropTypes.func,
    onClick: PropTypes.func,
    idAccess: PropTypes.func,
    eventIndexPrefix: PropTypes.any,
    edit: PropTypes.bool,
    sectionAccessor: PropTypes.func,
    loadMore: PropTypes.bool,
    addmore: PropTypes.bool,
    condensed: PropTypes.bool,
    limit: PropTypes.number,
    className: PropTypes.string,
    noDataMessage: PropTypes.string,
}


/**
 * @component
 * @param {TableProps} props
 * @type {{ new(props: TableProps): {props: TableProps} }}
 */
const Table = view(TableRaw);

export default Table;