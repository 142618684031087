
import moment from "moment";

import { isRTE } from "../../utils/mealUtils.js";
import { addEnum, isDateCyclicType } from "../../utils/weekGridUtils.js";

export const actionEnum = {
    ADD: 'add',
    ADD_PERSON_MEAL: 'add-person-meal',
    ADD_PERSON_MEAL_MY_MENU: 'add-person-meal-my-menu',
    DELETE: 'delete',
    DELETE_PERSON_MEAL: 'delete-person-meal',
    DELETE_PERSON_MEAL_MY_MENU: 'delete-person-meal-my-menu'
}

export const commitTypeEnum = {
    FOR_MY_MENU: 1,
    FOR_PATTERN_WEEK: 2
}

export class PatternChangesApi {
    patternWeekSnapshot = undefined;
    __patternWeekSnapshot = undefined;

    changes = [];
    deletedMap = {};
    counter = 0;

    constructor() { }

    //change TYPES = 'add' 'add-person-meal' 'delete' 'delete-person-meal'
    init(pattern_week) {
        this.reset(pattern_week);
    }

    discardChanges() {
        this.reset(this.__patternWeekSnapshot);
    }

    reset(pattern_week) {
        this.patternWeekSnapshot = pattern_week;
        this.__patternWeekSnapshot = JSON.parse(JSON.stringify(pattern_week))
        this.changes = [];
        this.deletedMap = {};
        this.counter = 0;
    }

    /* ======================================== UTILS ======================================== */

    getNextId() {
        this.counter -= 1;
        return this.counter;
    }

    addOverrideToDeletedMap(override_id) {
        if (!this.deletedMap[override_id]) {
            this.deletedMap[override_id] = [];
        }
    }

    addPersonMealToDeletedMap(person_meal_id, override_id) {
        if (this.deletedMap[override_id]) {
            this.deletedMap[override_id].push(person_meal_id)
        } else {
            this.deletedMap[override_id] = [person_meal_id];
        }
    }

    removeOverrideFromDeletedMap(override_id) {
        if (this.deletedMap[override_id]) {
            delete this.deletedMap[override_id];
        }
    }

    removePersonMealFromDeletedMap(person_meal_id, override_id) {
        if (this.deletedMap[override_id]) {
            this.deletedMap[override_id] = this.deletedMap[override_id].filter(pm => pm !== person_meal_id)
        }
    }

    /* ======================================== ACTION HANDLE ================================= */

    /**
     * TYPE: 'add-person-meal'
     * @param {object} selected 
     * @param {object} override 
     */
    addPersonMealToOverride(selected, override) {
        if (!selected && !override) return false;

        override.person_meals.push(selected);
        this.changes.push({
            type: actionEnum.ADD_PERSON_MEAL,
            data: {
                selected, // { id, type = 'recipe|food', name, amount (optional), extra_portion (optional), portion_count (optional) }
                meal_id: override.meal_id,
                person_id: override.person_id,
                date: override.date,
                pattern_meal_override_id: override.id,
            }
        });
        console.warn('[pattern.js]', {
            type: actionEnum.ADD_PERSON_MEAL, data: {
                selected,
                meal_id: override.meal_id,
                person_id: override.person_id,
                date: override.date,
                pattern_meal_override_id: override.id,
            }
        });
    }

    /**
     * TYPE: 'add-person-meal' || 'add'
     * @param {number} person_meal_id 
     * @param {object} override 
     */
    addMealOverride(selected, mealDay, person_id, date) {
        if (!selected || !selected?.type || !mealDay || person_id === undefined || !date) return false;
        // sprawdzenie czy dodaje person meal do istniejącego overrajda
        // sprawdzenie czy dodaje person meal o innym id niż istniejace person_meale do istniejącego overrajda
        // sprawdzenie czy dodaje  nowy override z pattern_meal
        // sprawdzenie czy dodaje nowy override z person_mealem 
        const overrideObj = {
            id: this.getNextId(),
            date: date,
            meal_id: mealDay.id,
            person_id: person_id,
            pattern_meal_id: 0,
            person_meals: []
        }

        if (selected.type === 'recipe' || selected.type === 'food') {
            const overrides = patternUtils.getOverridesWithPersonMeals(mealDay);
            // check if person_meal have exsisting override isCyclic|isSingle

            if (overrides?.length > 0) {
                const filter = overrides.filter(pmo => pmo.date === date);
                if (filter.length > 0) {
                    return this.addPersonMealToOverride(selected, filter[0]);
                }
            }
            overrideObj.person_meals.push(selected) // new pattern_meal_override with pattern_meal
            mealDay.overrides.push(overrideObj);

            this.changes.push({
                type: actionEnum.ADD,
                data: overrideObj
            });
        }

        if (selected.type === 'pattern-meal') { // new pattern_meal_override with pattern_meal
            overrideObj.pattern_meal_id = selected.id
            overrideObj.pattern = [
                {
                    id: selected.id,
                    name: selected.name,
                    tags: [],
                    ingredients: []
                }
            ]
            mealDay.overrides.push(overrideObj);
            this.changes.push({
                type: actionEnum.ADD,
                data: overrideObj
            });
        }
        console.warn('[pattern.js]', { type: actionEnum.ADD, data: overrideObj });
    }

    /**
     * TYPE: 'delete-person-meal'
     * @param {number} person_meal_id 
     * @param {object} override 
     */
    deletePersonMealFromOveride(person_meal_id, override) {
        // scenariusze do testów!
        // sprawdzenie czy usuwany overajd ma zostać usuniety tylko dla tego konkretnego person_meala
        // sprawdzenie czy po usunieciu person_meala został jakiś inny lub pattern_meal_id
        if (override?.id === undefined || person_meal_id === undefined) return false;

        const momentDate = moment(override.date);
        if (!momentDate.isValid()) return false;

        const alreadyDeleted = this.deletedMap[override.id]?.some(pm => pm === person_meal_id)
        if (alreadyDeleted) { // cancel deletion
            this.removePersonMealFromDeletedMap(person_meal_id, override.id); // omit action in deletedMap{}
            this.changes = this.changes.filter(change => { // omit action in changes[]
                if (change.type === actionEnum.DELETE_PERSON_MEAL &&
                    change.data.person_meal_id === person_meal_id &&
                    change.data.pattern_meal_override_id === override.id) {
                    return false;
                }
                return true;
            })

            console.warn('[pattern.js] REMOVE ACTION', { type: actionEnum.DELETE_PERSON_MEAL, data: person_meal_id, pattern_meal_override_id: override.id });
        } else {
            this.addPersonMealToDeletedMap(person_meal_id, override.id);
            this.changes.push({
                type: actionEnum.DELETE_PERSON_MEAL,
                data: {
                    person_meal_id,
                    pattern_meal_override_id: override.id,
                    date: momentDate.format('YYYY-MM-DD')
                }
            });
            console.warn('[pattern.js]', { type: actionEnum.DELETE_PERSON_MEAL, data: { person_meal_id, pattern_meal_override_id: override.id, date: momentDate.format('YYYY-MM-DD') } });
        }
    }

    /**
     * Delete pattern_meal_override for current id
     * @param {number} id 
     */
    deleteMealOverride(override) {
        if (override?.id === undefined) return false;

        const momentDate = moment(override.date);
        if (!momentDate.isValid()) return false;

        // scenariusze do testów!
        // sprawdzenie usuwany overrajd jest tym nie zapisanym w db 
        const alreadyDeleted = this.deletedMap[override.id]
        if (alreadyDeleted) {
            this.removeOverrideFromDeletedMap(override.id);
            this.changes = this.changes.filter(change => { // omit action in changes[]
                if (change.type === actionEnum.DELETE && change.data.pattern_meal_override_id === override.id) {
                    return false;
                }
                return true;
            })
            console.warn('[pattern.js] REMOVE ACTION', { type: actionEnum.DELETE, data: { pattern_meal_override_id: override.id } })
            return;
        } else {
            this.addOverrideToDeletedMap(override.id);
            this.changes.push({
                type: actionEnum.DELETE,
                data: {
                    pattern_meal_override_id: override.id,
                    date: momentDate.format('YYYY-MM-DD')
                }
            });
            console.warn('[pattern.js]', { type: actionEnum.DELETE, data: { pattern_meal_override_id: override.id } })
        }
    }

    disablePatternMeal(pattern_meal, mealDay, person_id, date, dayCount) {
        if (pattern_meal?.id === undefined || !mealDay || !person_id || !date || dayCount === undefined) return false;

        const deletedId = `${pattern_meal.id}|${mealDay.id}|${dayCount}`;
        if (this.deletedMap[deletedId]) {
            this.changes = this.changes.filter(change => { // omit action in changes[]
                if (change.type === actionEnum.ADD &&
                    change.data.pattern_meal_id === pattern_meal.id &&
                    change.data.meal_id === mealDay.id) {
                    return false;
                }
                return true;
            })
            this.removeOverrideFromDeletedMap(deletedId);
            console.warn('[pattern.js] REMOVE ACTION', { type: actionEnum.DELETE, data: { pattern_meal_id: pattern_meal.id } })
        } else {
            this.addMealOverride({
                id: pattern_meal.id,
                type: 'pattern-meal',
                name: ''
            }, mealDay, person_id, date);
            this.addOverrideToDeletedMap(deletedId)
        }
    }

    restorePatternMeal(pattern_meal, mealDay, dayCount) {
        if (pattern_meal?.id === undefined || !mealDay || dayCount === undefined) return false;

        // found pattern_meal_override that disable base pattern_meal;
        const filter = mealDay.overrides.filter(pmo => pmo.pattern_meal_id === pattern_meal.id)
        const override = filter.length > 0 ? filter[0] : false;

        this.deleteMealOverride(override)

        const deletedId = `${pattern_meal.id}|${mealDay.id}|${dayCount}`;
        if (this.deletedMap[deletedId]) {
            this.removeOverrideFromDeletedMap(deletedId)
        } else {
            this.addOverrideToDeletedMap(deletedId)
        }
    }

    /**
     * TYPE: 'add-person-meal'
     * @param {object} selected 
     * @param {object} override 
     */
    addPersonMeal(selected, mealDay, person_id, date) {
        if (!selected || !selected?.type || !mealDay || person_id === undefined || !date) return false;

        this.changes.push({
            type: actionEnum.ADD_PERSON_MEAL_MY_MENU,
            data: {
                selected, // { id, type = 'recipe|food', name, amount (optional), extra_portion (optional), portion_count (optional) }
                meal_id: mealDay?.id,
                person_id: person_id,
                date: date,
            }
        });
        console.warn('[pattern.js]', {
            type: actionEnum.ADD_PERSON_MEAL_MY_MENU, data: {
                selected,
                meal_id: mealDay?.id,
                person_id: person_id,
                date: date,
            }
        });
    }

    /**
 * Delete person_meal for current person_meal_id
 * @param {number} person_meal_id
 * @param {number} meal_id
 * @param {number} index
 * @param {string} date
 */
    deletePersonMeal(person_meal_id, meal_id, index, date) {
        const alreadyDeleted = this.deletedMap[person_meal_id + '|' + meal_id + '|' + index]
        if (alreadyDeleted) {
            delete this.deletedMap[person_meal_id + '|' + meal_id + '|' + index];
            this.changes = this.changes.filter(change => { // omit action in changes[]
                if (change.type === actionEnum.DELETE_PERSON_MEAL_MY_MENU && change.data.person_meal_id === person_meal_id) {
                    return false;
                }
                return true;
            })
            console.warn('[pattern.js] REMOVE ACTION', { type: actionEnum.DELETE_PERSON_MEAL_MY_MENU, data: { person_meal_id: person_meal_id } })
            return;
        } else {
            this.deletedMap[person_meal_id + '|' + meal_id + '|' + index] = true;
            this.changes.push({
                type: actionEnum.DELETE_PERSON_MEAL_MY_MENU,
                data: {
                    person_meal_id: person_meal_id,
                    date: date
                }
            });
            console.warn('[pattern.js]', { type: actionEnum.DELETE_PERSON_MEAL_MY_MENU, data: { person_meal_id: person_meal_id, date: date } })
        }
    }
}

export const patternUtils = {
    /**
     * Get meal list array base on pattern_week
     * @param {object} pattern_week 
     * @returns {array}
     */
    getMealList: (pattern_week = {}) => {
        if (!pattern_week || !Array.isArray(pattern_week?.days)) return false;
        const mealMap = {};

        for (let i = 0; i < pattern_week.days.length; i++) {
            const meals = pattern_week.days[i]?.meals;
            if (!Array.isArray(meals)) continue;

            for (let j = 0; j < meals.length; j++) {
                const meal = meals[j];
                mealMap[meal.id] = { id: meal.id, name: meal.name, order: meal.order }
            }
        }
        const mealArr = [...Object.values(mealMap)];
        return mealArr.sort((a, b) => a.order - b.order);
    },

    getPatternWeekDayByDayCount(week_pattern, dayCount) {
        if (!week_pattern || !Array.isArray(week_pattern?.days)) return false;

        const patternWeekDay = week_pattern?.days[dayCount];
        return patternWeekDay ? patternWeekDay : false;
    },

    /**
     * Get meal from day base on meal_id and dayCount
     * @param {number} meal_id 
     * @param {number} dayCount
     * @param {array} pattern_week 
     * @returns 
     */
    getMealDay: (meal_id, dayCount, pattern_week = []) => {
        if (meal_id === undefined || dayCount === undefined || !pattern_week || !Array.isArray(pattern_week?.days)) return false;

        const day = pattern_week.days[dayCount];
        if (!Array.isArray(day?.meals)) return false;

        const filter = day.meals.filter(meal => meal.id === meal_id);
        if (filter.length > 0) {
            return filter[0];
        }
        return false;
    },

    /**
     * returns overrides with person_meals: meal|day object
     * with some person_meals
     * @param {object} mealDay 
     * @returns {array} override
    */
    getOverridesWithPersonMeals: (mealDay) => {
        if (mealDay?.overrides?.length === 0) return false;

        return mealDay.overrides.filter(pmo => pmo.person_meals?.length > 0);
    },

    /**
     * Checks if pattern_meal is disabled by pattern_meal_override
     * @param {number} pattern_meal_id from the same day|meal
     * @param {number} person_id from the same day|meal
     * @param {number} meal_id from the same day|meal
     * @param {number} date from the same day|meal
     * @param {array} overrides from the same day|meal
     * @returns {boolean}
     */
    isDisabled: (pattern_meal_id, person_id, meal_id, dayCount, overrides = []) => {
        if (!Array.isArray(overrides)) return false;

        return overrides.some(pmo => {
            const _dayCount = moment(pmo?.date).isoWeekday() - 1;

            // console.log(pmo?.date, _dayCount === dayCount, _dayCount, dayCount)
            // console.log('pmoid', pmo?.pattern_meal_id, pattern_meal_id)
            // console.log('person_id', pmo?.person_id, person_id)
            // console.log('meal_id', pmo?.meal_id, meal_id)
            // console.log('--------------------------------------------------')
            return pmo?.pattern_meal_id === pattern_meal_id &&
                pmo?.person_id === person_id &&
                pmo?.meal_id === meal_id &&
                _dayCount === dayCount &&
                pmo.id > 0
        });
    },

    /**
     * check if person meal is already added in override distnct between real person_meal and those not subbmited
     * @param {object} mealDay 
     * @param {object} selected 
     * @param {string} addType 'cyclic'|'single| 
     * @returns {boolean}
     */
    isAddCandidate: (mealDay, selected, addType) => {
        if (!selected || selected?.type === undefined) return false;

        let isAddCandidate = true;
        if (selected.type === 'recipe' || selected.type === 'food') { // for not real person_meals
            const overrides = patternUtils.getOverridesWithPersonMeals(mealDay);

            if (!Array.isArray(overrides)) return isAddCandidate;
            const { id, type } = selected;

            // check if person meal is already added in override distnct between real person_meal and those not subbmited
            for (let i = 0; i < overrides.length; i++) {
                const override = overrides[i];
                const isOccupied = override.person_meals.some(pm => {
                    if (pm.recipe_type === undefined) { // not real person_meal
                        return pm.id === id && pm.type === type;
                    }
                    if (isRTE(pm) && pm?.ingredients) {// generated person_meal
                        return pm?.ingredients[0]?.food_id === pm.id;
                    }
                    return pm.recipe_id === pm.id
                })
                if (isOccupied) { // determine wchich overrivde is occupied with person_meal '1970'|current date
                    const _isCyclicAddType = addType === addEnum.CYCLIC;
                    if (isDateCyclicType(override.date) && _isCyclicAddType) {
                        isAddCandidate = false;
                    }
                    const _isSingleAddType = addType === addEnum.SINGLE;
                    if (!isDateCyclicType(override.date) && _isSingleAddType) {
                        isAddCandidate = false;
                    }
                }
            }
        }
        return isAddCandidate;
    }
}