import React from 'react';

const deleteIcon = () => (
<svg viewBox="0 0 51.02 60">
  <path d="M4.698,60h40.536l2.453-48.435H2.245L4.698,60z M35.239,52.385l0.995-30.154
    c0.059-1.801,1.433-3.274,3.068-3.274l0,0c1.634,0,2.886,1.474,2.795,3.274l-1.513,30.154c-0.074,1.482-1.32,2.673-2.784,2.673h0
    C36.337,55.057,35.19,53.866,35.239,52.385z M24.966,18.957L24.966,18.957c1.634,0,2.947,1.474,2.931,3.274l-0.259,30.154
    c-0.013,1.482-1.209,2.673-2.673,2.673l0,0c-1.463,0-2.66-1.191-2.673-2.673l-0.259-30.154
    C22.019,20.43,23.331,18.957,24.966,18.957z M10.63,18.957L10.63,18.957c1.634,0,3.008,1.474,3.068,3.274l0.995,30.154
    c0.049,1.482-1.098,2.673-2.561,2.673l0,0c-1.463,0-2.709-1.191-2.784-2.673L7.835,22.231C7.744,20.43,8.995,18.957,10.63,18.957z
    "/>
  <path d="M33.741,3.893c0-2.15-1.743-3.893-3.893-3.893H21.58c-2.15,0-3.893,1.743-3.893,3.893v0.189H0v5.85
    h51.02v-5.85H33.741V3.893z"/>
</svg>
)

export default deleteIcon;