
const microNutrientsInfo = {
    vits: [
        { name: 'Biotin', selected: false, unit: 'μg', getValue: (item) => item.biotin, propName: 'biotin' },
        { name: 'Choline', selected: false, unit: 'mg', getValue: (item) => item.choline, propName: 'choline' },
        { name: 'Folate, total', selected: false, unit: 'μg', getValue: (item) => item.folate, propName: 'folate' },
        { name: 'Niacin', selected: false, unit: 'mg', getValue: (item) => item.niacin, propName: 'niacin' },
        { name: 'Pantothenic Acid', selected: false, unit: 'mg', getValue: (item) => item.pantothenicAcid, propName: 'pantothenicAcid' },
        { name: 'Riboflavin', selected: false, unit: 'mg', getValue: (item) => item.riboflavin, propName: 'riboflavin', precision: 1 },
        { name: 'Thiamin', selected: false, unit: 'mg', getValue: (item) => item.thiamin, propName: 'thiamin', precision: 1 },
        { name: 'Vitamin A', selected: false, unit: 'μg', getValue: (item) => item.vitA, propName: 'vitA' },
        { name: 'Vitamin B6', selected: false, unit: 'mg', getValue: (item) => item.vitB6, propName: 'vitB6', precision: 1 },
        { name: 'Vitamin B12', selected: false, unit: 'μg', getValue: (item) => item.vitB12, propName: 'vitB12', precision: 1 },
        { name: 'Vitamin C', selected: false, unit: 'mg', getValue: (item) => item.vitC, propName: 'vitC' },
        { name: 'Vitamin D', selected: false, unit: 'μg', getValue: (item) => item.vitD, propName: 'vitD' },
        { name: 'Vitamin E', selected: false, unit: 'mg', getValue: (item) => item.vitE, propName: 'vitE' },
        { name: 'Vitamin K', selected: false, unit: 'μg', getValue: (item) => item.vitK, propName: 'vitK' },
    ],

    mins: [
        { name: 'Calcium', selected: false, unit: 'mg', getValue: (item) => item.calcium, propName: 'calcium' },
        { name: 'Chromium', selected: false, unit: 'μg', getValue: (item) => item.chromium, propName: 'chromium' },
        { name: 'Copper', selected: false, unit: 'μg', getValue: (item) => item.copper, propName: 'copper' },
        { name: 'Iron', selected: false, unit: 'mg', getValue: (item) => item.iron, propName: 'iron' },
        { name: 'Magnesium', selected: false, unit: 'mg', getValue: (item) => item.magnesium, propName: 'magnesium' },
        { name: 'Manganese', selected: false, unit: 'mg', getValue: (item) => item.manganese, propName: 'manganese' },
        { name: 'Phosphorus', selected: false, unit: 'mg', getValue: (item) => item.phosphorus, propName: 'phosphorus' },
        { name: 'Potassium', selected: false, unit: 'g', getValue: (item) => item.potassium, propName: 'potassium' },
        { name: 'Selenium', selected: false, unit: 'μg', getValue: (item) => item.selenium, propName: 'selenium' },
        { name: 'Zinc', selected: false, unit: 'mg', getValue: (item) => item.zinc, propName: 'zinc' },
    ],

    mainNutrients: [
        { name: 'Calories (kcals)', getValue: (item) => item.kcal, propName: 'kcal', variability: undefined },
        { name: 'Protein (g)', getValue: (item) => item.protein, propName: 'protein', targetHidden: false, variability: undefined },
        { name: 'Total Carbohydrate (g)', getValue: (item) => item.carbs, propName: 'carbs', targetHidden: false, variability: undefined },
        { name: 'Dietary Fiber (g)', getValue: (item) => item.fiber, propName: 'fiber', variability: 3, className: 'pl-2' },
        { name: 'Total Sugar (g)', getValue: (item) => item.sugar, propName: 'sugar', variability: undefined, className: 'pl-2' },
        { name: 'Total Fat (g)', getValue: (item) => item.fat, propName: 'fat', targetHidden: false, variability: undefined },
        //{name: 'Saturated fat (g)', getValue: (item) => item.weight.fat*0.3, propName: undefined, variability: undefined },
        //{name: 'Trans fat (g)', getValue: (item) => item.weight.fat*0.2, propName: undefined, variability: undefined },
        //{name: 'Unsaturated fat (g)', getValue: (item) => item.weight.fat*0.5, propName: undefined, variability: undefined },
        // {name: 'Cholesterol (mg)', getValue: (item) => item.weight.fat * 2.1, propName: undefined, variability: undefined },
        { name: 'Sodium (mg)', getValue: (item) => item.sodium, propName: 'sodium', variability: undefined },
        { name: 'Alcohol (g)', getValue: (item) => item.alcohol, propName: 'alcohol', variability: undefined, precision: 1 },
    ],
}


export { microNutrientsInfo };
export default microNutrientsInfo;