import React from 'react';

const twitterIcon = () => (
    <svg viewBox="0 0 59.999 60">
        <path d="M30,0C13.431,0,0,13.432,0,29.999C0,46.568,13.431,60,30,60c16.568,0,29.999-13.432,29.999-30.001
		C59.999,13.432,46.568,0,30,0z M43.403,23.125c0.437,9.727-6.817,20.573-19.657,20.573c-3.905,0-7.54-1.145-10.601-3.108
		c3.669,0.433,7.331-0.583,10.238-2.864c-3.027-0.056-5.581-2.054-6.459-4.803c1.081,0.207,2.147,0.148,3.122-0.118
		c-3.326-0.668-5.621-3.664-5.547-6.869c0.932,0.518,1.999,0.83,3.134,0.866c-3.081-2.058-3.952-6.125-2.141-9.234
		c3.41,4.185,8.505,6.938,14.254,7.227c-1.009-4.326,2.272-8.492,6.736-8.492c1.99,0,3.787,0.84,5.049,2.184
		c1.574-0.31,3.055-0.886,4.391-1.679c-0.516,1.617-1.613,2.972-3.04,3.826c1.398-0.167,2.731-0.538,3.972-1.089
		C45.927,20.933,44.754,22.151,43.403,23.125z"/>
    </svg>
)

export default twitterIcon;