import React from 'react';
import PropTypes from 'prop-types';
import { view } from '@risingstack/react-easy-state';

import './CircleChart.scss';

const x = 50;
const y = 50;
const radius = 40;

const colors = [
    "rgb(135, 196, 92)",
    "rgb(60, 170, 167)",
    "#e8e068",
    '#F38890'
]

/**
 * Function that translate polar coordinate system to cartesian coordinate system
 * @param {number} centerX 
 * @param {number} centerY 
 * @param {number} radius 
 * @param {number} angleInDegrees 
 */
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
}

function getPathData(accRatio, ratio) {

    const start = polarToCartesian(x, y, radius, (ratio + accRatio - 0.003) * 360);
    const end = polarToCartesian(x, y, radius, (accRatio + 0.003) * 360);

    const largeArcFlag = ratio < 0.5 ? "0" : "1";

    const d = [
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");

    return d;
}

/**
 * @typedef SectionObject
 * @type {Array}
 * @property {boolean} value
 * @property {boolean} color

/**
 * @typedef CircleChartProps
 * @type {object}
 * @property {SectionObject} sections
 * @property {element} children React.Element 
*/

/**
 * Component that draws circle chart graph
 * @component
 * @param {CircleChartProps} props
 * @type {{ new(props: CircleChartProps): {
 *   props: { 
 *     children: React.Element, 
 *     sections: [{
 *      value: number,
 *      color: string     
 *      }],
 *     }
 *   }
 *  }}
 */
const CircleChart = (props) => {
    const paths = [];
    let accRatio = 0;
    let totalValue = 0;

    if (props.sections && props.sections.length > 0) {
        props.sections.map((s) => totalValue += (typeof (s) === 'object' ? s.value : s));
        for (let i = 0; i < props.sections.length; i++) {
            const s = props.sections[i];
            paths.push(
                <path
                    key={"path_" + i}
                    style={{ stroke: (typeof (s) === 'object' ? s.color : colors[i % colors.length]) }}
                    d={getPathData(accRatio, (typeof (s) === 'object' ? s.value : s) / totalValue)}
                />
            )
            accRatio += (typeof (s) === 'object' ? s.value : s) / totalValue;
        }
    }

    return (<div className="circle-chart">
        <svg viewBox="0 0 100 100">
            {paths}
        </svg>
        <div className="textContainer">
            <div className="text">
                {props.children}
            </div>
        </div>
    </div>)
};

CircleChart.propTypes = {
    sections: PropTypes.array.isRequired,
    children: PropTypes.arrayOf(PropTypes.element)
};

export default view(CircleChart);