import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { view } from '@risingstack/react-easy-state';
import moment from 'moment';

import './WeekGridForMyMenu.scss';

import Text from 'components/Text';
import RawGrid from 'components/RawGrid';
import { getGridAvailableMealsForDay, isInEditMode, isInPreviewMode, isInDeleteMode, getMealListFromWeeklyData } from 'utils/weekGridUtils';
import Icon, { FA, icons } from '../Icon';
import { getMealName, isRTE } from 'utils/mealUtils';
import { getStartOfWeek } from 'utils/date/getStartOfWeek';
import { fixCapitalizeName } from 'storeUtils/layoutStore';

const defaultMeals = ['Breakfast', 'Morning Snack', 'Lunch', 'Afternoon Snack', 'Dinner', 'Evening Snack']
const defaultWeekDays = ['1970-01-05', '1970-01-06', '1970-01-07', '1970-01-01', '1970-01-02', '1970-01-03', '1970-01-04']

/**
 * @typedef WeekGridForMyMenuProps
 * @type {object}
 * @property {function} onDayClick
 * @property {function} onNewTileClick
 * @property {function} onDeleteTileClick
 * @property {function} isAddCandidate
 * @property {string} mode
 * @property {array} weeklyData
*/

/**
 * @param {WeekGridForMyMenuProps} props
 */
class RawWeekGridForMyMenu extends Component {
    meals = defaultMeals;
    content = [];

    isEmpty = weeklyData => {
        if (!Array.isArray(weeklyData)) return undefined;

        return weeklyData.every(day => {
            if (Array.isArray(day?.menu)) {
                return day.menu.length === 0;
            }
            return undefined;
        });
    }

    constructor(props) {
        super(props);

        if (this.props.weeklyData) {
            if (this.props.meals) {
                this.meals = this.props.meals;
            } else if (!this.isEmpty(this.props.weeklyData)) {
                this.meals = getMealListFromWeeklyData(this.props.weeklyData);
            }
            this.content = getGridAvailableMealsForDay(this.meals, this.props.weeklyData)
        }
    }

    /**
     * Get meal from day base on meal_id and dayCount
     * @param {number} meal_id 
     * @param {number} dayCount
     * @param {array} pattern_week 
     * @returns 
     */
    getMealDayFromMyMenu(meal_id, dayCount, weeklyData = []) {
        if (meal_id === undefined || dayCount === undefined || !Array.isArray(weeklyData)) return false;

        const day = weeklyData[dayCount];
        if (!Array.isArray(day?.menu)) return false;

        const filter = day.menu.filter(meal => meal.id === meal_id);
        if (filter.length > 0) {
            return filter[0];
        }
        return false;
    }

    /* ======================================== RENDER ======================================== */

    renderPersonMeal(person_meal, meal_id, index, date) {
        const isDeleted = this.props.deletedMap[person_meal.id + '|' + meal_id + '|' + index];

        return <Text fullWidth inlineBlock key={`${person_meal.id}|${meal_id}|${index}`}
            className={'meal-override ' +
                (isDeleted && isInDeleteMode(this.props.mode) ? 'is-deleted ' : '')
            }
            onClick={() => {
                if (isInEditMode(this.props.mode)) return false;

                if (typeof this.props.onDeleteTileClick === 'function') {
                    this.props.onDeleteTileClick(person_meal.id, meal_id, index, date);
                    this.forceUpdate()
                }
            }}
        >
            <Text inlineBlock fullWidth className="name">
                {person_meal.type === 'food' || (person_meal?.recipe_type !== undefined && isRTE(person_meal)) ? <Icon className="rte-icon mr-1"
                    icon={icons.rteIcon}
                /> : ''}
                {person_meal.type === 'recipe' || (person_meal?.recipe_type !== undefined && !isRTE(person_meal)) ? <Icon className="recipe-icon mr-1"
                    icon={icons.recipe}
                /> : ''}
                {fixCapitalizeName(getMealName(person_meal))}
            </Text>
            <Icon className='delete-icon' icon={icons.delete} />
        </Text>
    }

    renderNewTile(mealDay, dayCount) {
        if (isInPreviewMode(this.props.mode) || isInDeleteMode(this.props.mode)) {
            return null;
        }
        return <div className="tile-new"
            onClick={() => {
                if (typeof this.props.onNewTileClick === 'function') {
                    this.props.onNewTileClick(mealDay, dayCount)
                }
            }}
        ><Icon className='edit-icon active-edit-mode' icon={icons.add} />
        </div>;
    }

    renderTile = (meal_id, dayCount = 0) => {
        const mealDay = this.getMealDayFromMyMenu(meal_id, dayCount, this.props.weeklyData);
        if (!Array.isArray(mealDay?.recipes)) {
            return <FA icon="far fa-fw fa-times" className="ico" />
        }

        const date = this.props.weeklyData[dayCount] ? this.props.weeklyData[dayCount].date : '';
        return <React.Fragment key={meal_id}>
            {mealDay?.recipes?.length > 0 ? <div className="tile-container">
                {mealDay.recipes.map((person_meal, index) => {
                    return this.renderPersonMeal(person_meal, meal_id, index, date)
                })}
            </div> : ''}
            {this.renderNewTile(mealDay, dayCount)}
        </React.Fragment>
    }

    render() {
        return <div className='week-grid for-my-menu '>
            <RawGrid
                top={defaultWeekDays}
                getTop={(el) => {
                    const beginingOfWeek = getStartOfWeek(this.props.selectedDay?.date);

                    const _dayCount = moment(el).isoWeekday();
                    const currentDate = beginingOfWeek.add(_dayCount - 1, 'days');
                    return <React.Fragment>
                        <Text className="primary my-auto" textCenter fullWidth inlineBlock bold>
                            <span className="extra-mobile-hidden">{moment(el).format('dddd')}</span>
                            <span className="extra-mobile-only">{moment(el).format('ddd')}</span>
                        </Text>
                        <Text fullWidth textCenter inlineBlock className="secondary">{currentDate.format('MMM DD')}</Text>
                    </React.Fragment>
                }}

                count={this.props.count}
                offset={this.props.offset}

                handleTouchStart={this.props.handleTouchStart}
                handleTouchMove={this.props.handleTouchMove}
                handleTouchEnd={this.props.handleTouchEnd}
                goPreviousPage={this.props.goPreviousPage}
                goNextPage={this.props.goNextPage}
                withNavigation
                leftSize={'8rem'}
                left={this.meals}
                getLeft={(el) => <Text className="primary my-auto" textCenter fullWidth inlineBlock bold>
                    {el.name}
                </Text>}

                content={this.content}
                getContent={(el, i, j) => {
                    const meal = this.meals[i];
                    if (this.props.omitNoValuesCheck) {
                        return this.renderTile(meal.id, j);
                    }
                    if (el === -1 || el === '') {
                        return <FA icon="far fa-fw fa-times" className="ico" />
                    }
                    return this.renderTile(meal.id, j);
                }}
                getContentClass={(el, i, j) => {
                    let className = 'week-grid-tile ';
                    const meal = this.meals[i];
                    const mealDay = this.getMealDayFromMyMenu(meal.id, j, this.props.weeklyData);
                    if (mealDay && mealDay?.recipes?.length === 0) {
                        className += 'empty ';
                    }
                    if (isInEditMode(this.props.mode)) {
                        className += 'week-grid-tile-new '
                    }
                    if (el === 1) {
                        className += 'selected ';
                    }
                    if (el === -1 && !this.props.omitNoValuesCheck) {
                        className += 'disabled ';
                    }
                    return className;
                }}
                onClick={(el, i, j, event) => { }}
            />
        </div>
    }
}

RawWeekGridForMyMenu.propTypes = {
    onDayClick: PropTypes.func.isRequired,
    onNewTileClick: PropTypes.func.isRequired,
    onDeleteTileClick: PropTypes.func.isRequired,
    selected: PropTypes.any,
    mode: PropTypes.string.isRequired,
    selectedDay: PropTypes.any.isRequired,
    weeklyData: PropTypes.any.isRequired,
    deletedMap: PropTypes.any.isRequired,
    meals: PropTypes.any,
    omitNoValuesCheck: PropTypes.bool,
    omitIsAddCandidateCheck: PropTypes.bool,
    count: PropTypes.number,
    offset: PropTypes.number,
    handleTouchStart: PropTypes.func,
    handleTouchMove: PropTypes.func,
    handleTouchEnd: PropTypes.func,
    goNextPage: PropTypes.func,
    goPreviousPage: PropTypes.func
};

/**
 * 
 * @component
 * @type {{ new(props: WeekGridForMyMenuProps): {
 *   props: { 
 *     onDayClick: function, 
 *     onNewTileClick: function, 
 *     onDeleteTileClick: function, 
 *     onDisablePatternMeal: function, 
 *     selected: object,
 *     mode: string, 
 *     weeklyData: Array, 
 *     }
 *   }
 *  }}
 */

const WeekGridForMyMenuForMyMenu = view(RawWeekGridForMyMenu);
export default WeekGridForMyMenuForMyMenu;