
import React, { Component } from 'react';
import { store, view } from '@risingstack/react-easy-state';
import PropTypes from "prop-types";

import './ChangeAmountModal.scss';

import { Text } from 'components/Text';
import { Icon, icons } from 'components/Icon';
import Button from 'components/Button';
import FractionControllInput from 'components/FractionControllInput';
import UnitPicker from 'components/UnitPicker/UnitPicker';

import mainStore from 'storeUtils/mainStore';
import { shopUtil } from 'storeUtils/shoppingUtils';
import shoppingStore from 'storeUtils/shoppingStore';
import { fixCapitalizeName } from 'storeUtils/layoutStore';

class ChangeAmountModal extends Component {
    static class = 'modal-medium';
    ezState = store({ portions: [], searchValue: '', unit: '', loading: true, amount: 0, portion_type_id: 0 });

    constructor(props) {
        super(props);
        this.ezState.initName = shopUtil.getPortionName(this.props.displayGroup, mainStore.portionTypes)
        this.ezState.unit = shopUtil.getUnit(this.props.displayGroup);
        this.ezState.portion_type_id = shopUtil.getPortionTypeId(this.props.displayGroup);
        this.ezState.amount = shopUtil.getSum(this.props.displayGroup);
    }

    /* ======================================== UTILS ======================================== */

    getButtonTitle = () => {
        return "CONFIRM";
    }

    /* ======================================== EVENT HANDLERS ======================================== */

    onSearchChange = (query) => {
        this.ezState.portion_type_id = 0;
        this.ezState.unit = query;
    }

    onChangeIngredientPortion = (portion) => {
        this.ezState.portion_type_id = portion.portion_type_id;
        this.ezState.unit = '';
    }

    onSubmit = () => {
        const oldUnit = shopUtil.getUnit(this.props.displayGroup);
        const oldPortion = shopUtil.getPortionTypeId(this.props.displayGroup);

        if (this.ezState.portion_type_id != oldPortion || (this.ezState.portion_type_id == 0 && this.ezState.unit != oldUnit)) {
            shopUtil.setPortionType(this.props.displayGroup, this.ezState.portion_type_id, this.ezState.unit)
        }
        if (this.ezState.amount != shopUtil.getSum(this.props.displayGroup)) {
            shopUtil.setAmount(this.props.displayGroup, this.ezState.amount);
        }
        this.props.closeAction();
    }

    onChangeAmount = (value) => {
        this.ezState.amount = value;
    }

    /* ======================================== MAIN RENDER ======================================== */

    render() {
        const rangeId = shoppingStore.selectedRange.id;
        const rangeData = shoppingStore.preparedKeyMap[rangeId];
        const name = shopUtil.getName(this.props.displayGroup, rangeData.foods)
        const brand = shopUtil.getBrand(this.props.displayGroup, rangeData.foods);
        
        return (
            <div className="changeamount-modal">
                <Text header className='has-title'><Icon icon={icons.edit} />Edit Shopping Item</Text>

                <Text block textLeft>
                    {fixCapitalizeName(name)}
                    {brand ? <i className="ingredient-brand-name pl-2 d-inline-block">{brand}</i> : ''}
                </Text>

                <div className="controls">
                    <FractionControllInput
                        roundSourceToFraction
                        minValue={0.25}
                        holdStepValue={0.5}
                        changeValueByStep
                        step={0.5}
                        stepAcceleration
                        value={this.ezState.amount}
                        onChange={this.onChangeAmount} />
                    <UnitPicker initSearch={this.ezState.initName} onUnitChange={this.onChangeIngredientPortion} onSearchChange={this.onSearchChange} foodId={shopUtil.getFoodId(this.props.displayGroup)} />
                </div>

                <Button onClick={this.onSubmit} green>{this.getButtonTitle()}</Button>
                <Button onClick={this.props.closeAction}>CANCEL</Button>
            </div>
        );
    }
}

ChangeAmountModal.propTypes = {
    closeAction: PropTypes.func,
    displayGroup: PropTypes.array,
    readonly: PropTypes.any
}

export default view(ChangeAmountModal);