import React from 'react';

const recipeIcon = () => (
    <svg viewBox="0 0 60 47.193">
        <path d="M30.327,30.904v-0.008c-0.107,0-0.216,0.003-0.327,0.004c-0.106-0.001-0.216-0.004-0.327-0.004
    v0.008c-7.293,0.07-13.649,1.604-15.257,1.933v0.005l2.828,14.352c0,0,4.766-2.042,12.756-2.097
    c7.99,0.055,12.76,2.097,12.76,2.097l2.825-14.352l-0.006-0.005C43.976,32.508,37.624,30.973,30.327,30.904z"/>
        <path d="M46.72,5.117c-2.11,0-4.103,0.504-5.877,1.381c-2.142-3.597-5.931-6.07-10.336-6.425V0
    C30.34,0,30.171,0.029,30,0.035C29.828,0.029,29.663,0,29.492,0v0.073c-4.404,0.356-8.196,2.828-10.339,6.425
    c-1.77-0.877-3.76-1.381-5.87-1.381C5.944,5.117,0,11.066,0,18.403C0,25.742,5.944,31.69,13.283,31.69
    c0.265,0,0.527-0.022,0.789-0.04c-0.281-0.198-0.281-0.581,0.013-0.826c0.311-0.258,8.491-1.708,15.914-2.128
    c7.343,0.366,15.603,1.87,15.914,2.128c0.295,0.245,0.292,0.628,0.013,0.826c0.265,0.018,0.527,0.04,0.793,0.04
    c7.335,0,13.28-5.947,13.28-13.286C60,11.066,54.055,5.117,46.72,5.117z"/>
    </svg>
)

export default recipeIcon;