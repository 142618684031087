import React from 'react';
import layoutStore from 'storeUtils/layoutStore';

/**
 * returns img element with appConfig logo settings
 * @returns <img />
 */
function getLogoImg() {
    const { logo } = layoutStore.config;

    return <img
        src={logo?.src}
        alt={logo?.alt}
        width={logo?.width}
        height={logo?.height}
    />
}

export default getLogoImg;