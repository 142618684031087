import React, { Component } from 'react';
import { store, view } from '@risingstack/react-easy-state';

import mainStore from 'storeUtils/mainStore';

import "./SettingsPatternMyMenu.scss"

import Text from 'components/Text';
import Button, { ProgressButton } from 'components/Button';
import InputSearch from 'components/InputSearch';
import Icon, { FA, icons } from 'components/Icon';
import WeekGridForMyMenuForMyMenu from 'components/WeekGridForMyMenu';
import { addNotification } from 'components/NotificationWrapper';

import { commitTypeEnum, PatternChangesApi } from 'subsystems/pattern/patternChangesApi.js';

import commitChanges from 'api/pattern/commitChanges';
import { updateStore } from 'api/login/verifyLogin';
import getAssembledRecipe from 'api/recipe/recipeGetAssembled';
import getAssembledFood from 'api/recipe/recipeGetAssembledFood';

import searchWrapper from 'utils/flexSearchWrapper';
import { mealWarningEnum } from 'utils/mealUtils';
import getFractionDisplay from 'utils/getFractionDisplay';
import { getStartOfWeek } from 'utils/date/getStartOfWeek';
import {
    isInEditMode,
    addEnum,
    stateEnum,
    isInPreviewMode,
    isInDeleteMode,
    isInRestoreMode,
    getSingleDate
} from 'utils/weekGridUtils';

import { getSelectedDate, onMealSubstituteWarnings, onMealWarningModal, setWeek } from 'storeUtils/storeUtils';
import layoutStore, { fixCapitalizeName, removeDefaultBrand } from 'storeUtils/layoutStore';
import { addModal } from 'components/ModalWrapper';
import InfoModal from 'components/InfoModal';

class SettingsPatternMyMenu extends Component {
    patternChangesApi = new PatternChangesApi();
    ezState = store({
        mode: stateEnum.PREVIEW_MODE,
        selected: undefined,
        addType: addEnum.SINGLE,
        weeklyData: [],
        deletedMap: {},
        onlyRecipe: true,
        onlyRTE: true,
        searchValue: '',
        offset: 0,
        count: 3,
        loading: false
    });

    constructor(props) {
        super(props);

        if (this.props.selected && this.props.selected.id && this.props.selected.type) {
            this.ezState.selected = this.props.selected;
            this.ezState.mode = stateEnum.EDIT_MODE;
        }
        this.ezState.weeklyData = JSON.parse(JSON.stringify(mainStore.weeklyData))
        this.patternChangesApi.init(this.ezState.weeklyData, this.ezState.deletedMap) //change to this.props.pattern_week

        layoutStore.routerBlockedModalFunction = (tx, callback) => {
            addModal(InfoModal, {
                title: 'Are you sure?',
                text: 'You are leaving the page without saving changes.',
                confirm: 'continue',
                cancel: 'cancel',
                closeOnBg: true
            },
                (data) => {
                    if (data) {
                        layoutStore.routerBlocked = false;
                        tx?.retry();
                        if (callback) {
                            callback();
                        }
                    }
                });
        }
    }

    componentWillUnmount() {
        layoutStore.routerBlockedModalFunction = undefined;
        layoutStore.routerBlocked = false;
    }

    componentDidUpdate() {
        if (this.patternChangesApi.changes.length > 0 || isInEditMode(this.ezState.mode) || isInDeleteMode(this.ezState.mode) || isInRestoreMode(this.ezState.mode)) {
            layoutStore.routerBlocked = true;

        } else {
            layoutStore.routerBlocked = false;
        }
    }

    /* ======================================== UTILS ======================================== */

    toggleRecipesFilter() {
        if (isInPreviewMode(this.ezState.mode)) {
            this.ezState.onlyRecipe = !this.ezState.onlyRecipe;
        }
    }

    toggleRTEFilter() {
        if (isInPreviewMode(this.ezState.mode)) {
            this.ezState.onlyRTE = !this.ezState.onlyRTE;
        }
    }

    setSearchEl(searchEl) {
        if (!searchEl) return;
        this.ezState.mode = stateEnum.EDIT_MODE;
        this.ezState.selected = searchEl;
    }

    goNext = () => {
        if (this.ezState.offset === 2) return false;

        this.ezState.offset += 1;
    }

    goPrev = () => {
        if (this.ezState.offset === 0) return false;

        this.ezState.offset -= 1;
    }

    getSelected = (changes) => {
        const selectedMap = {};

        for (let i = 0; i < changes.length; i++) {
            const selected = changes[i].data?.selected;
            selectedMap[selected.id] = selected;
        }

        const keys = Object.keys(selectedMap);
        if (keys.length > 0) {
            const id = keys[0];
            return selectedMap[id]
        }
        return undefined;
    }

    /* ===================================== ACTION HANDLE ==================================== */

    onNewTileClick = (mealDay, dayCount) => {
        if (!mealDay || dayCount === undefined || !this.ezState.selected || this.ezState.loading || mainStore.weekLoading) return false;

        const date = getSingleDate(dayCount, mainStore.selectedDay?.date);
        this.patternChangesApi.addPersonMeal(this.ezState.selected, mealDay, mainStore.profile.id, date)

        const _pm = JSON.parse(JSON.stringify(this.ezState.selected))
        mealDay.recipes.push(_pm)
    }

    /**
     * Assign given id to patternChangesApi.deletedMap based on override_type 
     * @param {number} person_meal_id may be >pattern_meal_override_id< or >person_meal_id<
     * @param {number} override_type 'pattern-meal' | 'person-meal'
     */
    onDeleteTileClick = (person_meal_id, meal_id, index, date) => {
        if (person_meal_id === undefined || meal_id === undefined || index === undefined || date === undefined || this.ezState.loading || mainStore.weekLoading) return false;

        this.patternChangesApi.deletePersonMeal(person_meal_id, meal_id, index, date);

        if (this.patternChangesApi.changes.length === 0) {
            this.ezState.mode = stateEnum.PREVIEW_MODE;
        } else {
            this.ezState.mode = stateEnum.DELETE_MODE;
        }
        this.ezState.deletedMap = this.patternChangesApi.deletedMap;
    }

    onCommitChange = async (changes) => {
        layoutStore.bigLoading = true;
        const selected_date = getSelectedDate();
        const result = await commitChanges(mainStore.profile.id, changes, commitTypeEnum.FOR_MY_MENU, undefined, selected_date)

        if (result?.success && result?.baseInformation) {
            updateStore(result)
            this.ezState.weeklyData = JSON.parse(JSON.stringify(mainStore.weeklyData));
            this.resetState(this.ezState.weeklyData);
        } else {
            this.onClear();
            if (result?.message) {
                addNotification(result.message, { timeout: 7000 })
            }
            console.error('[SettingsPatternMyMenu.js] onSubmit - Error: changes not saved ')
        }
        layoutStore.bigLoading = false;
    }

    getAssembledItem = async (id, type) => {
        if (type === 'recipe') {
            const result = await getAssembledRecipe(mainStore.profile.id, id);
            if (result?.recipe) {
                return result.recipe;
            }
            if (result?.recipe === false) {
                const resultWithoutRestrictions = await getAssembledRecipe(mainStore.profile.id, id, undefined, true);
                if (resultWithoutRestrictions?.recipe) {
                    return resultWithoutRestrictions.recipe;
                }
            }
        }

        const result = await getAssembledFood(mainStore.profile.id, id, 1);
        if (result?.recipe) {
            return result.recipe;
        }
        return false;
    }

    onWarningSubmitConfirm = async (changes, personMeal, keepOriginal) => {
        for (let i = 0; i < changes.length; i++) {
            const change = changes[i];
            if (change.data.selected.id === personMeal.id) {
                changes[i].data.personMeal = personMeal;
                changes[i].data.keepOriginal = keepOriginal;
            }
        }
        await this.onCommitChange(changes);
        this.ezState.loading = false;
    }

    onSubmit = async () => {
        if (this.ezState.loading || mainStore.weekLoading) return;
        const changes = this.patternChangesApi.changes;

        if (changes.length === 0) {
            this.resetState(this.ezState.weeklyData, false);
            return;
        }

        this.ezState.loading = true;

        if (isInEditMode(this.ezState.mode)) {
            const selected = this.getSelected(changes);
            const personMeal = await this.getAssembledItem(selected.id, selected.type);
            if (personMeal === false) {
                return onMealWarningModal([mealWarningEnum.CANT_ADD]);
            }
            onMealSubstituteWarnings(personMeal,
                mainStore.profile.id,
                (modalResult) => {
                    let keepOriginal = true;
                    if (modalResult) {
                        keepOriginal = modalResult.keepOriginal
                    }
                    this.onWarningSubmitConfirm(changes, personMeal, keepOriginal)
                },
                () => { this.ezState.loading = false; }

            )
        } else { // RESTORE mode or DELETE mode
            await this.onCommitChange(changes);
            this.ezState.loading = false;
        }
        console.warn('[SettingsPatternMyMenu.js] Submit')
    }

    onClear() {
        this.resetState(this.patternChangesApi.__patternWeekSnapshot)
    }

    resetState(weeklyData, resetSelected = true) {
        if (resetSelected) {
            this.ezState.selected = undefined;
            this.ezState.mode = stateEnum.PREVIEW_MODE;
        } else if (this.ezState.mode === stateEnum.DELETE_MODE) {
            this.ezState.mode = stateEnum.PREVIEW_MODE;
        }
        this.ezState.searchValue = '';
        this.ezState.deletedMap = {};
        this.ezState.offset = 0;

        this.ezState.weeklyData = JSON.parse(JSON.stringify(weeklyData));
        this.patternChangesApi.init(this.ezState.weeklyData, this.ezState.deletedMap);
    }

    onWeekChange = async (dateString) => {
        if (this.ezState.loading || mainStore.weekLoading) return false;

        this.ezState.loading = true;

        await setWeek(dateString)

        this.ezState.weeklyData = JSON.parse(JSON.stringify(mainStore.weeklyData));
        this.resetState(this.ezState.weeklyData, false);

        this.ezState.loading = false;
    }

    /* ======================================== RENDER ======================================== */

    renderServings() {
        const amount = this.ezState.selected?.amount;
        const extra_portions = this.ezState.selected?.extra_portions;

        if (amount === undefined || extra_portions === undefined) return false;

        return <span className="manual-servings">{getFractionDisplay(amount)}<span> of {getFractionDisplay(amount + extra_portions)} Serving(s)</span></span>
    }

    renderOptions() {
        return <div className="settings-options">
            <div>{this.renderServings()}</div>
            <div className={'settings-option-section ' +
                (isInRestoreMode(this.ezState.mode) || isInEditMode(this.ezState.mode) || isInDeleteMode(this.ezState.mode) ? 'disabled' : '')
            }>
                <div className="option cyclic" onClick={() => this.toggleRecipesFilter()}>
                    <Icon className="check-icon"
                        icon={this.ezState.onlyRecipe ? icons.checkboxSelected : icons.checkboxEmpty}
                    />
                    <Text inlineBlock className="option-text-filter">
                        <Icon className="recipe-icon mr-1"
                            icon={icons.recipe}
                        />Recipes</Text>
                </div>
                <div className="option cyclic" onClick={() => this.toggleRTEFilter()}>
                    <Icon className="check-icon"
                        icon={this.ezState.onlyRTE ? icons.checkboxSelected : icons.checkboxEmpty}
                    />
                    <Text inlineBlock className="option-text-filter">
                        <Icon className="rte-icon mr-1"
                            icon={icons.rteIcon}
                        />Foods</Text>
                </div>
            </div>
        </div>
    }

    renderInputSearch() {
        return <div className="s">
            <InputSearch placeholder="Search..."
                searchFunction={async (query) => {
                    this.ezState.searchValue = query

                    if ((!this.ezState.onlyRecipe && !this.ezState.onlyRTE) ||
                        (this.ezState.onlyRecipe && this.ezState.onlyRTE)) {
                        return await searchWrapper.search(query, {
                            globalCount: 125
                        });
                    }
                    if (this.ezState.onlyRecipe) {
                        return await searchWrapper.search(query, {
                            recipeCount: 125
                        });
                    }
                    if (this.ezState.onlyRTE) {
                        return await searchWrapper.search(query, {
                            foodCount: 125
                        });
                    }
                }}
                searchValue={this.ezState.searchValue}
                offRecentResults
                showSelected
                delayed
                selected={this.ezState.selected}
                disableBySelected={this.ezState.selected}
                getElement={(el) => {
                    const name = fixCapitalizeName(el.name);
                    if (el.type === 'recipe') {
                        let renderedElement = <i key={el.id + '_' + el.brand} className='pl-2 ingredient-site-name'>{el.brand}</i>;
                        if (el.recipe_site_url) {
                            renderedElement = <a key={el.id + '_' + el.brand}
                                href={el.recipe_site_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="pl-1 ingredient-site-name">
                                {el.brand}
                            </a>
                        }
                        return [<Icon key={el.id} className='mr-75 recipe-icon' icon={icons.recipe} />, name, renderedElement]
                    }
                    const brand = fixCapitalizeName(removeDefaultBrand(el.brand))
                    if (el.type === 'food') {
                        return [<Icon key={el.id} className='mr-75 rte-icon' icon={icons.rteIcon} />, name, <i key={el.id + '_' + el.brand} className='pl-2 ingredient-brand-name'>{brand}</i>]
                    }
                    return name;
                }}
                onElement={(searchEl) => this.setSearchEl(searchEl)}
                onlyRecipe={this.ezState.onlyRecipe}
                onlyRTE={this.ezState.onlyRTE}
                disabled={!isInPreviewMode(this.ezState.mode)}
            />
            {this.renderOptions()}
        </div>
    }

    renderButtons() {
        return <div className={'settings-button ' +
            ((isInEditMode(this.ezState.mode) || isInDeleteMode(this.ezState.mode) || isInRestoreMode(this.ezState.mode)) ? 'visible' : '')
        }>
            {this.ezState.error ? <Text inlineBlock normal className="error">
                {this.props.errorText}
            </Text> : ''}

            <Button block noShadow bordered className="clear-btn" transformNormal
                onClick={() => this.onClear()}>
                Cancel
            </Button>

            <ProgressButton
                inProgress={this.ezState.loading}
                extraProps={{ block: true, noShadow: true, transformNormal: true, green: true, red: isInDeleteMode(this.ezState.mode) }}
                className="ml-auto"
                onClick={async () => {
                    if (!isInEditMode(this.ezState.mode) && !isInDeleteMode(this.ezState.mode)) return;
                    await this.onSubmit();
                }}
            >
                {this.renderSubmitButtonText()}
            </ProgressButton>
        </div>
    }

    renderModeDescription() {
        if (isInRestoreMode(this.ezState.mode)) {
            return 'Restore mode';
        }
        if (isInEditMode(this.ezState.mode)) {
            return 'Edit mode';
        }
        if (isInDeleteMode(this.ezState.mode)) {
            return 'Delete mode';
        }
        return 'Preview mode';
    }

    renderSubmitButtonText() {
        if (isInRestoreMode(this.ezState.mode)) {
            return 'Restore';
        }
        if (isInDeleteMode(this.ezState.mode)) {
            return 'Delete';
        }
        return 'Save';
    }

    render() {
        const selectedDay = getSelectedDate();
        const nextWeekStartDate = getStartOfWeek(selectedDay).add(1, 'week');
        const previousWeekDate = getStartOfWeek(selectedDay).subtract(1, 'week');
        return (
            <div className="settings-pattern my-menu">
                <Text large inlineBlock className="">
                    My Menu Week Grid - {this.renderModeDescription()}
                </Text>

                <div className='search-container'>
                    {this.renderInputSearch()}
                    {this.renderButtons()}
                </div>

                <div className="settings-content">
                    <div className="settings-switch-buttons">
                        <div className="left" onClick={() => this.onWeekChange(previousWeekDate.format('YYYY-MM-DD'))}>
                            <FA icon="fas fa-caret-left" />
                            <Text textLeft inlineBlock large className="text">
                                Previous Week
                            </Text>
                        </div>
                        <div className="right" onClick={() => this.onWeekChange(nextWeekStartDate.format('YYYY-MM-DD'))}>
                            <Text textRight inlineBlock large className="text">
                                Next Week
                            </Text>
                            <FA icon="fas fa-caret-right" />
                        </div>
                    </div>
                    <div className={this.ezState.loading || mainStore.weekLoading ? 'week-grid-my-menu-loading' : ''}>
                        <FA className="progress-icon" icon="fas fa-sync fa-spin" />
                        <WeekGridForMyMenuForMyMenu
                            deletedMap={this.ezState.deletedMap}
                            weeklyData={this.ezState.weeklyData}
                            selectedDay={mainStore.selectedDay}
                            personId={mainStore.profile.id}
                            onNewTileClick={this.onNewTileClick}
                            onDeleteTileClick={this.onDeleteTileClick}
                            onDayClick={() => { }}
                            mode={this.ezState.mode}
                            selected={this.ezState.selected}
                            count={this.ezState.count}
                            offset={this.ezState.offset}
                            handleTouchStart={(e) => {
                                this.ezState.touchStart = e.targetTouches[0].clientX;
                            }}
                            handleTouchMove={(e) => {
                                this.ezState.touchEnd = e.targetTouches[0].clientX;
                            }}
                            handleTouchEnd={() => {
                                if (this.ezState.touchStart - this.ezState.touchEnd > 70) {
                                    this.goNext()
                                }

                                if (this.ezState.touchStart - this.ezState.touchEnd < -70) {
                                    this.goPrev()
                                }
                            }}
                            goNextPage={() => {
                                this.goNext()
                            }}
                            goPreviousPage={() => {
                                this.goPrev()
                            }}
                        />
                    </div>

                    {this.renderButtons()}
                </div>
            </div>
        );
    }
}

export default view(SettingsPatternMyMenu);
