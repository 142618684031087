import { 
    APP_TYPE_HOSTNAMES,
    APP_TYPE_ID,
    CAREMOAT_CONFIG,
    DEFAULT_CONFIG,
    GLOBALLIVER_CONFIG
} from "./utils/constants/appType.js";

/**
 * @param {string} hostname
 * @returns {object} app config js object
 */
export const getAppConfig = (hostname = '') => {
    if (APP_TYPE_HOSTNAMES.GLOBALLIVER.includes(hostname)) {
        return GLOBALLIVER_CONFIG;
    }
    if (APP_TYPE_HOSTNAMES.CAREMOAT.includes(hostname)) {
        return CAREMOAT_CONFIG;
    }
    return DEFAULT_CONFIG;
}

/**
 * @param {number} invitation_code_id
 * @returns {object} app config js object
 */
export const getAppConfigById = (invitation_code_id) => {
    const appTypeString = APP_TYPE_ID[invitation_code_id];

    if (appTypeString === GLOBALLIVER_CONFIG.type) {
        return GLOBALLIVER_CONFIG;
    }
    if (appTypeString === CAREMOAT_CONFIG.type) {
        return CAREMOAT_CONFIG;
    }
    return DEFAULT_CONFIG;
}