import { nutritionSymbolMap, nutritionSymbols } from '../../subsystems/nutrition/nutrition.js';


/**
 * @typedef {import('./dbModel.js').AllTables} AllTables
 */


/**
 * 
 * @param {AllTables} target 
 * @returns []
 */
const id = function (target) { return ['id', target] };
const int = ['int'];
const bigint = ['bigint'];
const tinyint = ['tinyint'];
const string = ['string'];
const text = ['text'];
const decimal = ['decimal'];
const nutrition = ['nutrition'];
const gtin = ['gtin'];
const bool = ['bool'];
const date = ['date'];
const datetime = ['datetime'];
const json = ['json'];
const dbjson = ['dbjson'];

function parseIntOrDefault(value, defaultValue) {
    const result = parseInt(value);
    if (isNaN(result)) {
        return defaultValue;
    }
    return result;
}

function parseFloatOrDefault(value, defaultValue) {
    let result = defaultValue;
    if (typeof (value) === 'string') {
        value = value.replace(',', '.');
        if (value.charAt(0) === '.') {
            value = '0' + value;
        }
    }
    result = parseFloat(value);
    if (isNaN(result)) {
        return defaultValue;
    }
    return result;
}

const isISODateRegex = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)$/;
const isSQLDateTimeRegex = /^\d{4}-[01]\d-[0-3]\d [0-2]\d:[0-5]\d:[0-5]\d$/;
const isSQLDateRegex = /^\d{4}-[01]\d-[0-3]\d$/;
export const nullDate = [1970, 0, 1, 0, 0, 0];


export function isISODate(stringValue) {
    return isISODateRegex.exec(stringValue)?.length > 0 || false;
}

export function isSQLDateTime(stringValue) {
    return isSQLDateTimeRegex.exec(stringValue)?.length > 0 || false;
}

export function isSQLDate(stringValue) {
    return isSQLDateRegex.exec(stringValue)?.length > 0 || false;
}

export function isoDateToSQLDateTime(value) {
    const S = value.split('T');
    const T = S[1].split('.');
    return S[0] + ' ' + T[0];
}

export function isoDateToSQLDate(value) {
    const S = value.split('T');
    return S[0];
}

export function parseDate(value) {
    const values = value.split('-').map(x => +x);
    values[1] -= 1;
    return values;
}

export function parseTime(value) {
    return value.split(':').map(x => +x);
}

export function sqlDateTimeToYMDHMS(value) {
    if (isSQLDateTime(value)) {
        const dateComponent = parseDate(value.split(' ')[0])
        const timeComponent = parseTime(value.split(' ')[1])
        return [...dateComponent, ...timeComponent]
    }
    return nullDate;
}

export function sqlDateToYMDHMS(value) {
    if (isSQLDate(value)) {
        const dateComponent = parseDate(value)
        const timeComponent = [0, 0, 0];
        return [...dateComponent, ...timeComponent]
    }
    if (isSQLDateTime(value)) {
        const dateComponent = parseDate(value.split(' ')[0])
        const timeComponent = [0, 0, 0];
        return [...dateComponent, ...timeComponent]
    }
    return nullDate;
}


// SQL VALUE -> TYPE
function transformToId(value) { return ((value === 0 || value === undefined) ? 0 : parseIntOrDefault(value, 0)) }
function transformToInt(value) { return parseIntOrDefault(value, 0); }
function transformToString(value) { return '' + value; }
function transformToText(value) { return '' + value; }
function transformToDate(value) {
    if (value instanceof Date)
        return value;
    return value ? new Date(Date.UTC(...sqlDateToYMDHMS(value))) : new Date(Date.UTC(...nullDate));
}
function transformToDateTime(value) {
    if (value instanceof Date)
        return value;
    return value ? new Date(Date.UTC(...sqlDateTimeToYMDHMS(value))) : new Date(Date.UTC(...nullDate));
}
function transformToDecimal(value) { return parseFloatOrDefault(value, 0); }
function transformToBool(value) { return value > 0; }
function transformToJSON(value) { return JSON.parse(value) }
function transformToNutrition(value) {
    const result = {};
    if (value === '') return result;
    if (value === '{}') return result;
    const arr = ('' + value).split(',');
    for (let i = 0; i < arr.length; i++) {
        const el = arr[i];
        if (el === '') continue;
        const s = el.charAt(0);
        let V = parseFloat(el.substr(1));
        if (isNaN(V)) V = 0;
        if (V === undefined) V = 0;
        if (V === null) V = 0;
        result[nutritionSymbolMap[s]] = V;
    }
    return result;
}

// TYPE -> VALUE CONVERSIONS
// INTEGERS
function transformFromId(value) { return ((value === undefined) ? 0 : parseIntOrDefault(value, 0)) }
function transformFromInt(value) { return parseIntOrDefault(value, 0); }

// TEXT
function transformFromString(value) { return '' + value; }
function transformFromText(value) { return '' + value; }

// DATES
function transformFromDate(value) {
    let result = '';
    if (value instanceof Date) {
        result = value.toISOString().split('T')[0];
    } else if (isSQLDateTime(value)) {
        result = value.split(' ')[0];
    } else if (isSQLDate(value)) {
        result = value;
    } else if (isISODate(value)) {
        result = isoDateToSQLDate(value);
    } else {
        result = new Date(Date.UTC(...nullDate));
    }
    return result;
}
function transformFromDateTime(value) {
    let result = '';
    if (value instanceof Date) {
        result = isoDateToSQLDateTime(value.toISOString());
    } else if (isISODate(value)) {
        result = isoDateToSQLDateTime(value);
    } else if (isSQLDateTime(value)) {
        result = value;
    } else {
        result = '1970-01-01 00:00:00'
    }
    return result;
}

// MISC
function transformFromDecimal(value) { return parseFloatOrDefault(value, 0); }
function transformFromBool(value) { return value ? 1 : 0; }
function transformFromJSON(value) { return JSON.stringify(value) }
function transformFromNutrition(value) {
    const result = [];
    for (let i in value) {
        if (isNaN(value[i])) continue;
        if (value[i] === undefined) continue;
        if (value[i] === null) continue;
        if (nutritionSymbols[i] === undefined) {
            console.log('invalid nutrition:', i);
            continue
        }
        let v = parseFloat(value[i]).toFixed(2);
        if (v.substr(v.length - 1, 1) === '0') v = v.substr(0, v.length - 1);
        if (v.substr(v.length - 1, 1) === '0') v = v.substr(0, v.length - 1);
        if (v.substr(v.length - 1, 1) === '.') v = v.substr(0, v.length - 1);
        result.push(nutritionSymbols[i] + v);
    }
    return result.join(',');
}

const transformTo = {}
const transformFrom = {}
const transformDefault = {}

transformTo[id()[0]] = transformToId;
transformTo[int[0]] = transformToInt;
transformTo[bigint[0]] = transformToInt;
transformTo[tinyint[0]] = transformToInt;
transformTo[string[0]] = transformToString;
transformTo[text[0]] = transformToText;
transformTo[decimal[0]] = transformToDecimal;
transformTo[gtin[0]] = transformToString;
transformTo[bool[0]] = transformToBool;
transformTo[date[0]] = transformToDate;
transformTo[datetime[0]] = transformToDateTime;
transformTo[nutrition[0]] = transformToNutrition;
transformTo[json[0]] = transformToJSON;

transformFrom[id()[0]] = transformFromId;
transformFrom[int[0]] = transformFromInt;
transformFrom[bigint[0]] = transformFromInt;
transformFrom[tinyint[0]] = transformFromInt;
transformFrom[string[0]] = transformFromString;
transformFrom[text[0]] = transformFromText;
transformFrom[decimal[0]] = transformFromDecimal;
transformFrom[gtin[0]] = transformFromString;
transformFrom[bool[0]] = transformFromBool;
transformFrom[date[0]] = transformFromDate;
transformFrom[datetime[0]] = transformFromDateTime;
transformFrom[nutrition[0]] = transformFromNutrition;
transformFrom[json[0]] = transformFromJSON;

transformDefault[id()[0]] = () => 0;
transformDefault[int[0]] = () => 0;
transformDefault[bigint[0]] = () => 0;
transformDefault[tinyint[0]] = () => 0;
transformDefault[string[0]] = () => '';
transformDefault[text[0]] = () => '';
transformDefault[decimal[0]] = () => 0;
transformDefault[gtin[0]] = () => '000000000000';
transformDefault[bool[0]] = () => false;
transformDefault[date[0]] = () => new Date();
transformDefault[datetime[0]] = () => new Date();
transformDefault[nutrition[0]] = () => ({});
transformDefault[json[0]] = () => ({});
transformDefault[dbjson[0]] = () => ({});

export {
    id,
    int,
    bigint,
    tinyint,
    string,
    text,
    decimal,
    nutrition,
    gtin,
    bool,
    date,
    datetime,
    json,
    dbjson,

    transformToId,
    transformToInt,
    transformToString,
    transformToText,
    transformToDecimal,
    transformToBool,
    transformToDate,
    transformToDateTime,
    transformToNutrition,
    transformToJSON,

    transformFromId,
    transformFromInt,
    transformFromString,
    transformFromText,
    transformFromDecimal,
    transformFromBool,
    transformFromDate,
    transformFromDateTime,
    transformFromNutrition,
    transformFromJSON,

    transformTo,
    transformFrom,
    transformDefault
}