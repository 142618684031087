import mainStore from 'storeUtils/mainStore';
import { pageNeedsReloading } from 'storeUtils/storeUtils';
import { fetchJSON } from 'utils/fetchJSON';

export function mealModifyIngredient(person_meal_id, person_id, new_food_id, amount, portion_type_id, date, meal_ingredient_to_replace_id = null) {
    return fetchJSON('/api/meal/modify-ingredient', { person_meal_id, person_id, new_food_id, amount, portion_type_id, date, meal_ingredient_to_replace_id, baseInfoTimeUTC: mainStore.baseInfoTimeUTC })
        .then((json) => {

            if (json.request?.error) {
                pageNeedsReloading();
            }
            return json;
        })
        .catch(error => {
            console.log('[api/meal/mealIngredient.js - mealModifyIngredient] Error: ', error);
            return undefined;
        });
}


export function mealDeleteIngredient(person_id, date, meal_ingredient_id) {
    return fetchJSON('/api/meal/delete-ingredient', { person_id, date, meal_ingredient_id, baseInfoTimeUTC: mainStore.baseInfoTimeUTC })
        .then((json) => {
            return json;
        })
        .catch(error => {
            console.log('[api/meal/mealIngredient.js - mealDeleteIngredient] Error: ', error);
            return undefined;
        });
}
