import React from 'react';
import { view } from '@risingstack/react-easy-state';

import './Tooltip.scss'

export default view((props) => {

    return (
        <span className={
            'tooltip ' +
            (props.small ? 'small ' : '') +
            (props.black ? 'black ' : '') +
            (props.inlineBlock ? 'd-inline-block ' : '') +
            (props.mobileHidden ? 'tooltip-mobile-hidden ' : '') +
            (props.className ? props.className : '')
        }>
            {props.text ? <span className="tooltip-content">{props.text}</span> : ''}
            {props.children}
        </span>
    );
});