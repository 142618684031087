import React from 'react';

const wellnessIcon = () => (
    <svg viewBox="0 0 60 53.314">
        <path d="M26.306,37.685l-6.703-16.756l-2.398,7.196H3.567c3.655,5.675,9.994,11.095,17.996,17.913
    c2.288,1.95,4.723,4.024,7.281,6.262L30,53.314l1.157-1.013c2.558-2.238,4.992-4.312,7.28-6.262
    C46.44,39.22,52.779,33.8,56.434,28.125H31.087L26.306,37.685z"/>
        <path d="M44.187,0C36.987,0.026,32.403,4.934,30,9.436C27.609,4.961,23.044,0,15.934,0
    c-0.041,0-0.081,0-0.12,0C7.448,0.067,0.447,7.203,0.022,16.246C-0.12,19.254,0.44,21.99,1.564,24.61h13.107l4.633-13.898
    l7.359,18.399l2.25-4.501h29.523c1.125-2.62,1.685-5.355,1.542-8.364C59.553,7.203,52.552,0.067,44.187,0z"/>
    </svg>
)

export default wellnessIcon;