import React from 'react';
import PropTypes from 'prop-types';
import { view } from '@risingstack/react-easy-state';

import './Text.scss'

/**
 * @typedef TextProps
 * @type {object}
 * @property {boolean} block
 * @property {boolean} green
 * @property {boolean} blue
 * @property {boolean} red
 * @property {boolean} accent
 * @property {boolean} left
 * @property {boolean} right
 * @property {boolean} textLeft
 * @property {boolean} textCenter
 * @property {boolean} textRight
 * @property {boolean} light
 * @property {boolean} lighter
 * @property {boolean} white
 * @property {boolean} tallLine
 * @property {boolean} tiny
 * @property {boolean} small
 * @property {boolean} normal
 * @property {boolean} large
 * @property {boolean} larger
 * @property {boolean} header
 * @property {boolean} bold
 * @property {boolean} unbold
 * @property {boolean} block
 * @property {boolean} blockGreen
 * @property {boolean} fullWidth
 * @property {boolean} inlineBlock
 * @property {boolean} cursorPointer
 * @property {boolean} hasError
 * @property {boolean} uppercase
 * @property {boolean} underline
 * @property {boolean} hoverUnderline
 * @property {boolean} breakWord
 * @property {string} className
 * @property {any} children React.Element
 * @property {funtion} onClick
*/

/**
 * @param {TextProps} props
 */
const RawText = (props) => (
    <span className={
        (props.green ? 'text-green ' : '') +
        (props.blue ? 'text-blue ' : '') +
        (props.red ? 'text-red ' : '') +
        (props.accent ? 'text-accent ' : '') +
        (props.left ? 'float-left ' : '') +
        (props.right ? 'float-right ' : '') +
        (props.textLeft ? 'text-left ' : '') +
        (props.textCenter ? 'text-center ' : '') +
        (props.textRight ? 'text-right ' : '') +
        (props.light ? 'text-light ' : '') +
        (props.lighter ? 'text-lighter ' : '') +
        (props.white ? 'text-white ' : '') +
        (props.tallLine ? 'text-tall-line ' : '') +
        (props.tiny ? 'font-tiny ' : '') +
        (props.small ? 'font-small ' : '') +
        (props.normal ? 'font-normal ' : '') +
        (props.large ? 'font-large ' : '') +
        (props.larger ? 'font-larger ' : '') +
        (props.header ? 'font-header ' : '') +
        (props.bold ? 'text-bold ' : '') +
        (props.unbold ? 'text-unbold ' : '') +
        (props.block ? 'text-block ' : '') +
        (props.blockGreen ? 'text-block text-block-green ' : '') +
        (props.fullWidth ? 'full-width ' : '') +
        (props.inlineBlock ? 'd-inline-block ' : '') +
        (props.cursorPointer ? 'cursor-pointer ' : '') +
        (props.hasError ? 'text-has-error ' : '') +
        (props.uppercase ? 'uppercase ' : '') +
        (props.underline ? 'underline ' : '') +
        (props.hoverUnderline ? 'hover-underline ' : '') +
        (props.breakWord ? 'break-word ' : '') +
        (props.className ? props.className : '')
    } onClick={props.onClick}>{props.children}</span>
);

RawText.propTypes = {
    green: PropTypes.bool,
    blue: PropTypes.bool,
    red: PropTypes.bool,
    accent: PropTypes.bool,
    left: PropTypes.bool,
    right: PropTypes.bool,
    textLeft: PropTypes.bool,
    textCenter: PropTypes.bool,
    textRight: PropTypes.bool,
    light: PropTypes.bool,
    lighter: PropTypes.bool,
    white: PropTypes.bool,
    tallLine: PropTypes.bool,
    tiny: PropTypes.bool,
    small: PropTypes.bool,
    normal: PropTypes.bool,
    large: PropTypes.bool,
    larger: PropTypes.bool,
    header: PropTypes.bool,
    bold: PropTypes.bool,
    unbold: PropTypes.bool,
    block: PropTypes.bool,
    blockGreen: PropTypes.bool,
    fullWidth: PropTypes.bool,
    inlineBlock: PropTypes.bool,
    cursorPointer: PropTypes.bool,
    hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    uppercase: PropTypes.bool,
    underline: PropTypes.bool,
    hoverUnderline: PropTypes.bool,
    breakWord: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.any
};


/**
 * @component
 * @type {{ new(props: TextProps): {
 *   props: { 
 *     green: boolean,
 *     blue: boolean,
 *     red: boolean,
 *     accent: boolean,
 *     left: boolean,
 *     right: boolean,
 *     textLeft: boolean,
 *     textCenter: boolean,
 *     textRight: boolean,
 *     light: boolean,
 *     lighter: boolean,
 *     white: boolean,
 *     tallLine: boolean,
 *     tiny: boolean,
 *     small: boolean,
 *     normal: boolean,
 *     large: boolean,
 *     larger: boolean,
 *     header: boolean,
 *     bold: boolean,
 *     unbold: boolean,
 *     block: boolean,
 *     blockGreen: boolean,
 *     fullWidth: boolean,
 *     inlineBlock: boolean,
 *     cursorPointer: boolean,
 *     hasError: boolean,
 *     uppercase: boolean,
 *     underline: boolean,
 *     hoverUnderline: boolean,
 *     breakWord: boolean,
 *     className: string,
 *     onClick: Function,
 *     children: any
 *    }
 *  }
 * }}
 */
const Text = view(RawText);
export default Text;