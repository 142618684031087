import React from 'react';
import { NavLink } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state';

import './HeaderBarButton.scss';

function isActive(match, location, to) {
    //console.log(location.pathname, to)
    return location.pathname === to;
}

const HeaderBarButton = view((props) => {
    if (props.to) {
        return (
            <NavLink
                to={props.to}
                className={
                    "header-bar-button " +
                    (props.left ? 'left ' : '') +
                    (props.right ? 'right ' : '') +
                    (props.dark ? 'dark ' : '') +
                    (props.darker ? 'darker ' : '') +
                    (props.disabled ? 'disabled ' : '') +
                    (props.animated ? 'animated ' : '') +
                    (props.className ? props.className : '')
                }
                exact={props.exact}
                isActive={(match, location) => isActive(match, location, props.to)} >{props.children}</NavLink>
        );
    }
    return (
        <div className={
            "header-bar-button " +
            (props.left ? 'left ' : '') +
            (props.right ? 'right ' : '') +
            (props.dark ? 'dark ' : '') +
            (props.darker ? 'darker ' : '') +
            (props.disabled ? 'disabled ' : '') +
            (props.animated ? 'animated ' : '') +
            (props.className ? props.className : '')
        } onClick={props.onClick} >{props.children}</div>
    );
});

export default HeaderBarButton;