import React from 'react';

const userIcon = () => (
    <svg viewBox="0 0 45.078 60">
        <path d="M29.292,27.135c4.557-2.43,7.699-7.176,7.699-12.69C36.991,6.461,30.519,0,22.539,0
    C14.555,0,8.091,6.461,8.091,14.444c0,5.515,3.142,10.261,7.692,12.69C6.657,30.027,0,38.595,0,48.632V60h45.078V48.632
    C45.078,38.595,38.422,30.027,29.292,27.135z"/>
    </svg>
)

export default userIcon;