import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';

import "./LoginScreen.scss"

import Container from 'components/Container';
import { Input } from 'components/Input';
import { Icon, icons } from 'components/Icon';
import Button from 'components/Button';
import Space from 'components/Space';
import Message from 'components/Message';
import Text from 'components/Text';

import { activationResend } from 'api/login';

import { validateRequiredField, validateEmail } from 'utils/forms';
import getLogoImg from 'utils/getLogoImg';

import { verifyLoginApiAction } from 'storeUtils/storeUtils';
import { layoutStore } from 'storeUtils/layoutStore';

class LoginScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mail: '',
            password: ''
        }
    }

    componentWillUnmount() {
        layoutStore.request = {
            progress: false,
            message: false,
            error: false,
            success: false
        }
        layoutStore.profileWizard.errors = {}
        layoutStore.selectUserProfileLoading = false;
    }

    render() {
        return (
            <div className="login">
                <div className="login-panel">
                    <Container>
                        <div className="login-header">
                            {getLogoImg()}
                        </div>
                        <form ref={(elem) => this.formElem = elem} autoComplete='on' className={"login-body" + ((layoutStore.request.message && layoutStore.request.success) ? ' fadeout' : '')} onSubmit={this.onSubmit} method="post" action="/api/login/login">
                            <Input placeholder="E-Mail address" name="mail"
                                leftIcon={icons.user}
                                value={this.state.mail}
                                onChange={(e) => this.setState({ mail: e.target.value })}
                                onKeyDown={(e) => this._onKeyDown(e)}
                                onBlur={() => validateEmail('mail', this.state.mail, layoutStore.profileWizard.errors)}
                                autoComplete="email"
                                hasError={layoutStore.profileWizard.errors.mail} />
                            <Space />
                            <Input spaced
                                placeholder="Password" name="password"
                                leftIcon={icons.password}
                                value={this.state.password}
                                onChange={(e) => this.setState({ password: e.target.value })}
                                onBlur={() => validateRequiredField({
                                    fieldName: 'password',
                                    value: this.state.password,
                                    validationErrorObject: layoutStore.profileWizard.errors
                                })}
                                onSubmit={this.onSubmit}
                                onKeyDown={(e) => this._onKeyDown(e)}
                                hasError={layoutStore.profileWizard.errors.password}
                                customErrorText={layoutStore.profileWizard.errors.password}
                                autoComplete="current-password"
                                password />
                        </form>
                        <div className={"login-forgot pb-1" + ((layoutStore.request.message && layoutStore.request.success) ? ' fadeout' : '')}>
                            <Button to="/forgotpassword" clear transformNormal >Forgot Password</Button>
                        </div>
                        <div className="login-message pb-1">
                            <Message request={layoutStore.request} extra={layoutStore.request.enabled !== undefined}>
                                {(layoutStore.request.enabled !== undefined) && <Text inlineBlock underline bold
                                    className="pl-1 cursor-pointer resend-activation-link"
                                    onClick={() => this.resendActivationLink()}
                                >
                                    Resend Activation Link
                                </Text>
                                }
                            </Message>
                        </div>
                        {layoutStore.request.message && layoutStore.request.success && <div className="login-footer login-success">
                            <Button green transformNormal onClick={() => {
                                layoutStore.request = {
                                    progress: false,
                                    message: false,
                                    error: false
                                }
                            }}><Icon icon={icons.arrowHeadLeft} color="#FFF" /> Go Back</Button>
                        </div>}
                        <div className={"login-footer" + ((layoutStore.request.message && layoutStore.request.success) ? ' fadeout' : '')}>
                            <Button green transformNormal to="/register" className="register-button register">Create Account</Button>
                            <Button className="login-button" disabled={layoutStore.insideSubmission} green transformNormal onClick={this.onSubmit} type="submit" ><Icon icon={icons.password} color="#FFF" /> Log In</Button>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }

    _onKeyDown(e) {
        if (e.key === 'Enter') {
            this.onSubmit(e)
        }
    }

    validateAll() {
        let isValid = true;

        if (!validateRequiredField({
            fieldName: 'mail',
            value: this.state.mail,
            validationErrorObject: layoutStore.profileWizard.errors
        })) {
            isValid = false
        }

        if (!validateRequiredField({
            fieldName: 'password',
            value: this.state.password,
            validationErrorObject: layoutStore.profileWizard.errors
        })) {
            isValid = false
        }

        return isValid
    }

    resendActivationLink() {
        activationResend({ mail: this.state.mail, userId: layoutStore.request.uid })
    }

    async onRememberUserProfile(rememberedProfileId) {
        layoutStore.selectUserProfileLoading = true;

        // await selectUserProfile(rememberedProfileId)

        layoutStore.selectUserProfileLoading = false;
        layoutStore.insideSubmission = false;
    }

    onSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (layoutStore.insideSubmission) return;

        if (this.validateAll()) {
            layoutStore.insideSubmission = true;
            // layoutStore.loggedIn = true
            await verifyLoginApiAction(this.state.mail, this.state.password);
        } else {
            layoutStore.request = {
                message: '',
                success: false,
                error: false
            }
        }
    }
}

export default view(LoginScreen);
