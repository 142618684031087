import React from 'react';

const medalIcon = () => (
    <svg version="1.1" id="Isolation_Mode" x="0px" y="0px" viewBox="0 0 184.1 176.6">
        <g>
            <path d="M90,89.4c-21.1,0-38.2,16.8-38.2,37.6s17.1,37.6,38.2,37.6s38.2-16.8,38.2-37.6S111.1,89.4,90,89.4z
		 M103.8,132l3.8,19.9l-18.2-9.2l-17.9,9.7l3.1-20l-14.8-13.9l20.1-3.2L88.7,97l9.3,18l20.2,2.6L103.8,132z"/>
            <path d="M95.1,81.7c0.8-1.1,1.2-2.4,1.2-3.8c0-1.7-0.7-3.3-1.8-4.4l45.9-69.1h-20.3L90.2,69.1L62.9,4.4H44.3L85.9,73
		c-1.4,1.2-2.3,3-2.3,4.9c0,1.4,0.5,2.7,1.2,3.8c-23.1,2.5-41.1,21.8-41.1,45.1c0,25.1,20.7,45.4,46.3,45.4
		c25.6,0,46.3-20.3,46.3-45.4C136.2,103.4,118.3,84.2,95.1,81.7z M87.9,76.4c0.2,0.8,0.6,1.3,1,1.6l0.1,0.1c0,0,0.5,0.3,1.1,0.3
		c0,0,0,0,0,0c0,0,0,0,0,0c0.7,0,1.3-0.3,1.3-0.3l1.5-2.3c0.5,0.6,0.7,1.3,0.7,2.1c0,1.6-1,2.9-2.5,3.4c-0.4,0-0.9,0-1.3,0
		c-0.4,0-0.9,0-1.3,0c-1.4-0.5-2.5-1.9-2.5-3.4c0-1,0.5-2,1.2-2.6L87.9,76.4z M90,166.5c-21.9,0-39.8-17.5-39.8-39.1
		c0-21.6,17.8-39.1,39.8-39.1s39.8,17.5,39.8,39.1C129.7,148.9,111.9,166.5,90,166.5z"/>
        </g>
    </svg>
)

export default medalIcon;