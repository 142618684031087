import React from 'react';

const linkedinIcon = () => (
    <svg viewBox="0 0 60 60">
        <path d="M30,0C13.432,0,0,13.431,0,30c0,16.568,13.432,30,30,30c16.569,0,30-13.432,30-30
    C60,13.431,46.569,0,30,0z M23.197,43.333h-6.185V24.099h6.185V43.333z M20.074,21.58c-2.02,0-3.657-1.591-3.657-3.55
    c0-1.96,1.637-3.549,3.657-3.549c2.021,0,3.658,1.589,3.658,3.549C23.732,19.989,22.095,21.58,20.074,21.58z M46.374,43.333
    h-6.155c0,0,0-7.326,0-10.097c0-2.769-1.093-4.316-3.367-4.316c-2.475,0-3.769,1.611-3.769,4.316c0,2.963,0,10.097,0,10.097H27.15
    V24.099h5.932v2.591c0,0,1.783-3.178,6.022-3.178c4.236,0,7.269,2.492,7.269,7.643C46.374,36.306,46.374,43.333,46.374,43.333z"/>
    </svg>
)

export default linkedinIcon;