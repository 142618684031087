import React from 'react';

const addPenIconCircle = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 70 70" >
    <g>
      <path d="M35,0.5C16,0.5,0.5,16,0.5,35S16,69.5,35,69.5S69.5,54,69.5,35S54,0.5,35,0.5z M13.2,28.4v-7.5h8.4v-8.8h7.8
          v8.8h8.4v7.5h-8.4v8.9h-7.8v-8.9H13.2z M31.4,59.4C31.3,59.4,31.3,59.4,31.4,59.4h-8.8l0.1-8.7c0,0.1-0.1,0.1,0,0L45,28.4l8.7,8.7
          L31.4,59.4z M56.2,34.5l-8.6-8.6l3.9-3.9c1.1-1.1,2.9-1.1,4.1,0l4.6,4.6c1.1,1.1,1.1,3,0,4.1L56.2,34.5z"/>
      <path d="M22.7,50.7C22.6,50.8,22.7,50.8,22.7,50.7L22.7,50.7z" />
    </g>
  </svg>
)

export default addPenIconCircle;