import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';

import "./Activation.scss"

import Container from 'components/Container';
import Icon from 'components/Icon';
import Text from 'components/Text';
import Message from 'components/Message';
import Button from 'components/Button';


import { mainStore } from 'storeUtils/mainStore';
import layoutStore from 'storeUtils/layoutStore';

import getLogoImg from 'utils/getLogoImg';
import { validateRequiredField } from 'utils/forms';

import { activation } from 'api/login/activation';
class Activation extends Component {

    constructor(props) {
        super(props)

        const queryParams = new URLSearchParams(window.location.search);
        const codeKey = queryParams.get('c')
        const userId = queryParams.get('u')

        this.state = {
            code: codeKey ? codeKey : '',
            userId: userId
        }

        if (this.validateAll()) {

            const data = {
                code: this.state.code,
                userId: this.state.userId
            }

            try {
                activation(data).then((result) => {
                    if (!layoutStore.request.error) {
                        layoutStore.profileWizard.errors = {}
                        if (result) {
                            layoutStore.request = result;
                        }
                        this.setState({
                            code: '',
                            userId: ''
                        })
                    }
                }).catch(error => {
                    if (error) {
                        layoutStore.request = error;
                    }
                })
            } catch (error) {
                console.log(error)
            }

        }
    }


    componentWillUnmount() {
        layoutStore.profileWizard.errors = {}
        layoutStore.request = {}
    }

    validateAll() {
        let isValid = true

        if (!validateRequiredField({
            fieldName: 'code',
            value: this.state.code,
            validationErrorObject: layoutStore.profileWizard.errors
        })
        ) {
            isValid = false;
        }
        return isValid
    }

    _onKeyDown(e) {
        if (e.key === 'Enter') {
            this.onSubmit(e)
        }
    }

    render() {
        return (
            <div className="login forgot-form">
                <div className="login-panel">
                    <Container>
                        <div className="login-header">
                            {getLogoImg()}
                        </div>
                        <Container fill>
                            <Text inlineBlock large className="pb-3">Account Activation</Text>
                            <Message request={layoutStore.request} textCenter preLine />
                            <Button to="/" right green block className="mt-3">
                                <Icon icon="fal fa-save" /> Go To MainPage
                            </Button>
                        </Container>
                    </Container>
                </div>
            </div>
        );
    }
}

export default view(Activation);
