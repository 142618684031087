import React from 'react';

const passwordIcon = () => (
    <svg viewBox="0 0 48.308 60">
        <path d="M43.057,19.841h-3.647v-4.57C39.409,6.853,32.551,0,24.129,0C15.71,0,8.861,6.853,8.861,15.271
			v4.57H5.197C2.339,19.841,0,22.184,0,25.038v29.717C0,57.657,2.339,60,5.197,60h37.86c2.905,0,5.252-2.343,5.252-5.245V25.038
			C48.308,22.184,45.961,19.841,43.057,19.841z M15.764,15.271c0-4.611,3.75-8.371,8.365-8.371c4.618,0,8.367,3.76,8.367,8.371v4.57
			H15.764V15.271z M26.955,46.248v7.588c0,0.327-0.272,0.559-0.596,0.559h-3.641c-0.275,0-0.545-0.232-0.545-0.559v-7.588
			c-2.486-0.967-4.281-3.365-4.281-6.219c0-3.671,2.99-6.661,6.675-6.661c3.675,0,6.665,2.99,6.665,6.661
			C31.232,42.884,29.438,45.281,26.955,46.248z"/>
    </svg>
)

export default passwordIcon;