import { removeDefaultBrand } from 'storeUtils/layoutStore';
import shoppingStore from './shoppingStore';



export const shopUtil = {
    /* ======================================== GENERATION ======================================== */
    getDictionary: (shoppingElements) => {
        const result = {}
        for (const shoppingElement of shoppingElements) {
            const key = shopUtil.getKey(shoppingElement);
            if (result[key]) {
                result[key].push(shoppingElement);
            } else {
                result[key] = [shoppingElement];
            }
        }
        return result;
    },

    /* ======================================== DISPLAY ======================================== */
    getSum: (shoppingElements, withOffset = true) => {
        const sum = shoppingElements.reduce((p, c) => {
            return p + (withOffset || !c.is_offset ? c.amount : 0)
        }, 0);
        return sum;
    },

    getMenuSum: (shoppingElements) => {
        const sum = shoppingElements.reduce((p, c) => {
            return p + (!c.shopping_additional_id && !c.is_offset ? c.amount : 0)
        }, 0);
        return sum;
    },

    getIsDeleted: (shoppingElements) => {
        return shoppingElements.some((se) => se.deleted) ? 1 : 0;
    },

    getIsDirty: (shoppingElements) => {
        return shoppingElements.some(x => x.is_dirty);
    },

    isPortionType: (shoppingElements) => {
        const id = shoppingElements[0]?.portion_type_id;
        if (id) {
            return true;
        }
        return false;
    },

    getPortionName: (shoppingElements, portionTypes) => {
        const id = shoppingElements[0]?.portion_type_id;
        if (id) {
            return portionTypes.find(x => x.id === id).name
        }
        return shoppingElements[0]?.unit ?? '';
    },

    getOriginalPortionName: (shoppingElements, portionTypes) => {
        const id = shoppingElements[0]?.original_portion_type_id;
        if (id) {
            return portionTypes.find(x => x.id === id).name
        }
        return shoppingElements[0]?.unit ?? '';
    },

    getPortionTypeId: (shoppingElements) => {
        const id = shoppingElements[0]?.portion_type_id;
        return id ?? 0;
    },

    getOriginalPortionTypeId: (shoppingElements) => {
        const id = shoppingElements[0]?.original_portion_type_id;
        return id ?? 0;
    },

    getUnit: (shoppingElements) => {
        const unit = shoppingElements[0]?.unit;
        return unit ?? '';
    },

    getRecipeSiteName: (shoppingElements, recipes) => {
        const id = shopUtil.getRecipeId(shoppingElements);
        const recipe = recipes.find(x => x.id == id);
        return recipe?.recipe_site_name ? recipe.recipe_site_name : '';
    },

    getRecipeSiteUrl: (shoppingElements, recipes) => {
        const id = shopUtil.getRecipeId(shoppingElements);
        const recipe = recipes.find(x => x.id == id);
        return recipe?.recipe_site_url ? recipe.recipe_site_url : '';
    },

    getBrand: (shoppingElements, foods) => {
        const id = shopUtil.getFoodId(shoppingElements);
        const food = foods.find(x => x.id == id);
        const brand = removeDefaultBrand(food?.brand) || '';
        return brand.toLowerCase() !== 'generic' ? brand : '';
    },

    getKey: (shoppingElement) => {
        return `${shoppingElement.food_id}_${shoppingElement.portion_type_id}_${shoppingElement.unit}`
    },

    getOffset: (shoppingElements) => {
        return shoppingElements.find(x => x.is_offset);
    },

    getRecipeId: (shoppingElements) => {
        return shoppingElements[0]?.recipe_id || 0;
    },

    getFoodId: (shoppingElements) => {
        return shoppingElements[0]?.food_id || 0;
    },

    getName: (shoppingElements, foods) => {
        return foods.find(x => x.id === shopUtil.getFoodId(shoppingElements)).name;
    },

    getShoppingCategoryId: (shoppingElements, foods) => {
        const food_id = shoppingElements[0]?.food_id || 0
        if (food_id) {
            return foods.find(x => x.id === food_id).shopping_category_id;
        }
        return 0;
    },

    getDontBuy: (shoppingElements) => {
        return shoppingElements[0]?.dont_buy || false;
    },

    /* ======================================== OPERATIONS ======================================== */
    setAmount: (shoppingElements, value) => {
        const sum = shopUtil.getSum(shoppingElements);
        if (value != sum) {
            let offset = shopUtil.getOffset(shoppingElements);
            if (!offset) {
                offset = shopUtil.createOffset(shoppingElements);
            }
            offset.amount += (value - sum)
            shoppingStore.unsavedChanges++;
            offset.is_dirty = true;
        }
    },

    setDeleted: (shoppingElements, value) => {
        for (const se of shoppingElements) {
            se.deleted = value;
            se.is_dirty = true;
        }
        shoppingStore.unsavedChanges++;
    },

    toggleDontBuy: (shoppingElements) => {
        for (const se of shoppingElements) {
            se.dont_buy = !se.dont_buy;
            se.is_dirty = true;
        }
        shoppingStore.unsavedChanges++;
    },

    createOffset: (shoppingElements) => {
        const newOffset = { ...shoppingElements[0] };
        newOffset.id = 0;
        newOffset.is_offset = true;
        newOffset.is_dirty = true;
        newOffset.amount = 0;
        shoppingElements.push(newOffset);
        shoppingStore.unsavedChanges++;
        return newOffset;
    },

    setPortionType: (shoppingElements, portion_type_id, unit = '') => {
        for (const se of shoppingElements) {
            se.portion_type_id = portion_type_id;
            se.unit = unit;
            se.is_dirty = true;
        }
        shoppingStore.unsavedChanges++;
        return shoppingElements;
    },

    mergeOffsets: (shoppingElements) => {
        const toRemove = [];
        const offsets = shoppingElements.filter(x => x.is_offset);
        if (offsets.length > 1) {
            for (let index = 1; index < offsets.length; index++) {
                const offset = offsets[index];
                offsets[0].amount += offset.amount;
                offset.amount = 0;
                toRemove.push(offset);
            }
        }
        return toRemove;
    },

    /* ======================================== UTILS ======================================== */

    createRangeData: (shoppingElementObject) => {
        if (!shoppingElementObject) return;

        const { done, obsolete, shopping_additional, shopping_elements, foods, recipes } = shoppingElementObject
        const shopDict = shopUtil.getDictionary(shopping_elements);
        shoppingStore.unsavedChanges = 0;

        return {
            done,
            obsolete,
            foods: foods,
            recipes: recipes,
            raw_elements: shopping_elements,
            additional: shopping_additional,
            dictionary: shopDict,
        }
    },

    getPopulatedCategories: (rangeData, allCategories) => {
        if (!rangeData.dictionary) {
            //compatibility with old system
            return [{
                type: 1,
                name: 'Shopping List',
                items: [],
            }];
        }

        const categoriesDictionary = {
            various: {
                name: 'Various Non-Food Items',
                order: 998,
                items: [],
            },
            deleted: {
                name: 'Deleted items',
                order: 999,
                items: [],
                deleted: true
            },

        };
        for (const key in rangeData.dictionary) {
            const shoppingElements = rangeData.dictionary[key];
            const catId = shopUtil.getShoppingCategoryId(shoppingElements, rangeData.foods);
            if (shopUtil.getIsDeleted(shoppingElements)) {
                // add to deleted
                categoriesDictionary.deleted.items.push(shoppingElements);
            } else if (!catId) {
                // add to various
                categoriesDictionary.various.items.push(shoppingElements);
            }
            else {
                // add to specific shopping category
                const categoryInfo = allCategories.find(x => x.id == catId);
                let category = categoriesDictionary[catId];
                if (categoryInfo && !category) {
                    category = { id: catId, name: categoryInfo.name || '', order: categoryInfo.order, items: [] };
                    categoriesDictionary[catId] = category;
                }
                if (!categoryInfo) {
                    categoriesDictionary.various.items.push(shoppingElements);
                } else {
                    category.items.push(shoppingElements);
                }
            }
        }

        let result = Object.values(categoriesDictionary);
        for (const cat of result) {
            cat.items = cat.items.sort((a, b) => { return shopUtil.getName(a, rangeData.foods).localeCompare(shopUtil.getName(b, rangeData.foods)) })
        }
        result = result.sort((a, b) => a.order - b.order)
        result = result.filter(x => x.items.length !== 0);
        return result;
    },
}