import moment from 'moment';

import { addModal } from 'components/ModalWrapper/ModalWrapper.js';
import InfoModal, { getInfoProps } from 'components/InfoModal/InfoModal.js';
import { addNotification } from 'components/NotificationWrapper/NotificationWrapper.js';

import MealWarningModal from 'screens/MealWarningModal/MealWarningModal.js';

import { USER_PERSON_RELATIONSHIP } from 'utils/constants/person.js';

import { DAILY_KCAL_TOLERANCE_MULTIPLIER, getDailyNutrition, getRecipeNutritionKcal } from 'subsystems/nutrition/nutrition.js';
import recommendedNutrition from 'subsystems/MyDB/recommendedNutrition.js';

import getWeeklyMenu from 'api/menu/getWeeklyMenu.js';
import { selectUserProfile, updateStore, verifyLogin } from 'api/login/verifyLogin.js';
import recalculateMenuForDates from 'api/menu/recalculateMenuForDates.js';

import mainStore, { defaults } from 'storeUtils/mainStore'
import layoutStore from 'storeUtils/layoutStore';
import shoppingStore from './shoppingStore';
import { getStartOfWeek } from 'utils/date/getStartOfWeek';
import getMealSubstituteWarnings from 'api/meal/getMealSubstituteWarnings';
import getAssembledRecipe from 'api/recipe/recipeGetAssembled';
import getAssembledFood from 'api/recipe/recipeGetAssembledFood';

function getCurrentKCal(day) {
    let summedKCal = 0;

    day.menu.forEach((meal) => meal.recipes.forEach((recipe) => summedKCal += (recipe.completed ? getRecipeNutritionKcal(recipe) : 0)));
    // console.log('-----------------------------------------------', summedKCal, day.men)
    return summedKCal;
}

function getTotalKCal(day, mealIdOverride, mealAmountOverride) {
    let summedKCal = 0;

    // meal.recipes are are actually person_meals
    day.menu.forEach((meal) => meal.recipes.forEach((person_meal) => {
        let amount = person_meal.amount;
        if (mealIdOverride === person_meal.id) {
            amount = mealAmountOverride;
        }
        summedKCal += getRecipeNutritionKcal(person_meal, false, amount)
    }));
    return summedKCal;
}

function recalculateWeeklyNutrition() {
    const chartData = [];
    for (let d = 0; d < 7; d++) {
        const menu = mainStore.weeklyData[d].menu;
        const day = {
            nutrition: getDailyNutrition(menu),
            menu: menu
        };

        mainStore.weeklyData[d].nutrition = day.nutrition;
        const current = getCurrentKCal(day);
        const date = moment(mainStore.weeklyData[d].date, 'YYYY-MM-DD');

        const preset = mainStore.presets.find((e) => e.id === mainStore.profile.preset);

        const zeroNutrients = recommendedNutrition.getNutrients(mainStore.profile, d, preset);

        chartData.push({
            label: date.format('ddd'),
            date: mainStore.weeklyData[d].date,
            planned: zeroNutrients.kcal - zeroNutrients.weightLoss.kcalDiff,
            zero: zeroNutrients.kcal,
            inMenu: getTotalKCal(day),
            current: current,
            anythingEntered: current > 0,
        })
    }
    mainStore.chartData = chartData;
}

function getCurrentMeal() {
    const menu = mainStore.menu.find((m) => !m.recipes.every((r) => r.completed));
    if (menu) {
        return menu;
    } else {
        return {
            name: '',
            recipes: []
        };
    }
}

function selectDay(day) {
    recalculateWeeklyNutrition();
    if (Array.isArray(day.menu)) {
        day.menu = day.menu.sort((a, b) => a.order - b.order)
    }
    mainStore.menu = day.menu.filter((me) => {
        if (me.order === 2 && mainStore.profile.extra?.snackTimes?.morning !== true) return false;
        if (me.order === 4 && mainStore.profile.extra?.snackTimes?.afternoon !== true) return false;
        if (me.order === 6 && mainStore.profile.extra?.snackTimes?.evening !== true) return false;
        return true;
    });
    mainStore.selectedDay = day;
    mainStore.currentMeal = getCurrentMeal();
    mainStore.menuNutrition = day.nutrition;

    mainStore.selectedNutritionTitle = 'day: ' + moment(day.date, 'YYYY-MM-DD').format('MMM DD');
}

/**
 * Checks whether the current day's calories are within the daily allowance.
 * @param {number} [tolerance=1.01] (optional) margin of tolerance for the comparison
 * @param {number} editedMealId (optional) used to properly calculate new nutrition
 * @param {number} editedMealAmount (optional) used to properly calculate new nutrition
 * @returns {boolean} totalKCal > weightNeutralKcal * tolerance
 */
function isDayTargetExceeded(editedMealId = undefined, editedMealAmount = undefined) {
    const day = mainStore.selectedDay;
    const profile = mainStore.profile;

    if (!day || !profile) {
        return false;
    }


    const totalKCal = getTotalKCal(day, editedMealId, editedMealAmount);
    const recommendation = recommendedNutrition.getNutrients(profile, day.index);

    const weightNeutralKcal = recommendation.kcal - recommendation.weightLoss.targetKcalDiff;
    // total kcal is bigger => we're gaining weight
    return totalKCal > weightNeutralKcal * DAILY_KCAL_TOLERANCE_MULTIPLIER;
}

/**
 * Basically logout, resets all mainStore variables todefaults
 */
function resetMainStoreToDefault() {
    const newDefaults = JSON.parse(JSON.stringify(defaults));
    for (let i in newDefaults) {
        mainStore[i] = newDefaults[i]
    }
}

/**
 * 
 * @param {number} personMealId id of person meal
 * @returns meal object or undefined
 */
export function findMealInWeeklyData(personMealId) {
    let result;
    outer:
    for (const day of mainStore.weeklyData) {
        for (const meal of day.menu) {
            for (const recipe of meal.recipes) {
                if (recipe.id === personMealId) {
                    result = recipe;
                    break outer;
                }
            }
        }
    }
    return result;
}

export function getSelectedDate() {
    return mainStore.selectedDay.date;
}

export function getTodayDate() {
    return moment().format('YYYY-MM-DD');
}


function isSelectedDateInPast(date = '') {
    const selectedDate = date ? date : getSelectedDate()
    const now = moment().format('YYYY-MM-DD');
    const day = moment(selectedDate);

    return day.isBefore(now, 'day');
}

export function getSelectedDayMeals() {
    return mainStore.selectedDay.menu.map(x => x.recipes).flat();
}


export function pageNeedsReloading() {
    addModal(InfoModal, getInfoProps("Your menu was updated.", "Your menu was changed from a different device. This session needs to be reloaded.", null, "RELOAD"), async (result) => {
        window.location.reload();
    });
}


export async function setWeek(dateFrom) {
    if (mainStore.weekLoading) return false;
    mainStore.weekLoading = true;

    const result = await getWeeklyMenu(mainStore.profile.id, dateFrom);
    if (result?.weeklyMenu) {
        const actualCurrentWeek = getStartOfWeek();
        const dateIsInThePast = actualCurrentWeek.isAfter(dateFrom)
        const dateIsWithin3Weeks = actualCurrentWeek.add(2, 'weeks').isAfter(dateFrom);
        const resultEmpty = !result.weeklyMenu.some(day => day.menu.length > 0);

        const updateMenu = (result) => {
            updateStore({ baseInformation: { weeklyMenu: result.weeklyMenu, shoppingLists: shoppingStore.shoppingLists } });
        }

        if (dateIsInThePast || !resultEmpty) {
            updateMenu(result);
        }
        else if (resultEmpty) {
            if (dateIsWithin3Weeks) {
                // just get the menu
                const result = await getWeeklyMenu(mainStore.profile.id, dateFrom, true);
                updateMenu(result);
            } else {
                // ask the user
                addModal(InfoModal, getInfoProps("Do you want to create a new menu?", "Menu for the days you've selected does not exist.", "PROCEED", "CANCEL"), async (result) => {
                    if (result) {
                        mainStore.weekLoading = true;
                        const result = await getWeeklyMenu(mainStore.profile.id, dateFrom, true);
                        updateMenu(result);
                        mainStore.weekLoading = false;
                    }
                });
            }
        }
    }
    mainStore.weekLoading = false;
}

export async function recalculateCurrentDayMenu(personMealIds = []) {
    if (!Array.isArray(personMealIds) || (Array.isArray(personMealIds) && personMealIds?.length === 0)) return false;

    mainStore.recalculateDayLoading = true;

    const baseInfo = await recalculateMenuForDates(mainStore.profile.id,
        [mainStore.selectedDay.date],
        personMealIds
    );
    if (baseInfo) {
        updateStore(baseInfo);
        addNotification('Your meal has been rebalanced', { timeout: 8000 })
    }

    mainStore.recalculateDayLoading = false;
}

export async function onMealWarningModal(warnings, alteredIngredients, includedRestrictions, onConfirm, onCancel) {
    addModal(MealWarningModal, { warnings, alteredIngredients, includedRestrictions }, modalResult => {
        if (!modalResult) {
            if (onCancel) {
                return onCancel();
            }
            return false;
        }

        if (onConfirm) {
            return onConfirm(modalResult)
        }
    });
}

export const onMealSubstituteWarnings = async (personMeal, person_id, onConfirm, onCancel) => {
    const result = await getMealSubstituteWarnings(
        personMeal,
        person_id,
    );

    const alteredIngredients = personMeal.ingredients.filter(foodIngr => foodIngr.originalSelectedFood);
    if (Array.isArray(result?.mealWarnings) && result?.mealWarnings.length > 0) {
        return onMealWarningModal(result.mealWarnings,
            alteredIngredients,
            result.includedRestrictions,
            onConfirm,
            onCancel
        );
    }
    return onConfirm();
}


export const getAssembledItem = async (id, type, amount, portion_type_id) => {
    if (type === 'recipe') {
        const result = await getAssembledRecipe(mainStore.profile.id, id, amount);
        if (result?.recipe) {
            return result.recipe;
        }
        if (result?.recipe === false) {
            const resultWithoutRestrictions = await getAssembledRecipe(mainStore.profile.id, id, amount, true);
            if (resultWithoutRestrictions?.recipe) {
                return resultWithoutRestrictions.recipe;
            }
        }
    }

    const result = await getAssembledFood(mainStore.profile.id, id, amount, portion_type_id);
    if (result?.recipe) {
        return result.recipe;
    }
    return false;
}


export async function verifyLoginApiAction(mail, password) {
    if (!mail || !password) return false;

    try {
        await verifyLogin(mail, password);

        if (!mainStore.APIKey) { // not logged properly
            layoutStore.insideSubmission = false;

            return false;
        }

        const ownerProfile = mainStore.availableProfiles.find(x => x.user_person_relationship === USER_PERSON_RELATIONSHIP.OWNER);

        mainStore.currentUser.name = ownerProfile.name;
        mainStore.currentUser.surname = ownerProfile.surname;
        mainStore.currentUser.id = ownerProfile.id;

        await selectUserProfile(ownerProfile?.person_id ?? -1, true);

        layoutStore.insideSubmission = false;

    } catch (error) {
        layoutStore.insideSubmission = false;

        if (error?.error) {
            layoutStore.request.message = error.message
            layoutStore.request.error = error.error
            if (error.enabled !== undefined) {
                layoutStore.request.enabled = error.enabled
            }
            if (error.uid) {
                layoutStore.request.uid = error.uid
            }
        }
        throw error;
    }
}

export { isDayTargetExceeded, resetMainStoreToDefault, selectDay, isSelectedDateInPast, recalculateWeeklyNutrition, getTotalKCal, getCurrentKCal };