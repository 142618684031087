import React from 'react';
import { Link } from 'react-router-dom'
import './Logo.scss';
import { view } from '@risingstack/react-easy-state';

export default view((props) => (
  <Link to="/" className={
    "logo " +
    (props.className ? props.className : '')
  }>{props.children}</Link>
))