import React from 'react';

const checkboxSelected = () => (
    <svg viewBox="0 0 70 70">
        <polygon points="16.007,37.907 16.008,37.907 21.616,30.463 31.892,38.205 46.503,18.742 53.993,24.385 
      33.727,51.258"/>
        <path d="M7.373,7.373v55.254h55.254V7.373H7.373z M54.77,54.77H15.23V15.23h39.54V54.77z" />
    </svg>
)

export default checkboxSelected;