import React from 'react';

const messengerIcon = () => (
<svg viewBox="0 0 60 60">
  <polygon points="28.235,24.412 19.157,34.145 27.418,29.582 31.664,34.122 40.741,24.389 32.584,28.952 		
    "/>
  <path d="M30,0C13.432,0,0,13.431,0,30c0,16.568,13.432,30,30,30c16.569,0,30-13.432,30-30
    C60,13.431,46.569,0,30,0z M30.029,44.254c-1.659,0.003-3.262-0.222-4.775-0.64l-5.66,3.139l-0.011-5.911
    c-3.787-2.835-6.215-7.182-6.224-12.061c-0.016-8.562,7.422-15.518,16.612-15.535c9.19-0.017,16.654,6.911,16.67,15.474
    C46.657,37.282,39.219,44.237,30.029,44.254z"/>
</svg>
)

export default messengerIcon;