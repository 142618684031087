import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { view } from '@risingstack/react-easy-state';

import mainStore from 'storeUtils/mainStore';

import './NutrientsModal.scss';

import MicroNutrients from 'components/MicroNutrients';
import NutrientSnippet from 'components/NutrientSnippet';
import Text from 'components/Text';
import { addModal } from 'components/ModalWrapper';
import NutrientByIngredientModal from 'screens/NutrientByIngredientModal';
import { selectDay } from 'storeUtils/storeUtils';
import { fixCapitalizeName } from 'storeUtils/layoutStore';

class NutrientsModal extends Component {
    debug = false;

    constructor(props) {
        super(props);
        if (this.debug)
            mainStore.debugData.push({ debugName: `${props.selectedRecipe.name}[nutrition]`, ...JSON.parse(JSON.stringify(props.selectedRecipe)) });
    }

    static class = this.props?.pushTop ? 'modal-large' : 'modal-large modal-top';

    onTableNutrientClickAction = (data) => {
        const _data = data ? data : {};

        if (this.props.selectedRecipe) {
            _data.selectedRecipe = this.props.selectedRecipe;
        }

        if (this.props.selectedDay) {
            _data.selectedDay = this.props.selectedDay;
        }
        return addModal(NutrientByIngredientModal, _data)
    }

    render() {
        const data = this.props.data ? this.props.data : mainStore.menuNutrition;
        return (
            <div className="nutrients-modal">
                <Text header uppercase className="nutrients-modal-title" >{this.props.title ? fixCapitalizeName(this.props.title) : "All Nutrients"}</Text>
                <NutrientSnippet
                    offCalendar={this.props.offCalendar}
                    data={data}
                    selectedDay={this.props.selectedDay ? this.props.selectedDay : mainStore.selectedDay}
                    onDaySelect={(i) => {
                        if (mainStore.weekLoading) return false;

                        selectDay(mainStore.weeklyData[i])
                    }}
                />
                <MicroNutrients
                    offTimeSelector={this.props.offTimeSelector}
                    data={data}
                    selectedDay={this.props.selectedDay ? this.props.selectedDay : mainStore.selectedDay}
                    onTableNutrientClickAction={this.onTableNutrientClickAction}
                    profile={mainStore.profile}
                    preset={mainStore}
                    weeklyData={mainStore.weeklyData}
                    isMealInfo={this.props.isMealInfo}
                    clickable
                />
            </div>
        )
    }
}

NutrientsModal.propTypes = {
    pushTop: PropTypes.bool,
    data: PropTypes.any,
    offCalendar: PropTypes.bool,
    offTimeSelector: PropTypes.bool,
    title: PropTypes.string,
    selectedRecipe: PropTypes.any,
    selectedDay: PropTypes.any,
    isMealInfo: PropTypes.bool,
};

export default view(NutrientsModal);
