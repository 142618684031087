import React from 'react';

const instagramIcon = () => (
    <svg viewBox="0 0 60 60">
        <path d="M30.031,36.41c3.193,0,5.781-2.589,5.781-5.781c0-3.193-2.588-5.782-5.781-5.782
    c-3.193,0-5.782,2.588-5.782,5.782C24.249,33.821,26.838,36.41,30.031,36.41z"/>
        <path d="M30,60c16.568,0,30-13.432,30-30.001C60,13.432,46.568,0,30,0S0,13.432,0,29.999
    C0,46.568,13.432,60,30,60z M12.792,23.477c0.083-1.846,0.377-3.107,0.806-4.21c0.443-1.141,1.037-2.108,2.001-3.072
    c0.964-0.965,1.932-1.558,3.072-2.001c1.103-0.429,2.365-0.722,4.211-0.806c1.85-0.085,2.44-0.104,7.151-0.104
    s5.301,0.019,7.151,0.104c1.846,0.084,3.107,0.378,4.21,0.806c1.141,0.443,2.108,1.036,3.072,2.001
    c0.964,0.964,1.557,1.931,2,3.072c0.429,1.103,0.722,2.364,0.806,4.21c0.085,1.85,0.105,2.441,0.105,7.151
    c0,4.71-0.02,5.301-0.105,7.151c-0.084,1.846-0.377,3.107-0.806,4.21c-0.443,1.141-1.036,2.108-2,3.072
    c-0.964,0.965-1.931,1.558-3.072,2.001c-1.103,0.429-2.364,0.722-4.21,0.806c-1.85,0.085-2.44,0.104-7.151,0.104
    s-5.301-0.02-7.151-0.104c-1.846-0.084-3.108-0.377-4.211-0.806c-1.14-0.443-2.108-1.036-3.072-2.001
    c-0.964-0.964-1.558-1.931-2.001-3.072c-0.429-1.103-0.722-2.364-0.806-4.21c-0.085-1.85-0.105-2.441-0.105-7.151
    C12.686,25.918,12.706,25.327,12.792,23.477z"/>
        <path d="M16.51,40.857c0.314,0.81,0.69,1.388,1.298,1.994c0.606,0.607,1.184,0.983,1.993,1.298
    c0.611,0.237,1.529,0.52,3.221,0.598c1.829,0.083,2.378,0.101,7.009,0.101c4.632,0,5.179-0.018,7.009-0.101
    c1.691-0.078,2.609-0.36,3.221-0.598c0.81-0.315,1.387-0.691,1.994-1.298c0.607-0.606,0.983-1.184,1.297-1.994
    c0.237-0.612,0.52-1.53,0.597-3.221c0.083-1.829,0.101-2.378,0.101-7.008c0-4.632-0.017-5.18-0.101-7.009
    c-0.077-1.691-0.36-2.609-0.597-3.221c-0.314-0.81-0.69-1.387-1.297-1.994c-0.607-0.607-1.184-0.983-1.994-1.298
    c-0.611-0.237-1.53-0.52-3.221-0.598c-1.829-0.083-2.378-0.101-7.009-0.101c-4.631,0-5.18,0.017-7.009,0.101
    c-1.691,0.078-2.61,0.36-3.221,0.598c-0.81,0.315-1.387,0.691-1.993,1.298c-0.608,0.606-0.984,1.184-1.298,1.994
    c-0.238,0.612-0.52,1.53-0.598,3.221c-0.084,1.829-0.101,2.377-0.101,7.009c0,4.631,0.017,5.18,0.101,7.008
    C15.99,39.328,16.273,40.245,16.51,40.857z M39.29,19.288c1.149,0,2.081,0.932,2.081,2.082c0,1.15-0.932,2.081-2.081,2.081
    c-1.149,0-2.082-0.932-2.082-2.081C37.208,20.22,38.14,19.288,39.29,19.288z M30.031,21.722c4.919,0,8.906,3.987,8.906,8.906
    c0,4.919-3.987,8.906-8.906,8.906c-4.919,0-8.907-3.987-8.907-8.906C21.124,25.709,25.112,21.722,30.031,21.722z"/>
    </svg>
)

export default instagramIcon;