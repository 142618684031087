import React from 'react';
import { view } from '@risingstack/react-easy-state';
import './TableRecipeIngredientRow.scss'

import Icon, { FA } from 'components/Icon';
import Text from 'components/Text';

import getFractionDisplay from 'utils/getFractionDisplay';
import { fixCapitalizeName, removeDefaultBrand } from 'storeUtils/layoutStore';

export default view((props) => {
    return <div className={'table-ingredient-row ' + (props.icon ? 'with-icon' : '')}>
        {props.icon ? <Icon icon={props.icon} className={'table-multi-recipe-icon ' + (props.iconClassName ? props.iconClassName : '')} /> : ''}


        <div className="table-ingredient-row">
            <div className={'main ' + (props.isSubstitute || props.isFoodProhibited ? 'is-warning' : '')}>
                {props.isSubstitute ? <div className="original">{fixCapitalizeName(props.originalName)}</div> : ''}
                <div className="text">
                    {props.isFoodProhibited ? <Text header className="warning-sign">
                        **
                    </Text> : ''}
                    {props.isSubstitute ? <Text header className="warning-sign">*</Text> : ''}

                    {fixCapitalizeName(props.name)} {props.servingAmount ? <small><b>({props.servingAmount})</b></small> : ''}
                </div>
            </div>
            <div className="left">
                {getFractionDisplay(props.amount)} <b>{props.portionType}</b>{props.prefix ? `, ${props.prefix}` : ''} {removeDefaultBrand(props.brand) ? <i className="brand"> {removeDefaultBrand(props.brand)} </i> : ''}
            </div>
        </div>
    </div>
})