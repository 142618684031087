/**
 * fraction unicode map 1/4, 1/3, 1/2, 2/3, 3/4
 */
export const fractionDisplayMap = {
    '0': '', // 0 -> '' (nothing to display)
    '0.25': '\u0031\u2044\u0034', // 1/4
    '0.3333': '\u0031\u2044\u0033', // 1/3
    '0.5': '\u0031\u2044\u0032', // 1/2
    '0.6666': '\u0032\u2044\u0033', // 2/3
    '0.75': '\u0033\u2044\u0034', // 3/4
}
export const availableFractions = [0, 0.25, 0.3333, 0.5, 0.6666, 0.75, 1]
export const RNDPRC = 0.01;


/* ======================================== PREPARATION ======================================== */

export function getRoundedValue(value) {
    const result = Math.round((+value) / RNDPRC) * RNDPRC;
    return result;
}

export function getFractionObject(value) {
    const workValue = getRoundedValue(value);
    let integer = Math.trunc(workValue);
    const decimalPortion = Math.abs(integer - workValue);

    let fractionIndex = 0;
    let currentBest = 1;

    for (let index = 0; index < availableFractions.length; index++) {
        const next_candidate = Math.abs(decimalPortion - availableFractions[index]);
        if (currentBest > next_candidate) {
            currentBest = next_candidate;
            fractionIndex = index;
        } else {
            break;
        }
    }
    if (availableFractions[fractionIndex] === 1) {
        integer++;
        fractionIndex = 0;
    }

    const sign = workValue >= 0 ? '' : '-';
    const signValue = workValue >= 0 ? 1 : -1;
    return { integer, fraction: availableFractions[fractionIndex], fractionIndex, sign, signValue }
}

/* ======================================== CONVERSION ======================================== */


export function getFullDecimal(value) {
    const fo = getFractionObject(value);
    return getFullDecimalFromFractionObject(fo);

}

export function getFractionPart(value) {
    const fractionObject = getFractionObject(value);
    return fractionObject.fraction;
}

export function getIntegerPart(value) {
    const fractionObject = getFractionObject(value);
    return fractionObject.integer;
}

export function getFullDecimalFromFractionObject(fractionObject) {
    const suffix = fractionObject.fraction !== 0 ? ('.' + `${fractionObject.fraction}`.split('.')[1]) : '';
    const result = `${fractionObject.integer}${suffix}` // to avoid 1.2500000001 etc
    return +result;
}


/* ======================================== RENDERING ======================================== */


export function renderText(value) {
    const integer = renderIntegerText(value);
    if (integer) {
        return `${integer} ${renderFractionText(value)}`;
    }
    return `${renderFractionText(value)}`;
}

export function renderIntegerText(value) {
    return value == 0 ? '' : getIntegerPart(value);
}

export function renderFractionText(value) {
    const fraction = getFractionPart(value);
    return fractionDisplayMap[fraction];
}

export function renderTextFromFractionObject(fractionObject) {
    if (fractionObject.integer == 0) {
        return renderFractionTextFromFractionObject(fractionObject);
    }
    return `${renderIntegerTextFromFractionObject(fractionObject)} ${renderFractionTextFromFractionObject(fractionObject)}`;
}

export function renderIntegerTextFromFractionObject(fractionObject) {
    return `${fractionObject.integer == 0 ? '' : fractionObject.integer}`
}

export function renderFractionTextFromFractionObject(fractionObject) {
    return `${fractionDisplayMap[fractionObject.fraction]}`
}

