import layoutStore from 'storeUtils/layoutStore';
import mainStore from 'storeUtils/mainStore';
import { pageNeedsReloading } from 'storeUtils/storeUtils';
import { fetchJSON } from 'utils/fetchJSON';

export default function deleteMeal(person_id, person_meal_id) {
    if (layoutStore.request.progress) return;

    layoutStore.bigLoading = true;
    layoutStore.request.progress = true;
    layoutStore.request.error = false
    return fetchJSON('/api/meal/delete', { person_meal_id, person_id, baseInfoTimeUTC: mainStore.baseInfoTimeUTC })
        .then((json) => {
            if (json.request?.error) {
                pageNeedsReloading();
            }
            layoutStore.bigLoading = false;
            layoutStore.request.progress = false;
            return json;
        })
        .catch(error => {
            console.log('[api/meal/completeMeal.js] Error: ', error);
            layoutStore.bigLoading = false;
            layoutStore.request.progress = false;
            return undefined;
        });
}
