import moment from 'moment';
import layoutStore from 'storeUtils/layoutStore';
import mainStore from 'storeUtils/mainStore';
import { pageNeedsReloading } from 'storeUtils/storeUtils';

import { fetchJSON } from 'utils/fetchJSON';

export default function getMealWarnings(date, dayIndex, person_id, personMeal, meal_id) {
    if (layoutStore.request.progress) return;
    layoutStore.request.progress = true;

    return fetchJSON('/api/meal/get-warnings', {
        date,
        dayIndex,
        person_id,
        personMeal,
        meal_id,
        userLocalDate: moment().format('YYYY-MM-DD'),
        baseInfoTimeUTC: mainStore.baseInfoTimeUTC
    })
        .then((json) => {
            layoutStore.request.progress = false;
            mainStore.baseInfoTimeUTC = json?.request?.newUTCTime;

            if (json.request?.error) {
                pageNeedsReloading();
            }
            return json;
        })
        .catch(error => {
            console.log('[api/meal/completeMeal.js] Error: ', error);
            layoutStore.request.progress = false;
            return undefined;
        });
}
