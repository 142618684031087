import mainStore from 'storeUtils/mainStore';
import { fetchJSON } from 'utils/fetchJSON';

export default function getWeeklyMenu(person_id, date_from, optional_generate = false) {
    return fetchJSON('/api/menu/getWeeklyMenu', {
        person_id,
        date_from,
        optional_generate,
        baseInfoTimeUTC: mainStore.baseInfoTimeUTC
    })
        .then((json) => {
            return json;
        })
        .catch(error => {
            console.log('[api/menu/getWeeklyMenu.js] Error: ', error);
            return undefined;
        });
}
