import moment from "moment";
import { getStartOfWeek } from "./date/getStartOfWeek.js";

/**
 * 
 * @param {array} mealList must be sorted before 
 * @param {array} days taken from weeklyData or pattern_week
 * @returns 2d array that represents meal available in specific day
 */
export function getGridAvailableMealsForDay(mealList = [], days = []) {
    if (!Array.isArray(mealList) || !Array.isArray(days)) return false;
    const defaultMeals = Object.values(mealList).sort((a, b) => a.order - b.order);
    const weekValues = [];

    for (let i = 0; i < defaultMeals.length; i++) {
        const meal = defaultMeals[i];
        const mealValues = [];

        for (let j = 0; j < 7; j++) {
            const day = days[j];

            let mealArr = undefined;

            if (Array.isArray(day?.meals)) {
                mealArr = day?.meals;
            } else {
                mealArr = day?.menu;
            }

            // day may be undefined
            if (day && Array.isArray(mealArr)) {
                const isMealInDay = mealArr.some(dayMeal => dayMeal.id === meal.id)
                if (isMealInDay) {
                    mealValues.push(0)
                } else {
                    mealValues.push(-1)
                }
            } else {
                mealValues.push(-1)

            }
        }
        weekValues.push(mealValues)
    }
    return weekValues;
}

/**
 * Get MealList from weeklyData
 * @param {array} weeklyData 
 * @returns 
 */
export function getMealListFromWeeklyData(weeklyData = []) {
    if (!Array.isArray(weeklyData)) return false;
    const mealMap = {};

    for (let i = 0; i < weeklyData.length; i++) {
        const day = weeklyData[i];

        if (!Array.isArray(day?.menu)) continue;

        for (let j = 0; j < day.menu.length; j++) {
            const meal = day.menu[j];
            mealMap[meal.id] = { id: meal.id, name: meal.name, order: meal.order };
        }
    }
    return [...Object.values(mealMap)].sort((a, b) => a.order - b.order);
}

/**
 * Checks if pattern is cyclic
 * @param {string} date 
 * @returns {boolean}
 */
export function isDateCyclicType(date = '') {
    const momentDate = moment(date, 'YYYY-MM-DD');
    if (momentDate.isValid()) {
        return momentDate.format('YYYY') === '1970';
    }
    return false;
}

/**
 * Get our day date 1970 date base on number
 * @param {number} dayCount 
 * @returns string
 */
export function getCyclicDate(dayCount) {
    return cyclicDatesEnum[dayCount];
}

/**
 * group pattern_meal and pattern_meal_override over same group and order contents of pattern_day
 * @param {Array} pattern 
 * @param {Array} overrides 
 */
export function getGroupOrderHashMap(pattern, overrides) {
    if (!Array.isArray(pattern) || !Array.isArray(overrides)) return false;

    const groupOrderHashMap = {};
    for (let i = 0; i < pattern.length; i++) {
        const pattern_meal = pattern[i];
        const group = pattern_meal.group !== undefined ? pattern_meal.group : 0;
        const order = pattern_meal.order !== undefined ? pattern_meal.order : 0;

        groupOrderHashMap[group + '_' + order + '_' + pattern_meal.id] = pattern_meal;
    }

    for (let j = 0; j < overrides.length; j++) {
        const pattern_meal_override = overrides[j];

        if (pattern_meal_override.pattern_meal_id !== undefined) {
            const filtered = pattern.filter(pattern_meal => pattern_meal.id === pattern_meal_override.pattern_meal_id);
            if (filtered.length > 0) {
                const pattern_meal = filtered[0];
                const group = pattern_meal.group !== undefined ? pattern_meal.group : 0;
                const order = pattern_meal.order !== undefined ? pattern_meal.order : 0;

                groupOrderHashMap[group + '_' + order + pattern_meal_override.id] = pattern_meal_override;
            }
        }
    }
    return groupOrderHashMap;
}

/**
 * Checks whether object has pattern_meal_id property it means that it's pattern_meal_override
 * @param {Object} pattern_meal_override 
 * @returns boolean
 */
export function isPatternMealOverride(object) {
    return object.pattern_meal_id !== undefined
}

/**
 * Get our day today from current week base on number
 * @param {number} dayCount 
 * @returns string
 */
export function getSingleDate(dayCount, selectedDate) {
    const beginningOfWeek = getStartOfWeek(selectedDate);
    return beginningOfWeek.add(dayCount, 'days').format('YYYY-MM-DD');
}

export function isInPreviewMode(state) {
    return state === stateEnum.PREVIEW_MODE
}

export function isInEditMode(state) {
    return state === stateEnum.EDIT_MODE
}

export function isInDeleteMode(state) {
    return state === stateEnum.DELETE_MODE
}

export function isInRestoreMode(state) {
    return state === stateEnum.RESTORE_MODE
}

export const cyclicDatesEnum = {
    0: '1970-01-05',
    1: '1970-01-06',
    2: '1970-01-07',
    3: '1970-01-01',
    4: '1970-01-02',
    5: '1970-01-03',
    6: '1970-01-04',
}

export const stateEnum = {
    PREVIEW_MODE: 'preview-mode',
    EDIT_MODE: 'edit-mode',
    DELETE_MODE: 'delete-mode',
    RESTORE_MODE: 'restore-mode'
}

export const addEnum = {
    CYCLIC: 'cyclic',
    SINGLE: 'single'
}
