import React from 'react';
import PropTypes from 'prop-types';
import { view } from '@risingstack/react-easy-state';

import './Checkbox.scss';

import { Icon, icons } from 'components/Icon';

/**
 * @typedef CheckboxProps
 * @type {object}
 * @property {boolean} accent change style
 * @property {boolean} green change style
 * @property {boolean} disabled change style
 * @property {boolean} hasError show up validation style error
 * @property {boolean} checked show check mark
 * @property {string} className
 * @property {function} onClick callable 
*/

/**
 * @param {CheckboxProps} props
 */
const RawCheckbox = (props) => {
    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            props.onClick();
        }
    }

    return (
        <span className={
            "checkbox-indicator " +
            (props.accent ? 'accent ' : '') +
            (props.green ? 'green ' : '') +
            (props.hasError ? 'has-error ' : '') +
            (props.disabled ? 'disabled ' : '') +
            (props.className ? props.className : '')}
            onClick={(evt) => { if (!props.disabled && props.onClick) props.onClick(evt) }}
            style={props.style}
            onKeyDown={(e) => props.onClick ? _handleKeyDown(e) : ''}
            tabIndex={0} >
            {props.radio ? (
                <span className={"radio " + (props.checked ? 'visible' : '')} >
                    <Icon icon={icons.tick} />
                </span>
            ) : (
                <Icon icon={props.checked ? icons.checkboxSelected : icons.checkboxEmpty} />
            )}
            {props.children}
        </span>
    )
}

RawCheckbox.propTypes = {
    accent: PropTypes.bool,
    green: PropTypes.bool,
    hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    radio: PropTypes.bool,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.any,
    children: PropTypes.any
};

/**
 * clickable rectangle that work as checkbox
 * 
 * @component
 * @type {{ new(props: CheckboxProps): {
 *   props: { 
 *     accent: boolean, 
 *     green: boolean,
 *     disabled: boolean,
 *     hasError: boolean,
 *     checked: boolean,
 *     className: string
 *     onClick: function
 *     }
 *   }
 *  }}
 */
const CheckBox = view(RawCheckbox);

export default CheckBox;
