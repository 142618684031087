import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { view } from '@risingstack/react-easy-state';

import './CompareModal.scss';

import { microNutrientsInfo } from 'utils/constants/microNutrientsInfo';
import { getRecipeNutrition } from 'subsystems/nutrition/nutrition.js';

import { Text } from 'components/Text';
import { Icon, icons } from 'components/Icon';
import CircleChart from 'components/CircleChart';
import Button from 'components/Button';

import MealInfo from '../ReplaceModal/MealInfo';

class CompareModal extends Component {
    static class = 'modal-medium nopadding modal-light noborder';

    constructor(props) {
        super(props);
        this.nutrientsListVits = microNutrientsInfo.vits;
        this.nutrientsListMins = microNutrientsInfo.mins;
        this.nutrientsListMain = microNutrientsInfo.mainNutrients;
    }

    getCompareTable(nutrientInfo = [], name = 'Nutrient') {
        nutrientInfo = nutrientInfo.filter(n => n.getValue(this.props.currentItem?.nutrition) !== n.getValue(this.props.newItem?.nutrition))

        if (nutrientInfo.length === 0) return;

        return (
            <div className="compare-table">
                <div className="row header">
                    <Text className="pl-1">
                        <b>{name}</b>
                    </Text>
                    <Text textCenter>
                        <b>Current <span className="mobile-hidden">Item</span></b>
                    </Text>
                    <Text textCenter>
                        <b>New <span className="mobile-hidden">Item</span></b>
                    </Text>
                    <Text className="pr-1" textCenter>
                        <b>Difference</b>
                    </Text>
                </div>

                {nutrientInfo.map((nutrient, index) =>
                    this.getRowDetailed(nutrient, index))}
            </div>
        );
    }

    getRowDetailed(nutrient, index) {
        const value = nutrient.getValue(this.props.currentItem?.nutrition) || 0;
        const newValue = nutrient.getValue(this.props.newItem?.nutrition) || 0;

        if (value === newValue) return;
        return (
            <div className="row item" key={index}>
                <Text className="pl-1">
                    {nutrient.name} {nutrient.unit ? `(${nutrient.unit})` : ''}
                </Text>
                <Text textCenter>{value.toFixed(2)}</Text>
                <Text textCenter>{newValue.toFixed(2)}</Text>
                <Text className="pr-1" textCenter>
                    {(newValue - value).toFixed(2)}
                </Text>
            </div>
        );
    }

    getCircleChart(data) {
        return <CircleChart sections={data.alcohol ? [data.carbs * 4, data.protein * 4, data.fat * 9, data.alcohol * 7] : [data.carbs * 4, data.protein * 4, data.fat * 9]} >
            <span className="small">Menu</span>
            <span className="big">{data.kcal.toFixed(0)}</span>
            <span className="small">Cal</span>
        </CircleChart>
    }

    getLegend(currentItem, newItem) {
        const nutrition = getRecipeNutrition(currentItem);
        const newNutrition = getRecipeNutrition(newItem);

        return <div className="legend">
            <div className="carbs"><span>{nutrition.carbs.toFixed(0)}</span> Carbs <span>{newNutrition.carbs.toFixed(0)}</span></div>
            <div className="protein"><span>{nutrition.protein.toFixed(0)}</span>Protein<span>{newNutrition.protein.toFixed(0)}</span></div>
            {nutrition.alcohol ? <div className="alcohol"><span>{nutrition.alcohol.toFixed(0)}</span>Alcohol<span>{newNutrition.alcohol.toFixed(0)}</span></div> : ""}
            <div className="fat"><span>{nutrition.fat.toFixed(0)}</span>Fat<span>{newNutrition.fat.toFixed(0)}</span></div>
        </div>
    }

    render() {
        const { currentItem, newItem } = this.props;
        console.log('[Compare.js]: currentItem: ', currentItem, ' newItem: ', newItem);
        return (
            <div className="compare-item-modal">
                <div className="compare-item-header">
                    <Text large uppercase textCenter>
                        Compare
                    </Text>
                </div>
                <div className="compare-item-inner">
                    <div className="compare-info pb-2">
                        <MealInfo className="left-column" meal={currentItem} description={this.getCircleChart(currentItem.nutrition)} />
                        <Text className="left-column" block textCenter>(current item)</Text>
                        <div className="center-section">
                            <Icon icon={icons.compare} className="compare-icon" />
                            {this.getLegend(currentItem, this.props.newItem)}
                        </div>

                        <MealInfo className="right-column" meal={newItem} description={this.getCircleChart(newItem.nutrition)} />
                        <Text className="right-column" block textCenter>(new item)</Text>
                    </div>
                    <div className='nutrition-tables'>
                        {this.getCompareTable(this.nutrientsListMain, 'Main')}
                        {this.getCompareTable(this.nutrientsListVits, 'Vitamins')}
                        {this.getCompareTable(this.nutrientsListMins, 'Minerals')}
                    </div>

                    <div className='buttons pt-2'>
                        <Button clear block transformNormal className="back" onClick={() => this.props.closeAction()}>
                            Back
                        </Button>
                        <Button green block transformNormal onClick={(e) => {
                            this.props.closeAction()
                            this.props.onSubmit()
                        }}>
                            Replace
                        </Button>
                    </div>
                </div>

            </div>
        );
    }
}

CompareModal.propTypes = {
    currentItem: PropTypes.object.isRequired,
    newItem: PropTypes.object.isRequired,
    type: PropTypes.string
};

export default view(CompareModal);
