import React from 'react';
import { NavLink } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state';

import './HeaderButton.scss';

function isActive(match, location) {
    if (!match) {
        return false;
    }

    if ((match.url === '') && (match.isExact === false)) {
        return false;
    }

    return true;
}

const HeaderButton = view((props) => {
    if (props.to) {
        if (props.exact) {
            return (
                <NavLink
                    exact
                    to={props.to}
                    className={
                        "header-button " +
                        (props.left ? 'left ' : '') +
                        (props.right ? 'right ' : '') +
                        (props.animated ? 'animated ' : '') +
                        (props.className ? props.className : '')
                    }
                    isActive={isActive}
                >{props.children}</NavLink>
            );
        } else {
            return (
                <NavLink
                    to={props.to}
                    className={
                        "header-button " +
                        (props.left ? 'left ' : '') +
                        (props.right ? 'right ' : '') +
                        (props.animated ? 'animated ' : '') +
                        (props.className ? props.className : '')
                    }
                    isActive={isActive}
                >{props.children}</NavLink>
            );
        }
    }
    return (
        <div className={
            "header-button " +
            (props.left ? 'left ' : '') +
            (props.right ? 'right ' : '') +
            (props.animated ? 'animated ' : '') +
            (props.className ? props.className : '')
        } onClick={props.onClick} >{props.children}</div>
    );
});

export default HeaderButton;
