import React from 'react';

import './Header.scss';
import { view } from '@risingstack/react-easy-state';

export default view((props) => (
  <header className={ 
    (props.className ? props.className : '')
  }>
    {props.children}
  </header>
));