import React from 'react';

const foodLogIcon = () => (
    <svg viewBox="0 0 51.304 60">
        <path style={{ fill: "none" }} d="M44.783,51.304c1.199,0,2.174-0.975,2.174-2.174V6.522c0-1.199-0.975-2.174-2.174-2.174H16.087v46.956
    H44.783z"/>
        <path style={{ fill: "none" }} d="M4.348,6.522V9.13v4.348v28.696v4.348v2.609c0,1.199,0.975,2.174,2.174,2.174h5.217V4.348H6.522
    C5.323,4.348,4.348,5.323,4.348,6.522z"/>
        <path d="M0,6.522V9.13v4.348v28.696v4.348v2.609c0,3.596,2.926,6.522,6.522,6.522h5V55.2
    c0-1.186,0.971-2.157,2.157-2.157h2.643c1.186,0,2.157,0.971,2.157,2.157v0.452h26.304c3.596,0,6.522-2.926,6.522-6.522V6.522
    C51.304,2.926,48.379,0,44.783,0H6.522C2.926,0,0,2.926,0,6.522z M44.783,4.348c1.199,0,2.174,0.975,2.174,2.174V49.13
    c0,1.199-0.975,2.174-2.174,2.174H16.087V4.348H44.783z M11.739,4.348v46.956H6.522c-1.199,0-2.174-0.975-2.174-2.174v-2.609
    v-4.348V13.478V9.13V6.522c0-1.199,0.975-2.174,2.174-2.174H11.739z"/>
        <path d="M16.321,53.043h-2.643c-1.186,0-2.157,0.971-2.157,2.157v0.452v2.191
    c0,1.186,0.971,2.157,2.157,2.157h2.643c1.186,0,2.157-0.971,2.157-2.157v-2.191V55.2C18.478,54.014,17.508,53.043,16.321,53.043z
    "/>
        <path d="M21.225,36.927c-0.446,0.444-0.446,1.166-0.003,1.61c0.447,0.445,1.168,0.445,1.613,0l7.104-7.104
    l-1.611-1.611L21.225,36.927z"/>
        <path d="M44.55,20.387c-0.236-0.239-0.623-0.239-0.863,0.001l-5.002,5.003l-0.864-0.863l5.004-5.004
    c0.24-0.24,0.24-0.625,0.003-0.863c-0.242-0.24-0.626-0.239-0.864,0.001l-5.005,5.004L36.092,22.8l5.005-5.002
    c0.239-0.241,0.239-0.627,0.002-0.865c-0.239-0.238-0.627-0.238-0.866,0.002l-5.002,5.005l-0.865-0.863l5.003-5.004
    c0.242-0.24,0.242-0.627,0.003-0.864c-0.239-0.241-0.626-0.241-0.865,0l-5.177,5.177c-0.039,0.039-0.061,0.088-0.089,0.134
    c-1.573,1.764-1.585,3.578-0.632,5.058c-0.036,0.027-0.081,0.041-0.112,0.073l-1.19,1.189l1.611,1.611l1.19-1.189
    c0.033-0.032,0.046-0.075,0.074-0.111c1.475,0.95,3.291,0.938,5.057-0.634c0.048-0.024,0.095-0.049,0.135-0.088l5.175-5.176
    C44.791,21.013,44.791,20.625,44.55,20.387z"/>
        <path d="M28.046,24.947c-0.056-0.053-0.122-0.08-0.182-0.12c0.83-1.749,0.233-4.257-1.633-6.122
    c-2.222-2.224-5.379-2.675-7.046-1.009c-1.667,1.668-1.218,4.825,1.005,7.049c1.865,1.865,4.373,2.464,6.122,1.634
    c0.041,0.06,0.069,0.126,0.122,0.179l12.08,12.081c0.446,0.444,1.165,0.445,1.612,0c0.445-0.444,0.443-1.165-0.001-1.61
    L28.046,24.947z"/>
    </svg>
)

export default foodLogIcon;