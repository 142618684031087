import { fetchJSON } from 'utils/fetchJSON';

export default function getAssembledFood(person_id, food_id, amount, portion_type_id) {
    return fetchJSON('/api/recipe/get-assembled-food', { person_id, food_id, amount, portion_type_id })
        .then((json) => {
            return json;
        })
        .catch(error => {
            console.error('error on /api/recipe/get-assembled-food', error)
            return undefined;
        });
}
