import React from 'react';

const shareIcon = () => (
    <svg viewBox="0 0 55.828 60">
        <path d="M55.828,23.505L33.905,0L11.981,23.505h12.037C24.509,49.292,0,60,0,60
    c36.025-3.237,42.758-29.771,43.89-36.495H55.828z"/>
    </svg>
)


export default shareIcon