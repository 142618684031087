import React from 'react';

const nutriStyleNIcon = () => (
    <svg viewBox="0 0 60 58.736">
        <path style={{ opacity: 0.6 }} d="M28.926,3.632l-4.135,2.067c0,0-1.178,0.43-2.012,0.335
    c-0.833-0.095-1.631-0.772-3.911-1.229C11.397,3.308,6.448,8.33,5.386,9.056c-2.092,1.754-7.103,8.481-4.789,16.088
    c0.823,2.705,9.64,11.373,9.64,11.373s-0.03-7.685,1.367-10.143s4.276-6.316,4.276-6.316s4.106-5.716,7.248-11.04
    c0.24-0.407,2.932-2.754,3.562-3.039c0.63-0.284,1.9-0.726,1.9-0.726L28.926,3.632z"/>
        <path d="M23.333,8.668c0,0-15.93,6.372-16.012,16.339c-0.016,1.938,0.666,6.079,1.062,7.107
    c0.408,1.062,2.104,4.391,2.104,4.391s0.429-4.432,0.797-5.841c0.368-1.409,1.532-4.983,2.328-6.638
    C14.408,22.372,23.333,8.668,23.333,8.668z"/>
    </svg>
);

export default nutriStyleNIcon;