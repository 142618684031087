import React, { Component } from 'react';
import { view } from '@risingstack/react-easy-state';
import PropTypes from 'prop-types';

import './FractionControllInput.scss';

import { FA } from 'components/Icon';
import Text from 'components/Text';

import { getFractionObject, getFullDecimal, renderTextFromFractionObject } from 'utils/fractionUtils';

class FractionControllInput extends Component {
    debugComponent = false;
    holdStepValue = 1;
    minValue;
    maxValue;


    constructor(props) {
        super(props);
        this.minValue = !isNaN(this.props.minValue) ? this.props.minValue : -Infinity;
        this.maxValue = !isNaN(this.props.maxValue) ? this.props.minValue : +Infinity;

        if (props.holdStepValue !== undefined) {
            this.holdStepValue = props.holdStepValue;
        }
    }


    onChange = (value) => {
        if (this.props.onChange && typeof this.props.onChange === 'function') {
            this.props.onChange(value)
            if (this.debugComponent)
                console.log('DEBUG: calling onchange', value);
        }
    }

    onBlur = () => {
        if (this.props.onBlur && typeof this.props.onBlur === 'function') {
            this.props.onBlur()
        }
    }

    onFocus = () => {
        if (this.props.onFocus && typeof this.props.onFocus === 'function') {
            this.props.onFocus()
        }
    }

    nextFractionIncrement = () => {
        let newValue = +this.props.value + 0.14;
        const result = getFullDecimal(newValue);
        if (result <= this.maxValue) {
            this.onChange(result)
        }
    }

    nextFractionDecrement = () => {
        let newValue = +this.props.value - 0.14;
        const result = getFullDecimal(newValue);
        if (result >= this.minValue) {
            this.onChange(result)
        }
    }

    stepIncrement = () => {
        let newValue = +this.props.value;

        if (newValue % this.props.step !== 0) {
            newValue = Math.round(newValue / this.props.step) * this.props.step
        } else {
            newValue += this.props.step;
        }

        if (newValue <= this.maxValue) {
            this.onChange(newValue)
        }
    }

    stepDecrement = () => {
        let newValue = +this.props.value;
        if (newValue % this.props.step !== 0) {
            newValue = Math.round(newValue / this.props.step) * this.props.step
        } else {
            newValue -= this.props.step;
        }

        if (newValue >= this.minValue) {
            this.onChange(newValue)
        }
    }

    increment = () => {
        if (this.isDirty) {
            this.isDirty = false;
            return;
        }

        if (this.props.changeValueByStep && !isNaN(this.props.step)) {
            this.stepIncrement()
        } else {
            this.nextFractionIncrement();
        }
    }

    decrement = () => {
        if (this.isDirty) {
            this.isDirty = false;
            return;
        }

        if (this.props.changeValueByStep && !isNaN(this.props.step)) {
            this.stepDecrement()
        } else {
            this.nextFractionDecrement();
        }
    }

    decTimeout;
    incTimeout;
    isDirty;

    onMouseDownDecrement = () => {
        this.decTimeout = setTimeout(() => {
            this.decrementer();
        }, 400);
    }

    onMouseUpDecrement = () => {
        clearTimeout(this.decTimeout);
    }

    onMouseDownIncrement = () => {
        this.incTimeout = setTimeout(() => {
            this.incrementer();
        }, 400);

    }
    onMouseUpIncrement = () => { // reset timer cancel all 
        clearTimeout(this.incTimeout);
    }

    decrementer = () => {
        const newValue = +this.props.value - this.holdStepValue
        if (newValue >= this.minValue) {
            this.decTimeout = setTimeout(() => { this.decrementer() }, 400);
            this.onChange(newValue);
            this.isDirty = true;
        }
    }

    incrementer = () => {
        const newValue = +this.props.value + this.holdStepValue
        if (newValue <= this.maxValue) {
            this.incTimeout = setTimeout(() => { this.incrementer() }, 400);
            this.onChange(newValue);
            this.isDirty = true;
        }
    }

    render() {
        this.fractionObject = getFractionObject(this.props.value);

        if (this.props.disabled && (this.incTimeout || this.decTimeout)) {
            this.onMouseUpIncrement();
            this.onMouseUpDecrement();
        }

        if (this.props.roundSourceToFraction) {
            const propValue = +this.props.value;
            const fractionValue = getFullDecimal(propValue);

            if (propValue != fractionValue) {
                if (this.debugComponent)
                    console.debug('DEBUG: rounding', fractionValue, propValue);
                this.onChange(fractionValue);
                return <span></span>;
            }
        }

        return <div className={'number-field ' +
            (this.props.disabled ? 'disabled ' : '') +
            (this.props.bordered ? 'bordered ' : '') +
            (this.props.hasError ? 'has-error ' : '')}
        >
            {this.props.disabled ? <span></span> : <FA icon="fa fa-fw fa-minus" onClick={this.decrement} onMouseDown={this.onMouseDownDecrement} onMouseUp={this.onMouseUpDecrement} />}
            <Text className={'fraction ' + (this.fractionObject.fractionIndex === 0 ? 'no-fraction' : '')}
                onBlur={() => this.onBlur()}
                onFocus={() => this.onFocus()}
            >
                {renderTextFromFractionObject(this.fractionObject) || 0}
            </Text>
            {this.props.disabled ? <span></span> : <FA icon="fa fa-fw fa-plus" onClick={this.increment} onMouseDown={this.onMouseDownIncrement} onMouseUp={this.onMouseUpIncrement} />}
            {this.props.hasError ? <div className="validation-error">
                {this.props.customErrorText && typeof this.props.customErrorText === 'string' ? this.props.customErrorText : ''}
            </div> : ''}
        </div>
    }
}


FractionControllInput.propTypes = {
    step: PropTypes.number,
    changeValueByStep: PropTypes.bool,
    stepAcceleration: PropTypes.bool,
    hasError: PropTypes.bool,
    customErrorText: PropTypes.string,
    disabled: PropTypes.bool,
    bordered: PropTypes.bool,
    roundSourceToFraction: PropTypes.bool,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.any,
    holdStepValue: PropTypes.number
}

export default view(FractionControllInput);