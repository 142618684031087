import React from 'react';

const clockIcon = () => (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 70 70">
        <g>
            <path d="M31.26,5.2c0.16-0.16-0.22-0.14-0.24-0.12C30.87,5.24,31.24,5.22,31.26,5.2z" />
            <path d="M31.97,5.2c0.16-0.16-0.22-0.14-0.24-0.12C31.58,5.24,31.96,5.22,31.97,5.2z" />
            <path d="M37.34,5.24c-1.56-0.56-3.49-0.28-5.13-0.28c0.32,0.13,0.65,0.22,0.99,0.28C34.57,5.16,35.96,5.16,37.34,5.24z
		"/>
            <path d="M38.03,5.2c0.16-0.16-0.22-0.14-0.24-0.12C37.63,5.24,38.01,5.22,38.03,5.2z" />
            <path d="M38.74,5.2c0.16-0.16-0.22-0.14-0.24-0.12C38.35,5.24,38.72,5.22,38.74,5.2z" />
            <path d="M39.45,5.2c0.16-0.16-0.22-0.14-0.24-0.12C39.06,5.24,39.43,5.22,39.45,5.2z" />
            <path d="M29.48,5.55c0.16-0.16-0.22-0.14-0.24-0.12C29.09,5.59,29.46,5.57,29.48,5.55z" />
            <path d="M29.72,5.32c0.23,0.13,0.37,0.19,0.53,0.24c0.08-0.01,0.16-0.03,0.24-0.04C30.26,5.41,30.09,5.38,29.72,5.32z"
            />
            <path d="M35.77,32.74c0.28-0.2,0.55-0.41,0.82-0.64c-0.2-0.26-0.33-0.56-0.4-0.89C36.09,31.75,35.97,32.27,35.77,32.74
		z"/>
            <path d="M64.62,33.09c0.08,0.2,0.14,0.41,0.19,0.62c-0.26-8.13-3.69-16.17-10.52-21.87c-2.93-2.44-6.36-4.18-9.97-5.34
		c-1.17-0.37-2.39-0.66-3.63-0.87c0.03,0.01,0.04,0.02,0.07,0.04c-0.22-0.04-0.36-0.07-0.49-0.1c-0.97-0.15-1.95-0.26-2.93-0.33
		c0.07,0.03,0.14,0.04,0.21,0.07c-1.38,0-2.95,0.19-4.35-0.07c-0.82,0.05-1.63,0.12-2.42,0.23c-0.1,0.01-0.2,0.03-0.3,0.05
		c0.09,0.04,0.18,0.09,0.3,0.15c-0.25-0.04-0.4-0.07-0.54-0.12c-3.32,0.52-6.45,1.66-9.43,3.24c-3.04,1.61-5.85,3.88-8.08,6.49
		C-0.66,30.93,5.7,56.28,25.45,63c1.38,0.47,2.82,0.82,4.28,1.07c0,0-0.01,0-0.01,0c0.14,0.02,0.24,0.04,0.33,0.06
		c0.74,0.12,1.48,0.22,2.23,0.29c1.56-0.01,3.42-0.25,5.01,0.07c0.69-0.04,1.37-0.09,2.05-0.18c0.22-0.03,0.45-0.06,0.67-0.1
		c-0.09-0.04-0.19-0.09-0.31-0.16c0.26,0.04,0.42,0.07,0.56,0.12c3.4-0.51,6.78-1.61,9.76-3.33c7.86-4.54,12.68-12.11,14.24-20.28
		v-0.01c0,0,0,0,0,0c0.15-0.78,0.26-1.57,0.35-2.36v-0.49c0.02,0.04,0.03,0.07,0.05,0.1c0.06-0.57,0.1-1.15,0.13-1.73
		c-0.05,0.19-0.09,0.37-0.17,0.55V33.09z M14.64,34.99c-0.16,0.16-0.14-0.22-0.12-0.23C14.68,34.59,14.66,34.97,14.64,34.99z
		 M9.3,34.99c-0.16,0.16-0.14-0.22-0.12-0.23C9.34,34.59,9.32,34.97,9.3,34.99z M14.41,35.58h-1.54c0.54,0.05,1.06,0.15,1.54,0.36
		c-0.4,0-0.82,0.02-1.23,0.03c0.43,0.06,0.84,0.16,1.23,0.33c-1.62,0-3.48,0.27-4.99-0.36c0.49,0,1.01-0.02,1.54-0.04
		c-0.41-0.06-0.81-0.16-1.18-0.31H9.42v-1.42h1.9c-0.54-0.05-1.06-0.15-1.54-0.36c1.52,0,3.22-0.24,4.63,0.36V35.58z M46.22,24.43
		c0.16-0.16,0.14,0.22,0.12,0.23C46.18,24.82,46.2,24.45,46.22,24.43z M33.99,9.94c0.24,0.56,0.35,1.15,0.36,1.75V9.23
		c0.19,0.46,0.29,0.99,0.36,1.53V8.88h1.07v0.36c0.19,0.46,0.29,0.99,0.36,1.53V9.23c0.57,1.35,0.57,3.28,0,4.63v-1.53
		c-0.06,0.54-0.16,1.07-0.36,1.53v0.36h-1.07v-1.89c-0.06,0.54-0.16,1.07-0.36,1.53v-2.1c0,0.59-0.12,1.19-0.36,1.75V9.94z
		 M35.54,14.46c-0.02,0.02-0.4,0.04-0.24-0.12C35.32,14.32,35.69,14.3,35.54,14.46z M36.13,60.51v-1.53
		c-0.06,0.54-0.16,1.07-0.36,1.53v0.36h-1.07v-1.89c-0.06,0.54-0.16,1.07-0.36,1.53v-2.04c-0.03,0.58-0.14,1.16-0.36,1.68v-3.92
		c0.22,0.52,0.33,1.1,0.36,1.68v-2.04c0.19,0.46,0.29,0.99,0.36,1.53v-1.89h1.07v0.36c0.19,0.46,0.29,0.99,0.36,1.53v-1.53
		C36.7,57.23,36.7,59.16,36.13,60.51z M34.71,35.58c0.52,0.09,0.62,0.11,1.07,0.36C35.26,35.85,35.15,35.83,34.71,35.58v-1.92
		c-0.05,0.54-0.15,1.07-0.36,1.57v-2.06c-0.04,0.59-0.13,1.16-0.36,1.7V19.2c0.21,0.5,0.31,1.03,0.36,1.57v-1.92
		c0.2,0.49,0.31,1.01,0.36,1.54v-1.9h1.07c-0.52-0.09-0.62-0.11-1.07-0.36c0.52,0.09,0.62,0.11,1.07,0.36
		c0.21,0.5,0.31,1.03,0.36,1.57v-1.57c0.55,1.32,0.36,2.86,0.36,4.27c0,3-0.33,6.23,0.27,9.18c3.02-2.64,5.62-7.07,9.34-8.11
		c-0.52-0.09-0.62-0.11-1.07-0.36c0.52,0.09,0.62,0.11,1.07,0.36c-0.27,1.72-1.29,2.7-2.49,3.92
		C41.15,30.22,38.12,34.63,34.71,35.58z M61.18,34.75c0.16-0.16,0.14,0.22,0.12,0.23C61.14,35.15,61.16,34.77,61.18,34.75z
		 M55.95,34.99c-0.16,0.16-0.14-0.22-0.12-0.23C55.99,34.59,55.97,34.97,55.95,34.99z M56.07,35.94c0.51,0,1.04-0.03,1.57-0.04
		c-0.42-0.06-0.83-0.15-1.22-0.31h-0.36v-1.42h1.9c-0.54-0.05-1.06-0.15-1.54-0.36c1.52,0,3.22-0.24,4.63,0.36v1.42h-1.93
		c0.54,0.05,1.07,0.15,1.57,0.36c-0.32,0-0.64,0.01-0.97,0.02c0.46,0.06,0.91,0.16,1.33,0.34C59.44,36.3,57.57,36.57,56.07,35.94z"
            />
            <path d="M39.69,5.32c0.26,0.14,0.41,0.21,0.58,0.25c0.14,0.02,0.28,0.04,0.41,0.06C40.31,5.43,40.17,5.4,39.69,5.32z"
            />
            <path d="M41.23,5.55c0.16-0.16-0.22-0.14-0.24-0.12C40.84,5.59,41.21,5.57,41.23,5.55z" />
            <path d="M46.22,6.98c0.16-0.16-0.22-0.14-0.24-0.12C45.82,7.02,46.2,7,46.22,6.98z" />
            <path d="M34.71,10.76v1.57C34.76,11.81,34.76,11.28,34.71,10.76z" />
            <path d="M36.13,10.76v1.57C36.19,11.81,36.19,11.28,36.13,10.76z" />
            <path d="M34.35,11.69v0.07C34.35,11.74,34.35,11.71,34.35,11.69z" />
            <path d="M36.84,32.38c-0.03-0.14-0.05-0.29-0.08-0.43c-0.06,0.05-0.11,0.1-0.17,0.15
		C36.67,32.19,36.74,32.29,36.84,32.38z"/>
            <path d="M36.13,20.06v2.7C36.13,21.88,36.21,20.95,36.13,20.06z" />
            <path d="M36.13,22.76v5.7c0,0.87-0.14,1.89,0.06,2.74C36.65,28.57,36.13,25.21,36.13,22.76z" />
            <path d="M34.71,20.39v3.09C34.71,22.48,34.8,21.41,34.71,20.39z" />
            <path d="M34.71,30.96v2.71C34.78,32.77,34.71,31.84,34.71,30.96z" />
            <path d="M34.35,30.96v2.21C34.39,32.44,34.35,31.69,34.35,30.96z" />
            <path d="M34.35,20.77v2.7C34.35,22.59,34.42,21.67,34.35,20.77z" />
            <path d="M64.5,28.22c0.02-0.02,0.04-0.4-0.12-0.24C64.36,28.01,64.34,28.38,64.5,28.22z" />
            <path d="M6.1,28.58c0.02-0.02,0.04-0.4-0.12-0.23C5.96,28.36,5.94,28.74,6.1,28.58z" />
            <path d="M64.5,28.94c0.02-0.02,0.04-0.4-0.12-0.23C64.36,28.72,64.34,29.1,64.5,28.94z" />
            <path d="M64.62,29.17v1.07C64.87,29.69,64.87,29.73,64.62,29.17z" />
            <path d="M5.74,30.36c0.02-0.02,0.04-0.4-0.12-0.23C5.6,30.14,5.58,30.52,5.74,30.36z" />
            <path d="M64.86,30.72c0.02-0.02,0.04-0.4-0.12-0.23C64.72,30.5,64.7,30.88,64.86,30.72z" />
            <path d="M5.74,31.07c0.02-0.02,0.04-0.4-0.12-0.23C5.6,30.86,5.58,31.23,5.74,31.07z" />
            <path d="M64.97,30.96v2.14C65.31,32.28,65.31,31.77,64.97,30.96z" />
            <path d="M5.15,32.38v1.78C5.44,33.44,5.44,33.1,5.15,32.38z" />
            <path d="M64.81,33.71c0.03,0.8,0.02,1.59-0.02,2.39c0.11-0.39,0.18-0.79,0.19-1.19v-0.07
		C64.97,34.46,64.91,34.08,64.81,33.71z"/>
            <path d="M64.97,33.09v1.75c0,0.02,0,0.04,0,0.07v1.75C65.45,35.51,65.45,34.23,64.97,33.09z" />
            <path d="M5.15,34.16v1.42C5.4,34.96,5.4,34.79,5.15,34.16z" />
            <path d="M14.41,34.16h-3.09C12.34,34.26,13.41,34.16,14.41,34.16z" />
            <path d="M61.06,34.16h-3.09C58.99,34.26,60.06,34.16,61.06,34.16z" />
            <path d="M5.15,35.58v1.78C5.44,36.64,5.44,36.31,5.15,35.58z" />
            <path d="M9.78,35.58h3.09C11.84,35.49,10.77,35.58,9.78,35.58z" />
            <path d="M56.43,35.58h2.7C58.24,35.51,57.31,35.58,56.43,35.58z" />
            <path d="M10.96,35.9c0.72,0.11,1.47,0.09,2.21,0.07C12.46,35.87,11.7,35.87,10.96,35.9z" />
            <path d="M59.73,35.96c-0.68-0.09-1.38-0.09-2.08-0.06C58.33,35.99,59.03,35.98,59.73,35.96z" />
            <path d="M64.97,38.08c0.25-0.63,0.25-0.8,0-1.42V38.08z" />
            <path d="M5.5,37.36v1.42C5.76,38.16,5.76,37.99,5.5,37.36z" />
            <path d="M64.62,38.79c0.23-0.51,0.24-0.54,0.05-0.96c-0.01,0.13-0.03,0.26-0.05,0.39V38.79z" />
            <path d="M5.62,39.03c-0.02,0.02-0.04,0.4,0.12,0.23C5.76,39.25,5.78,38.87,5.62,39.03z" />
            <path d="M64.74,39.03c-0.02,0.02-0.04,0.4,0.12,0.23C64.88,39.25,64.89,38.87,64.74,39.03z" />
            <path d="M64.62,40.57c0.25-0.55,0.25-0.52,0-1.07V40.57z" />
            <path d="M64.26,41.64c0.25-0.55,0.25-0.52,0-1.06c0,0,0,0.01,0,0.01V41.64z" />
            <path d="M64.38,41.88c-0.02,0.02-0.04,0.4,0.12,0.23C64.52,42.09,64.54,41.72,64.38,41.88z" />
            <path d="M6.69,44.01c-0.02,0.02-0.04,0.4,0.12,0.23C6.83,44.23,6.85,43.85,6.69,44.01z" />
            <path d="M7.05,45.08c-0.02,0.02-0.04,0.4,0.12,0.24C7.19,45.3,7.21,44.92,7.05,45.08z" />
            <path d="M34.71,57.41v1.57C34.76,58.46,34.76,57.93,34.71,57.41z" />
            <path d="M36.13,57.41v1.57C36.19,58.46,36.19,57.93,36.13,57.41z" />
            <path d="M34.35,57.92v0.55C34.36,58.29,34.36,58.11,34.35,57.92z" />
            <path d="M29.24,64.19c-0.16,0.16,0.22,0.14,0.24,0.12C29.64,64.15,29.26,64.17,29.24,64.19z" />
            <path d="M30.79,64.43c-0.33-0.18-0.47-0.24-0.73-0.29c-0.11-0.02-0.22-0.04-0.32-0.06
		C30.16,64.31,30.28,64.34,30.79,64.43z"/>
            <path d="M40.76,64.43c-0.22-0.12-0.36-0.19-0.5-0.23c-0.08,0.01-0.17,0.02-0.25,0.04
		C40.23,64.33,40.39,64.37,40.76,64.43z"/>
            <path d="M41,64.19c-0.16,0.16,0.22,0.14,0.24,0.12C41.39,64.15,41.02,64.17,41,64.19z" />
            <path d="M31.03,64.55c-0.16,0.16,0.22,0.14,0.24,0.12C31.42,64.51,31.05,64.53,31.03,64.55z" />
            <path d="M31.74,64.55c-0.16,0.16,0.22,0.14,0.24,0.12C32.13,64.51,31.76,64.53,31.74,64.55z" />
            <path d="M32.28,64.43c-0.02,0-0.05,0-0.07,0c1.77,0.74,4.14,0.36,6.05,0.36c-0.31-0.13-0.64-0.21-0.98-0.28
		C35.63,64.6,33.95,64.58,32.28,64.43z"/>
            <path d="M38.5,64.55c-0.16,0.16,0.22,0.14,0.24,0.12C38.9,64.51,38.52,64.53,38.5,64.55z" />
            <path d="M39.22,64.55c-0.16,0.16,0.22,0.14,0.24,0.12C39.61,64.51,39.24,64.53,39.22,64.55z" />
        </g>
    </svg>
)

export default clockIcon;