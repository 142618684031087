/**
 * Based on given defaults make it clean to prepare storeData
 * returns storeData object
 * 
 * @param {object} defaults in 'YYYY-MM-DD' format
 * @returns {object} storeData
 */
function prepareStoreData(defaults) {
    const storeData = { ...defaults };

    const cleanDefault = JSON.parse(JSON.stringify(defaults));
    for (const key of Object.keys(cleanDefault)) {
        storeData[key] = cleanDefault[key]
    }
    return storeData;
}

export default prepareStoreData;