
/**
 * 
 * Validation function
 * @example validateProfileWizard('name', profileValidation, layoutStore.profileWizard.errors, mainStore.temporaryProfile)
 * @param {string} fieldName 
 * @param {object} validationDefinition - object with validation messages
 * @param {object} validationErrorsObject - target object to store validation state
 * @param {object} profileObject - store object
 */
function validate(fieldName, validationDefinition, validationErrorsObject, profileObject) {
    if (validationDefinition && validationErrorsObject && profileObject && fieldName) {
        const fieldPath = fieldName.split('.');
        let obj = profileObject;
        for (let i = 0; i < fieldPath.length; i++) {
            if (obj[fieldPath[i]] === undefined) {
                obj = undefined;
                break;
            }
            obj = obj[fieldPath[i]];
        }
        const isValidMessage = validationDefinition[fieldName](obj)
        validationErrorsObject[fieldName] = isValidMessage
        return !!isValidMessage
    }
}

export default validate