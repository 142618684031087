/**
 * Test US zip code
 * @param {number} value 
 * @param {object} validationErrorObject mainstore error object layoutStore.profileWizard.errors
 */
function testZipCode(value, validationErrorObject) {
    const zipRegexPattern = /(^[0-9]{5}(-[0-9]{4})?$)/

    const isZipCorrect = zipRegexPattern.test(value);
    if (!isZipCorrect) {
        validationErrorObject.zip = 'Invalid zip-code'
    }

    return isZipCorrect
}

export default testZipCode