
/**
 * 
 * @param {*} fieldName 
 * @param {*} value 
 * @param {*} errorText 
 * @param {*} allowValue 
 * @param {*} validationErrorObject 
 */
function validateRequiredField({
    fieldName,
    value,
    errorText,
    allowValue,
    validationErrorObject
}) {
    if (errorText === undefined) {
        errorText = '* Field is required'
    }
    if (allowValue === undefined) {
        allowValue = []
    }
    if (fieldName === undefined) {
        return console.error('Missing fieldName')
    }
    let isValid = true;

    if (!value && !allowValue.includes(value)) {
        isValid = false;
        validationErrorObject[fieldName] = errorText;
    } else {
        validationErrorObject[fieldName] = undefined;
    }
    return isValid
}

export default validateRequiredField