import React from 'react'
import { view } from '@risingstack/react-easy-state';
import './Icon.scss';

import addIcon from './Svg/Add.js';
import addPenIcon from './Svg/AddPen.js';
import addPenIconCircle from './Svg/AddPenCircle.js';
import arrowHeadIcon from './Svg/Arrowhead.js';
import arrowHeadLeftIcon from './Svg/ArrowHeadLeft.js';
import arrowHeadTopIcon from './Svg/ArrowHeadTop.js';
import arrowHeadBottomIcon from './Svg/ArrowHeadBottom.js';
import calendarIcon from './Svg/Calendar.js';
import checkboxSelected from './Svg/CheckboxSelected.js';
import checkboxEmpty from './Svg/CheckboxEmpty.js';
import closeIcon from './Svg/Close.js';
import clockIcon from './Svg/Clock.js';
import closeCircleIcon from './Svg/CloseCircle.js';
import compareIcon from './Svg/Compare.js';
import deleteIcon from './Svg/Delete.js';
import editIcon from './Svg/Edit.js';
import facebookIcon from './Svg/Facebook.js';
import foodLogIcon from './Svg/FoodLog.js';
import helpIcon from './Svg/Help.js';
import homeIcon from './Svg/Home.js';
import homeSecondIcon from './Svg/HomeSecond.js';
import infoIcon from './Svg/Info.js';
import instagramIcon from './Svg/Instagram.js';
import linkedinIcon from './Svg/LinkedIn.js';
import mealIcon from './Svg/Meal.js';
import medalIcon from './Svg/Medal.js';
import messengerIcon from './Svg/Messenger.js';
import myMenuIcon from './Svg/MyMenu.js';
import nutriStyleNIcon from './Svg/NutristyleN.js';
import nutriStyleLeafIcon from './Svg/NutristyleLeaf.js';
import openIcon from './Svg/Open.js';
import passwordIcon from './Svg/Password.js';
import percentIcon from './Svg/Percent.js';
import pinterestIcon from './Svg/Pinterest.js';
import preparationIcon from './Svg/Preparation.js';
import printIcon from './Svg/Print.js';
import recipeIcon from './Svg/Recipe.js';
import removeIcon from './Svg/Remove.js';
import replaceIcon from './Svg/Replace.js';
import RTEIcon from './Svg/RTEIcon.js';
import runningIcon from './Svg/Running.js';
import savedIcon from './Svg/Saved.js';
import searchIcon from './Svg/Search.js';
import settingsIcon from './Svg/Settings.js';
import shareIcon from './Svg/Share.js';
import shareOutlineIcon from './Svg/ShareOutline.js';
import shoppingIcon from './Svg/Shopping.js';
import skypeIcon from './Svg/Skype.js';
import sofaIcon from './Svg/Sofa.js';
import tickIcon from './Svg/Tick.js';
import twitterIcon from './Svg/Twitter.js';
import userIcon from './Svg/User.js';
import walkingIcon from './Svg/Walking.js';
import wellnessIcon from './Svg/Wellness.js';
import whatsappIcon from './Svg/Whatsapp.js';
import youtubeIcon from './Svg/YouTube.js';

export const icons = {
  add: addIcon,
  addPen: addPenIcon,
  addPenCircle: addPenIconCircle,
  arrowHeadTop: arrowHeadTopIcon,
  arrowHeadBottom: arrowHeadBottomIcon,
  arrowHead: arrowHeadIcon,
  arrowHeadLeft: arrowHeadLeftIcon,
  calendar: calendarIcon,
  checkboxSelected: checkboxSelected,
  checkboxEmpty: checkboxEmpty,
  clock: clockIcon,
  close: closeIcon,
  closeCircle: closeCircleIcon,
  compare: compareIcon,
  delete: deleteIcon,
  edit: editIcon,
  facebook: facebookIcon,
  foodLog: foodLogIcon,
  help: helpIcon,
  home: homeIcon,
  homeSecond: homeSecondIcon,
  info: infoIcon,
  instagram: instagramIcon,
  linkedin: linkedinIcon,
  medal: medalIcon,
  meal: mealIcon,
  messenger: messengerIcon,
  myMenu: myMenuIcon,
  nutriStyleN: nutriStyleNIcon,
  nutriStyleLeaf: nutriStyleLeafIcon,
  open: openIcon,
  password: passwordIcon,
  percent: percentIcon,
  pinterest: pinterestIcon,
  preparation: preparationIcon,
  print: printIcon,
  recipe: recipeIcon,
  remove: removeIcon,
  replace: replaceIcon,
  running: runningIcon,
  saved: savedIcon,
  search: searchIcon,
  settings: settingsIcon,
  share: shareIcon,
  shareOutline: shareOutlineIcon,
  shopping: shoppingIcon,
  skype: skypeIcon,
  sofa: sofaIcon,
  tick: tickIcon,
  twitter: twitterIcon,
  user: userIcon,
  walking: walkingIcon,
  wellness: wellnessIcon,
  whatsapp: whatsappIcon,
  youtube: youtubeIcon,
  rteIcon: RTEIcon
}

export default view((props) => {
  let icon = false;
  let iconCN = props.fw ? ' fw' : '';
  let iconContents = typeof(props.icon)==='string' ? icons[props.icon] : props.icon;
  if(iconContents === undefined) return null;
  iconContents = iconContents();
  
  let element = false;
  if(props.onContentClick){
    element = <span className="clickable" onClick={props.onContentClick}>{props.children}</span>
  } else {
    element = <span>{props.children}</span>
  }
  if(props.onIconClick){
    icon = <i className={"icon clickable"+iconCN} style={ { fill: props.color } } onClick={props.onIconClick} >{iconContents}</i>
  } else {
    if(props.onContentClick){
      icon = <i className={"icon clickable"+iconCN} style={ { fill: props.color } } onClick={props.onContentClick} >{iconContents}</i>
    } else {
      icon = <i className={"icon"+iconCN} style={ { fill: props.color } } >{iconContents}</i>
    }
  }
  if(props.className){
    if(props.onClick){
      return (
        <span className={props.className} onClick={props.onClick} onContextMenu={props.onContextMenu}>
          {icon}{element}
        </span>
      ) 
    }
    return (
      <span className={props.className} onContextMenu={props.onContextMenu}>
        {icon}{element}
      </span>
    ) 
  }    
  if(props.onClick){
    return (
      <span onClick={props.onClick} onContextMenu={props.onContextMenu}>
        {icon}{element}
      </span>
    ) 
  }
  return (
    <span onContextMenu={props.onContextMenu}>
      {icon}{element}
    </span>
  ) 
})
