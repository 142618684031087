import React from 'react';

const addPenIcon = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 70 70" >
    <g><g id="White"><g><path d="M65.5,23l-6-6c-1.5-1.5-3.9-1.5-5.3,0l-5.1,5.1l11.3,11.3l5.1-5.1C67,26.9,67,24.5,65.5,23z"></path><path d="M16.6,54.6C16.6,54.6,16.6,54.6,16.6,54.6L16.6,54.6C16.6,54.7,16.5,54.7,16.6,54.6L16.5,66h11.4v0
 c0,0,0,0,0.1,0l29.2-29.2L45.8,25.4L16.6,54.6z"></path></g></g><polygon points="25.4,15.6 25.4,4.1 15.2,4.1 15.2,15.6 4.2,15.6 4.2,25.4 15.2,25.4 15.2,37.1 25.4,37.1 25.4,25.4 
 36.4,25.4 36.4,15.6 	"></polygon></g>
  </svg>
)

export default addPenIcon;